import './Search.css'
import { FiSearch } from "react-icons/fi";
import { useState } from "react";

export default function Search({ setActiveEnter, id }) {
  const [searchText, setSearchText] = useState("");
  return (
    <div className="search">
      <FiSearch className="searchIcon" style={{ cursor: "pointer" }} />
      <input
        className={`searchText${id}`}
        type="text"
        name={`searchText${id}`}
        id={`searchText${id}`}
        placeholder="Buscar"
        onChange={(evt) => {
          //console.log(evt.target.value)
          setSearchText(evt.target.value);
          if (evt.target.value.length === 0) {
            setActiveEnter(" ");
          }
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            setActiveEnter(searchText);
          }
        }}
        value={searchText}
      />
    </div>
  );
};
