import React, { useContext } from "react"
import { LoadingContext } from "../../contexts/loading.context"


import './loadingScreen.css'

const LoadingScreen = props => {

    const [showLoading] = useContext(LoadingContext)

    return (
        <div>
            {showLoading &&
                <div className="loadingDiv">
                    <div className="loadingio-spinner-spinner-i7ozunbmji">
                        <div className="ldio-3b1ir8li0qi">
                            <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                        </div>
                    </div>
                </div>
            }
        </div>

    )
}

export default LoadingScreen
