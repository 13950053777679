export const proformasStatus = [
    {
        value: 0, 
        label: "Seleccionar",
        color: "#CCCCCC" 
    },
    { 
        value: "pending", 
        label: "Pendiente", 
        color: "#27A36E" 
    },
    { 
        value: "paid", 
        label: "Pagada", 
        color: "#FFFF00" 
    }
]

export const proformasStatusValues = {
    pending: "Pendiente",
    paid: "Pagada"
}