import React from "react"
import "./proformaReviewModal.css"
import kakaoFrame125 from "../../assets/kakaoFrame125.png"
import CloseIcon from "../../assets/close.svg"

const ProformaReviewModal = ({ handleCloseModal, proformaInfo }) => {

    const handleCopyText = () => {
        navigator.clipboard.writeText(`https://pagos.kakaomarket.cr/proformas/${proformaInfo.proformaExternalId}`)
    }

    return (
        <div className="modal-container">
            <img className="modal-close-icon" src={CloseIcon} onClick={() => handleCloseModal(false)} />
            <div>
                <span className="main-title" style={{ textTransform: "uppercase" }}>{`¡Proforma #${proformaInfo.proformaId} Creada!`}</span>
            </div>
            <div className="button-container" style={{ alignItems: "center" }}>
                <span className="info-input proforma-link-text">
                    <span>{`https://pagos.kakaomarket.cr/proformas/${proformaInfo.proformaExternalId}`}</span>
                </span>
                <div className="main-action-button" onClick={() => handleCopyText()}>
                    <span>Copiar enlace</span>
                </div>
            </div>
            <div className="modal-image-container">
                <img src={kakaoFrame125} alt="Kakao ilustración" />
            </div>
        </div>
    )
}

export default ProformaReviewModal
