import React from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles({
  modal: {
    position: "fixed",
    zIndex: 5,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    overflow: "auto",
    backgroundColor: "rgba(33, 82, 115, 0.3)",
    display: "flex"
  },
  content: {
    position: "relative",
    margin: "auto",
    width: props => props.width,
    // animation-name: animatetop,
    // animation-duration: 0.4s,
    borderRadius: 10,
    display: "flex",
    flexDirection: "column"
  }
})

const ModalContainer = ({ active, handleClose, children, width = "80%", style = null, clickOutside = true }) => {
  const { modal, content } = useStyles({ width })

  return (
    <div>
      {active && <div className={modal} onClick={(clickOutside && handleClose) || undefined}>
        <div className={content} style={style || {}} onClick={(evt) => { evt.stopPropagation() }} >
          {children}
        </div>
      </div>}
    </div>
  )
}

export default ModalContainer
