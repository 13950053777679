import React, { useState, useEffect, useContext } from "react"
import { Switch, Route, useHistory, useRouteMatch, useLocation } from "react-router-dom"
import { ProformaList, ProformaNew, ProformaReview } from "../index"
import { UserContext } from "../../contexts/user.context"

const Proformas = (props) => {
  let match = useRouteMatch()
  let history = useHistory()

  const [user, setUser] = useContext(UserContext)

  useEffect(() => {
    if (!user.permissions.includes("kakao-seller-console/admin/proformas.POST")) {
      history.push("/home")
    }
  }, [])

  return (
    <Switch>
      <Route exact path={match.path} component={ProformaList} />
      <Route path={`${match.path}/new`} component={ProformaNew} />
      <Route path={`${match.path}/review`} component={ProformaReview} />
    </Switch>
  )
}

export default Proformas
