import './App.css'
import { Switch, Route, BrowserRouter as Router } from "react-router-dom"
import { Login, Home, ResetPassword } from "./containers"
import { UserProvider } from "./contexts/user.context"
import { LoadingProvider } from "./contexts/loading.context"
import { LoadingScreen } from "./components"

function App() {
  return (
    <div className="App">
      <UserProvider>
        <LoadingProvider>
          <LoadingScreen />
          <Router>
            <Switch>
              <Route exact path='/' component={Login} />
              <Route path='/home' component={Home} />
              <Route path='/resetPassword' component={ResetPassword} />
            </Switch>
          </Router>
        </LoadingProvider>
      </UserProvider>
      
    </div>
  )
}

export default App