import React from "react"
import { useMediaQuery } from "react-responsive";
import Arrow from "../../assets/tabs-arrow.svg"


const TabButtons = ({ buttons, changeTab, activeTab }) => {
    const isMobile = useMediaQuery({ query: "(max-width: 480px)" });


    return (
        <div className="tab-buttons">
            {buttons.map(button => {
                let btnActive = (button === activeTab && !isMobile) ? "active" : "active bgActive"                
                return (
                    <button 
                        key={button} className={(button === activeTab) ? btnActive : ''} 
                        onClick={() => changeTab(button)}
                    >
                        {button}
                        <div style={{ display: (button === activeTab && !isMobile) ? 'block' : 'none', position: "relative" }}>
                            <img src={Arrow} alt="" />
                        </div>
                    </button>
                )
            })}
        </div>
    )
}
export default TabButtons