import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/es";
import { createUseStyles } from "react-jss";
import { COLORS } from "../../constants/styles";
import { Row, Col } from "react-flexbox-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import chroma from "chroma-js";
import Send from "../../assets/paperPlane.svg.svg";
import { useMediaQueryHooks } from "../../hooks/mediaQuery";
import { UserContext } from "../../contexts/user.context"
import { LoadingContext } from "../../contexts/loading.context";
import Swal from "sweetalert2";

import { useMediaQuery } from "react-responsive";

import axios from "axios";

const useStyles = createUseStyles({
  modulePanel: {
    backgroundColor: COLORS.white,
    //boxShadow: "1px 1px 5px rgba(43, 43, 43, 0.2)",
    borderRadius: 10,
    padding: "48px 36px 16px 32px",
    position: "relative",
  },
  containerModal: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    height: "80vh",
    overflow: "auto",
  },
  orderName: {
    fontSize: 28,
    color: "#37474F",
  },
  containerText: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: " #C8CBCC",
    padding: "10px",
    display: "flex",
    flexDirection: "column",
  },
  styleTextArea: {
    minHeight: "40px",
    height: "40px",
    color: "#6C7980",
    fontSize: 18,
    width: "98%",
    fontWeight: "normal",
    outline: "none",
  },
  admin: {
    fontSize: 14,
    color: "#6C7980",
    fontWeight: 700,
    textAlign: "right",
    marginTop: "1%",
  },

  bodyText: {
    fontSize: 14,
    color: "#37474F",
    fontWeight: "bold",
  },

  boxText: {
    color: "#6C797F",
    fontSize: 18,
    fontWeight: "normal",
    lineHeight: "140%",
    wordBreak: "break-word",
  },
  tableText: {
    color: "#6C797F",
    fontSize: 16,
    fontWeight: "normal",
  },
  shippingContainer: {
    display: "flex",
    marginTop: "1%",
    flexDirection: "column",
    width: "100%",
    borderBottom: "1px solid #C8CBCC",
  },
  shippingRowData: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: "1%",
    marginRight: "1%",
    marginTop: "1%",
    marginBottom: "1%",
  },
  shippingText: {
    fontWeight: "500",
    style: "normal",
    size: "16px",
    lineHeight: "22px",
  },
  totalText: {
    fontWeight: "700",
    style: "normal",
    size: "16px",
    lineHeight: "22px",
  },
  tableRows: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    color: "#37474F",
    fontSize: "15px",
    borderBottom: "1px solid #C8CBCC",
  },

  tableRow: {
    display: "flex",
    width: "100%",
    marginTop: "1.75%",
    marginBottom: "1.75%",
  },
  closeIcon: {},
  containerMobile: {},
  dataInfo: {
    marginTop: 24,
    color: "#37474F",
  },
  titleInfo: {
    color: "#37474F",
    textTransform: "uppercase",
    fontSize: 14,
    fontWeight: 700,
  },
  subTitleInfo: {
    fontSize: 14,
  },
  productContainer: {
    marginTop: 16,
  },
  spaceLine: {
    margin: "10px 0",
  },
  headerItem: {
    display: "flex",
    fontSize: 14,
    justifyContent: "space-between",
    margin: "0 5px",
  },
  containerBtn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  answerContainer: {
    width: "98.25%",
    paddingBottom: "0px",
    flexDirection: "row",
  },
  answerTextArea: {
    minHeight: "40px",
    height: "40px",
    border: "none",
    color: "#6C7980",
    fontSize: 18,
    fontWeight: "normal",
    flex: "25",
    resize: "none",
  },

  answerBtnContainer: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    flex: "1",
  },

  answerBtnSend: {
    backgroundColor: "white",
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    marginRight: "1px",
  },

  "@media (max-width: 480px)": {
    modulePanel: {
      padding: "15px 15px 15px 15px",
    },
    orderName: {
      fontSize: 18,
      width: 220,
    },
    closeIcon: {
      position: "absolute",
      top: 37,
      right: 19,
    },
    answerTextArea: {
      fontSize: 14,
    },
    styleTextArea: {
      width: "90%",
      height: "90px",
    },
    answerContainer: {
      width: "90%",
    },
  },
});

const handleOnOrderStatusChange = ({ value }, id) => {
  Swal.fire({
    title: "¿Está seguro?",
    text: "¡Está cambiando el estado de una orden!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "¡Sí, cambiarla!",
  }).then((result) => {
    if (result.isConfirmed) {
      axios({
        method: "POST",
        url: "https://kakaomarket.cr/wp-json/sellerconsole/v1/updateorderstatus",
        data: {
          orderId: id,
          status: value,
        },
      }).then((response) => {
        if (response.status === 200) {
          Swal.fire(
            "¡Cambiado!",
            "Se ha cambiado el estado correctamente.",
            "success"
          );
        }
      });
    }
  });
};

const handleColorInventary = (_stock_status) => {
  // let _stock = parseInt(stock);
  //{ name: `En existencia (${_stock >= 0 ? _stock : null})`
  return _stock_status === "instock"
    ? { name: `En existencia`, color: "#27A36E" }
    : _stock_status === "outofstock"
    ? { name: "Bajo inventario", color: "#FF6D00" }
    : { name: "Se puede reservar", color: "#FF6D00" };
};

const ModulePanelPopup = ({ handleClose, order }) => {
  const isPcWindow = useMediaQueryHooks("(min-width: 767px)");

  const dot = (color = "#ccc") => ({
    alignItems: "center",
    display: "flex",

    ":before": {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: "block",
      marginRight: 8,
      height: 10,
      width: 10,
    },
  });
  const classes = useStyles();
  const colourStyles = {
    container: (styles) => ({ ...styles, flex: 1 }),
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? data.color
          : isFocused
          ? color.alpha(0.1).css()
          : null,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? chroma.contrast(color, "white") > 2
            ? "white"
            : "black"
          : data.color,
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor:
            !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
        },
      };
    },
    input: (styles) => ({ ...styles, ...dot() }),
    placeholder: (styles) => ({ ...styles, ...dot() }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
  };
  const orderTypes = [
    { value: "completed", label: "COMPLETADO", color: "#27A36E" },
    { value: "pending", label: "PENDIENTE", color: "#FFFF00" },
    { value: "on-hold", label: "EN ESPERA", color: "#6C7980" },
    { value: "failed", label: "FALLO", color: "#FF6D00" },
    //{ value: "cancelled", label: "CANCELADO", color: "#FF5A5F" },
    { value: "refunded", label: "DEVUELTO", color: "#7D3C98" },
    { value: "processing", label: "PROCESANDO", color: "#00B8D4" },
    //{ value: "trash", label: "ELIMINADA", color: "#FF5A5F"}
  ];

  const [orderProducts, setOrderProducts] = useState([]);
  const [shippingMethod, setShippingMethod] = useState("");
  const [shippingTotal, setShippingTotal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [user] = useContext(UserContext)

  const isMobile = useMediaQuery({ maxWidth: 480 });
  const isMobileOrTablet = useMediaQuery({ maxWidth: 1024 });
  console.log("isMobile", isMobile);

  const addDataToComponent = (data) => {
    const orderItems = [];
    if (Object.keys(data).length > 0) {
      setShippingMethod(data.shippingMethod);
      setShippingTotal(data.shippingTotal);
      setSubTotal(data.subtotal);

      data.itemsData.forEach((item) => {
        let itemInfo = {
          id: item.product_id,
          description: item.product_name,
          quantity: item.quantity,
          price: item.total,
          inStock: item.stock_status,
        };
        orderItems.push(itemInfo);
      });
      setOrderProducts(orderItems);
    }
  };

  const orderInfo = (id) => {
    setShowLoading(true);
    return new Promise((resolve) => {
      axios({
        method: "POST",
        url: "https://kakaomarket.cr/wp-json/sellerconsole/v1/getorderinfo",
        data: {
          orderId: id,
        },
      })
        .then((response) => {
          // console.log("orders km", JSON.parse(response.data));
          let data = JSON.parse(response.data).result;
          if (response.status === 200) {
            console.log("data:", data);
            addDataToComponent(data);
          }
        })
        .finally(() => {
          setShowLoading(false);
        });
    });
  };

  const setShowLoading = useContext(LoadingContext)[1];

  const getFirstRow = (id, status, paymentState) => {
    console.log("paymentState: ", paymentState);
    if (paymentState === "aproved") {
      if (
        status === "completed" ||
        status === "cancelled" ||
        status === "trash"
      ) {
        return null;
      }
      return (
        <Col>
          <div
            style={{ marginTop: "5px", marginBottom: isPcWindow ? "" : "15px" }}
          >
            <h2 className={classes.bodyText}>Estado</h2>
            <Select
              label={"Seleccionar"}
              defaultValue={orderTypes.find(({ value }) => value === status)}
              options={orderTypes}
              onChange={(value) => {
                handleOnOrderStatusChange(value, id);
                console.log("Se cambia a ", value);
              }}
              styles={colourStyles}
            />
          </div>
        </Col>
      );
    } else if (paymentState === "pending") {
      return (
        <Col>
          <div
            style={{
              marginTop: "28px",
              marginBottom: isPcWindow ? "" : "15px",
            }}
          >
            <Button
              classname="primary-lg"
              onClick={() => {
                processPayment(order);
              }}
              isMobile={isMobile}
            >
              ACEPTAR
            </Button>
          </div>
        </Col>
      );
    } else {
      return null;
    }
  };

  const getSecondRow = (id, status, paymentState) => {
    if (paymentState === "aproved") {
      if (
        status === "completed" ||
        status === "cancelled" ||
        status === "trash"
      ) {
        return null;
      }
      return (
        <Col>
          <div
            onClick={() => {}}
            className="row-data"
            style={{ flexBasis: "7%", cursor: "pointer" }}
          >
            <svg
              width="11"
              height="13"
              viewBox="0 0 11 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 3H2H10"
                stroke="#37474F"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3.5 3V2C3.5 1.73478 3.60536 1.48043 3.79289 1.29289C3.98043 1.10536 4.23478 1 4.5 1H6.5C6.76522 1 7.01957 1.10536 7.20711 1.29289C7.39464 1.48043 7.5 1.73478 7.5 2V3M9 3V10C9 10.2652 8.89464 10.5196 8.70711 10.7071C8.51957 10.8946 8.26522 11 8 11H3C2.73478 11 2.48043 10.8946 2.29289 10.7071C2.10536 10.5196 2 10.2652 2 10V3H9Z"
                stroke="#37474F"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4.5 5.5V8.5"
                stroke="#37474F"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.50024 5.5V8.5"
                stroke="#37474F"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span
              style={{
                fontSize: 14,
                color: "#37474F",
                fontWeight: "bold",
                marginLeft: "2%",
              }}
            >
              CANCELAR ORDEN
            </span>
          </div>
        </Col>
      );
    } else if (paymentState === "pending") {
      return (
        <Col>
          <div
            onClick={() => {
              rejectOrder(order.id);
            }}
            className="row-data"
            style={{ flexBasis: "7%", cursor: "pointer" }}
          >
            <svg
              width="11"
              height="13"
              viewBox="0 0 11 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 3H2H10"
                stroke="#37474F"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3.5 3V2C3.5 1.73478 3.60536 1.48043 3.79289 1.29289C3.98043 1.10536 4.23478 1 4.5 1H6.5C6.76522 1 7.01957 1.10536 7.20711 1.29289C7.39464 1.48043 7.5 1.73478 7.5 2V3M9 3V10C9 10.2652 8.89464 10.5196 8.70711 10.7071C8.51957 10.8946 8.26522 11 8 11H3C2.73478 11 2.48043 10.8946 2.29289 10.7071C2.10536 10.5196 2 10.2652 2 10V3H9Z"
                stroke="#37474F"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4.5 5.5V8.5"
                stroke="#37474F"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.50024 5.5V8.5"
                stroke="#37474F"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span
              style={{
                fontSize: 14,
                color: "#37474F",
                fontWeight: "bold",
                marginTop: "4px",
                marginLeft: "8px",
              }}
            >
              RECHAZAR
            </span>
          </div>
        </Col>
      );
    } else {
      return <Col />;
    }
  };

  const getCardInfo = (orderId) => {
    return new Promise((resolve) => {
      axios({
        method: "POST",
        url: "https://kakaomarket.cr/wp-json/misc/v1/getcardinfo",
        data: {
          order_id: orderId,
        },
      }).then((response) => {
        if (response.status === 200) {
          resolve(JSON.parse(response.data));
        }
      });
    });
  };

  const processPayment = async (order) => {
    setShowLoading(true);
    let card
    if (parseInt(order.total) > 0) {
      card = await getCardInfo(order.id);
      if (card.result.length === 0) {
        Swal.fire(
          "¡Error!",
          "Error a la hora de obtener información de la tarjeta",
          "error"
        );
        setShowLoading(false);
        return;
      }
    }

    //consumo puntos banco popular (si no aplica, retorna 200)
    var response = await axios.post( "https://kakaomarket.cr/wp-json/sellerconsole/v1/consumption_bp_points",
      {
        orderId: order.id,
      }
    ).catch((err) => {
      Swal.fire(
        "¡Error!", 
        "Error a la hora de consumir puntos del Banco Popular", 
        "error"
      );
      setShowLoading(false);
      return;
    });
    if(response) {
      response = JSON.parse(response?.data)
      if (response.status !== 200) {
        Swal.fire(
          "¡Error!",
          "Error a la hora de consumir puntos del Banco Popular",
          "error"
        );
        setShowLoading(false);
        return;
      }
    } else {
      Swal.fire(
        "¡Error!",
        "Error a la hora de consumir puntos del Banco Popular",
        "error"
      );
      setShowLoading(false);
      return;
    }
    
    if(parseInt(order.total) > 0){
      let paymentOrder = {
        id: order.id,
        amount: [parseInt(order.total)],
        merchantId: "0e57896b-9e16-42c2-9797-d24dd6303490",
      };
      axios({
        //cobro
        method: "POST",
        url: "https://vhmhsg38k7.execute-api.us-east-1.amazonaws.com/bcr-payment/process_payment",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          order: paymentOrder, // [ID:"", AMOUNT:""]
          cardInfo: card.result, //resp del endpoint
          vendorId: Number(user.vendorId)
        },
      }).then((response) => {
        if (response.data.statusCode === 200) {
          //exito en cobro
          let idForStateApproved = (parseInt(order.id) + 1).toString();
          if(response.data.statusInsert && response.data.statusInsert!=200){
            Swal.fire(
              "¡Atencion!",
              response.data.responseText,
              "warning"
            );
          }
          axios({
            //cambio de paymentState
            method: "POST",
            url: "https://kakaomarket.cr/wp-json/misc/v1/setaproved",
            data: {
              order_id: idForStateApproved,
              transaction_id: response.data.transactionNumber,
            },
          }).then((response) => {
            if (response.status === 200) {
              //exito en cambio de paymentState
  
              axios({
                //cambio de status
                method: "POST",
                url: "https://kakaomarket.cr/wp-json/sellerconsole/v1/updateorderstatus",
                data: {
                  orderId: order.id,
                  status: {
                    value: "processing",
                    label: "PROCESANDO",
                    color: "#00B8D4",
                  }, //pasar value processing
                },
              }).then((response) => {
                if (response.status === 200) {
                  //exito en cambio de status
                  setShowLoading(false);
                  Swal.fire(
                    "¡Éxito!",
                    "Se ha realizado el cobro correctamente.",
                    "success"
                  );
                }
              });
            }
          });
        } else {
          //fallo en el cobro
          let idForStateFailed = (parseInt(order.id) + 1).toString();
          console.log("FOR STATE", idForStateFailed);
          axios({
            //cambio de paymentState
            method: "POST",
            url: "https://kakaomarket.cr/wp-json/misc/v1/setfailed",
            data: {
              order_id: idForStateFailed,
              transaction_id: response.data.transactionNumber,
              responseText: response.data.responseText,
            },
          }).then((response) => {
            if (response.status === 200) {
              //exito en cambio de paymentState
              axios({
                //cambio de status
                method: "POST",
                url: "https://kakaomarket.cr/wp-json/sellerconsole/v1/updateorderstatus",
                data: {
                  orderId: order.id,
                  status: {
                    value: "trash",
                    label: "ELIMINADA",
                    color: "#FF5A5F",
                  }, //pasar value trash
                },
              }).then((response) => {
                if (response.status === 200) {
                  //exito en cambio de status
                  setShowLoading(false);
                  Swal.fire("¡Error!", "Ocurrió un error con el cobro.", "error");
                }
              });
            }
          });
        }
      });
    } else {
      let idForStateApproved = (parseInt(order.id) + 1).toString();

      axios({
        //cambio de paymentState
        method: "POST",
        url: "https://kakaomarket.cr/wp-json/misc/v1/setaproved",
        data: {
          order_id: idForStateApproved,
          transaction_id: "No necesaria",
        },
      }).then((response) => {
        if (response.status === 200) {
          //exito en cambio de paymentState

          axios({
            //cambio de status
            method: "POST",
            url: "https://kakaomarket.cr/wp-json/sellerconsole/v1/updateorderstatus",
            data: {
              orderId: order.id,
              status: {
                value: "processing",
                label: "PROCESANDO",
                color: "#00B8D4",
              }, //pasar value processing
            },
          }).then((response) => {
            if (response.status === 200) {
              //exito en cambio de status
              setShowLoading(false);
              Swal.fire(
                "¡Éxito!",
                "Se procesó correctamente. No se necesitó transaccion bancaria.",
                "success"
              );
            }
          });
        }
      });
    }
  };

  const rejectOrder = (orderId) => {
    console.log("ORDER IS ", orderId);
    setShowLoading(true);
    let idForStateRejected = (parseInt(orderId) + 1).toString();
    console.log("FOR STATE", idForStateRejected);
    axios({
      //cambio de paymentState
      method: "POST",
      url: "https://kakaomarket.cr/wp-json/misc/v1/setrejected",
      data: {
        order_id: idForStateRejected,
      },
    }).then((response) => {
      console.log(response);
      if (response.status === 200) {
        axios({
          method: "POST",
          url: "https://kakaomarket.cr/wp-json/sellerconsole/v1/updateorderstatus",
          data: {
            orderId: orderId,
            status: { value: "trash", label: "ELIMINADA", color: "#FF5A5F" }, //pasar value trash
          },
        }).then((response) => {
          setShowLoading(false);
          Swal.fire("¡Éxito!", "Se ha rechazado la orden.", "success");
        });
      }
    });
  };

  // let dateString = order.date.split("T")[0];

  useEffect(() => {
    orderInfo(order.id);
  }, []);

  return (
    <div className={`${classes.modulePanel} ${classes.containerModal}`}>
      <Row middle="md" style={{ backgroundColor: "white" }}>
        <Col md={10}>
          <h2 className={classes.orderName}>
            ORDEN #{order.id} {moment(order.date).format("D ")} DE{" "}
            {moment(order.date).format("MMMM").toUpperCase()}{" "}
            {moment(order.date).format("YYYY")}
          </h2>
        </Col>
        <Col md={2} className={`${classes.closeIcon}`}>
          <FontAwesomeIcon
            role="button"
            onClick={() => {
              handleClose();
            }}
            style={{ marginLeft: "90%", cursor: "pointer" }}
            icon={faTimes}
          />
        </Col>
      </Row>
      {!isMobile && (
        <>
          <Row
            middle="md"
            style={{ marginTop: "20px", backgroundColor: "white" }}
          >
            <Col
              md={9}
              style={{
                height: isPcWindow ? "100%" : "",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <h2
                className={classes.bodyText}
                style={{ textTransform: "uppercase", marginBottom: "5px" }}
              >
                Datos Cliente
              </h2>
              <div className={classes.containerText} style={{ height: "100%" }}>
                <h2 className={classes.boxText}>
                  <b>{order.customerName}</b>
                </h2>
                <h2 className={classes.boxText}>0-0000-0000</h2>
              </div>
            </Col>
            <Col
              md={3}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: isPcWindow ? "100%" : "",
              }}
            >
              {getFirstRow(order.id, order.status, order.paymentState[0])}
              {getSecondRow(order.id, order.status, order.paymentState[0])}
            </Col>
          </Row>
          <Row
            middle="md"
            style={{ backgroundColor: "white", marginTop: "12px" }}
          >
            <Col
              md={isMobileOrTablet ? 12 : 9}
              style={{
                height: isPcWindow ? "100%" : "",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <h2
                className={classes.bodyText}
                style={{ textTransform: "uppercase", marginBottom: "5px" }}
              >
                Dirección de Envío
              </h2>
              <div className={classes.containerText} style={{ height: "100%" }}>
                <h2 className={classes.boxText}>{order.city}</h2>
                <h2 className={classes.boxText}>{order.company}</h2>
                <h2 className={classes.boxText}>{order.country}</h2>
                <h2 className={classes.boxText}>
                  {order.address1} {order.address2}
                </h2>
                <h2 className={classes.boxText}>
                  <b>Dirección de envío:</b> {" " + shippingMethod}
                </h2>
              </div>
            </Col>
            <Col
              md={isMobileOrTablet ? 12 : 3}
              style={{ marginTop: isMobileOrTablet ? "20px" : "0" }}
            >
              <Row middle="md" style={{ backgroundColor: "white" }}>
                <Col md={12}>
                  <h2
                    className={classes.bodyText}
                    style={{
                      textTransform: "uppercase",
                      marginBottom: "5px",
                    }}
                  >
                    Correo
                  </h2>
                  <div className={classes.containerText}>
                    <h2 className={classes.boxText}>{order.email}</h2>
                  </div>
                </Col>
                <Col
                  md={12}
                  style={{ marginTop: isMobileOrTablet ? "20px" : "35px" }}
                >
                  <h2
                    className={classes.bodyText}
                    style={{
                      textTransform: "uppercase",
                      marginBottom: "5px",
                    }}
                  >
                    Teléfono
                  </h2>
                  <div className={classes.containerText}>
                    <h2 className={classes.boxText}>{`+506 ${order.phone.substr(
                      0,
                      4
                    )}-${order.phone.substr(4, 8)}`}</h2>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* <Row middle="md" style={{ backgroundColor: "white" }}>
        <Col md={9}>
          <h2
            className={classes.bodyText}
            style={{ marginTop: "2%", marginBottom: "0.5%" }}
          >
            Fecha
          </h2>
          <div
            className={classes.containerText}
            style={{
              width: "80%",
            }}
          >
            <h2 className={classes.boxText}>
              {dateString.replace("-", "/").replace("-", "/")}
            </h2>
          </div>
        </Col>
        {getSecondRow(order.id, order.status, order.paymentState[0])}
      </Row> */}
          <Row
            middle="md"
            style={{ backgroundColor: "white", marginTop: "2%" }}
          >
            <Col md={12}>
              <div className={classes.tableRows}>
                <div className="table-container">
                  <div
                    className="table-column"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      className="column-name"
                      style={{
                        flexBasis: "50%",
                        maxWidth: "50%",
                        justifyContent: "left",
                        marginLeft: "1%",
                      }}
                    >
                      <h3 style={{ fontSize: 14, color: "#37474F" }}>
                        PRODUCTO
                      </h3>
                    </div>
                    <div className="column-name">
                      <h3 style={{ fontSize: 14, color: "#37474F" }}>
                        CANTIDAD
                      </h3>
                    </div>
                    <div className="column-name">
                      <h3 style={{ fontSize: 14, color: "#37474F" }}>
                        INVENTARIO
                      </h3>
                    </div>
                    <div
                      className="column-name"
                      style={{ justifyContent: "right", marginRight: "1%" }}
                    >
                      <h3 style={{ fontSize: 14, color: "#37474F" }}>
                        PRECIO (₡)
                      </h3>
                    </div>
                  </div>
                  <div className={classes.tableRows}>
                    {orderProducts.map((product) => {
                      return (
                        <div
                          className={classes.tableRow}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            className="row-data"
                            style={{
                              flexBasis: "50%",
                              maxWidth: "50%",
                              justifyContent: "left",
                              marginLeft: "1%",
                            }}
                          >
                            {"#" + product.id + " " + product.description}
                          </div>
                          <div className="row-data">{product.quantity}</div>
                          <div className="row-data">
                            <svg
                              width="8"
                              height="8"
                              viewBox="0 0 8 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="4"
                                cy="4"
                                r="4"
                                fill={
                                  handleColorInventary(product.inStock).color
                                }
                              />
                            </svg>
                            <span style={{ marginLeft: "8px" }}>
                              {handleColorInventary(product.inStock).name}
                            </span>
                          </div>
                          <div
                            className="row-data"
                            style={{
                              justifyContent: "right",
                              marginRight: "1%",
                            }}
                          >
                            {product.price}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className={classes.shippingContainer}>
                <div className={classes.shippingRowData}>
                  <p className={classes.shippingText}>Subtotal</p>
                  <p className={classes.shippingText}>{subTotal}</p>
                </div>
                <div className={classes.shippingRowData}>
                  <p className={classes.shippingText}>Envío</p>
                  <p className={classes.shippingText}>{shippingTotal}</p>
                </div>
              </div>
              <div className={classes.shippingRowData}>
                <p className={classes.totalText}>TOTAL</p>
                <p className={classes.totalText}>{order.total}</p>
              </div>
            </Col>
          </Row>
          <Row
            middle="md"
            style={{ backgroundColor: "white", marginTop: "2.5%" }}
          >
            <Col md={12}>
              <h2
                className={classes.bodyText}
                style={{ textTransform: "uppercase", marginBottom: "5px" }}
              >
                Comentarios
              </h2>
              <Comment />
            </Col>
          </Row>
          <Row
            middle="md"
            style={{
              backgroundColor: "white",
              alignContent: "center",
              alignItems: "center",
              marginTop: "2%",
              marginLeft: "0px",
              marginRight: "0px",
              width: "100%",
              display: "block",
            }}
          >
            <Answer isMobileOrTablet={isMobileOrTablet} />
          </Row>
        </>
      )}

      {isMobile && (
        <div className={classes.containerMobile}>
          <div className={classes.dataInfo}>
            <h2 className={classes.titleInfo}>Datos cliente</h2>
            <p className={classes.subTitleInfo}>{order.customerName}</p>
            <p className={classes.subTitleInfo}>0-0000-0000</p>
            <p className={classes.subTitleInfo}>{order.email}</p>
            <p className={classes.subTitleInfo}>{`+506 ${order.phone.substr(
              0,
              4
            )}-${order.phone.substr(4, 8)}`}</p>
          </div>
          <div className={classes.dataInfo}>
            <h2 className={classes.titleInfo}>Dirección de envío</h2>
            <p className={classes.subTitleInfo}>{order.city}</p>
            <p className={classes.subTitleInfo}>{order.company}</p>
            <p className={classes.subTitleInfo}>{order.country}</p>
            <p className={classes.subTitleInfo}>
              {order.address1} {order.address2}
            </p>
          </div>

          <div className={classes.dataInfo}>
            <h2 className={classes.titleInfo}>Tipo de Envío</h2>
            <p className={classes.subTitleInfo}>{shippingMethod}</p>
          </div>
          <div className={classes.dataInfo}>
            <h2 className={classes.titleInfo}>Resumen de la orden</h2>
            <div className={classes.productContainer}>
              <div className={`${classes.spaceLine} line`}></div>
              <div className={classes.headerItem} style={{ fontWeight: 600 }}>
                <p>Producto</p>
                <p>Cantidad</p>
                <p>Precio</p>
              </div>
              {orderProducts.map((product) => {
                return (
                  <>
                    <div className={`${classes.spaceLine} line`}></div>
                    <div className={classes.headerItem}>
                      <p style={{ width: "95px" }}>
                        {"#" + product.id + " " + product.description}
                      </p>
                      <p>{product.quantity}</p>
                      <p> {product.price}</p>
                    </div>

                    <div
                      className="row-data"
                      style={{ marginTop: "5px", justifyContent: "start" }}
                    >
                      <svg
                        width="8"
                        height="8"
                        viewBox="0 0 8 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="4"
                          cy="4"
                          r="4"
                          fill={handleColorInventary(product.inStock).color}
                        />
                      </svg>
                      <span style={{ marginLeft: "4px", fontSize: "14px" }}>
                        {handleColorInventary(product.inStock).name}
                      </span>
                    </div>
                  </>
                );
              })}

              <div className={`${classes.spaceLine} line`}></div>
              <div className={classes.headerItem}>
                <p className={classes.shippingText}>Subtotal</p>
                <p>{subTotal}</p>
              </div>
              <div className={`${classes.spaceLine} line`}></div>
              <div className={classes.headerItem}>
                <p className={classes.shippingText}>Envío</p>
                <p>{shippingTotal}</p>
              </div>
            </div>
            <div className={`${classes.spaceLine} line`}></div>
            <div className={classes.headerItem}>
              <p className={classes.totalText}>TOTAL</p>
              <p style={{ fontWeight: 700 }}>{order.total}</p>
            </div>

            <div className={classes.containerBtn}>
              {getFirstRow(order.id, order.status, order.paymentState[0])}
              {getSecondRow(order.id, order.status, order.paymentState[0])}
            </div>

            <div className={classes.dataInfo}>
              <h2 className={classes.titleInfo}>Comentarios</h2>
              <Comment />
            </div>

            <div className={classes.dataInfo}>
              <Answer isMobile={isMobile}/>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
const Button = ({ children, classname, onClick, isMobile = false }) => (
  <button
    className={`inventoryBtn ${classname} `}
    onClick={onClick}
    style={{
      height: "30px",
      borderRadius: "4px",
      width: isMobile ? "240px" : "140px",
      marginRight: isMobile ? "0" : "20px",
    }}
  >
    {children}
  </button>
);

const Comment = ({}) => {
  const classes = useStyles();
  return (
    <>
      <textarea
        className={`${classes.containerText} ${classes.styleTextArea}`}
        rows="4"
      ></textarea>
      <h2 className={`${classes.admin}`}>ADMIN 55/55/55</h2>
    </>
  );
};


const Answer = ({ isMobileOrTablet = false, isMobile = false }) => {
  const classes = useStyles();

  return (
    <div
      className={`${classes.containerText} ${classes.answerContainer}`}
      style={{
        height: isMobile ? "90px" : isMobileOrTablet ? "120px" : "auto",
      }}
    >
      <textarea
        onChange={() => {}}
        className={`${classes.answerTextArea} custom-input`}
        type="text"
        placeholder="Escribir respuesta"
      />
      <div
        className={classes.answerBtnContainer}
      >
        <button
         className={classes.answerBtnSend}
        >
          <span
            className={classes.bodyText}
            style={{
              marginBottom: "5%",
              textAlign: "right",
              marginRight: "2%",
            }}
          >
            Enviar
          </span>
          <img style={{ width: "12px", marginTop: "-6px" }} src={Send} alt="" />
        </button>
      </div>
    </div>
  );
};
export default ModulePanelPopup;
