import React, { useState, useEffect, useContext, useRef } from "react"
import Swal from "sweetalert2"
import { EfikaOfflineFirst } from "../../../efika/efikaOfflineFirst"
import { useHistory } from "react-router-dom"
import { LoadingContext } from "../../../contexts/loading.context"
import { UserContext } from "../../../contexts/user.context"
import "./proformaReview.css"
import { v4 as uuidv4 } from 'uuid'
import { ModalBody, ModalContainer } from "../../../components/modal"
import { ProformaReviewModal } from "../../../components/index"
import NoImageProduct from "../../../assets/no-image-product.png"

const ProformaReview = (props) => {

    const [user] = useContext(UserContext)
    const setShowLoading = useContext(LoadingContext)[1]

    const [proformaTotals, setProformaTotals] = useState({ subTotal: 0, shipping: 0, total: 0 })
    const [showPopupModal, setShowPopupModal] = useState(false)
    const [customerData, setCustomerData] = useState({})
    const [productsData, setProductsData] = useState([])
    const [vendorId, setVendorId] = useState(null)
    const [serviceResponse, setServiceResponse] = useState({})

    const showLoading = () => {
        setShowLoading(true)
    }

    const hideLoading = () => {
        setShowLoading(false)
    }

    const eofProformas = useRef(new EfikaOfflineFirst("PROFORMAS", showLoading, hideLoading))

    const history = useHistory()

    const calculateProformaTotal = (products, shippingCost = 0) => {
        var temporalSubtotal = 0
        products.map((product) => {
            temporalSubtotal += (parseFloat(product.price) * product.quantity)
        })
        setProformaTotals({
            subTotal: temporalSubtotal.toFixed(2),
            shipping: parseFloat(shippingCost).toFixed(2),
            total: (temporalSubtotal + parseFloat(shippingCost)).toFixed(2)
        })
    }

    const handleCreateProforma = async () => {

        const externalId = uuidv4()

        const postProforma = {
            proformaExternalId: externalId,
            ...customerData,
            totalPrice: parseFloat(proformaTotals.total),
            products: productsData,
            authorId: user.userId,
            authorName: user.name + " " + user.lastName,
            vendorId
        }

        const response = await eofProformas.current.postAsPromise(postProforma)

        if (response.status === 200 && response.recordset.length > 0) {
            setServiceResponse({
                ...response.recordset[0],
                proformaExternalId: externalId
            })
            setShowPopupModal(true)
        } else {
            Swal.fire({
                title: "Error",
                text: "Ha ocurrido un error al crear la proforma",
                icon: "error",
            })
        }
    }

    const handleGoBack = () => {
        history.push({
            pathname: "new",
            state: {
                customerInfo: customerData,
                productsList: productsData
            }
        })
    }

    const handleCloseModal = () => {
        setShowPopupModal(false)
        history.push("/home/proformas")
    }

    useEffect(() => {
        if (props.history.location.state) {
            var { customerInfo, productsList, vendorId } = props.history.location.state
            if (vendorId == "44413") {
                customerInfo.shippingAddress = "NA"
            }
            setCustomerData(customerInfo)
            setProductsData(productsList)
            setVendorId(vendorId)
            calculateProformaTotal(productsList, customerInfo.shippingCost)
        } else {
            history.goBack()
        }
    }, [])

    return (
        <div className="main-container">
            <div>
                <h1 className="main-title">RESUMEN DE LA PROFORMA</h1>
            </div>

            <div>
                <span className="second-title">Datos del comprador</span>

                <table className="client-info-table">
                    <tbody>
                        <tr>
                            <td className="inner-label">Nombre</td>
                            <td style={{ borderBottom: 0 }}>{customerData.clientName || ""}</td>
                        </tr>
                        <tr>
                            <td className="inner-label">Apellidos</td>
                            <td>{customerData.clientLastName || ""}</td>
                        </tr>
                        <tr>
                            <td className="inner-label">Cédula</td>
                            <td>{customerData.clientIdentification || "Sin indicar"}</td>
                        </tr>
                        <tr>
                            <td className="inner-label">Correo electrónico</td>
                            <td>{customerData.clientEmail || "Sin indicar"}</td>
                        </tr>
                        <tr>
                            <td className="inner-label">Teléfono</td>
                            <td>{customerData.clientPhone || "Sin indicar"}</td>
                        </tr>
                        {vendorId == "44413" ? <></> :
                            <>
                                <tr>
                                    <td className="inner-label">Dirección de envío</td>
                                    <td>{customerData.shippingAddress || ""}</td>
                                </tr>
                                <tr>
                                    <td className="inner-label">Costo del envío</td>
                                    <td>₡{parseFloat(customerData.shippingCost || 0).toFixed(2) || 0.00}</td>
                                </tr>
                            </>
                        }
                    </tbody>
                </table>
            </div>

            <div>
                <span className="second-title">Productos</span>
                <div className="table-container">
                    <div className="table-column">
                        <div className="column-name">
                            <span className="table-header-text">Imagen</span>
                        </div>
                        <div className="column-name">
                            <span className="table-header-text">Nombre</span>
                        </div>
                        <div className="column-name">
                            <span className="table-header-text">Cantidad</span>
                        </div>
                        <div className="column-name">
                            <span className="table-header-text">Precio ( ₡ )</span>
                        </div>
                    </div>
                    <div className="table-rows max-height-table-review">
                        {productsData.map((product, index) => {
                            const { image, name, price, quantity } = product
                            return (
                                <div className="table-row" key={`product-${index}`}>
                                    <div className="row-data">
                                        <img className="product-image" src={image ? image.base64 : NoImageProduct} alt={image ? image.name : "Producto sin imagen"} />
                                    </div>
                                    <div className="row-data">
                                        <span>{name || ""}</span>
                                    </div>
                                    <div className="row-data">
                                        <span>{quantity || ""}</span>
                                    </div>
                                    <div className="row-data">
                                        <span>{parseFloat(price).toFixed(2) || "0.00"}</span>
                                    </div>
                                </div>
                            )
                        })}
                        <div className="table-row" key={"products-sub-total"} style={{ border: 0 }}>
                            <div className="row-data">
                                <span><b>Subtotal</b></span>
                            </div>
                            <div className="row-data"></div>
                            <div className="row-data"></div>
                            <div className="row-data">
                                <span><b>{proformaTotals.subTotal}</b></span>
                            </div>
                        </div>
                        <div className="table-row" key={"products-shipping-total"}>
                            <div className="row-data">
                                <span><b>Envío</b></span>
                            </div>
                            <div className="row-data"></div>
                            <div className="row-data"></div>
                            <div className="row-data">
                                <span><b>{proformaTotals.shipping}</b></span>
                            </div>
                        </div>
                        <div className="table-row" key={"products-total"} style={{ border: 0 }}>
                            <div className="row-data">
                                <span><b>Total</b></span>
                            </div>
                            <div className="row-data"></div>
                            <div className="row-data"></div>
                            <div className="row-data">
                                <span className="total-text">{proformaTotals.total}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="button-container">
                <div className="second-action-button" onClick={() => handleGoBack()}>
                    <span>Regresar</span>
                </div>
                <div className="main-action-button" onClick={() => handleCreateProforma()}>
                    <span>Crear proforma</span>
                </div>
            </div>

            <ModalContainer
                width={"initial"}
                style={{ alignItems: "center" }}
                handleClose={() => { handleCloseModal() }}
                active={showPopupModal}
            >
                <ModalBody>
                    <ProformaReviewModal
                        handleCloseModal={handleCloseModal}
                        proformaInfo={serviceResponse}
                    />
                </ModalBody>
            </ModalContainer>
        </div>
    )
}

export default ProformaReview
