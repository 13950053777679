import React, { useContext, useState,  useEffect, useCallback, Fragment } from "react";
import { UserContext } from "../../contexts/user.context";
import { ChartDonut, ChartLegend, ChartLabel } from "@patternfly/react-charts";
import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2";
import { useMediaQuery } from "react-responsive";

import { LoadingContext } from "../../contexts/loading.context";
import {
  orderStatus,
  getStatusIcon,
  changeDateFormat,
} from "../../constants/orderStatus";
import "./desktop.css";

import arrowBottom from "../../assets/arrowBottom.svg";

const Desktop = (props) => {
  const [user] = useContext(UserContext);
  const setShowLoading = useContext(LoadingContext)[1];

  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  const [orders, setOrders] = useState([]);
  const [sellsCategories, setSellsCategories] = useState([]);
  const [sellsAmount, setSellsAmount] = useState();
  const [dates, setDates] = useState([]);

  const generateDates = useCallback(() => {
    const months = {
      0: "Enero",
      1: "Febrero",
      2: "Marzo",
      3: "Abril",
      4: "Mayo",
      5: "Junio",
      6: "Julio",
      7: "Agosto",
      8: "Septiembre",
      9: "Octubre",
      10: "Noviembre",
      11: "Diciembre",
    };

    var dates = [];
    var startDate = new Date("2020/04/01");
    const endDate = new Date();
    while (startDate < endDate) {
      dates.push({
        value: startDate.getFullYear() + "-" + (startDate.getMonth() + 1),
        label: months[startDate.getMonth()] + " " + startDate.getFullYear(),
      });
      startDate.setMonth(startDate.getMonth() + 1);
    }
    return dates.reverse();
  }, []);

  const fetchOrders = useCallback(async () => {
    try {
      const responseOrders = await axios({
        method: "POST",
        url: "https://kakaomarket.cr/wp-json/sellerconsole/v1/getorders",
        data: {
          vendorId: user.vendorId,
        },
      });
      if (responseOrders.status === 200) {
        const dataJSON = JSON.parse(responseOrders.data);
        setOrders(dataJSON.result);
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: "Error",
        text: "Ha ocurrido un error al obtener los datos de las órdenes",
        icon: "error",
      });
    }
  }, []);

  const fetchSells = useCallback(async (year, month) => {
    try {
      const responseCategories = await axios.post(
        "https://kakaomarket.cr/wp-json/sellerconsole/v1/getsellscategories",
        { month, year, vendorId: user.vendorId }
      );
      
      if (responseCategories.status === 200) {
        const dataJSON = JSON.parse(responseCategories.data);
        setSellsCategories(dataJSON.categories);
        setSellsAmount(dataJSON.sellsAmount);
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: "Error",
        text: "Ha ocurrido un error al obtener los datos de las ventas",
        icon: "error",
      });
    }
  }, []);

  const handleSelectDate = async (date) => {
    setShowLoading(true);

    const values = date.value.split("-");
    await fetchSells(values[0], values[1]);

    setShowLoading(false);
  };

  useEffect(() => {
    async function fetchData() {
      setShowLoading(true);

      const resultDates = generateDates();

      setDates(resultDates);
      const thisMonth = resultDates[0];
      const values = thisMonth.value.split("-");

      await fetchOrders();
      await fetchSells(values[0], values[1]);

      setShowLoading(false);
    }

    fetchData();
  }, [setShowLoading, generateDates, fetchOrders, fetchSells]);

  const legendLabel = (...rest) => (
    <ChartLabel style={[{ color: "#37474F" }, {}]} text={rest.index} />
  );

  // Custom legend component
  const getLegend = (legendData) => (
    <ChartLegend
      data={legendData}
      gutter={25}
      itemsPerRow={6}
      labelComponent={legendLabel()}
      rowGutter={2}
      colorScale={[
        "#37474F",
        "#FF6D00",
        "#00B8D4",
        "#FF5A5F",
        "#27A36E",
        "#C8CBCC",
      ]}
    />
  );

  return (
    <div className="containerDesktop">
      <span className="pageTitle">Escritorio</span>
      <span className="welcomeText">Bienvenido {user ? user.name : ""}</span>

      <div className="gridContainer">
        {/* Comentado temporalmente por falta de implementacion 
        <div className="card item-1">
          <div className="cardHeader">
            <span className="cardTitle">Reporte</span>
          </div>
          <div className="cardBody"></div>
        </div>
        <div className="card item-2">
          <div className="cardHeader">
            <span className="cardTitle">Actividad</span>
            <span className="cardSecondTitle">Ver más</span>
          </div>
          <div className="cardBody"></div>
        </div>
        */}
        <div className="card item-3">
          <div className="cardHeader" style={{marginBottom:'20px'}}>
            <span className="cardTitle">Órdenes</span>
            <span className="cardSecondTitle">Ver más</span>
          </div>
          <div className="cardBody">
            {orders && orders.length > 0 && (
              <>
                {!isMobile ? (
                  <DesingTableOrDesktop orders={orders} />
                ) : (
                  <DesingMobile orders={orders} />
                )}
              </>
            )}

            {orders && orders.length === 0 && (
              <div className="noData">
                <span>Lo sentimos, no existe información para mostrar</span>
              </div>
            )}
          </div>
        </div>
        <div className="card item-4">
          <div className="cardHeader">
            <span className="cardTitle">Ventas</span>
            <div className="dateContainer">
              <span
                className="cardSecondTitle date"
                style={{ marginRight: "10px" }}
              >
                Fecha
                <br />
              </span>
              {dates.length > 0 && (
                <Select
                  className="salesSelect"
                  defaultValue={dates[0]}
                  onChange={handleSelectDate}
                  options={dates}
                />
              )}
            </div>
          </div>
          <div className="cardBody">
            {sellsAmount > 0 && (
              <ChartDonut
                constrainToVisibleArea={true}
                data={sellsCategories.map((category) => ({
                  x: category.name,
                  y: category.quantity,
                }))}
                labels={({ datum }) => `${datum.x}: ${datum.y}`}
                legendComponent={getLegend(
                  sellsCategories.map((category) => ({ name: category.name }))
                )}
                legendOrientation="vertical"
                legendPosition="right"
                padding={{
                  bottom: 20,
                  left: 20,
                  right: 140,
                  top: 20,
                }}
                subTitle={sellsAmount > 1 ? "ventas" : "venta"}
                title={sellsAmount}
                titleComponent={
                  <ChartLabel
                    style={[
                      {
                        fill: "#37474F",
                        fontSize: 40,
                        fontWeight: "bold",
                        dominantBaseline: "central",
                      },
                      {
                        fill: "#37474F",
                        fontSize: 14,
                        dominantBaseline: "text-before-edge",
                      },
                    ]}
                  />
                }
                width={450}
                innerRadius={70}
                colorScale={[
                  "#37474F",
                  "#FF6D00",
                  "#00B8D4",
                  "#FF5A5F",
                  "#27A36E",
                  "#C8CBCC",
                ]}
              />
            )}
            {sellsAmount === 0 && (
              <div className="noData">
                <span>Lo sentimos, no existe información para mostrar</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const DesingTableOrDesktop = ({ orders }) => {
  return (
    <table className="salesTable" style={{ width: "100%" }}>
      <thead style={{ textAlign: "left" }}>
        <tr>
          <th className="tableHeaderItem" style={{ width: "30%" }}>
            Orden
          </th>
          <th className="tableHeaderItem" style={{ width: "20%" }}>
            Fecha
          </th>
          <th className="tableHeaderItem" style={{ width: "20%" }}>
            Estado
          </th>
          <th className="tableHeaderItem">Detalle</th>
        </tr>
      </thead>
      <tbody>
        {orders.map((order) => {
          const { id, date, status, details, customerName } = order;
          return (
            <tr key={id}>
              <td className="tableBodyItem" style={{ width: "30%" }}>
                #{id} {customerName}
              </td>
              <td className="tableBodyItem" style={{ width: "20%" }}>
                {changeDateFormat(date)}
              </td>
              <td style={{ width: "20%" }}>
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="4" cy="4" r="4" fill={getStatusIcon(status)} />
                </svg>
                <span className="tableBodyItem" style={{ marginLeft: "7px" }}>
                  {orderStatus[status]}
                </span>
              </td>
              <td className="tableBodyItem">
                {details !== "" ? details : "-"}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const DesingMobile = ({ orders }) => {
  return (
    <div className="containerMobileOrders">
      {orders.map((order) => {
        return (
          <Fragment key={order.id}>
            <div className="line"></div>
            <CollapseHeader order={order} />
          </Fragment>
        );
      })}
    </div>
  );
};

const CollapseHeader = ({ order }) => {
  const { id, date, status, details, customerName } = order;
  const [isActive, setIsActive] = useState(false);
  return (
    <>
      <div
        className="ordersItemHeader"
        onClick={() => setIsActive(!isActive)}
        key={id}
      >
        <p className="number">
          <span>Order</span>#{id}
        </p>
        <div className="status">
          <svg
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="4" cy="4" r="4" fill={getStatusIcon(status)} />
          </svg>
          <span style={{ marginLeft: "5px" }}>{orderStatus[status]}</span>
        </div>
        <div className={`image ${isActive ? "isRotate" : ""}`}>
          <img src={arrowBottom} alt="arrow" />
        </div>
      </div>
      {isActive && (
        <>
          <CollapseItem title={"Cliente"} info={customerName} />
          <CollapseItem title={"Fecha"} info={changeDateFormat(date)} />
          <CollapseItem
            title={"Detalle"}
            info={
              details.length >= 15
                ? `${details.substring(0, 15)}...`
                : details
            }
          />
        </>
      )}
    </>
  );
};

const CollapseItem = ({ title, info }) => (
  <>
    <div className="line"></div>
    <div className="ordersItemBody">
      <p className="title">{title}</p>
      <p className="info">{info}</p>
      <span></span>
    </div>
  </>
);

export default Desktop;
