import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import "./proformaNew.css"
import PlusIcon from "../../../assets/add.svg"
import DeleteIcon from "../../../assets/delete.svg"
import InventoryIcon from "../../../assets/icons/inventory.svg"
import UploadIcon from "../../../assets/icons/upload.svg"

const ProformaNew = ({ history }) => {
    const historyHook = useHistory()
    const [customerInfo, setCustomerInfo] = useState(history.location.state ? history.location.state.customerInfo : {})
    const [productsList, setProductsList] = useState(history.location.state ? history.location.state.productsList : [])
    const [validContinue, setValidContinue] = useState(false)
    const userInformation = JSON.parse(localStorage.getItem("userInformation"))

    useEffect(() => {
        validateProformaInfo()
    }, [customerInfo, productsList])

    const handleAddProduct = () => {
        const newProduct = {
            image: null,
            name: "",
            quantity: 0,
            price: 0
        }
        setProductsList([...productsList, newProduct])
    }

    const handleProductInputChange = (index, value, key) => {

        if (key === "quantity" && value < 0) {
            return
        }

        var product = productsList[index]
        product[key] = value
        var productsTemporalList = [...productsList]
        productsTemporalList[index] = product
        setProductsList(productsTemporalList)
    }

    const handleDeleteProduct = (productIndex) => {
        setProductsList(productsList.filter((product, index) => index !== productIndex))
    }

    const handlePhotoInput = (index, event) => {
        const file = event.target.files[0]
        const reader = new FileReader()
        reader.onloadend = () => {
            const imageInfo = {
                base64: reader.result,
                name: file.name
            }
            handleProductInputChange(index, imageInfo, "image")
        }

        if (file) {
            reader.readAsDataURL(file)
        }
    }

    const handleCustomerInputChange = (value, key) => {
        var temporalCustomerInfo = { ...customerInfo }
        temporalCustomerInfo[key] = value
        setCustomerInfo(temporalCustomerInfo)
    }

    const validateProformaInfo = () => {
        const { clientName, clientLastName, clientEmail, shippingAddress } = customerInfo
        const incompleteProducts = productsList.filter((product) => product.name === "" || product.quantity === 0 || product.price === 0 || product.price === "")
        if (userInformation.vendorId == "44413") {
            if ((clientName && clientName !== "") && 
                (clientLastName && clientLastName !== "") && 
                (clientEmail && clientEmail !== "") && 
                productsList.length !== 0 && 
                incompleteProducts.length === 0) {
                setValidContinue(true)
            } else {
                setValidContinue(false)
            }
        } else {
            if ((clientName && clientName !== "") && 
                (clientLastName && clientLastName !== "") && 
                (clientEmail && clientEmail !== "") && 
                (shippingAddress && shippingAddress !== "") && 
                productsList.length !== 0 && 
                incompleteProducts.length === 0) {
                setValidContinue(true)
            } else {
                setValidContinue(false)
            }
        }
    }

    const handleContinueButton = () => {
        if (validContinue) {
            historyHook.push({
                pathname: "review",
                state: {
                    customerInfo,
                    productsList,
                    vendorId: userInformation.vendorId,
                }
            })
        }
    }

    return (
        <div className="main-container">
            <div>
                <h1 className="main-title">CREAR NUEVA PROFORMA</h1>
            </div>

            <div>
                <span className="second-title">Datos del comprador</span>
                <div className="info-row">
                    <div className="input-container input-container-small">
                        <span className="inner-label">Nombre *</span>
                        <input className="info-input info-input-required" type="text" onChange={(evt) => handleCustomerInputChange(evt.target.value, "clientName")} value={customerInfo.clientName ? customerInfo.clientName : ""} />
                    </div>
                    <div className="input-container input-container-small">
                        <span className="inner-label">Apellidos *</span>
                        <input className="info-input info-input-required" type="text" onChange={(evt) => handleCustomerInputChange(evt.target.value, "clientLastName")} value={customerInfo.clientLastName ? customerInfo.clientLastName : ""} />
                    </div>
                    <div className="input-container input-container-small">
                        <span className="inner-label">Cédula</span>
                        <input className="info-input" type="number" onChange={(evt) => handleCustomerInputChange(evt.target.value, "clientIdentification")} value={customerInfo.clientIdentification ? customerInfo.clientIdentification : ""} />
                    </div>
                </div>
                <div className="info-row">
                    <div className="input-container input-container-medium">
                        <span className="inner-label">Correo electrónico *</span>
                        <input className="info-input info-input-required" type="email" onChange={(evt) => handleCustomerInputChange(evt.target.value, "clientEmail")} value={customerInfo.clientEmail ? customerInfo.clientEmail : ""} />
                    </div>
                    <div className="input-container input-container-medium">
                        <span className="inner-label">Teléfono</span>
                        <input className="info-input" type="number" onChange={(evt) => handleCustomerInputChange(evt.target.value, "clientPhone")} value={customerInfo.clientPhone ? customerInfo.clientPhone : ""} />
                    </div>
                </div>
                {userInformation.vendorId == "44413" ? <></> :
                    <div className="info-row">
                        <div className="input-container input-container-large">
                            <span className="inner-label">Dirección de envío *</span>
                            <input className="info-input info-input-required" type="text" onChange={(evt) => handleCustomerInputChange(evt.target.value, "shippingAddress")} value={customerInfo.shippingAddress ? customerInfo.shippingAddress : ""} />
                        </div>
                        <div className="input-container input-container-small">
                            <span className="inner-label">Costo del envío</span>
                            <input className="info-input" type="number" onChange={(evt) => handleCustomerInputChange(parseFloat(evt.target.value), "shippingCost")} value={customerInfo.shippingCost ? customerInfo.shippingCost : 0} />
                        </div>
                    </div>
                }
            </div>

            <div>
                <span className="second-title">Productos</span>
                <div className="table-container">
                    <div className="table-column" style={{ marginTop: "0 !important" }}>
                        <div className="column-name">
                            <span className="table-header-text">Imagen</span>
                        </div>
                        <div className="column-name">
                            <span className="table-header-text">Nombre *</span>
                        </div>
                        <div className="column-name">
                            <span className="table-header-text">Cantidad</span>
                        </div>
                        <div className="column-name">
                            <span className="table-header-text">Precio ( ₡ ) *</span>
                        </div>
                        <div className="column-name">
                        </div>
                    </div>
                    <div className="table-rows max-height-table">
                        {productsList.map((product, index) => {

                            return (
                                <div className="table-row" key={`product-${index}`}>
                                    {!product.image && (
                                        <div className="row-data">
                                            <label className="upload-photo-container" htmlFor={`upload-photo-${index}`}>
                                                <img src={UploadIcon} alt="Subir foto" style={{ width: "15.02px", height: "16px" }} />
                                            </label>
                                            <input className="upload-photo-input" type="file" id={`upload-photo-${index}`} accept="image/png, image/jpeg, image/jpg" onChange={(evt) => { handlePhotoInput(index, evt) }} />
                                        </div>
                                    )}
                                    {product.image && (
                                        <div className="row-data">
                                            <img className="product-image" src={product.image.base64} alt={product.image.name} />
                                        </div>
                                    )}
                                    <div className="row-data">
                                        <input className="info-input info-input-required" type="text" value={product.name} onChange={(evt) => handleProductInputChange(index, evt.target.value, "name")} />
                                    </div>
                                    <div className="row-data">
                                        <button className="quantity-helper-button" onClick={() => handleProductInputChange(index, product.quantity - 1, "quantity")}>-</button>
                                        <input className="info-input small-info-input" type="number" value={product.quantity} onChange={(evt) => handleProductInputChange(index, parseInt(evt.target.value), "quantity")} />
                                        <button className="quantity-helper-button" onClick={() => handleProductInputChange(index, product.quantity + 1, "quantity")}>+</button>
                                    </div>
                                    <div className="row-data">
                                        <input className="info-input info-input-required" type="number" value={product.price} onChange={(evt) => handleProductInputChange(index, parseFloat(evt.target.value) || "", "price")} />
                                    </div>
                                    <div className="row-data">
                                        <div onClick={() => handleDeleteProduct(index)}>
                                            <img src={DeleteIcon} style={{ width: "12px", height: "12px", marginRight: "6px" }} />
                                            <span>Eliminar</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        <div className="table-row add-product-buttons-container" key="add-buttons">
                            <div className="add-product-button" onClick={() => handleAddProduct()}>
                                <img src={PlusIcon} alt="Agregar producto" style={{ width: "14px", height: "14px" }} />
                                <span style={{ marginLeft: "10px" }}>Agregar nuevo producto</span>
                            </div>
                            <div className="add-product-button" style={{ opacity: 0.5, cursor: "default" }}>
                                <img src={InventoryIcon} alt="Agregar producto" style={{ width: "14px", height: "14px" }} />
                                <span style={{ marginLeft: "10px" }}>Agregar producto existente</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="save-button-container">
                <div className={validContinue ? "main-action-button" : "disabled-main-action-button"} onClick={() => handleContinueButton()}>
                    Finalizar proforma
                </div>
            </div>
        </div>
    )
}

export default ProformaNew