import React, {useState} from "react";
import "./accordion.css";

const Accordion = ({ title, hideIcon, showIcon, children, style }) => {
  const [isActive, setIsActive] = useState(false)

  return (
    <div style={style}>
      <div className="accordion">
          <div className="accordion-header" onClick={() => setIsActive(!isActive)}>
            {title}
            {isActive ? showIcon : hideIcon}
          </div>
          {/* content to show when isActive */
            isActive 
              ? <div className="accordion-content">{children}</div> 
              : null
          }
      </div>
    </div>
  )
}

export default Accordion