import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  Fragment,
} from "react";
import Select, { components } from "react-select";
import { Tab, Tabs, CustomCheckbox } from "../../components/index";
import "./newProduct.css";
import SelectArrow from "../../assets/select-arrow.svg";
import CloseIcon from "../../assets/close.svg";
import Helper from "../../assets/helper.svg";
import AddIcon from "../../assets/add.svg";
import { LoadingContext } from "../../contexts/loading.context";
import axios from "axios";
import Swal from "sweetalert2";
import { useMediaQuery } from "react-responsive";
import { UserContext } from "../../contexts/user.context";
import {
  newProductConstants,
  handleConvertDate,
  toTimestamp,
  getMessageNullFields,
  formatArrayNewEdit,
  handleArrayVisibleEn,
  selectStylesCategory,
  selectStylesVisibility,
  selectStylesVisibilityPlace,
  selectStylesPublish,
  selectStylesFullWidth,
  handleAttributeCopy,
  capitalizarPrimeraLetra,
  handleConvertDataSelected,
  handleArrayStateParams,
  handleArrayStateLocal,
} from "../../constants/newProduct";

const NewProduct = (props) => {
  const viewState = props.history.location.state;
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 768 });
  const isDesktop = useMediaQuery({ minWidth: 1024, maxWidth: 1024 });

  const [user] = useContext(UserContext);
  const setShowLoading = useContext(LoadingContext)[1];

  const inventaryOptions = newProductConstants.INVENTARY_OPTIONS;
  const discountBancoPopularOptions =
    newProductConstants.DISCOUNT_BANCO_POPULAR_OPTIONS;
  const publishOptions = newProductConstants.PUBLISH_OPTIONS;
  const visibilityOptions = newProductConstants.VISIBILITY_OPTIONS;
  const visibilityPlaceOptions = newProductConstants.VISIBILITY_PLACE_OPTIONS;
  const temperatureOptions = newProductConstants.TEMPERATURE_OPTIONS;
  const deliveryOptions = newProductConstants.DELIVERY_OPTIONS;
  const provinces = newProductConstants.PROVINCES;

  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [visiblePassword, setVisisblePassword] = useState(false);
  const [description, setDescription] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [publishOption, setPublishOption] = useState("");
  const [visibilityOption, setVisibilitOption] = useState(visibilityOptions[0]);
  const [visibilityPlaceOption, setVisibilityPlaceOption] = useState(
    visibilityPlaceOptions[0]
  );
  const [deliveryRange, setDeliveryRange] = useState(false);
  const [deliveryType, setDeliveryType] = useState(null);
  const [deliveryDistance, setDeliveryDistance] = useState(null);
  const [featuredProduct, setFeaturedProduct] = useState(false);
  const [normalPrice, setNormalPrice] = useState(0);
  const [sku, setSku] = useState("");
  const [inventoryOption, setInventoryOption] = useState("");
  const [unitsAvailable, setUnitsAvailable] = useState(0);
  const [weight, setWeight] = useState(0);
  const [temperature, setTemperature] = useState({
    activeTemperature: false,
    selectedTemperature: "",
    enabledCheck: false,
  });
  const [length, setLength] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  //Estados necesarios para los atributos
  const [selectedAtributes, setSelectedAtributes] = useState([]);
  const [mainImage, setMainImage] = useState({});
  const [changeMainImage, setChangeMainImage] = useState(false);
  const [galeryImages, setGaleryImages] = useState([]);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [selectedRelatedProducts, setSelectedRelatedProducts] = useState([]);
  const [dataAttributes, setDataAttributes] = useState([]);

  //State de la peticion de todos los atributos
  const [attributes, setAttributes] = useState([]);
  //Valores mapeados para el componente select
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  //Este para que persista los valores que vienen desde el ID Producto editar
  const [selectedAttributesState, setSelectedAttributesState] = useState([]);

  const [productType, setProductType] = useState("simple");
  const [variations, setVariations] = useState([]);

  const [discountBancoPopular, setDiscountBancoPopular] = useState({
    active: false,
    type: "",
    amount: 0,
  });
  const defaultDiscountBancoPopularOption = viewState
    ? viewState.meta.bancoPopular_discount_product
      ? discountBancoPopularOptions.filter(
          (option) => option.value === discountBancoPopular.type
        )
      : null
    : null;
  const [discountPrice, setDiscountPrice] = useState({
    discontActive: false,
    discountAmount: 0,
    discontByDateActive: false,
    discountDate: {
      startDate: "",
      endDate: "",
    },
  });

  //Variables para campos obligatorios
  const [isNullProductName, setIsNullProductName] = useState(false);
  const [isNullDescription, setIsNullDescription] = useState(false);
  const [isNullCategory, setIsNullCategory] = useState(false);
  const [isNullVisibility, setIsNullVisibility] = useState(false);
  const [isNullPassword, setIsNullPassword] = useState(false);
  const [isNullVisibleIn, setIsNullVisibleIn] = useState(false);
  const [isNullPrice, setIsNullPrice] = useState(false);
  const [isNullReducedPrice, setIsNullReducedPrice] = useState(false);
  const [isNullStartDateReducedPrice, setIsNullStartDateReducedPrice] =
    useState(false);
  const [isNullFinishDateReducedPrice, setIsNullFinishDateReducedPrice] =
    useState(false);
  const [isNullInventory, setIsNullInventory] = useState(false);
  const [isNullUnitsAvailable, setIsNullUnitsAvailable] = useState(false);
  const [isNullTemperature, setIsNullTemperature] = useState(false);
  const [isNullDeliveryRange, setIsNullDeliveryRange] = useState(false);
  const [isNullImage, setIsNullImage] = useState(false);

  const handleSelectCategories = (value) => {
    if (!selectedCategories.includes(value)) {
      setSelectedCategories([...selectedCategories, value]);
    }
  };

  const handleDeleteCategory = (category) => {
    setSelectedCategories(
      selectedCategories.filter((item) => item !== category)
    );
  };

  const handleSelectRelatedProduct = (value) => {
    if (!selectedRelatedProducts.includes(value)) {
      setSelectedRelatedProducts([...selectedRelatedProducts, value]);
    }
  };

  const handleDeleteRelatedProduct = (product) => {
    setSelectedRelatedProducts(
      selectedRelatedProducts.filter((item) => item !== product)
    );
  };

  const handleDescription = (event) => {
    const value = event.target.value;
    if (value.length <= 10000) {
      setDescription(value);
    }
  };

  const handleAddImage = (event, type) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const fileName = file.name;
      if (type === "main") {
        setMainImage({
          name: fileName,
          base64: reader.result,
          type: file.type,
          action: "N",
        });
        setChangeMainImage(true);
      } else {
        setGaleryImages([
          ...galeryImages,
          {
            name: fileName,
            base64: reader.result,
            type: file.type,
            action: "N",
          },
        ]);
      }
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSelectAtributes = (value) => {
    if (
      selectedAttributes.filter((select) => select.value === value.value)
        .length === 0
    ) {
      value.subAttr = [];
      setSelectedAttributes([...selectedAttributes, value]);
    }
  };

  const handleSubAttributesOptions = (keyFather, subAttrValue, action) => {
    let vista = selectedAttributes;
    if (action === "U") {
      vista = selectedAttributes.map((attr) => {
        if (attr.value === keyFather) {
          if (
            attr.subAttr.filter((select) => select.value === subAttrValue.value)
              .length === 0
          ) {
            attr.subAttr = [...attr.subAttr, subAttrValue];
          }
        }

        return attr;
      });
    } else if (action === "D") {
      vista = selectedAttributes.map((attr) => {
        if (attr.value === keyFather) {
          attr.subAttr = attr.subAttr.filter((item) => item !== subAttrValue);
        }

        return attr;
      });
    }
    setSelectedAtributes(vista);
  };

  const handleDeleteImg = (id) => {
    setGaleryImages(galeryImages.filter((img) => img.base64 !== id));
  };

  const handleVariationRegularPrice = (index, event) => {
    const tempVariations = [...variations]
    tempVariations[index].display_regular_price = event.target.value
    setVariations(tempVariations)
  }

  const handleVariationDiscountActive = (index, event) => {
    const tempVariations = [...variations]
    tempVariations[index].discountActive = event.target.checked
    setVariations(tempVariations)
  }

  const handleVariationDisplayPrice = (index, event) => {
    const tempVariations = [...variations]
    tempVariations[index].display_price = event.target.value
    setVariations(tempVariations)
  }

  const handlePublishButton = (publicOnDraft = "P") => {
    var nullFields = checkRequiredFields();
    if (nullFields.length === 0) {
      return handleSetDataUpsertProduct(publicOnDraft);
    } else {
      Swal.fire("¡Error!", getMessageNullFields(nullFields), "error");
    }
  };

  //showInfoCategoria
  const [showInfoCategoria, setShowInfoCategoria] = useState(false);
  const toggleInfoCategoriaVisiblity = () => {
    setShowInfoCategoria(showInfoCategoria ? false : true);
  };

  //showInfoSKU
  const [showInfoSKU, setShowInfoSKU] = useState(false);
  const toggleInfoSKUVisiblity = () => {
    setShowInfoSKU(showInfoSKU ? false : true);
  };

  const mustBeHigher = (value) => {
    if (value <= 0) {
      Swal.fire("¡Error!", "El rango no puede ser menor a 0 km.", "error");
      setDeliveryDistance(null);
    } else {
      if (deliveryType === "K") {
        setDeliveryDistance(Number(value));
      } else {
        setDeliveryDistance(value);
      }
    }
  };

  const getInputType = () => {
    if (deliveryRange && deliveryType === "K") {
      return (
        <input
          style={{ marginBottom: ".5%", width: "40%" }}
          className="customInput"
          type="number"
          onChange={(event) => mustBeHigher(event.target.value)}
        />
      );
    } else if (deliveryRange && deliveryType === "P") {
      return (
        <Select
          className=""
          placeholder="Seleccionar provincia"
          onChange={(value) => {
            setDeliveryDistance(value.value);
          }}
          options={provinces}
          styles={{
            control: (styles) => ({ ...styles, width: "200px" }),
            indicatorSeparator: () => ({ display: "none" }),
          }}
          components={{ DropdownIndicator }}
        />
      );
    } else if (deliveryRange && deliveryType !== "P" && deliveryType !== "K")
      return null;
  };

  const handleSetDataUpsertProduct = (publicOnDraft) => {
    let post_status = viewState ? "" : "publish";
    let post_password = "";

    if (
      visibilityOption.value === "publica" ||
      visibilityOption.value === "protegido"
    ) {
      post_status = "publish";

      if (visibilityOption.value === "protegido") {
        if (password.length > 6) {
          post_password = password;
        } else {
          alert("Revise la contraseña");
        }
      }
    } else if (visibilityOption.value === "privada") {
      post_status = "private";
      post_password = "";
    }

    let visibilityInG = [];
    let arrayG = [];

    let objT = { value: 6, name: "product_visibility" };

    let objB = { value: 7, name: "product_visibility" };

    if (viewState) {
      objT = { ...objT, action: "N" };
      objB = { ...objB, action: "N" };
    }

    if (
      viewState &&
      visibilityPlaceOption.value === viewState.visibilityPlaceOption.value
    ) {
      visibilityInG = handleArrayVisibleEn(
        viewState.productVisibility,
        [],
        " ",
        "E"
      );
    } else if (visibilityPlaceOption.value === "tienda-y-busqueda") {
      if (viewState) {
        visibilityInG = viewState.productVisibility.map((prod) => {
          return {
            ...prod,
            action: "D",
          };
        });
      } else {
        visibilityInG = [];
      }
    } else if (
      visibilityPlaceOption.value === "busqueda" ||
      visibilityPlaceOption.value === "tienda"
    ) {
      if (viewState) {
        let objLocal = {};
        if (visibilityPlaceOption.value === "busqueda") {
          arrayG = [objB];
          objLocal = objT;
          objT.action = "D";
        } else {
          arrayG = [objT];
          objLocal = objB;
          objB.action = "D";
        }

        if (viewState.productVisibility.length > arrayG.length) {
          visibilityInG = handleArrayVisibleEn(
            viewState.productVisibility,
            arrayG,
            "E",
            "D"
          );
        } else if (arrayG.length > viewState.productVisibility.length) {
          visibilityInG = handleArrayVisibleEn(
            arrayG,
            viewState.productVisibility,
            "",
            "N"
          );
        } else if (arrayG.length === viewState.productVisibility.length) {
          visibilityInG = handleArrayVisibleEn(
            arrayG,
            viewState.productVisibility,
            " ",
            "N"
          );
          visibilityInG.push(objLocal);
        }
      } else {
        if (visibilityPlaceOption.value === "busqueda") {
          visibilityInG = [objB];
        } else {
          visibilityInG = [objT];
        }
      }
    } else if (visibilityPlaceOption.value === "oculto") {
      if (viewState) {
        arrayG = [objT, objB];
        if (arrayG.length > viewState.productVisibility.length) {
          visibilityInG = handleArrayVisibleEn(
            arrayG,
            viewState.productVisibility,
            "E",
            "N"
          );
        }
      } else {
        visibilityInG = [objT, objB];
      }
    }

    if (featuredProduct) {
      var productVisibilityArgs = {
        value: 8,
        name: "product_visibility",
      };
      if (viewState) {
        if (!viewState.outstanding) {
          visibilityInG.push({ ...productVisibilityArgs, action: "N" });
        }
      } else {
        visibilityInG.push(productVisibilityArgs);
      }
    } else {
      if (viewState) {
        visibilityInG.push({ ...productVisibilityArgs, action: "D" });
      }
    }

    //Categoria
    let formatStateCat,
      formatCatLocal,
      category,
      categoryStateParams,
      categoryG;
    if (viewState) {
      formatStateCat = formatArrayNewEdit(
        viewState.category,
        "product_cat",
        [],
        1
      );
      formatCatLocal = formatArrayNewEdit(
        selectedCategories,
        "product_cat",
        [],
        1
      );
      categoryStateParams = handleArrayStateParams(
        formatStateCat,
        formatCatLocal
      );
      let categoryNews = handleArrayStateLocal(formatCatLocal, formatStateCat);
      categoryG = categoryStateParams.concat(categoryNews);
    } else {
      category = selectedCategories.map(({ label }) => {
        let objReturn = { name: "product_cat", value: label };
        return objReturn;
      });
    }

    //Atributos
    let formatStateAttr,
      formatAttrLocal,
      attributesStateParams,
      attributesLocal,
      attributesG,
      attributes;
    if (viewState) {
      formatStateAttr = [];
      formatAttrLocal = [];

      selectedAttributes.filter(({ subAttr, value }) => {
        return formatArrayNewEdit(subAttr, value, formatAttrLocal, 2);
      });

      selectedAttributesState.filter(({ subAttr, value }) => {
        return formatArrayNewEdit(subAttr, value, formatStateAttr, 2);
      });

      attributesStateParams = handleArrayStateParams(
        formatStateAttr,
        formatAttrLocal
      );
      attributesLocal = handleArrayStateLocal(formatAttrLocal, formatStateAttr);
      attributesG = attributesStateParams.concat(attributesLocal);
    } else {
      attributes = [];
      selectedAttributes.filter(({ subAttr, value }) => {
        return formatArrayNewEdit(subAttr, value, attributes, 2);
      });
    }

    //Si tiene precio rebajado
    let precios = [],
      discountBP = [];
    let priceAux, salePriceAux;

    if (discountPrice.discontActive) {
      priceAux = {
        name: "_price",
        value: Number(discountPrice.discountAmount),
      };
      salePriceAux = {
        name: "_sale_price",
        value: Number(discountPrice.discountAmount),
      };
      if (viewState) {
        precios = [
          { ...priceAux, action: "E" },
          { ...salePriceAux, action: "E" },
        ];
      } else {
        precios = [priceAux, salePriceAux];
      }
    } else {
      priceAux = { name: "_price", value: Number(normalPrice) };
      salePriceAux = {
        name: "_sale_price",
        value: Number(discountPrice.discountAmount),
      };
      if (viewState) {
        precios = [
          { ...priceAux, action: "E" },
          { ...salePriceAux, action: "D" },
        ];
      } else {
        precios = [priceAux];
      }
    }

    let fechas = [];
    let saleFromAux = {
      name: "_sale_price_dates_from",
      value: toTimestamp(discountPrice.discountDate.startDate),
    };
    let saleToAux = {
      name: "_sale_price_dates_to",
      value: toTimestamp(discountPrice.discountDate.endDate),
    };
    if (discountPrice.discontActive && discountPrice.discontByDateActive) {
      if (viewState) {
        fechas = [
          { ...saleFromAux, action: "E" },
          { ...saleToAux, action: "E" },
        ];
      } else {
        fechas = [saleFromAux, saleToAux];
      }
    } else {
      fechas = [
        { ...saleFromAux, action: "D" },
        { ...saleToAux, action: "D" },
      ];
    }

    if (viewState) {
      if (discountBancoPopular.active) {
        discountBP = [
          {
            name: "bancoPopular_discount_product",
            value:
              discountBancoPopular.type + "-" + discountBancoPopular.amount,
          },
        ];
      }
    }

    let meta;
    let regularPriceMetaAux = {
      name: "_regular_price",
      value: Number(normalPrice),
    };
    let temperatureMetaAux = {
      name: "product_temperature_handler",
      value: temperature.selectedTemperature.value,
    };
    let consolidatedMetaAux = {
      name: "can_be_consolidated",
      value: temperature.activeTemperature ? "yes" : "no",
    };
    let skuMetaAux = { name: "_sku", value: sku };
    let stockMetaAux = { name: "_stock", value: Number(unitsAvailable) };
    let weightMetaAux = { name: "_weight", value: Number(weight) };
    let lengthMetaAux = { name: "_length", value: Number(length) };
    let widthMetaAux = { name: "_width", value: Number(width) };
    let heightMetaAux = { name: "_height", value: Number(height) };
    let stockStatusMetaAux = {
      name: "_stock_status",
      value: inventoryOption.value,
    };
    if (viewState) {
      meta = [
        { ...regularPriceMetaAux, action: "E" },
        { ...temperatureMetaAux, action: "E" },
        { ...consolidatedMetaAux, action: "E" },
        { ...skuMetaAux, action: "E" },
        { ...stockMetaAux, action: "E" },
        { ...weightMetaAux, action: "E" },
        { ...lengthMetaAux, action: "E" },
        { ...widthMetaAux, action: "E" },
        { ...heightMetaAux, action: "E" },
        { ...stockStatusMetaAux, action: "E" },
        {
          name: "bancoPopular_discount_product",
          value: discountBancoPopular.active
            ? discountBancoPopular.type + "-" + discountBancoPopular.amount
            : null,
          action: viewState.meta.bancoPopular_discount_product
            ? !discountBancoPopular.active
              ? "D"
              : "E"
            : "E",
        },
      ];
    } else {
      meta = [
        regularPriceMetaAux,
        temperatureMetaAux,
        consolidatedMetaAux,
        skuMetaAux,
        stockMetaAux,
        weightMetaAux,
        lengthMetaAux,
        widthMetaAux,
        heightMetaAux,
        stockStatusMetaAux,
        //Cambiar id de vendedor
        { name: "vendedor", value: Number(user.vendorId) },
        //Inserta el rango de entrega
        { name: "range", value: deliveryDistance },
        { name: "_manage_stock", value: "yes" },
      ];
    }

    let relationships = []
    let metaConcat = []
    if (viewState) {
      relationships = visibilityInG.concat(categoryG, attributesG);
      metaConcat = meta.concat(precios, fechas);
    } else {
      relationships = visibilityInG.concat(category, attributes);
      metaConcat = meta.concat(precios, fechas, discountBP);
    }

    //Validamos si es borrador
    post_status = publicOnDraft === "B" ? "draft" : post_status;
    let relatedID = selectedRelatedProducts.map((related) => {
      return Number(related.value);
    });

    let data;
    let data1 = {
      post_title: name,
      post_excerpt: description,
      post_status,
      post_password,
    };
    let data2 = {
      mainImage: mainImage,
      galeryImages: galeryImages,
      meta: metaConcat,
      relationships,
      relatedProducts: {
        name: "_upsell_ids",
        value: relatedID,
      },
    };
    if (viewState) {
      data = {
        ID: Number(viewState.id),
        ...data1,
        changeMainImage,
        ...data2,
        productType,
        variations
      };
      handleUpdateProduct(data);
    } else {
      let data = { ...data1, ...data2 };
      handleInsertProduct(data);
    }
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={SelectArrow} alt="" />
      </components.DropdownIndicator>
    );
  };

  /**handleAttributesFiltered : Sacamos la data del selecct en general*/
  const handleAttributesFiltered = (subAtrr) => {
    let dataConvert, dataAux;
    dataConvert = dataAttributes.filter(
      (atrrSub) => atrrSub.name_slug === subAtrr
    );

    if (dataConvert.length > 0) {
      dataAux = dataConvert[0].value
        ? dataConvert[0].value.map((subAtrr) => {
            return {
              label: subAtrr.name,
              value: subAtrr.id,
            };
          })
        : [];

      return {
        attribute: dataConvert[0].attribute,
        name_slug: dataConvert[0].name_slug,
        value: dataAux,
      };
    } else {
      return [];
    }
  };

  const handleRequest = useCallback(
    async (url, method, params, indicative, msj) => {
      setShowLoading(true);
      try {
        const response =
          method === "POST"
            ? await axios.post(url, params)
            : await axios.get(url);
        if (response.status === 200) {
          let dataParse = null;
          if (indicative === 1) {
            // Todos los atributos
            dataParse = JSON.parse(response.data);

            setDataAttributes(dataParse.attributes);
            setAttributes(
              handleConvertDataSelected(
                dataParse.attributes,
                "attribute",
                "name_slug",
                true
              )
            );

            let categoryFavorite = dataParse.category.filter(
              (cat) => Number(cat.favorite) === 1
            );
            if (categoryFavorite.length > 0) {
              setCategories(
                handleConvertDataSelected(categoryFavorite, "name", "id", false)
              );
            } else {
              setCategories(
                handleConvertDataSelected(
                  dataParse.category,
                  "name",
                  "id",
                  false
                )
              );
            }

            setRelatedProducts(
              handleConvertDataSelected(
                dataParse.relatedProducts,
                "post_title",
                "id",
                false
              )
            );

            setShowLoading(false);
          } else if (indicative === 2) {
            // Los atributos por ID
            dataParse = JSON.parse(response.data);
            setVariations(dataParse.variations)
            setProductType(dataParse.productType)

            if (dataParse.result[0].attribute) {
              setSelectedAttributes(handleAttributeCopy(dataParse));
              setSelectedAttributesState(handleAttributeCopy(dataParse));

              setShowLoading(false);
            }
          }
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: `Ha ocurrido un error al tener información ${msj}`,
          icon: "error",
        });
        setShowLoading(false);
      }
    },
    []
  );

  const handleUpdateProduct = useCallback(async (data) => {
    /* Read more about isConfirmed, isDenied below */

    try {
      setShowLoading(true);
      const response = await axios.post(
        "https://kakaomarket.cr/wp-json/sellerconsole/v1/update_producto_inventary",
        {
          data: data,
        }
      );

      if (response.status === 200) {
        Swal.fire("Producto Actualizado!", "", "success");
        props.history.push("/home/inventory");
        setShowLoading(false);
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Ha ocurrido un error al actualizar el producto del inventario",
        icon: "error",
      });
      setShowLoading(false);
    }
  }, []);

  const handleInsertProduct = useCallback(async (data) => {
    try {
      setShowLoading(true);
      const response = await axios.post(
        "https://kakaomarket.cr/wp-json/sellerconsole/v1/insertproduct",
        {
          data: data,
        }
      );

      if (response.status === 200) {
        Swal.fire("¡Producto insertado!", "", "success");
        props.history.push("/home/inventory");
        setShowLoading(false);
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Ha ocurrido un error al insertar el producto del inventario.",
        icon: "error",
      });
      setShowLoading(false);
    }
  }, []);

  function checkRequiredFields() {
    var nullFields = [];
    if (name === "") {
      nullFields.push("Nombre del producto");
      setIsNullProductName(true);
    } else {
      setIsNullProductName(false);
    }

    if (description === "") {
      nullFields.push("Descripción");
      setIsNullDescription(true);
    } else {
      setIsNullDescription(false);
    }

    if (selectedCategories.length === 0) {
      nullFields.push("Categorías");
      setIsNullCategory(true);
    } else {
      setIsNullCategory(false);
    }

    if (visibilityOption === "") {
      nullFields.push("Visibilidad");
      setIsNullVisibility(true);
      setIsNullPassword(false);
    } else if (visibilityOption.value === "protegido") {
      setIsNullVisibility(false);
      if (password === "") {
        nullFields.push("Contraseña");
        setIsNullPassword(true);
      } else {
        setIsNullPassword(false);
      }
    } else {
      setIsNullVisibility(false);
      setIsNullPassword(false);
    }

    if (visibilityPlaceOption === "") {
      nullFields.push("Visible en");
      setIsNullVisibleIn(true);
    } else {
      setIsNullVisibleIn(false);
    }

    if (normalPrice < 1 && productType=='simple') {
      nullFields.push("Precio normal");
      setIsNullPrice(true);
    } else {
      setIsNullPrice(false);
    }

    if (discountPrice.discontActive) {
      if (discountPrice.discountAmount < 1) {
        nullFields.push("Precio rebajado ");
        setIsNullReducedPrice(true);
      } else {
        setIsNullReducedPrice(false);
      }
    } else {
      setIsNullReducedPrice(false);
    }

    if (discountPrice.discontByDateActive) {
      if (discountPrice.discountDate.startDate === "") {
        nullFields.push("Fecha inicio de precio rebajado ");
        setIsNullStartDateReducedPrice(true);
      } else {
        setIsNullStartDateReducedPrice(false);
      }
      if (discountPrice.discountDate.endDate === "") {
        nullFields.push("Fecha fin de precio rebajado ");
        setIsNullFinishDateReducedPrice(true);
      } else {
        setIsNullFinishDateReducedPrice(false);
      }
    } else {
      setIsNullStartDateReducedPrice(false);
      setIsNullFinishDateReducedPrice(false);
    }

    if (inventoryOption === "") {
      nullFields.push("Inventario");
      setIsNullInventory(true);
      setIsNullUnitsAvailable(false);
    } else if (inventoryOption.value === "instock") {
      setIsNullInventory(false);
      if (unitsAvailable < 1) {
        nullFields.push("Unidades disponibles");
        setIsNullUnitsAvailable(true);
      } else {
        setIsNullUnitsAvailable(false);
      }
    } else {
      setIsNullInventory(false);
      setIsNullUnitsAvailable(false);
    }

    if (temperature.activeTemperature) {
      if (temperature.selectedTemperature === "") {
        nullFields.push("Temperatura");
        setIsNullTemperature(true);
      } else {
        setIsNullTemperature(false);
      }
    } else {
      setIsNullTemperature(false);
    }

    if (deliveryRange) {
      if (deliveryType === null) {
        nullFields.push("Rango de entrega");
        setIsNullDeliveryRange(true);
      } else {
        if (deliveryDistance === null) {
          nullFields.push("Rango de entrega");
          setIsNullDeliveryRange(true);
        } else {
          setIsNullDeliveryRange(false);
        }
      }
    } else {
      setIsNullDeliveryRange(false);
    }

    if(viewState && changeMainImage){
      if (!mainImage.name) {
        nullFields.push("Imagen principal");
        setIsNullImage(true);
      } else {
        setIsNullImage(false);
      }
    }
    if (!viewState && !mainImage.name) {
      nullFields.push("Imagen principal");
      setIsNullImage(true);
    } else {
      setIsNullImage(false);
    }

    //variaciones
    variations.forEach(variation => {
      var key = Object.keys(variation.attributes)[0]
      if (variation.display_regular_price < 1) {
        nullFields.push("Variación " + variation.attributes[key] + " ");
      }
      if (variation.discountActive) {
        if (variation.display_price >= variation.display_regular_price ||
          variation.display_price == '') {
          nullFields.push("Variación " + variation.attributes[key] + " ");
        }
      }
    });

    return nullFields;
  }

  async function getGalleryImgs(id) {
    try {
      const response = await axios.post(
        "https://kakaomarket.cr/wp-json/sellerconsole/v1/get_gallery_imgs",
        {
          post_id: id,
        }
      );
      var responseimgs = JSON.parse(response.data).result
      responseimgs = responseimgs.map((img) => {
        return {
          ...img,
          action: "E",
        };
      });
  
      return responseimgs.length > 0 ? responseimgs : null
    } catch (error) {
      console.log("Error al obtener la galeria de imagenes: ", error);
    }
  }

  useEffect(() => {
    if (viewState) {
      handleRequest(
        "https://kakaomarket.cr/wp-json/sellerconsole/v1/get_product_attributes_by_id",
        "POST",
        { idProduct: viewState.id },
        2,
        "de los atributos del producto"
      );
    }
    handleRequest(
      "https://kakaomarket.cr/wp-json/sellerconsole/v1/get_product_extra_info",
      "POST",
      {
        idSeller: user.vendorId,
        email: user.user_email
      },
      //   {
      //     "idSeller": "12739",
      //     "email": "fjos807@gmail.com"
      // },
      1,
      "del listado de atributos"
    );
  }, []);

  useEffect(async () => {
    if (viewState) {
      setName(viewState.post_title ? viewState.post_title : "");
      setPassword(viewState.post_password ? viewState.post_password : "");
      setVisisblePassword(
        viewState.visiblePassword ? viewState.visiblePassword : false
      );
      setDescription(viewState.post_excerpt);
      setSelectedCategories(viewState.category ? viewState.category : []);
      setVisibilitOption(
        viewState.visibilityOption ? viewState.visibilityOption : ""
      );
      setVisibilityPlaceOption(
        viewState.visibilityPlaceOption ? viewState.visibilityPlaceOption : ""
      );
      setFeaturedProduct(viewState.outstanding ? viewState.outstanding : false);
      setNormalPrice(
        viewState.meta._regular_price ? viewState.meta._regular_price : 0
      );
      setSku(viewState.meta._sku ? viewState.meta._sku : "");
      setInventoryOption(
        viewState.inventoryOption ? viewState.inventoryOption : ""
      );
      setUnitsAvailable(viewState.meta._stock ? viewState.meta._stock : 0);
      setWeight(viewState.meta._weight ? viewState.meta._weight : 0);

      setTemperature({
        activeTemperature:
          viewState.meta.can_be_consolidated === "yes" ? true : false,
        selectedTemperature: viewState.temperatureOption
          ? viewState.temperatureOption
          : "",
        enabledCheck: viewState.enabledCheckTemp
          ? viewState.enabledCheckTemp
          : false,
      });

      setLength(viewState.meta._length ? viewState.meta._length : 0);
      setWidth(viewState.meta._width ? viewState.meta._width : 0);
      setHeight(viewState.meta._height ? viewState.meta._height : 0);
      setMainImage(
        viewState.imagen ? viewState.imagen : {}
      );

      var galleryImgsBase64 = await getGalleryImgs(viewState.id)
      setGaleryImages(galleryImgsBase64 ? galleryImgsBase64 : []);

      setSelectedRelatedProducts(
        viewState.relatedProducts ? viewState.relatedProducts : []
      );

      setDiscountPrice({
        discontActive: viewState.meta._sale_price ? true : false,
        discountAmount: viewState.meta._sale_price
          ? viewState.meta._sale_price
          : 0,
        discontByDateActive: viewState.meta._sale_price_dates_from
          ? true
          : false,
        discountDate: {
          startDate: viewState.meta._sale_price_dates_from
            ? handleConvertDate(viewState.meta._sale_price_dates_from)
            : "",
          endDate: viewState.meta._sale_price_dates_from
            ? handleConvertDate(viewState.meta._sale_price_dates_to)
            : "",
        },
      });

      setDiscountBancoPopular(
        viewState.meta.bancoPopular_discount_product
          ? {
              active: viewState.meta.bancoPopular_discount_product
                ? true
                : false,
              type: viewState.meta.bancoPopular_discount_product
                ? viewState.meta.bancoPopular_discount_product.includes("P")
                  ? "P"
                  : "T"
                : "T",
              amount: viewState.meta.bancoPopular_discount_product
                ? parseInt(
                    viewState.meta.bancoPopular_discount_product.substr(2)
                  )
                : 0,
            }
          : { active: false, type: "", amount: 0 }
      );
    }
  }, [viewState]);

  return (
    <div className="mainContainer">
      <div>
        <span className="newProductTitle">Agregar producto</span>
      </div>
      <div className="topContainer">
        <div className="generalInfoContainer">
          <span className="sectionTitle">Información general</span>
          <div className="inputContainer">
            <div>
              <span className="sectionSubTitle">Nombre del producto</span>
              {isNullProductName ? (
                <span
                  className="sectionSubTitle"
                  style={{
                    color: "red",
                    marginLeft: "7px",
                    fontWeight: "inherit",
                  }}
                >
                  Requerido *
                </span>
              ) : (
                <span style={{ color: "red" }}>*</span>
              )}
            </div>
            <input
              className="customInput"
              type="text"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </div>
          <div className="inputContainer">
            <div>
              <span className="sectionSubTitle">Descripción</span>
              {isNullDescription ? (
                <span
                  className="sectionSubTitle"
                  style={{
                    color: "red",
                    marginLeft: "7px",
                    fontWeight: "inherit",
                  }}
                >
                  Requerido *
                </span>
              ) : (
                <span style={{ color: "red" }}>*</span>
              )}
            </div>
            <textarea
              className="customInput"
              rows="10"
              value={description}
              onChange={handleDescription}
              style={{ height: "10vh" }}
            />
            <div className="charCounterContainer">
              <span className="charCounter">{description.length}/10000</span>
            </div>
          </div>
          <div>
            <div className="selectContainer isFlexColumn">
              <div className="helperContainer">
                <span className="sectionSubTitle">Categorías</span>
                {isMobile && <ErroRequired isNull={isNullCategory} />}
              </div>

              <div className="helperContainer">
                <Select
                  className=""
                  placeholder="Seleccionar"
                  onChange={handleSelectCategories}
                  options={categories}
                  styles={{
                    ...selectStylesCategory,
                    control: (styles) => ({
                      ...styles,
                      width: isTablet ? "150px" : "250px",
                    }),
                  }}
                  components={{ DropdownIndicator }}
                />

                {!isMobile && <ErroRequired isNull={isNullCategory} />}
                <img
                  src={Helper}
                  alt=""
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                  onClick={toggleInfoCategoriaVisiblity}
                />
              </div>

              {showInfoCategoria ? (
                <div className="helperInfoContainer">
                  <table>
                    <td style={{ whiteSpace: "normal", border: "0px" }}>
                      <tr>
                        <h6 style={{ color: "#37474F", textAlign: "justify" }}>
                          Esta categoría permitirá ubicar el producto en los
                          filtros de búsqueda.
                        </h6>
                      </tr>
                    </td>
                    <td style={{ border: "0px" }}>
                      <tr>
                        <div className="infoXContainer">
                          <h6
                            className="h6-info-X"
                            onClick={toggleInfoCategoriaVisiblity}
                          >
                            x
                          </h6>
                        </div>
                      </tr>
                    </td>
                  </table>
                </div>
              ) : (
                <div></div>
              )}
            </div>
            <div className="itemListContainer">
              {selectedCategories.map((category) => (
                <div key={category.value} className="itemContainer">
                  <img
                    src={CloseIcon}
                    alt=""
                    onClick={() => handleDeleteCategory(category)}
                    style={{ marginRight: "3px" }}
                  />
                  <span>{category.label}</span>
                </div>
              ))}
            </div>
          </div>
          <div></div>
        </div>
        <div className="publishContainer">
          <span className="sectionTitle">Publicar</span>
          <div className="publishSubContainer">
            <div
              className={"selectContainer"}
              style={{ flexDirection: `${isTablet ? "column" : "row"}` }}
            >
              <span className="sectionSubTitle">Publicar</span>

              <Select
                className=""
                placeholder="Seleccionar"
                onChange={(option) => setPublishOption(option.value)}
                options={publishOptions}
                styles={{
                  ...selectStylesPublish,
                  control: (styles) => ({
                    ...styles,
                    width: isDesktop
                      ? "200px"
                      : isTablet
                      ? "180px"
                      : isMobile
                      ? "150px"
                      : "10vw",
                  }),
                }}
                components={{ DropdownIndicator }}
                defaultValue={publishOptions[0]}
              />
            </div>
            <div
              className="selectContainer"
              style={{ flexDirection: `${isTablet ? "column" : "row"}` }}
            >
              <span className="sectionSubTitle">Visibilidad</span>
              <div style={{ display: "flex", alignItems: "baseline" }}>
                <Select
                  className=""
                  placeholder="Seleccionar"
                  onChange={(option) => {
                    setVisisblePassword(
                      option.value === "protegido" ? true : false
                    );
                    setVisibilitOption(option);
                  }}
                  options={visibilityOptions}
                  styles={{
                    ...selectStylesVisibility,
                    control: (styles) => ({
                      ...styles,
                      width: isDesktop
                        ? "200px"
                        : isTablet
                        ? "180px"
                        : isMobile
                        ? "150px"
                        : "7vw",
                    }),
                  }}
                  components={{ DropdownIndicator }}
                  defaultValue={visibilityOptions[0]}
                />
                <ErroRequired isNull={isNullVisibility} />
              </div>
            </div>
            {visiblePassword && (
              <div className="selectContainer">
                <div
                  className="selectContainer"
                  style={{ flexDirection: `${isTablet ? "column" : "row"}` }}
                >
                  <span className="sectionSubTitle">Contraseña</span>
                  <input
                    className="customInput"
                    type="password"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                  />
                  <ErroRequired isNull={isNullPassword} />
                </div>
              </div>
            )}
            <div
              className="selectContainer"
              style={{ flexDirection: `${isTablet ? "column" : "row"}` }}
            >
              <span className="sectionSubTitle">Visible en</span>
              <div style={{ display: "flex", alignItems: "baseline" }}>
                <Select
                  className=""
                  placeholder="Seleccionar"
                  onChange={(option) => setVisibilityPlaceOption(option)}
                  options={visibilityPlaceOptions}
                  styles={{
                    ...selectStylesVisibilityPlace,
                    control: (styles) => ({
                      ...styles,
                      width: isDesktop
                        ? "200px"
                        : isTablet
                        ? "180px"
                        : isMobile
                        ? "150px"
                        : "10vw",
                    }),
                  }}
                  components={{ DropdownIndicator }}
                  defaultValue={visibilityPlaceOptions[0]}
                />
                <ErroRequired isNull={isNullVisibleIn} />
              </div>
            </div>
            <div className="productOuts">
              <CustomCheckbox
                label="Producto destacado"
                checked={featuredProduct}
                handleChange={(event) =>
                  setFeaturedProduct(event.target.checked)
                }
                className="sectionSubTitle"
              />
            </div>
            <button
              className="publishButton"
              onClick={() => {
                handlePublishButton("P");
              }}
            //acá
            >
              Publicar
            </button>
            <button
              className="saveDraftButton"
              onClick={() => {
                handlePublishButton("B");
              }}
            >
              Guardar como borrador
            </button>
          </div>
        </div>

        <div className="productDetail">
          <span className="sectionTitle">Detalle del producto</span>
          <div style={{ marginTop: "1vh" }}>
            <Tabs>
              <Tab label="Inventario">
                <div className="tabContainer">
                  <div className="tabInnerContainer">
                    <div className="inputContainer">
                      <span className="sectionSubTitle">Sku</span>
                      <div className="skuInputContainer">
                        <input
                          className="customInput"
                          type="text"
                          style={{ width: "95%" }}
                          value={sku}
                          onChange={(event) => setSku(event.target.value)}
                        />
                        <img
                          src={Helper}
                          alt=""
                          style={{ marginLeft: "10px", marginRight: "10px" }}
                          onClick={toggleInfoSKUVisiblity}
                        />
                      </div>
                      {showInfoSKU ? (
                        <div className="helperInfoContainer2">
                          <table>
                            <td style={{ whiteSpace: "normal", border: "0px" }}>
                              <tr>
                                <h6
                                  style={{
                                    color: "#37474F",
                                    textAlign: "justify",
                                  }}
                                >
                                  Stock Keeping Unit: Número de referencia único
                                  de un producto.
                                </h6>
                              </tr>
                            </td>
                            <td style={{ border: "0px" }}>
                              <tr>
                                <div className="infoXContainer">
                                  <h6
                                    className="h6-info-X"
                                    onClick={toggleInfoSKUVisiblity}
                                  >
                                    x
                                  </h6>
                                </div>
                              </tr>
                            </td>
                          </table>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                  <div
                    className="tabInnerContainer"
                    style={{ marginTop: "2vh" }}
                  >
                    <div className="selectContainer isFlexColumnG">
                      <span className="sectionSubTitle">Inventario</span>
                      <div
                        style={{
                          display: "flex",
                          justifyItems: "center",
                          alignItems: "baseline",
                        }}
                      >
                        <Select
                          className=""
                          placeholder="Seleccionar"
                          onChange={(option) => setInventoryOption(option)}
                          options={inventaryOptions}
                          styles={{
                            ...selectStylesFullWidth,
                            control: (styles) => ({
                              ...styles,
                              width: isMobile ? "200px" :isTablet || isDesktop ? "185px" : "20vh",
                            }),
                          }}
                          components={{ DropdownIndicator }}
                          defaultValue={inventoryOption}
                        />
                        <ErroRequired isNull={isNullInventory} />
                      </div>
                    </div>
                    <div className="inputContainer">
                      <div className={"helperContainer"}>
                        <span className="sectionSubTitle">
                          Unidades disponibles
                        </span>

                        {inventoryOption.value === "instock" ? (
                          <div>
                            <ErroRequired isNull={isNullUnitsAvailable} />
                          </div>
                        ) : null}
                      </div>

                      <input
                        className="customInput"
                        type="number"
                        value={unitsAvailable}
                        onChange={(event) =>
                          setUnitsAvailable(event.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </Tab>
              { productType == 'simple' ?
                (
                    <Tab label="Precio">
                      <div className="tabContainer">
                        <div className="tabInnerContainer">
                          <div className="inputContainer">
                            <div>
                              <span className="sectionSubTitle">Precio normal ₡</span>
                              <ErroRequired isNull={isNullPrice} />
                            </div>

                            <input
                              className="customInput"
                              type="number"
                              value={normalPrice}
                              onChange={(event) => setNormalPrice(event.target.value)}
                            />
                          </div>
                        </div>
                        <div className="tabInnerContainer">
                          <div
                            className="inputContainer"
                            style={{ marginTop: "3.5vh" }}
                          >
                            <div className="continer-text-require">
                              <CustomCheckbox
                                label="Precio rebajado ₡"
                                checked={discountPrice.discontActive}
                                handleChange={(event) => {
                                  if (!event.target.checked) {
                                    setDiscountPrice({
                                      ...discountPrice,
                                      discontActive: event.target.checked,
                                      discontByDateActive: event.target.checked,
                                    });
                                  } else {
                                    setDiscountPrice({
                                      ...discountPrice,
                                      discontActive: event.target.checked,
                                    });
                                  }
                                }}
                                className="sectionSubTitle"
                              />
                              {discountPrice.discontActive ? (
                                <div>
                                  {isMobile && (
                                    <ErroRequired isNull={isNullReducedPrice} />
                                  )}
                                </div>
                              ) : null}
                            </div>
                            <input
                              className="customInput checkInput"
                              type="number"
                              value={discountPrice.discountAmount}
                              disabled={!discountPrice.discontActive}
                              onChange={(event) =>
                                setDiscountPrice({
                                  ...discountPrice,
                                  discountAmount: event.target.value,
                                })
                              }
                              style={{ marginTop: "15px", marginBottom: "15px" }}
                            />
                          </div>
                          <div className="inputContainer">
                            <div>
                              <CustomCheckbox
                                label="Fecha de precio rebajado"
                                checked={discountPrice.discontByDateActive}
                                handleChange={(event) => {
                                  if (discountPrice.discontActive) {
                                    setDiscountPrice({
                                      ...discountPrice,
                                      discontByDateActive: event.target.checked,
                                    });
                                  }
                                }}
                                className="sectionSubTitle"
                              />
                            </div>
                            {discountPrice.discontByDateActive && (
                              <div
                                className="dateContainer"
                                style={{ marginTop: "10px" }}
                              >
                                <div
                                  className="dateSelectorContainer"
                                  style={{ marginLeft: "15px" }}
                                >
                                  <span className="sectionSubTitle">Desde</span>
                                  <input
                                    className="customInput"
                                    type="date"
                                    value={discountPrice.discountDate.startDate}
                                    onChange={(event) => {
                                      setDiscountPrice({
                                        ...discountPrice,
                                        discountDate: {
                                          ...discountPrice.discountDate,
                                          startDate: event.target.value,
                                        },
                                      });
                                    }}
                                  />
                                  <ErroRequired
                                    isNull={isNullStartDateReducedPrice}
                                  />
                                </div>
                                <div
                                  className="dateSelectorContainer"
                                  style={{ marginLeft: "10px" }}
                                >
                                  <span className="sectionSubTitle">Hasta</span>
                                  <input
                                    className="customInput"
                                    type="date"
                                    value={discountPrice.discountDate.endDate}
                                    onChange={(event) =>
                                      setDiscountPrice({
                                        ...discountPrice,
                                        discountDate: {
                                          ...discountPrice.discountDate,
                                          endDate: event.target.value,
                                        },
                                      })
                                    }
                                  />
                                  <ErroRequired
                                    isNull={isNullFinishDateReducedPrice}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          <div
                            className="inputContainer"
                            style={{ marginTop: "3.5vh" }}
                          >
                            <div>
                              <CustomCheckbox
                                label="Descuento del Banco Popular"
                                checked={discountBancoPopular.active}
                                handleChange={(event) => {
                                  setDiscountBancoPopular({
                                    active: event.target.checked,
                                    type: "",
                                    amount: 0,
                                  });
                                }}
                                className="sectionSubTitle"
                              />
                            </div>
                            {discountBancoPopular.active && (
                              <div style={{ marginLeft: "3%" }}>
                                <div
                                  className="selectContainer isFlexColumnG"
                                  style={{ marginTop: "2vh" }}
                                >
                                  <span className="sectionSubTitle">
                                    Tipo de descuento
                                  </span>
                                  <Select
                                    className=""
                                    placeholder="Seleccionar"
                                    onChange={(option) =>
                                      setDiscountBancoPopular({
                                        ...discountBancoPopular,
                                        type: option.value,
                                        amount: 0,
                                      })
                                    }
                                    options={discountBancoPopularOptions}
                                    styles={{
                                      ...selectStylesFullWidth,
                                      control: (styles) => ({
                                        ...styles,
                                        width: isTablet ? "185px" : "20vh",
                                      }),
                                    }}
                                    components={{ DropdownIndicator }}
                                    defaultValue={defaultDiscountBancoPopularOption}
                                  />
                                </div>
                                <div>
                                  <span className="sectionSubTitle" style={{marginRight:"5px"}}>Monto</span>
                                  <input
                                    className="customInput checkInput"
                                    type="number"
                                    value={discountBancoPopular.amount}
                                    onChange={(event) =>
                                      setDiscountBancoPopular({
                                        ...discountBancoPopular,
                                        amount: event.target.value,
                                      })
                                    }
                                    style={{
                                      marginTop: "15px",
                                      marginBottom: "15px",
                                      marginLeft: 0,
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Tab>
                ) :
                (
                    <Tab label="Variaciones">
                      <div className="tabContainerSingle">
                        <div className="tabInnerContainer"  style={{ marginTop: "2vh" }}>
                          {variations.map((variation, index) => {
                            var key = Object.keys(variation.attributes)[0]
                            return (
                              <div key={index}>
                                <span className="sectionTitle">{"Variación " + variation.attributes[key]}</span>
                                <div className="tabContainer">
                                  <div className="tabInnerContainer">
                                    <div className="inputContainer">
                                      <div>
                                        <span className="sectionSubTitle">Precio normal ₡</span>
                                      </div>

                                      <input
                                        className="customInput"
                                        type="number"
                                        value={variation.display_regular_price}
                                        onChange={(event) => handleVariationRegularPrice(index, event)}
                                      />
                                    </div>
                                  </div>
                                  <div className="tabInnerContainer">
                                    <div
                                      className="inputContainer"
                                      style={{ marginTop: "3.5vh" }}
                                    >
                                      <div className="continer-text-require">
                                        <CustomCheckbox
                                          className="sectionSubTitle"
                                          label="Precio rebajado ₡"
                                          checked={variation.discountActive}
                                          handleChange={(event) => handleVariationDiscountActive(index, event)}
                                        />
                                      </div>
                                      <input
                                        className="customInput checkInput"
                                        type="number"
                                        value={variation.display_price}
                                        disabled={!variation.discountActive}
                                        onChange={(event) => handleVariationDisplayPrice(index, event) }
                                        style={{ marginTop: "15px", marginBottom: "15px" }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </Tab>
                )
              }
              <Tab label="Envío">
                <div className="tabContainer">
                  <div className="tabInnerContainer">
                    <div className="inputContainer">
                      <span className="sectionSubTitle">Peso ( kg )</span>
                      <input
                        className="customInput"
                        type="number"
                        value={weight}
                        onChange={(event) => setWeight(event.target.value)}
                      />
                    </div>
                    <div style={{ marginTop: isMobile ? "25px" : "2vh" }}>
                      <div
                        className="selectContainer isFlexColumnG"
                        style={{ marginTop: "2vh" }}
                      >
                        <div className="helperContainer">
                          <CustomCheckbox
                            label="Temperatura"
                            checked={temperature.activeTemperature}
                            handleChange={(event) =>
                              setTemperature({
                                ...temperature,
                                activeTemperature: event.target.checked,
                              })
                            }
                            className="sectionSubTitle"
                            disabled={temperature.enabledCheck}
                          />

                          {temperature.activeTemperature ? (
                            <div>
                              <ErroRequired isNull={isNullTemperature} />
                            </div>
                          ) : null}
                        </div>
                        {temperature.activeTemperature ? (
                          <div className="selectspecial">
                            <Select
                              className=""
                              placeholder="Seleccionar"
                              onChange={(option) => {
                                let enabledCheck = false,
                                  isChecked = false;
                                if (option.value === "caliente") {
                                  enabledCheck = true;
                                  isChecked = false;
                                } else {
                                  if (temperature.activeTemperature) {
                                    isChecked = true;
                                  }
                                }

                                setTemperature({
                                  ...temperature,
                                  selectedTemperature: option,
                                  enabledCheck: enabledCheck,
                                  activeTemperature: isChecked,
                                });
                              }}
                              options={temperatureOptions}
                              styles={{
                                ...selectStylesFullWidth,
                                control: (styles) => ({
                                  ...styles,
                                  width: isDesktop
                                    ? "160px"
                                    : isTablet
                                    ? "250px"
                                    : isMobile
                                    ? "200px"
                                    : "20vh",
                                }),
                              }}
                              components={{ DropdownIndicator }}
                              //   isDisabled={!temperature.activeTemperature}
                              defaultValue={temperature.selectedTemperature}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div style={{ marginTop: isMobile ? "25px" : "2vh" }}>
                      <div
                        className="selectContainer isFlexColumnG"
                        style={{ marginTop: "2vh" }}
                      >
                        <div className="helperContainer">
                          <CustomCheckbox
                            label="Producto con rango de entrega"
                            checked={deliveryRange}
                            handleChange={() => {
                              setDeliveryRange(!deliveryRange);
                            }}
                            className="sectionSubTitle"
                          />

                          {deliveryRange ? (
                            <div>
                              <ErroRequired isNull={isNullDeliveryRange} />
                            </div>
                          ) : null}
                        </div>
                        {deliveryRange ? (
                          <div className="selectspecial">
                            <Select
                              className=""
                              placeholder="Seleccionar"
                              onChange={(value) => setDeliveryType(value.value)}
                              options={deliveryOptions}
                              styles={{
                                ...selectStylesFullWidth,
                                control: (styles) => ({
                                  ...styles,
                                  width: isDesktop
                                    ? "160px"
                                    : isTablet
                                    ? "250px"
                                    : isMobile
                                    ? "200px"
                                    : "20vh",
                                }),
                              }}
                              components={{ DropdownIndicator }}
                            />
                          </div>
                        ) : (
                          <div style={{ marginTop: "4.5%" }} />
                        )}
                        {getInputType()}
                      </div>
                    </div>
                  </div>
                  <div className="tabInnerContainer">
                    <div className="inputContainer">
                      <span className="sectionSubTitle">Longitud ( cm )</span>
                      <input
                        className="customInput"
                        type="number"
                        value={length}
                        onChange={(event) => setLength(event.target.value)}
                      />
                    </div>
                    <div className="inputContainer">
                      <span className="sectionSubTitle">Anchura ( cm )</span>
                      <input
                        className="customInput"
                        type="number"
                        value={width}
                        onChange={(event) => setWidth(event.target.value)}
                      />
                    </div>
                    <div className="inputContainer">
                      <span className="sectionSubTitle">Altura ( cm )</span>
                      <input
                        className="customInput"
                        type="number"
                        value={height}
                        onChange={(event) => setHeight(event.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab label="Atributos">
                <div className="tabContainerSingle">
                  <div className="tabInnerContainer">
                    <div
                      className="selectContainer isFlexColumn"
                      style={{ marginTop: "2vh" }}
                    >
                      <span className="sectionSubTitle">Atributos</span>
                      <Select
                        className=""
                        placeholder="Seleccionar"
                        onChange={(value) => {
                          handleSelectAtributes(value);
                        }}
                        options={attributes}
                        styles={{
                          ...selectStylesFullWidth,
                          control: (styles) => ({
                            ...styles,
                            width: isMobile || isTablet ? "200px" : "20vh",
                          }),
                        }}
                        components={{ DropdownIndicator }}
                      />
                    </div>
                  </div>
                </div>
                {selectedAttributes.length > 0 && (
                  <hr style={{ margin: "0 32px", color: "#C8CBCC" }} />
                )}

                {selectedAttributes.map((attr, idx) => {
                  return (
                    <Fragment key={`${attr.value}${attr.label}${idx}`}>
                      <SubAttributes
                        data={handleAttributesFiltered(attr.value)}
                        keyFather={attr}
                        handleSubAttributesOptions={handleSubAttributesOptions}
                        //   handleSelectSubAtributtes={handleSelectSubAtributtes}
                      />
                      {idx !== selectedAttributes.length - 1 && (
                        <hr style={{ margin: "0 32px", color: "#C8CBCC" }} />
                      )}
                    </Fragment>
                  );
                })}
              </Tab>
              <Tab label="Imagen">
                <div className="tabContainer">
                  <div
                    className="tabInnerContainer "
                    style={{ marginTop: "2vh" }}
                  >
                    <div className="selectContainer isFlexColumnG">
                      <span className="sectionSubTitle">Imágen principal</span>
                      <div className="fileInput">
                        <input
                          type="file"
                          id="mainImage"
                          className="file"
                          onChange={(event) => handleAddImage(event, "main")}
                          accept="image/png, image/jpeg"
                        />
                        <label htmlFor="mainImage">Seleccionar imágen</label>
                        <ErroRequired isNull={isNullImage} />
                      </div>
                    </div>
                    {mainImage.name ? 
                    (
                      <img
                        src={mainImage.base64}
                        alt=""
                        className="mainImage"
                      />
                    ) : 
                    (
                      viewState ?
                      (
                        <img
                          src={changeMainImage ? mainImage.base64 : mainImage}
                          alt=""
                          className="mainImage"
                        />
                      ) :
                      (
                        <div></div>
                      )
                    )}
                  </div>
                  <div
                    className="tabInnerContainer"
                    style={{ marginTop: "2vh" }}
                  >
                    <span className="sectionSubTitle">Imágenes galería</span>
                    <div className="galeryContainer">
                      {galeryImages.map((image) => (
                        <div style={{ position: "relative" }}>
                          <img
                            key={image.name}
                            src={image.base64}
                            alt=""
                            className="secondImage"
                          />
                          <img
                            src={CloseIcon}
                            alt="CloseIcon"
                            onClick={() => {
                              handleDeleteImg(image.base64);
                            }}
                            className={"closeImagen"}
                          />
                        </div>
                      ))}
                      <div className="secondImageContainer">
                        <input
                          type="file"
                          id="secondImage"
                          className="file"
                          onChange={(event) => handleAddImage(event, "second")}
                          accept="image/png, image/jpeg"
                          //webkitdirectory={galeryImages.length > 0 ? true : false}
                        />
                        <label htmlFor="secondImage">
                          <img
                            src={AddIcon}
                            alt=""
                            style={{ cursor: "pointer" }}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab label="Productos relacionados">
                <div className="tabContainerSingle">
                  <div style={{ width: "100%" }}>
                    <div className="tabInnerContainer">
                      <div
                        className="selectContainer isFlexColumn"
                        style={{ marginTop: "2vh" }}
                      >
                        <span className="sectionSubTitle">
                          Productos relacionados
                        </span>
                        <Select
                          className=""
                          placeholder="Seleccionar"
                          onChange={handleSelectRelatedProduct}
                          options={relatedProducts}
                          styles={{
                            ...selectStylesFullWidth,
                            control: (styles) => ({
                              ...styles,
                              width: isMobile
                                ? "200px"
                                : isTablet
                                ? "185px"
                                : "20vh",
                            }),
                          }}
                          components={{ DropdownIndicator }}
                        />
                      </div>
                    </div>
                    <div className="itemListContainer">
                      {selectedRelatedProducts.map((product) => (
                        <div key={product.value} className="itemContainer">
                          <img
                            src={CloseIcon}
                            alt=""
                            onClick={() => handleDeleteRelatedProduct(product)}
                            style={{ marginRight: "3px" }}
                          />
                          <span>{product.label}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

const SubAttributes = ({ data, keyFather, handleSubAttributesOptions }) => {
  //   const [subAttributes, setSubAttributes] = useState(data.value); // general todos con el API
  const handleDeleteSubAttributtes = (subAtrr) => {
    handleSubAttributesOptions(keyFather.value, subAtrr, "D");
  };

  /**Metodo global solo para nuevos */
  const handleSelectSubAtributtesLocal = (value) => {
    handleSubAttributesOptions(keyFather.value, value, "U");
  };

  const selectSubAttributtes = {
    control: (styles) => ({ ...styles, width: "250px" }),
    indicatorSeparator: () => ({ display: "none" }),
  };

  return (
    <div className="subattributesContainer">
      <div className="selectContainer isFlexColumn">
        <div style={{display:'flex', justifyItems:'center'}}>
          <input type="checkbox" style={{ marginRight: "5px", marginTop:'5px' }} />
          <span className="sectionSubTitle">{data.attribute}</span>
        </div>
        <Select
          className=""
          placeholder="Seleccionar"
          onChange={(value) => {
            handleSelectSubAtributtesLocal(value);
          }}
          options={data.value}
          styles={selectSubAttributtes}
          //components={{ DropdownIndicator }}
        />
      </div>
      <div className="itemListContainer" style={{ marginLeft: "20px" }}>
        {keyFather.subAttr.map((subAtrr, idx) => (
          <div key={`${subAtrr.label}${idx}`} className="itemContainer">
            <img
              src={CloseIcon}
              alt=""
              onClick={() => handleDeleteSubAttributtes(subAtrr)}
              style={{ marginRight: "3px" }}
            />
            <span>{subAtrr.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

const ErroRequired = ({ isNull }) => (
  <>
    {isNull ? (
      <span
        className="sectionSubTitle"
        style={{
          color: "red",
          marginLeft: "7px",
          fontWeight: "inherit",
        }}
      >
        Requerido *
      </span>
    ) : (
      <span style={{ color: "red", marginLeft: "7px" }}> *</span>
    )}
  </>
);
export default NewProduct;
