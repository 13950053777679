import React from "react";
import "./customCheckbox.css";

const CustomCheckbox = (props) => {
  return (
    <label className="container">
      <span className={props.className} style={{ marginTop: "2px" , marginLeft: "15px"}}>
        {props.label || ""}
      </span>
      <input
        type="checkbox"
        checked={props.checked}
        onChange={props.handleChange}
        disabled={props.disabled}
      />
      <span className="checkmark"></span>
    </label>
  );
};
export default CustomCheckbox;
