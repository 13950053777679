import React, { useState, useContext, useEffect, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Select, { components } from "react-select";
import {
  GoogleMap,
  withGoogleMap,
  withScriptjs,
  Marker,
} from "react-google-maps";
import { FiUser } from "react-icons/fi"
import { LoadingContext } from "../../contexts/loading.context";
import { CustomCheckbox } from "../../components/index";
import { UserContext } from "../../contexts/user.context";
import { ModalBody, ModalContainer } from "../../components/modal";
import selectArrowStyle2 from "../../assets/selectArrowStyle2.svg";
import pencil from "../../assets/pencil.svg";
import delete2 from "../../assets/delete.svg";
import Swal from "sweetalert2";
import Locations from "../../constants/Locations";
import ChangePassword from './changePassword/changePassword'
import Accordion from "../../components/accordion/accordion"
import arrowRight from "../../assets/arrowRight.svg"
import arrowDown from "../../assets/arrowDown.svg"
import logo from "../../assets/logo-admin.svg"
import "./profile.css";

const Profile = (props) => {
  const history = useHistory()
  let initialStateMethod = { description: "", price: "" };
  const [user] = useContext(UserContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const [emailContact, setEmailContact] = useState("");
  const [phone, setPhone] = useState("");
  const [messageBot, setMessageBot] = useState("");
  
  const [comment, setComment] = useState(false);
  const [newOrders, setNewOrders] = useState(false);
  const [statusOrders, setStatusOrders] = useState(false);
  const [inventory, setInventory] = useState(false);

  const [pickUpLocal, setPickUpLocal] = useState(false);
  const [deliveries, setDeliveries] = useState(false);
  const [own, setOwn] = useState(false);

  const [changedPickUp, setChangedPickUp] = useState(false);
  const [changedOwn, setChangedOwn] = useState(false);
  const [changedKakao, setChangedKakao] = useState(false);

  const [province, setProvince] = useState("");
  const [canton, setCanton] = useState("");
  const [district, setDistrict] = useState("");
  const [addressExact, setAddressExact] = useState("");

  const [initialProvince, setInitialProvince] = useState("");
  const [initialCanton, setInitialCanton] = useState("");
  const [initialDistrict, setInitialDistrict] = useState("");
  const [initialAddressExact, setInitialAddressExact] = useState("");

  const [provinceOption, setProvinceOption] = useState([]);
  const [cantonOption, setCantonOption] = useState([]);
  const [districtOption, setDistrictOption] = useState([]);
  const setShowLoading = useContext(LoadingContext)[1];

  const [profileImage, setProfileImage] = useState()
  const [commerceDescription, setCommerceDescription] = useState("")

  const getProvince = () => {
    let province = [];
    Object.keys(Locations).map((key) => {
      const { codigo, provincia } = Locations[key];
      province.push({ label: provincia.toUpperCase(), value: codigo });
      return null;
    });
    setProvinceOption(province);
  };
  const getCanton = () => {
    let catonA = [];
    if (province !== "") {
      const { value: idProv } = province;
      Object.keys(Locations[idProv].cantones).map((key) => {
        const { codigo, canton } = Locations[idProv].cantones[key];
        catonA.push({ label: canton.toUpperCase(), value: codigo });
        return null;
      });
      setCantonOption(catonA);
    }
  };
  const getDistrict = () => {
    let distritos = [];
    if (canton !== "") {
      const { value: idProv } = province;
      const { value: idCant } = canton;
      Object.keys(Locations[idProv].cantones[idCant].distritos).map((key) => {
        const { codigo, distrito } =
          Locations[idProv].cantones[idCant].distritos[key];
        distritos.push({ label: distrito, value: codigo });
        return null;
      });
      setDistrictOption(distritos);
      
    }
  };
  useEffect(() => {
    getShippingMethods()
    getProvince();
    getProfileImage()
    getCommerceInfo()
  }, []);
  useEffect(() => {
    getCanton();
  }, [province]);
  useEffect(() => {
    getDistrict();
  }, [canton]);

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordRepeat, setNewPasswordRepeat] = useState("");
  const [popupModal, setShowPopupModal] = useState(false);
  
  const [isAdd, setIsAdd] = useState(false);
  const [dataMethod, setDataMethod] = useState(initialStateMethod);
  const [isEdit, setEdit] = useState(false);
  
  const handleChandeMethod = (e) => {
    const { name, value } = e.target;
    setDataMethod({ ...dataMethod, [name]: value });
  };

  useEffect(() => {
    const { description, price } = dataMethod;
    setIsAdd(description !== "" && Number(price) > 0 ? true : false);
  }, [dataMethod]);

  const [method, setMethod] = useState([]);
  const [methodCount, setMethodCount] = useState(0);
  const [newOwnMthod, setNewOwnMthod] = useState(false);
  
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={selectArrowStyle2} alt="" />
      </components.DropdownIndicator>
    );
  };
  const handleAddMethod = (data) => {
    setMethod([...method, { ...data}]);
    setNewOwnMthod(true)
    setDataMethod(initialStateMethod);
  };
  const handleEditMethod = (data) => {
    const { id } = data;
    setMethod([...method.filter((item) => item.id !== id), data]);
    setEdit(false);
    setDataMethod(initialStateMethod);
  };
  const handleDeleteMethod = (id) => {
    setMethod(method.filter((item) => item.id !== id));
  };
  const addMethods = (data) => {
    setShowLoading(true);
    return new Promise((resolve) => {
      axios({
        method: "POST",
        url: "https://kakaomarket.cr/wp-json/sellerconsole/v1/add_shipping_methods",
        data: {
          vendorId: Number(user.vendorId),
          shippingMethods: JSON.stringify(data)
        },
      }).then((response) => {
        if (response.status === 200) {
          resolve(JSON.parse(response.data));
          Swal.fire(
            "¡Se han actualizado los datos correctamente!",
            "",
            "success"
          );
        }
      })
      .finally(() => {
        setShowLoading(false);
        window.location.reload();
      });
    });
  }
  const saveMethods = () => {
    var data = []
    if(pickUpLocal && (addressExact === "" || district === "" || canton === "" || province === "")){ 
      alert("Complete los datos para recoger en el local")
      return
    }
    if(pickUpLocal){
      data.push({
        "idShipping": 1,
        "name": "Recoger en el local",
        "location": {
            "provincia": province,
            "canton": canton,
            "distrito": district,
            "fullAdress": addressExact
        }
      });
    }
    if (deliveries){
      data.push({
        "idShipping": 2,
        "name": "Kakao Entregas"
      });
    }
    if (own){
      setMethod([...method]);
      data.push({
        "idShipping": 3,
        "name": "Método Propio",
        "methods": method
      });    
    }    
    if(data.length > 0){
      console.log(data)
      addMethods(data);
    }
  }
  const getShippingMethods = (async()  =>  {
    setShowLoading(true);
    await axios({
      method: "POST",
      url: "https://kakaomarket.cr/wp-json/sellerconsole/v1/get_shipping_methods",
      data: {
        vendorId: Number(user.vendorId)
      },
    }).then((response) => {
      let data = JSON.parse(response.data).result;
      if (response.status === 200) {
        const shippingMethods = JSON.parse(data);
        setCheckedShippingMethods(shippingMethods);
      }
    })
    .finally(() => {
      setShowLoading(false);
    });
  })
  const setCheckedShippingMethods = (wholeData) => {
    if(wholeData != null){
      wholeData.forEach(data => {
        if (data.idShipping === 1){
          setPickUpLocal(true)  
          setProvince(data.location.provincia)
          setCanton(data.location.canton)
          setDistrict(data.location.distrito)
          setInitialProvince(data.location.provincia.label)
          setInitialCanton(data.location.canton.label)
          setInitialDistrict(data.location.distrito.label)
          setInitialAddressExact(data.location.fullAdress)
          setAddressExact(data.location.fullAdress)
        }
        else if (data.idShipping === 2){
          setDeliveries(true)
        }
        else if (data.idShipping === 3){
          setOwn(true)
          setMethod(data.methods)
          setMethodCount(data.methods.length)
        }
      })
    }
  }

  async function updateProfileImage ( name, base64, type) {
    setShowLoading(true)
    await axios({
      method: "POST",
      url: "https://kakaomarket.cr/wp-json/sellerconsole/v1/update_profile_photo",
      data: {
        name: name,
        base64: base64,
        type: type,
        vendorId: user.vendorId
      }
    }).then((response) => {
      if (JSON.parse(response.data).status === 200) {
        setProfileImage(JSON.parse(response.data).data)
        Swal.fire(
          "¡Se ha actualizado la imagen de perfil correctamente!",
          "",
          "success"
        )
      }
    }).finally(() => {
      setShowLoading(false)
    })
  }

  async function getProfileImage () { 
    await axios({
      method: "POST",
      url: "https://kakaomarket.cr/wp-json/sellerconsole/v1/get_profile_photo",
      data: {
        vendorId: user.vendorId
      }
    }).then((response) => { 
      if (JSON.parse(response.data).status === 200) {
        setProfileImage(JSON.parse(response.data).data)        
      }
    })
  }

  async function handleSetProfileImage (event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const fileName = file.name
      updateProfileImage(
        fileName,
        reader.result,
        file.type
      )
    }
    if (file) {
      reader.readAsDataURL(file);
    }
  }

  async function updateCommerceInfo () {
    setShowLoading(true)
    await axios({
      method: "POST",
      url: "https://kakaomarket.cr/wp-json/sellerconsole/v1/update_commerce_information",
      data: {
        vendorId: user.vendorId,
        description: commerceDescription
      }
    }).then((response) => {
      if(JSON.parse(response.data).status === 200){
        Swal.fire(
          "¡Se han actualizado los datos correctamente!",
          "",
          "success"
        )
      }
    }).finally(() => {
      setShowLoading(false)
    })
  }

  async function getCommerceInfo () {
    await axios({
      method: "POST",
      url: "https://kakaomarket.cr/wp-json/sellerconsole/v1/get_commerce_information",
      data: {
        vendorId: user.vendorId
      }
    }).then((response) => {
      if (JSON.parse(response.data).status === 200) {
        setCommerceDescription(JSON.parse(response.data).data)      
      }
    })
  }


  return (
    <div className="mainContainerProfile">
      <ModalContainer
        width={"60%"}
        style={{ alignItems: "center" }}
        handleClose={() => {
          setShowPopupModal(false);
        }}
        active={popupModal}
      >
        <ModalBody>
          <Maps setShowPopupModal={setShowPopupModal} user={user} />
        </ModalBody>
      </ModalContainer>

      <div className="perfileTitle">
        <span>Perfil</span>
      </div>

      <div className="profile-container">
       {profileImage == undefined ?  
          <div className="circularSquare container-icon-image-profile">
            <FiUser className="icon-image-profile" />
          </div>
        : 
        <img
          className="circularSquare"
          src={profileImage}
          alt=""
        />}
        <div className="select-image-btn">
          <input
            type="file"
            id="mainImage"
            className="file"
            onChange={(event) => handleSetProfileImage(event)}
            accept="image/png, image/jpeg"
          />
          <label htmlFor="mainImage">Seleccionar imágen</label>
        </div>
      </div>

      {/*
      <div className="topContainerProfile">
        <div className="generalInfoProfile">
          <span className="sectionTitle">Información</span>
           <button
            // className="btnPhoto"
            style={{ marginLeft: "10px", fontSize: "12px" }}
            onClick={() => {
              setShowPopupModal(true);
            }}
          >
            Insertar ubicación
          </button> 
          <div className="inputContainer">
            <span className="sectionSubTitle">Nombre</span>
            <input
              className="customInput"
              type="text"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </div>

          <div className="inputContainer">
            <span className="sectionSubTitle">Descripción</span>
            <textarea
              className="customInput"
              rows="10"
              value={email}
              onChange={(event) =>
                event.target.value.length <= 330
                  ? setEmail(event.target.value)
                  : null
              }
              style={{ height: "10vh" }}
            />
          </div>
          <div className="charCounterContainer">
            <span className="charCounter">{email.length}/330</span>
          </div>
        </div>
        
        <div className="publishContainerProfile">
          <span className="sectionTitle">Foto de perfil</span>
          <div className="containerPhotoPerfile">
            <div className="inputContainer">
              <img
                className="circularSquare"
                src="https://cdn.computerhoy.com/sites/navi.axelspringer.es/public/styles/1200/public/media/image/2018/08/fotos-perfil-whatsapp_16.jpg?itok=fl2H3Opv"
                alt=""
              />
            </div>

            <button className="btnPhoto">Cambiar foto</button>
          </div>
        </div>
        
      </div>
      
      <div className="topContainerProfile">
        <div className="generalInfoProfile">
          <span className="sectionTitle">Información de contacto</span>

          <div className="inputContainer">
            <span className="sectionSubTitle">Correo electrónico</span>
            <input
              className="customInput"
              type="email"
              value={emailContact}
              onChange={(event) => setEmailContact(event.target.value)}
            />
          </div>
          <div className="inputContainer">
            <span className="sectionSubTitle">Número Telefónico</span>
            <input
              className="customInput"
              type="number"
              value={phone}
              onChange={(event) => setPhone(event.target.value)}
            />
          </div>
          <div className="inputContainer">
            <span className="sectionSubTitle">Mensaje Chatbot</span>
            <textarea
              className="customInput"
              rows="10"
              value={messageBot}
              onChange={(event) =>
                event.target.value.length <= 140
                  ? setMessageBot(event.target.value)
                  : null
              }
              style={{ height: "10vh" }}
            />
          </div>
          <div className="charCounterContainer">
            <span className="charCounter">{messageBot.length}/140</span>
          </div>
        </div>
         
        <div className="publishContainerProfile">
          <span className="sectionTitle">Notificaciones</span>
          <div className="notificationOptions">
            <div className="inputContainerCheck">
              <CustomCheckbox
                label="Comentarios"
                checked={comment}
                handleChange={(event) => setComment(event.target.checked)}
                className="sectionSubTitle"
              />
            </div>

            <div className="inputContainerCheck">
              <CustomCheckbox
                label="Nuevas Órdenes"
                checked={newOrders}
                handleChange={(event) => setNewOrders(event.target.checked)}
                className="sectionSubTitle"
              />
            </div>
            <div className="inputContainerCheck">
              <CustomCheckbox
                label="Estado Órdenes"
                checked={statusOrders}
                handleChange={(event) => setStatusOrders(event.target.checked)}
                className="sectionSubTitle"
              />
            </div>
            <div className="inputContainerCheck">
              <CustomCheckbox
                label="Inventario Bajo (X elementos)"
                checked={inventory}
                handleChange={(event) => setInventory(event.target.checked)}
                className="sectionSubTitle"
              />
            </div>
          </div>
        </div>
        
      </div>
      
      <div className="topContainerProfile">
        
        <div className="generalInfoProfile">
          <span className="sectionTitle">Reestablecer Contraseña</span>

          <div className="inputContainer">
            <span className="sectionSubTitle">Contraseña Actual</span>
            <input
              className="customInput"
              type="password"
              value={currentPassword}
              onChange={(event) => setCurrentPassword(event.target.value)}
            />
          </div>
          <div className="inputContainer">
            <span className="sectionSubTitle">Nueva Contraseña</span>
            <input
              className="customInput"
              type="password"
              value={newPassword}
              onChange={(event) => setNewPassword(event.target.value)}
            />
          </div>
          <div className="inputContainer">
            <span className="sectionSubTitle">Repertir Nueva Contraseña</span>
            <input
              className="customInput"
              type="password"
              value={newPasswordRepeat}
              onChange={(event) => setNewPasswordRepeat(event.target.value)}
            />
          </div>
          <div className="containerPassword">
            <button className="btnPassword">
              <p>Establecer nueva contraseña</p>
            </button>
          </div>
        </div>

        */}

        <ChangePassword setShowLoading={setShowLoading} user={user} history={history} />

        <Accordion 
          title={<span className="sectionTitle commerce-title">Información del Comercio</span>} 
          hideIcon={<img src={arrowRight}/>} 
          showIcon={<img src={arrowDown}/>} 
        >
          <div className="set-commerce-container">
            <div className="commerce-input-container">
              <label className="sectionSubTitle">Descripción</label>
              <input
                  className="commerce-input"
                  type="text"
                  name="description"
                  value={commerceDescription}
                  onChange={(event) => setCommerceDescription(event.target.value)}
              />
            </div>
            <div className="set-commerce-btn-container">
              <button className="set-commerce-btn" onClick={updateCommerceInfo}>
                  Modificar
              </button>
            </div>
          </div>
        </Accordion>
      
        <div className="publishContainerProfile">
          <span className="sectionTitle">Método de Envío</span>
          <p className="sectionSubTitleProfile">
            Seleccione su método de envío de preferencia, puede seleccionar uno
            o varios.
          </p>
          <div className="notificationOptions">
            <div className="inputContainerCheck">
              <CustomCheckbox
                label="Recoger en el locaL"
                checked={pickUpLocal}
                handleChange={(event) => {setPickUpLocal(event.target.checked); setChangedPickUp(!changedPickUp)}}
                className="sectionSubTitle"
              />
            </div>

            {pickUpLocal && (
              <div className="containerOptionsDelevery">
                <div className="inputContainer">
                  <span className="sectionSubTitle">Provincia</span>
                  <Select
                    value={province}
                    placeholder="Seleccionar"
                    onChange={(e) => {
                      setProvince(e);
                      setCanton("");
                      setDistrict("");
                      setCantonOption([]);
                      setDistrictOption([]);
                    }}
                    options={provinceOption}
                    components={{ DropdownIndicator }}
                  />
                </div>
                <div className="inputContainer">
                  <span className="sectionSubTitle">Cantón</span>
                  <Select
                    value={canton}
                    placeholder="Seleccionar"
                    onChange={(e) => {
                      setCanton(e);
                      setDistrict("");
                      setDistrictOption([]); // verificar que cuando se eliga otra provincia o canton se reinicie
                    }}
                    options={cantonOption}
                    components={{ DropdownIndicator }}
                  />
                </div>
                <div className="inputContainer">
                  <span className="sectionSubTitle">Distrito</span>
                  <Select
                    value={district}
                    placeholder="Seleccionar"
                    onChange={(e) => {
                      setDistrict(e);
                    }}
                    options={districtOption}
                    components={{ DropdownIndicator }}
                  />
                </div>
                <div className="inputContainer">
                  <span className="sectionSubTitle">Dirección Exacta </span>
                  <textarea
                    className="customInput"
                    rows="10"
                    value={addressExact}
                    onChange={(event) =>
                      event.target.value.length <= 330
                        ? setAddressExact(event.target.value): null
                      }
                    style={{ height: "10vh" }}
                  />
                </div>{" "}
                <div className="charCounterContainer">
                  <span className="charCounter">{addressExact.length}/330</span>
                </div>
              </div>
            )}

            <div className="inputContainerCheck">
              <CustomCheckbox
                label="Kakao Entregas"
                checked={deliveries}
                handleChange={(event) => {setDeliveries(event.target.checked);setChangedKakao(!changedKakao)}}
                className="sectionSubTitle"
              />
            </div>

            <div className="inputContainerCheck">
              <CustomCheckbox
                label="Método propio"
                checked={own}
                handleChange={(event) => {setOwn(event.target.checked); setChangedOwn(!changedOwn)}}
                className="sectionSubTitle"
              />
            </div>

            {own && (
              <>
                <div
                  className={`containerOptionsDelevery ${
                    isEdit ? "editMethod" : ""
                  }`}
                >
                  {isEdit && (
                    <span className="sectionSubTitle">Editar método</span>
                  )}
                  <div className="inputContainer">
                    <span className="sectionSubTitle">Descripción</span>
                    <input
                      name="description"
                      className="customInput"
                      type="text"
                      placeholder="Ingrese la descripción"
                      value={dataMethod.description}
                      onChange={handleChandeMethod}
                    />
                  </div>

                  <div className="inputContainer">
                    <span className="sectionSubTitle">Precio</span>
                    <input
                      name="price"
                      className="customInput"
                      type="number"
                      placeholder="0.00"
                      value={dataMethod.price}
                      onChange={handleChandeMethod}
                    />
                  </div>

                  {!isEdit && (
                    <button
                      className={`btnMethodAdd ${
                        isAdd ? "isActive" : "isNotActive"
                      }`  }
                      onClick={() => {
                        handleAddMethod(dataMethod);
                      }}
                    >
                      <span>Agregar método</span>
                    </button>
                  )}

                  {isEdit && (
                    <div className="containerActiosEdit">
                      <button
                        className={`save ${isAdd ? "isActive" : "isNotActive"}`}
                        onClick={() => {
                          handleEditMethod(dataMethod);
                        }}
                      >
                        Guardar Cambios
                      </button>
                      <button
                        className="cancel"
                        onClick={() => {
                          setDataMethod(initialStateMethod);
                          setEdit(false);
                        }}
                      >
                        Cancelar
                      </button>
                    </div>
                  )}
                </div>
                {!isEdit && (
                  <div className="methodAdd">
                    <span className="sectionTitle" style={{ fontSize: "14px" }}>
                      {method.length === 0 ? "No hay métodos agregados" : "Métodos propios Agregados"}
                    </span>
                    <div className="listAdd">
                      {method.sort().map((item) => {
                        const { id, description, price } = item;
                        return (
                          <div className="cartAdd" key={id}>
                            <div className="cartInfo">
                              <p className="description">{description}</p>
                              <p className="price">{price}</p>
                            </div>
                            <div className="cartActions">
                              <p
                                className="edit"
                                onClick={() => {
                                  setEdit(true);
                                  setDataMethod(item);
                                }}
                              >
                                <span>Editar</span>{" "}
                                <img src={pencil} alt="pencil" />
                              </p>
                              <p
                                className="delete"
                                onClick={() => {
                                  handleDeleteMethod(id);
                                }}
                              >
                                <img src={delete2} alt="delete2" />
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </>
            )}
            <div style={{paddingTop:9}}>
              {(pickUpLocal || deliveries || own) &&(
                <button
                  className={`btnMethodAdd ${(changedKakao || 
                    ((changedOwn && method.length>0) || (newOwnMthod) || (methodCount>method.length))
                    || changedPickUp 
                    || (initialProvince !== province.label || initialCanton !== canton.label || initialDistrict !== district.label 
                    || initialAddressExact !== addressExact)) 
                    ? "isActive" : "isNotActive"}`}
                  onClick={() => {
                    saveMethods()
                  }}
                >
                  <span>Guardar cambios</span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
  );
};

const Button = ({ children, classname, onClick }) => (
  <button
    style={{ width: "20%", marginLeft: "5%" }}
    className={`inventoryBtn ${classname} `}
    onClick={onClick}
  >
    {children}
  </button>
);

const Maps = ({ setShowPopupModal, user }) => {
  const setShowLoading = useContext(LoadingContext)[1];
  const center = {
    lat: 9.938165,
    lng: -84.3105621,
  };
  const [place, setPlace] = useState("");
  const [dragged, setDragged] = useState(false);
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");

  const getCenter = () => {
    if (lat === "" && lng === "") {
      return center;
    } else {
      return { lat, lng };
    }
  };
  const getMap = () => {
    if (lat === "" && lng === "") {
      return null;
    } else {
      return (
        <div
          style={{
            width: "50vw",
            height: "50vh",
            alignContent: "center",
            alignItems: "center",
            marginTop: "5%",
            marginLeft: "9%",
          }}
        >
          <WrappedMap
            googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyC_dmMqXfrygYbCypGDJ4AwuecQtOsyQ38`}
            loadingElement={<div style={{ height: "100%" }} />}
            containerElement={<div style={{ height: "100%" }} />}
            mapElement={<div style={{ height: "100%" }} />}
          />
        </div>
      );
    }
  };

  function Map() {
    return (
      <GoogleMap defaultZoom={10} defaultCenter={getCenter()}>
        <Marker
          position={{ lat, lng }}
          draggable={true}
          onDragEnd={(coord) => onMarkerDragEnd(coord)}
        />
      </GoogleMap>
    );
  }

  const WrappedMap = withScriptjs(withGoogleMap(Map));

  const handleInsertLatLng = (lat, lng, vendorId) => {
    setShowLoading(true);
    axios({
      method: "POST",
      url: "https://kakaomarket.cr/wp-json/sellerconsole/v1/insertLatLng",
      data: {
        vendorId: vendorId,
        lat: lat,
        lng: lng,
      },
    }).then((response) => {
      if (response.status === 200) {
        setShowLoading(false);
      }
    });
  };

  const geocode = () => {
    if (dragged === false) {
      axios
        .get("https://maps.googleapis.com/maps/api/geocode/json", {
          params: {
            address: place,
            key: "AIzaSyC_dmMqXfrygYbCypGDJ4AwuecQtOsyQ38",
          },
        })
        .then(function (response) {
          console.log(response);
          console.log(response.data.results[0].formatted_address);
          var lat = response.data.results[0].geometry.location.lat;
          setLat(lat);
          var lng = response.data.results[0].geometry.location.lng;
          setLng(lng);
          setDragged(true);
          console.log("LATITUD", lat);
          console.log("LONGITUD", lng);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };
  const onMarkerDragEnd = (coord) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    console.log("cambiando a TRUE------------");
    setDragged(true);
    setLat(lat);
    setLng(lng);
  };
  const searchPlace = (place) => {
    setDragged(false);
    setPlace(place);
  };
  return (
    <div style={{ backgroundColor: "white", height: "700px" }}>
      <h1 style={{ marginLeft: "2%" }}>Ingrese lugar</h1>
      <div
        style={{
          position: "absolute",
          right: "20px",
          top: "0",
          fontSize: "50px",
          cursor: "pointer",
        }}
        onClick={() => {
          setShowPopupModal(false);
        }}
      >
        &times;
      </div>
      <div style={{ display: "flex" }}>
        <input
          style={{ marginLeft: "2%" }}
          className="customInput"
          type="text"
          value={place}
          onChange={(event) => searchPlace(event.target.value)}
        />
        <Button
          classname="primary-lg"
          onClick={() => {
            handleInsertLatLng(lat, lng, user.vendorId);
          }}
        >
          ACEPTAR
        </Button>
      </div>
      {geocode(false)}
      {getMap()}
    </div>
  );
};
export default Profile;
