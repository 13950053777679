import React, { useState } from "react"
import { TabButtons } from "../index"
import "./tabs.css"

const Tabs = props => {

    const [activaTab, setActiveTab] = useState(props.children[0].props.label)

    let content
    let buttons = []

    return (
        <div>
            {React.Children.map(props.children, child => {
                buttons.push(child.props.label)
                if (child.props.label === activaTab) content = child.props.children
            })}

            <TabButtons activeTab={activaTab} buttons={buttons} changeTab={(tab) => { setActiveTab(tab) }} />
            <div className="tab-content">{content}</div>
        </div>
    )
}
export default Tabs