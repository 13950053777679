import React from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles({
  body: {
    height: "150%",
    width:"100%"
  }
})

const ModalBody = (props) => {
  const { body } = useStyles()

  return (
    <div className={body}>
      {props.children}
    </div>
  )
}

export default ModalBody
