import React, { createContext, useState }  from "react"

const LoadingContext = createContext([null, () => null])

const LoadingProvider = (props) => {
    const [showLoading, setShowLoading] = useState(false)

    return (
        <LoadingContext.Provider value={[showLoading, setShowLoading]} >
            {props.children}
        </LoadingContext.Provider >
    )
}

export {
    LoadingContext,
    LoadingProvider
}