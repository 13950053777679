import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import "./resetPassword.css";
import logo from "../../assets/logo-admin.svg";
import goBackIcon from "../../assets/goBackArrow.svg";
import { LoadingContext } from "../../contexts/loading.context";
import Swal from "sweetalert2";
import lisztClient from "../../efika/lisztClient"

const ResetPassword = (props) => {

  const [userEmail, setUserEmail] = useState("");

  const setShowLoading = useContext(LoadingContext)[1];

  const history = useHistory();

  const resetPasswordHandler = async () => {
    setShowLoading(true);
    try {
      const response = await lisztClient.tripcomReset({
          email: userEmail
      });
      setShowLoading(false)
      if (response.status === 200) {
        await Swal.fire({
          title: "Contraseña enviada",
          text: "La nueva contraseña se ha enviado al correo ingresado",
          icon: "success",
        })
        history.push("/")
      } else {
        Swal.fire({
          title: "Error",
          text: "Ha ocurrido un error",
          icon: "error",
        })
      }
    } catch (error) {
      setShowLoading(false)

      console.log(error)

      Swal.fire({
        title: "Error",
        text: "Ha ocurrido un error desconocido",
        icon: "error",
      })
    }
  }

  return (
    <div className="resetPassword-background-container">
      <div className="resetPassword-container">
        <img src={logo} alt="kakao-market-logo" style={{ width: "50%" }} />
        <div className="resetPassword-title-container">
          <img className="resetPassword-goBack-svg" src={goBackIcon} onClick={() => history.push("/") } />
          <label className="resetPassword-title-label">Olvidé mi contraseña</label>
        </div>
        <div className="resetPassword-input-container">
          <label className="resetPassword-input-label">Correo</label>
          <input
            className="resetPassword-input"
            type="email"
            id="email"
            name="email"
            value={userEmail}
            onChange={(event) => setUserEmail(event.target.value)}
          />
        </div>
        <div className="resetPassword-button-container">
          <button className="resetPassword-button" onClick={resetPasswordHandler}>
            Enviar contraseña
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
