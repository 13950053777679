import React from "react"

const Tab = props => {

    return (
        <React.Fragment>
            {props.children}
        </React.Fragment>
    )
}
export default Tab