import React, { useState, useContext, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import "./login.css";
import logo from "../../assets/logo-admin.svg";
import { UserContext } from "../../contexts/user.context";
import { LoadingContext } from "../../contexts/loading.context";
import Swal from "sweetalert2";
import lisztClient from "../../efika/lisztClient"
import {EfikaEncryptation} from "../../efika/encryptation"
import { EfikaOfflineFirst } from "../../efika/efikaOfflineFirst"

const Login = (props) => {

  const [userName, setUserName] = useState("");
  const [userPassword, setUserPassword] = useState("");

  const [user, setUser] = useContext(UserContext);
  const setShowLoading = useContext(LoadingContext)[1];

  const eofUsers = useRef(new EfikaOfflineFirst("USERS", () => setShowLoading(true), () => setShowLoading(false)))

  const history = useHistory();

  const loginHandler = async () => {
    setShowLoading(true);
    var encryp = new EfikaEncryptation()
    try {
      const response = await lisztClient.login({
          user: userName,
          password: encryp.whirpoolEncrypt(userPassword)
      });
      setShowLoading(false)
      if (response.status === 200) {
        const data = response.recordset[0]

        const generator = eofUsers.current.getGenerator(data.userId, "userId")
        for await (var get of generator) {
          const { additionalInfo } = get[0]
          const dataToStore = {
            ...data,
            vendorId: additionalInfo ? additionalInfo.vendorId : "",
            roles: ["administrator"]
          }
          localStorage.setItem("userInformation", JSON.stringify(dataToStore))
          setUser(dataToStore)
          history.push("/home")
        }
      } else {
        Swal.fire({
          title: "Error",
          text: "Ha ocurrido un error, por favor verificar los datos ingresados.",
          icon: "error",
        })
      }

    } catch (error) {
      setShowLoading(false)

      console.log(error)

      Swal.fire({
        title: "Error",
        text: "Ha ocurrido un error desconocido",
        icon: "error",
      })
    }
  }

  useEffect(() => {
    if (user !== null) {
      history.push("/home");
    }
  }, [user, history]);

  return (
    <div className="backgroundContainer">
      <div className="loginContainer">
        <img src={logo} alt="kakao-market-logo" style={{ width: "50%" }} />
        <div className="loginInputContainer">
          <label className="loginInputLabel">Usuario</label>
          <input
            className="loginInput"
            type="email"
            id="user"
            name="user"
            value={userName}
            onChange={(event) => setUserName(event.target.value)}
          />
        </div>
        <div className="loginInputContainer">
          <label className="loginInputLabel">Contraseña</label>
          <input
            className="loginInput"
            type="password"
            id="password"
            name="password"
            value={userPassword}
            onChange={(event) => setUserPassword(event.target.value)}
          />
          <div className="passwordRecoveryContainer">
            <span className="passwordRecovery" onClick={() => history.push("/resetPassword")}>¿Olvidaste tu contraseña?</span>
          </div>
        </div>
        <div className="loginButtonContainer">
          <button className="loginButton" onClick={loginHandler}>
            INICIAR SESIÓN
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
