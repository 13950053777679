import React, { useEffect, useState, useContext } from "react";
import "./orders.css";
import Select from "react-select";
import chroma from "chroma-js";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { UserContext } from "../../contexts/user.context";
import { LoadingContext } from "../../contexts/loading.context";
import Swal from "sweetalert2";
import { ModulePanelPopup } from "../../components";

import { ModalBody, ModalContainer } from "../../components/modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import { useMediaQuery } from "react-responsive";

const prevIcon = (
  <svg
    style={{ cursor: "pointer" }}
    width="26"
    height="25"
    viewBox="0 0 26 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.5 13.3727C8.83333 12.9848 8.83333 12.0152 9.5 11.6273L15.5 8.13651C16.1667 7.74865 17 8.23348 17 9.00921V15.9908C17 16.7665 16.1667 17.2514 15.5 16.8635L9.5 13.3727Z"
      fill="#37474F"
    />
    <path
      d="M2 1H24V-1H2V1ZM25 2V23H27V2H25ZM24 24H2V26H24V24ZM1 23V2H-1V23H1ZM2 24C1.44772 24 1 23.5523 1 23H-1C-1 24.6569 0.343146 26 2 26V24ZM25 23C25 23.5523 24.5523 24 24 24V26C25.6569 26 27 24.6569 27 23H25ZM24 1C24.5523 1 25 1.44772 25 2H27C27 0.343146 25.6569 -1 24 -1V1ZM2 -1C0.343146 -1 -1 0.343145 -1 2H1C1 1.44772 1.44772 1 2 1V-1Z"
      fill="#C8CBCC"
    />
  </svg>
);

const nextIcon = (
  <svg
    style={{ cursor: "pointer" }}
    width="26"
    height="25"
    viewBox="0 0 26 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5 11.6273C16.1667 12.0152 16.1667 12.9848 15.5 13.3727L9.5 16.8635C8.83333 17.2514 8 16.7665 8 15.9908L8 9.00921C8 8.23348 8.83333 7.74864 9.5 8.13651L15.5 11.6273Z"
      fill="#37474F"
    />
    <path
      d="M2 1H24V-1H2V1ZM25 2V23H27V2H25ZM24 24H2V26H24V24ZM1 23V2H-1V23H1ZM2 24C1.44772 24 1 23.5523 1 23H-1C-1 24.6569 0.343146 26 2 26V24ZM25 23C25 23.5523 24.5523 24 24 24V26C25.6569 26 27 24.6569 27 23H25ZM24 1C24.5523 1 25 1.44772 25 2H27C27 0.343146 25.6569 -1 24 -1V1ZM2 -1C0.343146 -1 -1 0.343145 -1 2H1C1 1.44772 1.44772 1 2 1V-1Z"
      fill="#C8CBCC"
    />
  </svg>
);

const orderTypes = [
  { value: "completed", label: "COMPLETADO", color: "#27A36E" },
  { value: "pending", label: "PENDIENTE", color: "#FFFF00" },
  { value: "on-hold", label: "EN ESPERA", color: "#6C7980" },
  { value: "failed", label: "FALLO", color: "#FF6D00" },
  //{ value: "cancelled", label: "CANCELADO", color: "#FF5A5F" },
  { value: "refunded", label: "DEVUELTO", color: "#7D3C98" },
  { value: "processing", label: "PROCESANDO", color: "#00B8D4" },
  //{ value: "trash", label: "ELIMINADA", color: "#FF5A5F"}
];

/**KM * Nota importante: cuando este el ambiente de desarrollo cambiar la URL a todas los endpoint a https://kakaomarket.cr/ */

const dot = (color = "#ccc") => ({
  alignItems: "center",
  display: "flex",

  ":before": {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: "block",
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

const Orders = (props) => {
  let initialStatusTabs = {
    all: true,
    active: false,
    completed: false,
    deleted: false,
  };

  const isMobile = useMediaQuery({maxWidth: 480 });
  const isMobileOrTablet = useMediaQuery({maxWidth: 1024 });
  const isValidate = useMediaQuery({ minWidth: 321, maxWidth: 480 });
  const isValidateSmall = useMediaQuery({ minWidth: 320, maxWidth: 320 });

  const [popupModal, setShowPopupModal] = useState(false);
  const [isStatus, setIsStatus] = useState(true);
  const [user] = useContext(UserContext);
  const setShowLoading = useContext(LoadingContext)[1];

  const perPage = 8;
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [statusFilter, setStatusFilter] = useState({
    value: 0,
    label: "Seleccionar",
  });
  const [search, setSearch] = useState("");
  const [dateFilter, setDateFilter] = useState("");
  const [filterButtons, setFilterButtons] = useState(initialStatusTabs);
  const [actualOrder, setActualOrder] = useState();
  const handlePageClick = (evt) => {
    const selectedPage = evt.selected;
    const offset = selectedPage * perPage;

    //setFilteredOrders(orders.slice(offset, offset + perPage))
    setPage(filteredOrders.slice(offset, offset + perPage));
  };

  //const isMobile = useMediaQuery({ query: "(max-width: 480px)" });

  const colourStylesFilter = {
    container: (styles) => ({ ...styles, flex: 1 }),
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      // height: `${isMobile ? "30px" : "revert"}`,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? data.color
          : isFocused
          ? color.alpha(0.1).css()
          : null,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? chroma.contrast(color, "white") > 2
            ? "white"
            : "black"
          : data.color === "#FFFF00"
          ? "black"
          : data.color,
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor:
            !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
        },
      };
    },
    input: (styles) => ({ ...styles, ...dot() }),
    placeholder: (styles) => ({ ...styles, ...dot() }),
    singleValue: (styles, { data }) => ({
      ...styles,
      maxWidth: "none",
      ...dot(data.color),
    }),
  };

  const colourStyles = {
    ...colourStylesFilter,
    control: (styles) => ({
      ...styles,
      width: `${isValidate ? "170px" : isValidateSmall ? "122px" : "revert"}`,
    }),
    singleValue: (styles, { data }) => ({
      ...styles,
      fontSize: `${isValidate ? "14px" : "revert"}`,
      ...dot(data.color),
    }),
  };

  const getOrders = () => {
    //console.log('user.vendorId', user.vendorId);
    setShowLoading(true);
    axios({
      method: "POST",
      url: "https://kakaomarket.cr/wp-json/sellerconsole/v1/getallorders",
      data: {
        vendorId: user.vendorId,
      },
    })
      .then((response) => {
        let data = JSON.parse(response.data).result;
        //data = data.filter(({ status }) => status !== 'trash')
        if (response.status === 200) {
          setOrders(data);
          setPage(data.slice(0, perPage));
          setFilteredOrders(data);
        }
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  useEffect(() => {
    getOrders();
  }, []);

  const handleOnSearch = ({ target: { value } }) => {
    //console.log('search', value)
    setSearch(value);
    setDateFilter("");
    setStatusFilter({ value: 0, label: "Seleccionar" });

    const searchedOrders = orders.filter(({ id, details, customerName }) => {
      const orderSearched = id
        .toLowerCase()
        .trim()
        .includes(value.toLocaleLowerCase().trim());
      const detailSearched = details
        .toLowerCase()
        .trim()
        .includes(value.toLocaleLowerCase().trim());
      const clientSearched = customerName
        .toLowerCase()
        .trim()
        .includes(value.toLocaleLowerCase().trim());
      return orderSearched || detailSearched || clientSearched;
    });
    setFilteredOrders(searchedOrders);
    setPage(searchedOrders.slice(0, perPage));
  };

  const handleOnStatusFilter = ({ value, label, color }) => {
    //KM preguntar
    let statusFiltered = [];
    if (value === "completed") {
      statusFiltered = orders.filter(({ status, paymentState }) => {
        //Solo las aprobadas
        return status === value && paymentState.toString() === "aproved";
      });
    } else if (value === "processing") {
      statusFiltered = orders.filter(({ status, paymentState }) => {
        //Solo las diferentes de (Fallida, rechzada, y cancelada)
        return (
          status === value &&
          paymentState.toString() !== "failed" &&
          paymentState.toString() !== "reject"
        );
      });
    } else {
      statusFiltered = orders.filter(({ status }) => {
        return status === value;
      });
    }

    //console.log(statusFiltered);
    setStatusFilter({ value, label, color });
    setFilteredOrders(statusFiltered);
    setPage(statusFiltered.slice(0, perPage));

    setSearch("");
    setDateFilter("");
  };

  const handleOnDateFilterChange = ({ target: { value } }) => {
    const filteredOrders = orders.filter(({ date }) => {
      const newDate = date.split("T")[0];
      return newDate === value;
    });
    setDateFilter(value);
    setFilteredOrders(filteredOrders);
    setPage(filteredOrders.slice(0, perPage));

    setStatusFilter({ value: 0, label: "Seleccionar" });
    setSearch("");
  };

  const handleOnOrderDelete = (value) => {
    console.log("orderId", value);
    Swal.fire({
      title: "¿Está seguro?",
      text: "¡Está eliminando una orden permanentemente!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "¡Sí, eliminarla!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "POST",
          url: "https://kakaomarket.cr/wp-json/sellerconsole/v1/deleteorder",
          data: {
            orderId: value,
          },
        })
          .then((response) => {
            console.log("res", response);
            if (response.status === 200) {
              Swal.fire(
                "¡Eliminada!",
                "La orden ha sido eliminada.",
                "success"
              );
              setSearch("");
              setDateFilter("");
              setStatusFilter({ value: 0, label: "Seleccionar" });
              getOrders();
              setFilterButtons(initialStatusTabs);
            }
          })
          .catch((error) => {
            console.log("Delete order error: ", error);
          });
      }
    });
  };

  const handleOnOrderStatusChange = ({ value }, id) => {
    Swal.fire({
      title: "¿Está seguro?",
      text: "¡Está cambiando el estado de una orden!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "¡Sí, cambiarla!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "POST",
          url: "https://kakaomarket.cr/wp-json/sellerconsole/v1/updateorderstatus",
          data: {
            orderId: id,
            status: value,
          },
        }).then((response) => {
          if (response.status === 200) {
            Swal.fire(
              "¡Cambiado!",
              "Se ha cambiado el estado correctamente.",
              "success"
            );
            setSearch("");
            setDateFilter("");
            setStatusFilter({ value: 0, label: "Seleccionar" });
            getOrders();
            setFilterButtons(initialStatusTabs);
          }
        });
      }
    });
  };

  const getCardInfo = (orderId) => {
    return new Promise((resolve) => {
      axios({
        method: "POST",
        url: "https://kakaomarket.cr/wp-json/misc/v1/getcardinfo",
        data: {
          order_id: orderId,
        },
      }).then((response) => {
        if (response.status === 200) {
          resolve(JSON.parse(response.data));
        }
      });
    });
  };

  const processPayment = async (order) => {
    setShowLoading(true);
    let card
    if (parseInt(order.total) > 0) {
      card = await getCardInfo(order.id);
      if (card.result.length === 0) {
        Swal.fire(
          "¡Error!",
          "Error a la hora de obtener información de la tarjeta",
          "error"
        );
        setShowLoading(false);
        return;
      }
    }
    
    //consumo puntos banco popular (si no aplica, retorna 200)
    var response = await axios.post( "https://kakaomarket.cr/wp-json/sellerconsole/v1/consumption_bp_points",
      {
        orderId: order.id,
      }
    ).catch((err) => {
      Swal.fire(
        "¡Error!", 
        "Error a la hora de consumir puntos del Banco Popular", 
        "error"
      );
      setShowLoading(false);
      return;
    });
    if(response) {
      response = JSON.parse(response?.data)
      if (response.status !== 200) {
        Swal.fire(
          "¡Error!",
          "Error a la hora de consumir puntos del Banco Popular",
          "error"
        );
        setShowLoading(false);
        return;
      }
    } else {
      Swal.fire(
        "¡Error!",
        "Error a la hora de consumir puntos del Banco Popular",
        "error"
      );
      setShowLoading(false);
      return;
    }
    
    if (parseInt(order.total) > 0) {
      let paymentOrder = {
        id: order.id,
        amount: [parseInt(order.total)],
        merchantId: "0e57896b-9e16-42c2-9797-d24dd6303490",
      };
      axios({
        //cobro
        method: "POST",
        url: "https://vhmhsg38k7.execute-api.us-east-1.amazonaws.com/bcr-payment/process_payment",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          order: paymentOrder, // [ID:"", AMOUNT:""]
          cardInfo: card.result, //resp del endpoint
          vendorId: Number(user.vendorId),
        },
      })
        .then((response) => {
          console.log(response);
          if (response.data.statusCode === 200) {
            //exito en cobro
            let idForStateApproved = (parseInt(order.id) + 1).toString();
            if(response.data.statusInsert && response.data.statusInsert!=200){
              Swal.fire(
                "¡Atencion!",
                response.data.responseText,
                "warning"
              );
            }
            axios({
              //cambio de paymentState
              method: "POST",
              url: "https://kakaomarket.cr/wp-json/misc/v1/setaproved",
              data: {
                order_id: idForStateApproved,
                transaction_id: response.data.transactionNumber,
              },
            }).then((response) => {
              if (response.status === 200) {
                //exito en cambio de paymentState

                axios({
                  //cambio de status
                  method: "POST",
                  url: "https://kakaomarket.cr/wp-json/sellerconsole/v1/updateorderstatus",
                  data: {
                    orderId: order.id,
                    status: {
                      value: "processing",
                      label: "PROCESANDO",
                      color: "#00B8D4",
                    }, //pasar value processing
                  },
                }).then((response) => {
                  if (response.status === 200) {
                    //exito en cambio de status
                    setShowLoading(false);
                    Swal.fire(
                      "¡Éxito!",
                      "Se ha realizado el cobro correctamente.",
                      "success"
                    );
                  }
                });
              } else if (response.status !== 200) {
                console.log("as", response);
                // Swal.fire(
                //     'Error!',
                //     `${response.status}`,
                //     'success'
                // )
              }
            });
          } else {
            //fallo en el cobro
            let idForStateFailed = (parseInt(order.id) + 1).toString();
            axios({
              //cambio de paymentState
              method: "POST",
              url: "https://kakaomarket.cr/wp-json/misc/v1/setfailed",
              data: {
                order_id: idForStateFailed,
                transaction_id: response.data.transactionNumber,
                responseText: response.data.responseText,
              },
            }).then((response) => {
              // console.log(response);
              // if(response.data) {
              //     if(response.data.statusCode) {
              //         Swal.fire(
              //             '¡Error!',
              //             'Error a la hora de obtener información de la tarjeta',
              //             'error'
              //         )
              //     }

              // }

              if (response.status === 200) {
                //exito en cambio de paymentState
                axios({
                  //cambio de status
                  method: "POST",
                  url: "https://kakaomarket.cr/wp-json/sellerconsole/v1/updateorderstatus",
                  data: {
                    orderId: order.id,
                    status: {
                      value: "trash",
                      label: "ELIMINADA",
                      color: "#FF5A5F",
                    }, //pasar value trash
                  },
                }).then((response) => {
                  if (response.status === 200) {
                    //exito en cambio de status
                    setShowLoading(false);
                    Swal.fire(
                      "¡Error!",
                      "Ocurrió un error con el cobro.",
                      "error"
                    );
                  }
                });
              }
            });
          }
          getOrders()
        })
        .catch((err) => {
          Swal.fire("¡Error!", "¿Ocurrió un error el proceso de pago", "error");
          setShowLoading(false);
          return;
        });

    } else {
        let idForStateApproved = (parseInt(order.id) + 1).toString();
  
        axios({
          //cambio de paymentState
          method: "POST",
          url: "https://kakaomarket.cr/wp-json/misc/v1/setaproved",
          data: {
            order_id: idForStateApproved,
            transaction_id: "No necesaria",
          },
        }).then((response) => {
          if (response.status === 200) {
            //exito en cambio de paymentState
  
            axios({
              //cambio de status
              method: "POST",
              url: "https://kakaomarket.cr/wp-json/sellerconsole/v1/updateorderstatus",
              data: {
                orderId: order.id,
                status: {
                  value: "processing",
                  label: "PROCESANDO",
                  color: "#00B8D4",
                }, //pasar value processing
              },
            }).then((response) => {
              if (response.status === 200) {
                //exito en cambio de status
                setShowLoading(false);
                Swal.fire(
                  "¡Éxito!",
                  "Se procesó correctamente. No se necesitó transaccion bancaria.",
                  "success"
                );
              }
            });
          }
        });
      }
  };

  const rejectOrder = (orderId) => {
    setShowLoading(true);
    let idForStateRejected = (parseInt(orderId) + 1).toString();
    axios({
      //cambio de paymentState
      method: "POST",
      url: "https://kakaomarket.cr/wp-json/misc/v1/setrejected",
      data: {
        order_id: idForStateRejected,
      },
    }).then((response) => {
      if (response.status === 200) {
        axios({
          method: "POST",
          url: "https://kakaomarket.cr/wp-json/sellerconsole/v1/updateorderstatus",
          data: {
            orderId: orderId,
            status: { value: "trash", label: "ELIMINADA", color: "#FF5A5F" }, //pasar value trash
          },
        }).then((response) => {
          setShowLoading(false);
          Swal.fire("¡Éxito!", "Se ha rechazado la orden.", "success");
        });
      }
    });
  };

  const handleOnTableType = (value) => {
    let data;
    let data1;
    setIsStatus(false);
    switch (value) {
      case "all":
        setPage(orders.slice(0, perPage));
        setFilteredOrders(orders);
        setFilterButtons({
          all: true,
          active: false,
          completed: false,
          deleted: false,
        });
        setIsStatus(true);
        setStatusFilter({ value: 0, label: "Seleccionar" });
        break;
      case "active":
        data1 = orders.filter(
          ({ paymentState }) =>
            paymentState.toString() !== "failed" &&
            paymentState.toString() !== "fail" &&
            paymentState.toString() !== "" &&
            paymentState.toString() !== "reject"
        );
        data = data1.filter(
          ({ status }) =>
            status !== "completed" &&
            status !== "trash" &&
            status !== "failed" &&
            status !== "cancelled"
        );
        setPage(data.slice(0, perPage));
        setFilteredOrders(data);
        setFilterButtons({
          all: false,
          active: true,
          completed: false,
          deleted: false,
        });
        break;
      case "completed":
        data1 = orders.filter(({ paymentState }) => paymentState === "aproved");
        data = data1.filter(({ status }) => status === "completed");
        setPage(data.slice(0, perPage));
        setFilteredOrders(data);
        setFilterButtons({
          all: false,
          active: false,
          completed: true,
          deleted: false,
        });
        break;
      case "deleted":
        data1 = orders.filter(
          ({ paymentState }) =>
            paymentState.toString() === "failed" ||
            paymentState.toString() === "fail" ||
            paymentState.toString() === "" ||
            paymentState.toString() === "rejected"
        );
        data = orders.filter(
          ({ status }) => status === "trash" || status === "cancelled"
        );
        setPage(data.slice(0, perPage));
        setFilteredOrders(data);
        setFilterButtons({
          all: false,
          active: false,
          completed: false,
          deleted: true,
        });
        break;
      default:
        break;
    }
  };

  const showStatus = (status, paymentState, id, order) => {
    if (paymentState === "pending") {
      if (status !== "trash") {
        return (
          <div className="actionsOrders">
            <Button
              onClick={() => {
                processPayment(order);
              }}
            >
              Aceptar
            </Button>
            <div
              onClick={() => {
                rejectOrder(order.id);
              }}
              className="row-data"
              style={{ flexBasis: "7%", cursor: "pointer", marginTop: "3.5%" }}
            >
              <svg
                width="11"
                height="13"
                viewBox="0 0 11 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 3H2H10"
                  stroke="#37474F"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3.5 3V2C3.5 1.73478 3.60536 1.48043 3.79289 1.29289C3.98043 1.10536 4.23478 1 4.5 1H6.5C6.76522 1 7.01957 1.10536 7.20711 1.29289C7.39464 1.48043 7.5 1.73478 7.5 2V3M9 3V10C9 10.2652 8.89464 10.5196 8.70711 10.7071C8.51957 10.8946 8.26522 11 8 11H3C2.73478 11 2.48043 10.8946 2.29289 10.7071C2.10536 10.5196 2 10.2652 2 10V3H9Z"
                  stroke="#37474F"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4.5 5.5V8.5"
                  stroke="#37474F"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.50024 5.5V8.5"
                  stroke="#37474F"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span style={{ marginLeft: "5px" }}>{"RECHAZAR"}</span>
            </div>
          </div>
        );
      } else if (status === "trash") {
        return <CircleStatus color="#FF5A5F" name="ELIMINADA" />;
      }
    } else if (
      paymentState === "aproved" ||
      paymentState === "approved" ||
      paymentState === "fail"
    ) {
      if (
        status !== "completed" &&
        status !== "cancelled" &&
        status !== "trash"
      ) {
        return (
          <Select
            label={"Seleccionar"}
            defaultValue={orderTypes.find(({ value }) => value === status)}
            options={orderTypes}
            onChange={(value) => {
              handleOnOrderStatusChange(value, id);
            }}
            styles={colourStyles}
          />
        );
      } else if (status === "completed") {
        return <CircleStatus color="#27A36E" name="COMPLETADA" />;
      } else if (status === "cancelled") {
        return <CircleStatus color="#FF5A5F" name="CANCELADA" />;
      } else if (status === "processing") {
        return (
          <Select
            label={"Seleccionar"}
            defaultValue={orderTypes.find(({ value }) => value === status)}
            options={orderTypes}
            onChange={(value) => {
              handleOnOrderStatusChange(value, id);
            }}
            styles={colourStyles}
          />
        );
      } else if (status === "trash") {
        return <CircleStatus color="#FF5A5F" name="ELIMINADA" />;
      }
    } else if (paymentState === "failed" || paymentState === "fail") {
      if (status === "pending") {
        return (
          <Select
            label={"Seleccionar"}
            defaultValue={orderTypes.find(({ value }) => value === status)}
            options={orderTypes}
            onChange={(value) => {
              handleOnOrderStatusChange(value, id);
            }}
            styles={colourStyles}
          />
        );
      } else {
        return (
          <Select
            label={"Seleccionar"}
            defaultValue={orderTypes.find(
              ({ value }) => value === paymentState.toString()
            )}
            options={orderTypes}
            onChange={(value) => {
              handleOnOrderStatusChange(value, id);
            }}
            styles={colourStyles}
          />
        );
      }
    } else if (paymentState === "reject") {
      return <CircleStatus color="#FF5A5F" name="RECHAZADO" />;
    } else if (status === "trash") {
      return <CircleStatus color="#FF5A5F" name="ELIMINADA" />;
    }
  };

  const handleActiveModalOrder = (order) => {
    setActualOrder(order);
    setShowPopupModal(true);
  };

  // console.log("filteredOrders", filteredOrders);

  return (
    <div className="main-container">
      <h1 className="title-orders">ÓRDENES</h1>
      <div className="headerOptions">
        <div className="scrollOptions">
          <ul>
            <li
              className={`${filterButtons.all ? "isActive" : ""}`}
              onClick={() => handleOnTableType("all")}
            >
              <p>TODAS</p>
            </li>
            <li
              className={`${filterButtons.active ? "isActive" : ""}`}
              onClick={() => handleOnTableType("active")}
            >
              <p>ACTIVAS</p>
            </li>
            <li
              className={`${filterButtons.completed ? "isActive" : ""}`}
              onClick={() => handleOnTableType("completed")}
            >
              <p>COMPLETADAS</p>
            </li>
            <li
              className={`${filterButtons.deleted ? "isActive" : ""}`}
              onClick={() => handleOnTableType("deleted")}
            >
              <p>
                <FontAwesomeIcon
                  style={{ width: "10%", marginRight: "2.5%" }}
                  icon={faTrashAlt}
                />
                ELIMINADAS
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className="search-container">
        <label className="column-text" style={{ display: "flex" }}>
          <svg
            style={{ position: "absolute", padding: 12 }}
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.143 10.7144C8.66775 10.7144 10.7145 8.66764 10.7145 6.14289C10.7145 3.61814 8.66775 1.57143 6.143 1.57143C3.61825 1.57143 1.57153 3.61814 1.57153 6.14289C1.57153 8.66764 3.61825 10.7144 6.143 10.7144Z"
              stroke="#6C7980"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.8568 11.8571L9.37109 9.37138"
              stroke="#6C7980"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <input
            onChange={handleOnSearch}
            value={search}
            className="custom-input"
            style={{ padding: "4px 25px", width: "100%", height: "30px" }}
            type="text"
            placeholder="Buscar"
          />
        </label>
        {isStatus && (
          <label className="column-text">
            <span>ESTADO</span>
            <div className="filterStatus">
              <Select
                label={"Seleccionar"}
                options={orderTypes}
                styles={colourStylesFilter}
                onChange={(value) => handleOnStatusFilter(value)}
                value={statusFilter}
              />
            </div>
          </label>
        )}

        <label className="column-text">
          <span>FECHA</span>
          <input
            onChange={handleOnDateFilterChange}
            value={dateFilter}
            className="custom-input"
            type="date"
          />
        </label>
      </div>
      {!isMobile && (
        <div className="table-container">
          <div className="table-column">
            <div className="column-name" style={{ flexBasis: "7%" }}>
              <h3>ORDEN</h3>
            </div>
            <div className="column-name">
              <h3>DETALLE</h3>
            </div>
            <div className="column-name">
              <h3>TIPO</h3>
            </div>
            <div className="column-name">
              <h3>CLIENTE</h3>
            </div>
            <div className="column-name" style={{ flexBasis: "7%" }}>
              <h3>FECHA</h3>
            </div>
            <div className="column-name" style={{ flexBasis: "14%" }}>
              <h3>ESTADO</h3>
            </div>
            <div className="column-name" style={{ flexBasis: "8%" }}>
              <h3>TOTAL (₡)</h3>
            </div>
            {/*
            <div className="column-name" style={{ flexBasis: "7%" }}>
              <h3>RESPUESTA</h3>
            </div>*/}
            <div className="column-name" style={{ flexBasis: "7%" }}></div>
          </div>
          <div className="table-rows">
            {filteredOrders.length !== 0 &&
              page.map((order) => {
                let dateHoursString = order.dateHours.date.substr(0, 19);
                return (
                  <div className="table-row" key={order.id}>
                    <div
                      className="row-data"
                      style={{ flexBasis: "7%" }}
                      onClick={() => {
                        handleActiveModalOrder(order);
                      }}
                    >{`#${order.id}`}</div>
                    <div
                      className="row-data"
                      onClick={() => {
                        handleActiveModalOrder(order);
                      }}
                    >
                      {order.details}
                    </div>
                    <div
                      className="row-data"
                      onClick={() => {
                        handleActiveModalOrder(order);
                      }}
                    >
                      {parseFloat(order.bpPoints)<0 ? "Puntos Banco Popular" : "Kakao Market"}
                    </div>
                    <div
                      className="row-data"
                      onClick={() => {
                        handleActiveModalOrder(order);
                      }}
                    >
                      {order.customerName}
                    </div>
                    <div
                      className="row-data"
                      style={{ flexBasis: "7%" }}
                      onClick={() => {
                        handleActiveModalOrder(order);
                      }}
                    >
                      {dateHoursString}
                    </div>
                    <div className="row-data" style={{ flexBasis: "14%" }}>
                      {showStatus(
                        order.status,
                        order.paymentState[0],
                        order.id,
                        order
                      )}
                    </div>
                    <div
                      className="row-data"
                      style={{ flexBasis: "8%" }}
                      onClick={() => {
                        handleActiveModalOrder(order);
                      }}
                    >
                      {order.total}
                    </div>
                    {/*
                    <div className="row-data" style={{ flexBasis: "7%" }}>
                      <Answer />
                    </div>*/}
                    {ActionsDelete(
                      filterButtons,
                      order,
                      handleOnOrderStatusChange,
                      handleOnOrderDelete
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      )}

      {isMobile && (
        <div className="containerMobileOrders">
          {filteredOrders.length !== 0 &&
            page.map((order) => {
              let dateHoursString = order.dateHours.date.substr(0, 19);
              return (
                <div className="cardMobileOrders">
                  <div className="itemContainerOrders">
                    <div
                      className="order-info"
                      onClick={() => {
                        handleActiveModalOrder(order);
                      }}
                    >
                      <p style={{ textDecoration: "underline" }}>
                        <span>Order</span> #{order.id}
                      </p>
                    </div>
                    <div className="order-actions">
                      {showStatus(
                        order.status,
                        order.paymentState[0],
                        order.id,
                        order
                      )}
                    </div>
                  </div>
                  <div className="line"></div>
                  <div className="itemContainerOrders">
                    <div className="order-info">
                      <p>
                        <span>Detalle</span>
                      </p>
                    </div>
                    <div className="order-data">
                      <p>{order.details}</p>
                    </div>
                  </div>
                  <div className="itemContainerOrders">
                    <div className="order-info">
                      <p>
                        <span>TIPO</span>
                      </p>
                    </div>
                    <div className="order-data">
                      <p>{parseFloat(order.bpPoints)<0 ? "Puntos Banco Popular" : "Kakao Market"}</p>
                    </div>
                  </div>
                  <div className="line"></div>
                  <div className="itemContainerOrders">
                    <div className="order-info">
                      <p>
                        <span>Cliente</span>
                      </p>
                    </div>
                    <div className="order-data">
                      <p> {order.customerName}</p>
                    </div>
                  </div>
                  <div className="line"></div>
                  <div className="itemContainerOrders">
                    <div className="order-info">
                      <p>
                        <span>Fecha</span>
                      </p>
                    </div>
                    <div className="order-data">
                      <p>{dateHoursString}</p>
                    </div>
                  </div>
                  <div className="line"></div>
                  <div className="itemContainerOrders">
                    <div className="order-info">
                      <p>
                        <span>Precio</span>
                      </p>
                    </div>
                    <div className="order-data">
                      <p> {order.total}</p>
                    </div>
                  </div>
                  <div className="line"></div>
                  {/*<div className="itemContainerOrders">
                    <div className="order-info">
                      <p>
                        <span>Respuesta</span>
                      </p>
                    </div>
                    <div className="order-data">
                      <p>
                        <Answer />
                      </p>
                    </div>
                  </div>*/}
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {ActionsDelete(
                      filterButtons,
                      order,
                      handleOnOrderStatusChange,
                      handleOnOrderDelete
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      )}
      {filteredOrders.length === 0 && (
        <div className="table-row" style={{ justifyContent: "center" }}>
          No hay órdenes
        </div>
      )}
      <div>
        <ReactPaginate
          onPageChange={handlePageClick}
          previousLabel={prevIcon}
          nextLabel={nextIcon}
          breakLabel={"..."}
          pageCount={Math.ceil(filteredOrders.length / perPage)}
          pageRangeDisplayed={isMobile ? 2 : 5}
          marginPagesDisplayed={isMobile ? 1 : 2}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      </div>

      <ModalContainer
        width={isMobileOrTablet ? "80%" : "60%"}
        style={{ alignItems: "center" }}
        handleClose={() => {
          setShowPopupModal(false);
        }}
        active={popupModal}
      >
        <ModalBody>
          <ModulePanelPopup
            handleClose={() => {
              setShowPopupModal(false);
            }}
            order={actualOrder}
          />
        </ModalBody>
      </ModalContainer>
    </div>
  );
};

const ActionsDelete = (
  filterButtons,
  order,
  handleOnOrderStatusChange,
  handleOnOrderDelete
) => {
  let ActionsDelete = (<div className="row-data" style={{ flexBasis: "7%", cursor: "pointer" }} />);
  if (!filterButtons.deleted) {
    if (
      order.status === "trash" ||
      order.paymentState[0] === "pending" ||
      order.paymentState[0] === "failed"
    ) {
      ActionsDelete = (<div className="row-data" style={{ flexBasis: "7%", cursor: "pointer" }} />);
    } else {
      ActionsDelete = (
        <BtnDelete
          handleAction={() => {
            handleOnOrderStatusChange(
              {
                value: "trash",
                label: "ELIMINADA",
                color: "#FF5A5F",
              },
              order.id
            );
          }}
        />
      );
    }
  } else if (filterButtons.deleted) {
    if (
      order.status === "trash" ||
      order.status === "cancelled" ||
      order.paymentState[0] !== "pending" ||
      order.paymentState[0] !== "failed"
    ) {
      ActionsDelete = (
        <BtnDelete
          handleAction={() => {
            handleOnOrderDelete(order.id);
          }}
        />
      );
    } else {
      ActionsDelete = (<div className="row-data" style={{ flexBasis: "7%", cursor: "pointer" }} />)
    }
  } else {
    ActionsDelete = (<div className="row-data" style={{ flexBasis: "7%", cursor: "pointer" }} />)
  } 

  return ActionsDelete;
};

const BtnDelete = ({ handleAction }) => {
  return (
    <div
      onClick={() => handleAction()}
      className="row-data"
      style={{ flexBasis: "7%", cursor: "pointer" }}
    >
      <svg
        width="11"
        height="13"
        viewBox="0 0 11 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 3H2H10"
          stroke="#37474F"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.5 3V2C3.5 1.73478 3.60536 1.48043 3.79289 1.29289C3.98043 1.10536 4.23478 1 4.5 1H6.5C6.76522 1 7.01957 1.10536 7.20711 1.29289C7.39464 1.48043 7.5 1.73478 7.5 2V3M9 3V10C9 10.2652 8.89464 10.5196 8.70711 10.7071C8.51957 10.8946 8.26522 11 8 11H3C2.73478 11 2.48043 10.8946 2.29289 10.7071C2.10536 10.5196 2 10.2652 2 10V3H9Z"
          stroke="#37474F"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.5 5.5V8.5"
          stroke="#37474F"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.50024 5.5V8.5"
          stroke="#37474F"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <span style={{ marginLeft: "5px" }}>{"ELIMINAR"}</span>
    </div>
  );
};

const Answer = (params) => (
  <>
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 5.72223C11.0019 6.45549 10.8306 7.17883 10.5 7.83334C10.108 8.61764 9.5054 9.27732 8.75968 9.73849C8.01396 10.1997 7.15457 10.4441 6.27777 10.4444C5.54451 10.4464 4.82117 10.275 4.16666 9.94445L1 11L2.05555 7.83334C1.72496 7.17883 1.55364 6.45549 1.55555 5.72223C1.55589 4.84543 1.80034 3.98604 2.26151 3.24032C2.72268 2.4946 3.38236 1.892 4.16666 1.50002C4.82117 1.16942 5.54451 0.998104 6.27777 1.00002H6.55555C7.71352 1.0639 8.80723 1.55266 9.62729 2.37271C10.4473 3.19277 10.9361 4.28648 11 5.44445V5.72223Z"
        stroke="#37474F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
    {2}
  </>
);

const Button = ({ children, classname, onClick }) => (
  <button className="btn-orders-primary" onClick={onClick}>
    {children}
  </button>
);

const CircleStatus = ({ color, name }) => {
  return (
    <div>
      <svg
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="4" cy="4" r="4" fill={color} />
      </svg>
      <span className="statusOrders">{name}</span>
    </div>
  );
};
export default Orders;
