import { createUseStyles } from "react-jss"

export const COLORS = {
  blue: "#215273",
  turquoise: "#359d9e",
  aqua: "#55c595",
  green: "#7ce495",
  mint: "#52CC98",
  white: "#ffffff",
  gray: "#e6e6e6",
  black: "#2B2B2B",
  grayText: "#808080",
  gray2: "#F7F9FA",
  grayImg: "#F0F1F2",
  gold: "#D77507",
  navy: "#215273"
}

export const useHelperStyles = createUseStyles({
  flex: { display: "flex" },
  flexRow: { flexDirection: "row" },
  alignCenter: { alignItems: "center" },
  spaceBetween: { justifyContent: "space-between" },
  grayTextColor: { 
    fontSize: "12px", 
    fontWeight: "normal",
    lineHeight: "16px",
    color: COLORS.grayText
  },
  grayTextBackground: { backgroundColor: COLORS.grayText },
  header2: { 
    fontSize: "22px", 
    fontWeight: "bold",
    lineHeight: "30px",
    color: "#637689" 
  },
  canvasInput: {
    backgroundColor: COLORS.grayImg,
    border: 0,
    padding: "11px 16px",
    borderRadius: 110,
    width: "100%",
    "&:focus": {
      outline: "none"
    }
  }
})
