import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  Link,
  useLocation,
  useHistory,
} from "react-router-dom";
import {
  FiHome,
  FiPackage,
  FiShoppingCart,
  FiUser,
  FiPlus,
  FiBell,
  FiLogOut,
  FiHeart,
  FiFileText
} from "react-icons/fi";

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import axios from "axios";
import Swal from "sweetalert2";

import { UserContext } from "../../contexts/user.context";
import { LoadingContext } from "../../contexts/loading.context";
import {
  Desktop,
  Inventory,
  Orders,
  Comments,
  Profile,
  NewProduct,
  Proformas
} from "../index";
import Select, { components } from "react-select";
import { useMediaQuery } from "react-responsive";
import { ModalBody, ModalContainer } from "../../components/modal";
import { selectStylesCategory } from "../../constants/newProduct";

import Arrow from "../../assets/user-arrow.svg";
import SelectArrow from "../../assets/select-arrow.svg";
import CloseIcon from "../../assets/close.svg";

import logo from "../../assets/logo-admin.svg";
import hambur from "../../assets/hamburguesa.svg";
// import close from "../../assets/close.svg";
import "./home.css";
import lisztClient from "../../efika/lisztClient"

const Home = (props) => {
  const history = useHistory();
  let match = useRouteMatch();
  let location = useLocation();

  const [user, setUser] = useContext(UserContext);
  const [popupModal, setShowPopupModal] = useState(false);
  const isTabletOrDesktop = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
  const setShowLoading = useContext(LoadingContext)[1];

  const [toggleMenu, setToggleMenu] = useState(false);

  const handleNewProduct = (event) => {
    event.preventDefault();
    history.push(`${match.url}/new-product`);
  };

  const handleNewProforma = (event) => {
    event.preventDefault()
    history.push(`${match.url}/proformas/new`)
  }

  const logout = async () => {
    setShowLoading(true);
    var response = await lisztClient.tripcomLogout()
    setShowLoading(false);
    if(response.status === 200){
        localStorage.removeItem("userInformation");
        setUser(null);
        history.push("/")
    }else{
        Swal.fire({
          title: "Error",
          text: "Ha ocurrido un error al cerrar sesión",
          icon: "error",
        });
    }
  };

  useEffect(() => {
    if (user === null) {
      history.push("/");
    }
  }, [user, history]);

  const toggleActive = () => {
    setToggleMenu(!toggleMenu);
  };

  return (
    <div className="homeContainer">
      <div
        id="overlay"
        style={{
          display: `${
            !toggleMenu ? "none" : isTabletOrDesktop ? "block" : "none"
          }`,
        }}
      ></div>
      <div
        className="sideBarContainer"
        style={{ left: `${!toggleMenu ? "-100vw" : "0"}` }}
      >
        <div className="kakaoLogoContainer">
          <img className="kakaoLogo" src={logo} alt="" />
        </div>
        <div className="optionsContainer">
          <Link
            to={match.url}
            className={`${
              location.pathname === "/home" ? "activeOption" : ""
            } ${"menuOption"}`}
            onClick={toggleActive}
          >
            <FiHome className="optionIcon" />
            <span>Escritorio</span>
          </Link>
          <Link
            to={`${match.url}/inventory`}
            className={`${
              location.pathname === "/home/inventory" ||
              location.pathname === "/home/new-product"
                ? "activeOption"
                : ""
            } ${"menuOption"}`}
            onClick={toggleActive}
          >
            <div className="inventaryOption">
              <FiPackage className="optionIcon" />
              <span>Inventario</span>
            </div>
            <div className="newProductButton">
              <FiPlus onClick={handleNewProduct} />
            </div>
          </Link>
          <Link
            to={`${match.url}/orders`}
            className={`${
              location.pathname === "/home/orders" ? "activeOption" : ""
            } ${"menuOption"}`}
            onClick={toggleActive}
          >
            <FiShoppingCart className="optionIcon" />
            <span>Órdenes</span>
          </Link>
          {/*----------------RUTAS INACTIVAS PARA PRODUCCIÓN----------------------------*/}
          {/*
                    <Link to={`${match.url}/comments`} className={`${location.pathname === "/home/comments" ? "activeOption" : ""} ${"menuOption"}`}>
                        <FiMessageCircle className="optionIcon" />
                        <span>Comentarios</span>
                    </Link>*/}
          <Link
            to={`${match.url}/profile`}
            className={`${
              location.pathname === "/home/profile" ? "activeOption" : ""
            } ${"menuOption"}`}
            onClick={toggleActive}
          >
            <FiUser className="optionIcon" />
            <span>Perfil</span>
          </Link>
          {user && user.permissions.includes("kakao-seller-console/admin/proformas.POST") && (
            <Link
              to={`${match.url}/proformas`}
              className={`${location.pathname.includes("/home/proformas") ? "activeOption" : ""} menuOption`}
              onClick={toggleActive}
            >
              <div className="inventaryOption">
                <FiFileText className="optionIcon" />
                <span>Proformas</span>
              </div>
              <div className="newProductButton">
                <FiPlus onClick={handleNewProforma} />
              </div>
            </Link>
          )}
        </div>
      </div>
      <div className="containerHome">
        <div className="headerHome">
          <div className="h-hamgur" onClick={toggleActive}>
            {!toggleMenu && (
              <img src={hambur} alt="hambur" style={{ margin: 0 }} />
            )}
            {toggleMenu && (
              <FontAwesomeIcon
                role="button"
                icon={faTimes}
                className="h-close"
                onClick={toggleActive}
              />
            )}
          </div>
          <div className="h-logo">
            <img src={logo} alt="kakao" style={{ margin: 0 }} />
          </div>
          <div className="topBar">
            <div style={{ display: "flex", alignItems: "center", marginRight: "15px" }}>
              <FiBell style={{ fontSize: "19px" }} />
            </div>
            <div className="userDropdown">
              <div className="userProfileCircle">
                <FiUser className="userProf" />
              </div>
              <div className="userDropdownOptions">
                <div className="dropdownArrow">
                  <img src={Arrow} alt="" />
                </div>
                <div className="innerDropdownOptions">
                  {/*
                                <span>Ver perfil</span>
                                */}
                  <div className="logoutOption" onClick={() => logout()}>
                    <FiLogOut style={{ fontSize: "19px" }} />
                    <p
                      className="dropdownOptionText"
                      style={{ marginLeft: "5px", marginTop: "3px" }}
                    >
                      Cerrar sesión
                    </p>
                  </div>
                  <div
                    className="logoutOption"
                    onClick={() => setShowPopupModal(true)}
                  >
                    <FiHeart style={{ fontSize: "19px" }} />
                    <p
                      className="dropdownOptionText"
                      style={{ marginLeft: "5px", marginTop: "3px" }}
                    >
                      Categorías
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <ModalContainer
              width={"initial"}
              style={{ alignItems: "center" }}
              handleClose={() => {
                setShowPopupModal(false);
              }}
              active={popupModal}
            >
              <ModalBody>
                <div className="containerFavorite">
                  <CategoryFavorite
                    handleClose={() => {
                      setShowPopupModal(false);
                    }}
                  />
                </div>
              </ModalBody>
            </ModalContainer>
          </div>
        </div>
        <div style={{ overflow: "auto" }}>
          <Switch>
            <Route exact path={match.path} component={Desktop} />
            <Route path={`${match.path}/inventory`} component={Inventory} />
            <Route path={`${match.path}/orders`} component={Orders} />
            <Route path={`${match.path}/comments`} component={Comments} />
            <Route path={`${match.path}/profile`} component={Profile} />
            <Route path={`${match.path}/new-product`} component={NewProduct} />
            <Route path={`${match.path}/proformas`} component={Proformas} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

const CategoryFavorite = ({ handleClose }) => {
  const [user, setUser] = useContext(UserContext);
  const setShowLoading = useContext(LoadingContext)[1];
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const handleSelectCategories = (value) => {
    let valueFormated = { ...value, favorite: 1 };
    console.log(valueFormated);
    if (!selectedCategories.includes(valueFormated)) {
      setSelectedCategories([...selectedCategories, valueFormated]);
      setCategories(
        categories.filter((item) => item.value !== valueFormated.value)
      );
    }
  };

  const handleDeleteCategory = (value) => {
    let valueFormated = { ...value, favorite: 0 };
    setCategories([...categories, valueFormated]);
    setSelectedCategories(
      selectedCategories.filter((item) => item.value !== valueFormated.value)
    );
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={SelectArrow} alt="" />
      </components.DropdownIndicator>
    );
  };

  const hadleConvertSelect = (data, key1, key2) =>
    data.map((item) => {
      return {
        label: item[key1],
        value: item[key2],
        favorite: Number(item.favorite),
      };
    });

  const handleRequest = useCallback(
    async (url, method, params, indicative, msj) => {
      setShowLoading(true);
      try {
        const response =
          method === "POST"
            ? await axios.post(url, params)
            : await axios.get(url);
        if (response.status === 200) {
          let dataParse = null;
          if (indicative === 1) {
            dataParse = JSON.parse(response.data);
            setCategories(
              hadleConvertSelect(
                dataParse.category.filter((cat) => Number(cat.favorite) === 0),
                "name",
                "id",
                false
              )
            );
            setSelectedCategories(
              hadleConvertSelect(
                dataParse.category.filter((cat) => Number(cat.favorite) === 1),
                "name",
                "id",
                false
              )
            );

            setShowLoading(false);
          }
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: `Ha ocurrido un error al tener información ${msj}`,
          icon: "error",
        });
        setShowLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    handleRequest(
      //   "http://localhost:10010/wp-json/sellerconsole/v1/get_product_extra_info",
      "https://kakaomarket.cr/wp-json/sellerconsole/v1/get_product_extra_info",
      "POST",
      {
        idSeller: user.vendorId,
        email: user.user_email,
      },
      //   {
      //     idSeller: "12739",
      //     email: "fjos807@gmail.com",
      //   },
      1,
      "del listado de atributos"
    );
  }, []);

  const handleUpsertCategoryFavorites = async () => {
    console.log(
      selectedCategories
        .map((item) => {
          return item.value;
        })
        .join()
    );
    try {
      setShowLoading(true);
      const response = await axios.post(
        "https://kakaomarket.cr/wp-json/sellerconsole/v1/favorites_category",
        {
          //   email: "fjos807@gmail.com",
          email: user.user_email,
          values: selectedCategories
            .map((item) => {
              return item.value;
            })
            .join()
            .toString(),
        }
      );

      //   console.log(response);

      if (response.status === 200) {
        Swal.fire(
          "¡Se han actualizado los datos correctamente!",
          "",
          "success"
        );
        setShowLoading(false);
        handleClose();
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Ha ocurrido un error al guardar las categorias como favoritas.",
        icon: "error",
      });
      setShowLoading(false);
    }
  };
  //   console.log(categories);

  return (
    <div>
      <h1 className="newProductTitle">Categorías favoritas</h1>
      <div className="selectContainer" style={{ display: "block" }}>
        {/* <span className="sectionSubTitle">Categorías</span> */}
        <Select
          className=""
          placeholder="Seleccionar"
          onChange={handleSelectCategories}
          options={categories}
          styles={selectStylesCategory}
          components={{ DropdownIndicator }}
        />

        <FontAwesomeIcon
          role="button"
          onClick={() => {
            handleClose();
          }}
          style={{
            position: "absolute",
            top: "30px",
            right: "30px",
            cursor: "pointer",
          }}
          icon={faTimes}
        />
      </div>
      <div className="itemListContainer" style={{ flexWrap: "wrap" }}>
        {selectedCategories.map((category) => (
          <div
            key={category.value}
            className="itemContainer"
            style={{ marginTop: "5px" }}
          >
            <img
              src={CloseIcon}
              alt="CloseIcon"
              onClick={() => handleDeleteCategory(category)}
              style={{ marginRight: "3px" }}
            />
            <span>{category.label}</span>
          </div>
        ))}
      </div>
      <button
        className="publishButton"
        style={{
          position: "absolute",
          bottom: "20px",
          width: "250px",
          right: 0,
        }}
        onClick={() => {
          handleUpsertCategoryFavorites();
          console.log(
            selectedCategories
              .map((item) => {
                return item.value;
              })
              .join()
          );
        }}
      >
        Guardar
      </button>
    </div>
  );
};

export default Home;
