import './HeadContainer.css'
import Title from './Title/Title'

export default function HeadContainer({ title = 'vacio', children, headerOpt, handleOptionsSelected }) {

  return (
    <div className="headContainer">
      <Title>{title}</Title>
      {children}
    </div>
  )
}
