import React, { useState, useEffect, useContext, useRef } from "react"
import Swal from "sweetalert2"
import Select from "react-select"
import chroma from "chroma-js"
import { FiLink2, FiPlus } from "react-icons/fi"
import { useHistory } from "react-router-dom"
import { LoadingContext } from "../../../contexts/loading.context"
import "./proformaList.css"
import SearchIcon from "../../../assets/icons/search.svg"
import { proformasStatus, proformasStatusValues } from "../../../constants/proformasStatus"
import { EfikaOfflineFirst } from "../../../efika/efikaOfflineFirst"
import moment from "moment"
import "moment/locale/es"

const ProformaList = (props) => {
    const setShowLoading = useContext(LoadingContext)[1]
    const userInformation = JSON.parse(localStorage.getItem("userInformation"))

    const dot = (color = "#ccc") => ({
        alignItems: "center",
        display: "flex",

        ":before": {
            backgroundColor: color,
            borderRadius: 10,
            content: '" "',
            display: "block",
            marginRight: 8,
            height: 10,
            width: 10,
        },
    })

    const colourStylesFilter = {
        container: (styles) => ({ ...styles, flex: 1 }),
        control: (styles) => ({
            ...styles,
            backgroundColor: "white",
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = chroma(data.color)
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? null
                    : isSelected
                        ? data.color
                        : isFocused
                            ? color.alpha(0.1).css()
                            : null,
                color: isDisabled
                    ? "#ccc"
                    : isSelected
                        ? chroma.contrast(color, "white") > 2
                            ? "white"
                            : "black"
                        : data.color === "#FFFF00"
                            ? "black"
                            : data.color,
                cursor: isDisabled ? "not-allowed" : "default",

                ":active": {
                    ...styles[":active"],
                    backgroundColor:
                        !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
                },
            };
        },
        input: (styles) => ({ ...styles, ...dot() }),
        placeholder: (styles) => ({ ...styles, ...dot() }),
        singleValue: (styles, { data }) => ({
            ...styles,
            maxWidth: "none",
            ...dot(data.color),
        }),
    }

    const [filterButtons, setFilterButtons] = useState("all")
    const [searchText, setSearchText] = useState("")
    const [dateFilter, setDateFilter] = useState("")
    const [statusFilter, setStatusFilter] = useState({ value: 0, label: "Seleccionar" })
    const [proformasList, setProformasList] = useState([])
    const [filteredProformasList, setFilteredProformasList] = useState([])

    const showLoading = () => {
        setShowLoading(true)
    }

    const hideLoading = () => {
        setShowLoading(false)
    }

    const eofProformas = useRef(new EfikaOfflineFirst("PROFORMAS", showLoading, hideLoading))

    const history = useHistory()

    const handleOnTableType = (status) => {
        alert(status)
    }

    const handleOnSearch = ({ target: { value } }) => {
        setSearchText(value)
        const searchedProformas = proformasList.filter(({ proformaId, clientName, clientLastName, productName }) => {
            const proformaIdSearched = String(proformaId)
                .toLowerCase()
                .trim()
                .includes(value.toLocaleLowerCase().trim())
            const clientNameSearched = clientName
                .toLowerCase()
                .trim()
                .includes(value.toLocaleLowerCase().trim())
            const clientLastNameSearched = clientLastName
                .toLowerCase()
                .trim()
                .includes(value.toLocaleLowerCase().trim())
            const productNameSearched = productName
                .toLowerCase()
                .trim()
                .includes(value.toLocaleLowerCase().trim())
            return proformaIdSearched || clientNameSearched || clientLastNameSearched || productNameSearched
        })
        setFilteredProformasList(searchedProformas)
    }

    const handleOnStatusFilter = (option) => {
        setStatusFilter(option)
        if (option.value === 0) {
            setFilteredProformasList(proformasList)
        } else {
            const searchedProformas = proformasList.filter(({ status }) => {
                return status === option.value
            })
            setFilteredProformasList(searchedProformas)
        }
    }

    const handleOnDateFilterChange = ({ target: { value } }) => {
        console.log(value)
        if (value === "") {
            setFilteredProformasList(proformasList)
        } else {
            const filteredProformas = proformasList.filter(({ date }) => {
                const newDate = date.split("T")[0]
                return newDate === value
            })
            setFilteredProformasList(filteredProformas)
        }
        setDateFilter(value)
    }

    const handleCopyLink = (proformaExternalId, active) => {
        if (active) {
            const link = `https://pagos.kakaomarket.cr/proformas/${proformaExternalId}`
            navigator.clipboard.writeText(link)

            Swal.fire(
                "Enlace de pago",
                "El enlace de pago ha sido copiado",
                "success"
            )
        }
    }

    useEffect(async () => {
        const generator = eofProformas.current.listParamsGenerator("proformaId",{vendorId: userInformation.vendorId})
        for await (const value of generator) {
            var sortedData = value.sort((a, b) => b.proformaId - a.proformaId)
            setProformasList(sortedData)
            setFilteredProformasList(sortedData)
        }
    }, [])


    return (
        <div className="main-container">
            <h1 className="title-orders">PROFORMAS</h1>
            <div className="headerOptions">
                <div className="scrollOptions">
                    <ul>
                        <li
                            className={`${filterButtons === "all" ? "isActive" : ""}`}
                        //onClick={() => handleOnTableType("all")}
                        >
                            <p>TODAS</p>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="second-header-container">
                <div className="search-bar">
                    <img
                        src={SearchIcon}
                        alt="Buscar"
                        style={{ width: "12px", height: "12px" }}
                    />
                    <input
                        onChange={handleOnSearch}
                        value={searchText}
                        className="custom-search-input"
                        type="text"
                        placeholder="Buscar"
                    >
                    </input>
                </div>

                <div className="second-header-inner-container">
                    <span className="complementary-text" style={{ marginRight: "15px" }}>ESTADO</span>
                    <Select
                        label={"Seleccionar"}
                        options={proformasStatus}
                        styles={colourStylesFilter}
                        onChange={(value) => handleOnStatusFilter(value)}
                        value={statusFilter}
                    />
                </div>

                <div className="second-header-inner-container">
                    <span className="complementary-text">FECHA</span>
                    <input
                        onChange={handleOnDateFilterChange}
                        value={dateFilter}
                        className="custom-input"
                        type="date"
                        style={{ height: "auto" }}
                    />
                </div>
                <div className="second-header-inner-container">
                    <div className="main-action-button" onClick={() => history.push("proformas/new")}>
                        <FiPlus className="new-proforma-icon" />
                        <span style={{ marginLeft: "10px" }}>Crear una nueva proforma</span>
                    </div>
                </div>
            </div>

            <div className="table-container" style={{paddingBottom: "40px"}}>
                <div className="table-column">
                    <div className="column-name">
                        <h3>PROFORMA</h3>
                    </div>
                    <div className="column-name">
                        <h3>AUTOR</h3>
                    </div>
                    <div className="column-name">
                        <h3>DETALLE</h3>
                    </div>
                    <div className="column-name">
                        <h3>CLIENTE</h3>
                    </div>
                    <div className="column-name">
                        <h3>FECHA</h3>
                    </div>
                    <div className="column-name">
                        <h3>TOTAL ( ₡ )</h3>
                    </div>
                    <div className="column-name">
                        <h3>ESTADO</h3>
                    </div>
                    <div className="column-name">
                    </div>
                </div>
                <div className="table-rows">
                    {filteredProformasList.map((entry) => {

                        return (
                            <div className="table-row" key={entry.proformaId}>
                                <div className="row-data">
                                    {`#${entry.proformaId}`}
                                </div>
                                <div className="row-data">
                                    {entry.authorName}
                                </div>
                                <div className="row-data">
                                    {entry.productName}
                                </div>
                                <div className="row-data">
                                    {entry.clientName + " " + entry.clientLastName}
                                </div>
                                <div className="row-data">
                                    {moment.utc(entry.date).format('L')}
                                </div>
                                <div className="row-data">
                                    {(entry.totalPrice).toFixed(2)}
                                </div>
                                <div className="row-data">
                                    {proformasStatusValues[entry.status]}
                                </div>
                                <div className="row-data">
                                    <div className="copy-link-container" style={entry.status === "paid" ? { opacity: 0.3 } : {}} onClick={() => handleCopyLink(entry.proformaExternalId, entry.status !== "paid")}>
                                        <FiLink2 className="copy-link-icon" />
                                        <span className="copy-link-text">Copiar enlace</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>

        </div>
    )
}

export default ProformaList
