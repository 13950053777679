export const orderStatus = {
  processing: "Procesando",
  completed: "Completada",
  "on-hold": "En pausa",
  cancelled: "Cancelada",
  pending: "Pendiente de pago",
  refunded: "Reembolsada",
  failed: "Fallida",
  "pago-parcial": "Pago parcial",
  transporte: "Transporte",
  "checkout-draft": "Borrador",
};

export const getStatusIcon = (state) => {
  switch (state) {
    case "cancelled":
      return "#FF5A5F";

    case "completed":
      return "#27A36E";

    case "on-hold":
      return "#FF6D00";

    case "processing":
      return "#00B8D4";
    default:
      return "#FF5A5F";
  }
};

export const changeDateFormat = (date) => {
  var fullDateArray = date.split("T");
  fullDateArray.pop();
  var finalArray = fullDateArray[0].split("-");
  finalArray.reverse();
  return finalArray.join("/");
};
