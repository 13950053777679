import { EfikaEncryptation } from "./encryptation"
import {
  TRIPCOM,
  LISZT,
  APP_NAME,
  TRIPCOM_KEY,
  TRIPCOM_ENDPOINT,
  LISZT_ENDPOINT,
  CLIENT_REGISTER_ENDPOINT,
  CLIENT_REGISTER_KEY
} from "./services.json"
import PouchDB from "pouchdb-browser"

const PENDING_POST = "Post"

function LisztClient() {
  const lisztEndpoint = LISZT_ENDPOINT
  const clientRegisterEndpoint = CLIENT_REGISTER_ENDPOINT
  const tripcomEndpoint = TRIPCOM_ENDPOINT
  const appName = APP_NAME
  const tripcomServices = TRIPCOM
  const lisztServices = LISZT
  const dbPending = new PouchDB(PENDING_POST)
  let encryptPostObj = getPublicKey() ? new EfikaEncryptation(getPublicKey()) : null

  const tripcomLogin = async (data, encrypt = true) => {
    try {
      const encryptObj = new EfikaEncryptation(TRIPCOM_KEY)
      let postData = { __action: "POST", ...data }

      if (encrypt) postData = encryptObj.encrypt(postData)

      let params = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "liszt-app": appName
        },
        body: JSON.stringify(postData)
      }
      
      let request = new Request(tripcomEndpoint + tripcomServices.AUTH, params)
      
      let response = await fetch(request)
            
      if (response.status !== 200) return { status: response.status }

      let json = await response.json()

      return { status: 200, ...json }
    } catch (error) {
      console.error("tripcomLogin error:", error)
      return { status: 500, message: error.message }
    }
  }

  const lisztLogin = async data => {
    let params = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        "liszt-app": appName
      }),
      body: JSON.stringify(data)
    }

    try {
      let request = new Request(lisztEndpoint + lisztServices.LOGIN, params)
      let response = await fetch(request)
      let json = await response.json()
      let lisztToken = response.headers.get("liszt-token")

      if (response.status !== 200) return { status: response.status, error: json.error || "" }

      localStorage.setItem("session", json.session)
      localStorage.setItem("publicKey", json.publicKey)
      localStorage.setItem("lisztToken", lisztToken)
      localStorage.setItem("userData", JSON.stringify(json.recordset))
      // localStorage.setItem("tokenData", JSON.stringify({ session: json.session, publicKey: json.publicKey, lisztToken }))

      encryptPostObj = new EfikaEncryptation(json.publicKey)

      return { status: 200, ...json }
    } catch (error) {
      return { status: 500, error: error.message }
    }
  }

  this.tripcomReset = async (data, encrypt = true) => {
    try {
      let postData = { ...data, __action: "POST" }
      const encryptObj = new EfikaEncryptation(TRIPCOM_KEY)

      if (encrypt) postData = encryptObj.encrypt(postData)

      let params = {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          "liszt-app": appName
        }),
        body: JSON.stringify(postData)
      }
      let request = new Request(tripcomEndpoint + tripcomServices.RESET, params)
      let response = await fetch(request)

      if (response.status === 404) return { status: 404 }
      if (response.status !== 200) return { status: response.status }

      let json = await response.json()

      return json
    } catch (error) {
      console.error("tripcomReset error:", error)
      return { status: 500 }
    }
  }

  this.tripcomLogout = async () => {
    const encryptObj = new EfikaEncryptation(TRIPCOM_KEY)
    const dataEncrypted = encryptObj.encrypt({
      __action: "POST",
      session: getSession()
    })

    const request = new Request(`${tripcomEndpoint}${tripcomServices.LOGOUT}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        "liszt-app": appName
      }),
      body: JSON.stringify(dataEncrypted)
    })

    const response = await fetch(request)

    if (response.status !== 200) {
      return undefined
    }

    // Limpiar localStorage
    localStorage.clear()

    // Eliminar BDs Pouch
    const dbs = await window.indexedDB.databases()
    dbs.forEach(db => {
      window.indexedDB.deleteDatabase(db.name)
    })

    return response.json()
  }

  this.lisztPost = async (data, resource, encrypt = true) => {
    if (encrypt) {
      // const publicKey = getPublicKey()
      data = encryptPostObj.encrypt(data)
    }

    const lisztToken = getLisztToken()
    const session = getSession()

    let params = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        "liszt-token": lisztToken,
        "liszt-app": appName
      }),
      body: JSON.stringify({ session, ...data })
    }

    try {
      let request = new Request(`${lisztEndpoint}${appName}/${resource}`, params)
      let response = await fetch(request)
      let json = await response.json()

      if (json.hasOwnProperty("e")) {
        return { status: 401 }
      }

      let lisztToken = response.headers.get("liszt-token")
      setLisztToken(lisztToken)

      return json
    } catch (err) {
      console.log("lisztPost error:", err)
      return { status: 500, error: err.message }
    }
  }

  this.lisztCheckLogin = async () => {
    const session = getSession()
    const lisztToken = getLisztToken()

    if (!session || !lisztToken) return false

    try {
      let params = {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          "liszt-token": lisztToken,
          "liszt-app": appName
        }),
        body: JSON.stringify({ session })
      }

      let request = new Request(lisztEndpoint + lisztServices.CHECK_LOGIN, params)
      let response = await fetch(request)
      let json = await response.json()

      if (json.status && json.status === 200) return true

      return false
    } catch (error) {
      return false
    }
  }

  this.login = async (data, encrypt = true) => {
    try {
      const tripcomLoginRes = await tripcomLogin(data, encrypt)
      if (tripcomLoginRes.status !== 200) return tripcomLoginRes

      const { l } = tripcomLoginRes

      const lisztLoginRes = await lisztLogin({ l })

      return lisztLoginRes
    } catch (error) {
      return { status: 500, error: error.message }
    }
  }

  this.clientRegister = async (data, encrypt = true) => {
    try {
      const encryptObj = new EfikaEncryptation(CLIENT_REGISTER_KEY)

      if (encrypt) data = encryptObj.encrypt(data)

      let params = {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json"
        }),
        body: JSON.stringify(data)
      }

      let request = new Request(clientRegisterEndpoint, params)

      let response = await fetch(request)

      return response
    } catch (error) {
      console.log("clientRegister error:",error)
    }
  }

  function getLisztToken() {
    return localStorage.getItem("lisztToken")
  }

  function getSession() {
    return localStorage.getItem("session")
  }

  function getPublicKey() {
    return localStorage.getItem("publicKey")
  }

  function setLisztToken(lisztToken) {
    localStorage.setItem("lisztToken", lisztToken)
  }

  const sendOfflinePosts = async () => {
    const postsInPouch = await dbPending.allDocs({ include_docs: true })

    if (!postsInPouch.rows) return
    const { rows } = postsInPouch

    for (let i = 0; i < rows.length; i++) {
      try {
        const resPost = await this.lisztPost(rows[i].doc.data, rows[i].doc.serviceName, true)

        if (resPost.status !== 200) continue

        dbPending.remove(rows[i].doc)
      } catch (error) {
        console.log("sendOfflinePosts error:",error)
      }
    }
  }

  if (window.navigator.onLine) sendOfflinePosts()

  window.addEventListener("online", async evt => {
    setTimeout(() => sendOfflinePosts(), 2000)
  })
}

export default new LisztClient()
