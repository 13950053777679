import React from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles({
  footer: {
    padding: 20
  }
})

const ModalFooter = (props) => {
  const { footer } = useStyles()

  return (
    <div className={footer}>
      {props.children}
    </div>
  )
}

export default ModalFooter
