import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from "react";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import Swal from "sweetalert2";
import { FiSearch, FiPlus } from "react-icons/fi";
import { FaRegComment } from "react-icons/fa";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import { LoadingContext } from "../../contexts/loading.context";
import ReactPaginate from "react-paginate";
import "./inventory.css";
import { UserContext } from "../../contexts/user.context";
import arrowBottom from "../../assets/arrowBottom.svg";

import {
  handleTexSuppress,
  handleColorInventary,
} from "../../constants/newProduct";

const Inventory = (props) => {
  const [user] = useContext(UserContext);
  const [dataPublish, setDataPublish] = useState([]);
  const [dataDraft, setDataDraft] = useState([]);
  const [dataTrash, setDataTrash] = useState([]);
  const [data, setData] = useState([]);
  const [selectOptions, setSelectOptions] = useState("1");

  const setShowLoading = useContext(LoadingContext)[1];

  const history = useHistory();

  const handleEditProductInventary = async (data) => {
    // const selectedAttributesState = await handleGetAttributeById(data.id);
    // if (!selectedAttributesState) return;

    data.meta._stock = data.meta._stock === null ? 0 : data.meta._stock;

    let temperatureOption = {};
    let enabledCheckTemp = false;
    if (data.meta.product_temperature_handler === "") {
      temperatureOption = { value: "select", label: "Select" };
    }
    if (data.meta.product_temperature_handler === "frio") {
      temperatureOption = { value: "frio", label: "Frío" };
    } else {
      temperatureOption = { value: "caliente", label: "Caliente" };
      enabledCheckTemp = true;
    }

    data.temperatureOption = temperatureOption;
    data.enabledCheckTemp = enabledCheckTemp;

    let categoryNew = data.category.filter(
      (catg) => catg.type === "product_cat"
    );

    let productVisibility = data.category.filter(
      (catg) => catg.type === "product_visibility" && catg.id !== 8
    );

    //Producto destacado
    data.outstanding =
      data.category.filter(({ type, id }) => {
        return type === "product_visibility" && id === 8;
      }).length > 0
        ? true
        : false;

    //Formateando las categorias para la edicion en los selected
    data.category = categoryNew.map((catg) => {
      return {
        label: catg.name,
        value: catg.id,
      };
    });

    //Formateando las los prtoductos relacionado para la edicion en los selected
    data.relatedProducts = data.relatedProducts.map((related) => {
      return {
        ...related,
        label: related.name,
      };
    });

    //Verificamos si el producto esta protegido por contraseña
    data.visiblePassword = data.post_password.length > 0 ? true : false;

    /** Valor default select VISIBILIDAD*/
    let optionDefaultV = {};
    if (data.post_status === "private") {
      optionDefaultV = { value: "privada", label: "Privada" };
    } else if (data.post_password.length > 0) {
      optionDefaultV = {
        value: "protegido",
        label: "Protegido con contraseña",
      };
    } else {
      optionDefaultV = { value: "publica", label: "Pública" };
    }

    data.visibilityOption = optionDefaultV;
    /****************************************************/

    /**Valor default select VISIBLE EN */

    let optionDefaultV2 = {};
    //console.log('xd', productVisibility);
    if (productVisibility.length === 0) {
      optionDefaultV2 = {
        value: "tienda-y-busqueda",
        label: "En la tienda y en los resultados de búsqueda",
      };
    } else {
      if (productVisibility.length === 1) {
        if (productVisibility[0].id === 6) {
          optionDefaultV2 = { value: "tienda", label: "Solo en la tienda" };
        } else if (productVisibility[0].id === 7) {
          optionDefaultV2 = {
            value: "busqueda",
            label: "Solo en los resultados de búsqueda",
          };
        }
      } else {
        optionDefaultV2 = { value: "oculto", label: "Oculto" };
      }

      //KM
    }

    data.visibilityPlaceOption = optionDefaultV2;
    data.productVisibility = productVisibility.map((productV) => {
      return {
        name: productV.type,
        value: productV.id,
        action: "E",
      };
    });

    /********************************************** */

    /*****Select  inventario default*/
    let optionDefaultV3 = {};

    if (data.meta._stock_status === "instock") {
      optionDefaultV3 = { value: "instock", label: "Hay en existencia" };
    } else if (data.meta._stock_status === "outofstock") {
      optionDefaultV3 = { value: "outofstock", label: "Bajo Inventario" };
    } else {
      optionDefaultV3 = { value: "onbackorder", label: "Se puede reservar" };
    }

    data.inventoryOption = optionDefaultV3;
    /********************************************** */

    // data.selectedAttributesState = selectedAttributesState;

    //Imagenes
   /* data.imagen._product_image_gallery = data.imagen._product_image_gallery.map(
      (gallery) => {
        return {
          ...gallery,
          action: "E",
        };
      }
    );
    */

    console.log(data);

    history.push({
      pathname: "new-product",
      state: data,
    });
  };

  const headerOpt = [
    {
      id: "1",
      name: `Todos (${data.length})`,
      data,
    },
    {
      id: "2",
      name: `Publicados`,
      data: dataPublish,
    },
    {
      id: "3",
      name: `Borradores`,
      data: dataDraft,
    },
    {
      id: "4",
      name: `En Papelera`,
      data: dataTrash,
    },
  ];

  const handleConvertMeta = (data) => {
    return data.map((data, idx) => {
      Object.keys(data.meta).forEach((key) => {
        return (data.meta[key] = data.meta[key][0]);
      });

      return {
        ...data,
      };
    });
  };

  const handleListProduct = useCallback(
    async (id) => {
      setShowLoading(true);

      try {
        const response = await axios.post(
          "https://kakaomarket.cr/wp-json/sellerconsole/v1/get_inventary_data",
          {
            idSeller: id,
          }
        );
        if (response.status === 200) {
          let dataParse = JSON.parse(response.data);

          setDataPublish(handleConvertMeta(dataParse.publish));
          setDataDraft(handleConvertMeta(dataParse.draft));
          setDataTrash(handleConvertMeta(dataParse.trash));
          setData(handleConvertMeta(dataParse.result));
          setShowLoading(false);
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: "Ha ocurrido un error al tener información del inventario",
          icon: "error",
        });
        setShowLoading(false);
      }
    },
    [data, dataPublish, dataDraft, dataTrash]
  );

  const handleRecycleProduct = useCallback(
    async (id) => {
      try {
        setShowLoading(true);
        const response = await axios.post(
          "https://kakaomarket.cr/wp-json/sellerconsole/v1/recycle_product_inventary",
          {
            idProduct: id,
          }
        );

        if (response.status === 200) {
          let product = data.find((product) => product.id === id);
          product.post_status = "trash";
          setDataPublish(dataPublish.filter((product) => product.id !== id));
          setDataDraft(dataDraft.filter((product) => product.id !== id));
          setDataTrash(dataTrash.concat(product));
          Swal.fire("Producto movido a la papalera", "", "success");
          setShowLoading(false);
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: "Ha ocurrido un error al mover el producto a la papelera",
          icon: "error",
        });
        setShowLoading(false);
      }
    },
    [data, dataPublish, dataDraft, dataTrash]
  );

  const handleDeleteProductInventary = useCallback(
    (id) => {
      Swal.fire({
        title: `¿Seguro que quiere eliminar el producto del inventario?`,
        text: "Esta acción será irreversible",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          try {
            setShowLoading(true);
            const response = await axios.post(
              "https://kakaomarket.cr/wp-json/sellerconsole/v1/delete_producto_inventary",
              {
                idProduct: id,
              }
            );

            if (response.status === 200) {
              //let product = data.find((product) => product.id === id)

              setDataPublish(
                dataPublish.filter((product) => product.id !== id)
              );
              setDataDraft(dataDraft.filter((product) => product.id !== id));
              setDataTrash(dataTrash.filter((product) => product.id !== id));
              setData(data.filter((product) => product.id !== id));
              Swal.fire("¡Producto eliminado!", "", "success");
              setShowLoading(false);
            }
          } catch (error) {
            Swal.fire({
              title: "Error",
              text: "Ha ocurrido un error al eliminar el producto del inventario",
              icon: "error",
            });
            setShowLoading(false);
          }
        }
      });
    },
    [data, dataPublish, dataDraft, dataTrash]
  );

  async function toggleOutstanding(outstanding, productId) {
    setShowLoading(true);
    try {
      const response = await axios.post( outstanding > 0 ?
        "https://kakaomarket.cr/wp-json/sellerconsole/v1/removefeaturedproduct" :
        "https://kakaomarket.cr/wp-json/sellerconsole/v1/setfeaturedproduct" ,
        {
          productId: productId,
        }
      );
      if (response.status === 200) {
        outstanding > 0 ?
          Swal.fire({
            title: "Producto removido de destacados",
            text: "Se ha removido el producto de destacados correctamente",
            icon: "success",
          })
          :
          Swal.fire({
            title: "Producto destacado",
            text: "Se ha destacado el producto correctamente",
            icon: "success",
          })
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Ha ocurrido un error al destacar el producto",
        icon: "error",
      })
    }
    setShowLoading(false)
  }

  useEffect(() => {
    handleListProduct(user.vendorId);
  }, []);

  const handleOptionsSelected = (id) => {
    setShowLoading(true);
    setTimeout(() => {
      setSelectOptions(id);
      setShowLoading(false);
    }, 3000);
  };

  return (
    <div className="inventoryContainer">
      <h1 className="inventoryTitle"> Inventario </h1>
      <HeaderOptions
        headerOpt={headerOpt}
        handleOptionsSelected={handleOptionsSelected}
        //setShowLoading={setShowLoading}
      />
      {headerOpt.map(({ data, id }, idx) => {
        let propsRequerided = {
          id: idx,
          vendorId: user.vendorId,
          data: data,
          history: history,
          isVisible: selectOptions === id ? true : false,
          setShowLoading: setShowLoading,
          handleListProduct: handleListProduct,
          handleEditProductInventary: handleEditProductInventary,
          handleDeleteProductInventary: handleDeleteProductInventary,
          handleRecycleProduct: handleRecycleProduct,
          toggleOutstanding: toggleOutstanding
        };

        return <Table key={idx} propsRequerided={propsRequerided} />;
      })}
    </div>
  );
};

const Table = ({ propsRequerided }) => {
  const {
    vendorId,
    id,
    data,
    history,
    isVisible,
    setShowLoading,
    handleEditProductInventary,
    handleDeleteProductInventary,
    handleRecycleProduct,
    toggleOutstanding,
    handleListProduct 
  } = propsRequerided;

  const [activeEnter, setActiveEnter] = useState("");
  const [optionsInventary, setOptionsInventary] = useState("");

  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });

  const prevIcon = (
    <svg
      style={{ cursor: "pointer" }}
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 13.3727C8.83333 12.9848 8.83333 12.0152 9.5 11.6273L15.5 8.13651C16.1667 7.74865 17 8.23348 17 9.00921V15.9908C17 16.7665 16.1667 17.2514 15.5 16.8635L9.5 13.3727Z"
        fill="#37474F"
      />
      <path
        d="M2 1H24V-1H2V1ZM25 2V23H27V2H25ZM24 24H2V26H24V24ZM1 23V2H-1V23H1ZM2 24C1.44772 24 1 23.5523 1 23H-1C-1 24.6569 0.343146 26 2 26V24ZM25 23C25 23.5523 24.5523 24 24 24V26C25.6569 26 27 24.6569 27 23H25ZM24 1C24.5523 1 25 1.44772 25 2H27C27 0.343146 25.6569 -1 24 -1V1ZM2 -1C0.343146 -1 -1 0.343145 -1 2H1C1 1.44772 1.44772 1 2 1V-1Z"
        fill="#C8CBCC"
      />
    </svg>
  );

  const nextIcon = (
    <svg
      style={{ cursor: "pointer" }}
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5 11.6273C16.1667 12.0152 16.1667 12.9848 15.5 13.3727L9.5 16.8635C8.83333 17.2514 8 16.7665 8 15.9908L8 9.00921C8 8.23348 8.83333 7.74864 9.5 8.13651L15.5 11.6273Z"
        fill="#37474F"
      />
      <path
        d="M2 1H24V-1H2V1ZM25 2V23H27V2H25ZM24 24H2V26H24V24ZM1 23V2H-1V23H1ZM2 24C1.44772 24 1 23.5523 1 23H-1C-1 24.6569 0.343146 26 2 26V24ZM25 23C25 23.5523 24.5523 24 24 24V26C25.6569 26 27 24.6569 27 23H25ZM24 1C24.5523 1 25 1.44772 25 2H27C27 0.343146 25.6569 -1 24 -1V1ZM2 -1C0.343146 -1 -1 0.343145 -1 2H1C1 1.44772 1.44772 1 2 1V-1Z"
        fill="#C8CBCC"
      />
    </svg>
  );

  const perPage = 8;
  const [page, setPage] = useState([]);

  useEffect(() => {
    setPage(data.slice(0, perPage));
  }, [data]);

  const handlePageClick = (evt) => {
    const selectedPage = evt.selected;
    const offset = selectedPage * perPage;

    //setFilteredOrders(orders.slice(offset, offset + perPage))
    setPage(filteredData.slice(offset, offset + perPage));
  };

  const filteredData = useMemo(() => {
    let dataGlobal = data;

    //Ordena la información que se mostrará en la tabla según la fecha y hora de creación
    //Los que se crearon primero aparecen primero
    dataGlobal = dataGlobal
      .sort((a, b) => {
        return (
          new Date(a.post_date).getTime() - new Date(b.post_date).getTime()
        );
      })
      .reverse();

    console.log("dataGlobal", dataGlobal);

    //Si esta vacio el search se retorna la dara original
    if (!activeEnter && optionsInventary.length === 0) {
      setPage(dataGlobal.slice(0, perPage));
      return dataGlobal;
    }

    //Se filtra por Existencia o Bajo por inventario
    if (optionsInventary.length > 0) {
      dataGlobal = dataGlobal.filter(({ meta }) => {
        const { _stock_status } = meta;
        return _stock_status === optionsInventary ? true : false;
      });
    }

    dataGlobal = dataGlobal.filter(({ post_title }) =>
      post_title.toLowerCase().includes(activeEnter)
    );
    setPage(dataGlobal.slice(0, perPage));

    //Se filtra por lo que tenga el search
    return dataGlobal;
  }, [activeEnter, data, optionsInventary]);

  const handleOptionsInventary = (options) => {
    setShowLoading(true);
    setTimeout(() => {
      setOptionsInventary(options);

      setShowLoading(false);
    }, 3000);
  };

  let propsData = {
    vendorId,
    page,
    history,
    handleEditProductInventary,
    handleRecycleProduct,
    toggleOutstanding,
    handleDeleteProductInventary,
    handleListProduct 
  };

  return (
    <div style={{ display: `${isVisible ? "block" : "none"}` }}>
      <div className="inventoryFilter">
        <div className="inventoryLeft">
          <Search setActiveEnter={setActiveEnter} id={id} />
          <div className="select">
            <h2> Inventario </h2>
            <Select handleOptionsInventary={handleOptionsInventary} />
          </div>
        </div>
        {data.length > 0 && (
          <Button
            classname="primary-lg positionAddProd"
            onClick={() => {
              history.push("/home/new-product");
            }}
          >
            <FiPlus className="inventoryBtnIcon" />
            Agregar
          </Button>
        )}
      </div>
      {!isMobile ? (
        <DesingTableOrDesktop propsData={propsData} />
      ) : (
        <DesingMobile propsData={propsData} />
      )}

      {page.length > 0 && (
        <div style={{ marginTop: isMobile ? "20px" : "5px" }}>
          <ReactPaginate
            onPageChange={handlePageClick}
            previousLabel={prevIcon}
            nextLabel={nextIcon}
            breakLabel={"..."}
            pageCount={Math.ceil(filteredData.length / perPage)}
            pageRangeDisplayed={isMobile ? 2 : 5}
            marginPagesDisplayed={isMobile ? 1 : 2}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      )}
    </div>
  );
};

const DesingTableOrDesktop = ({ propsData }) => {
  let {
    vendorId,
    page,
    history,
    handleEditProductInventary,
    handleRecycleProduct,
    handleDeleteProductInventary,
    toggleOutstanding,
    handleListProduct
  } = propsData;
  const isTabletOrDesktop = useMediaQuery({ minWidth: 768, maxWidth: 1024 });

  return (
    <div className="inventoryTable">
      <table className="salesTable" style={{ width: "100%" }}>
        <thead style={{ textAlign: "left" }}>
          <tr>
            <th
              className="tableHeaderItemInv"
              colSpan={`${isTabletOrDesktop ? "2" : "0"}`}
            >
              {" "}
              Imagen{" "}
            </th>
            <th className="tableHeaderItemInv" colSpan={"3"}>
              Producto
            </th>
            <th
              className="tableHeaderItemInv"
              colSpan={`${isTabletOrDesktop ? "2" : "0"}`}
            >
              {" "}
              Sku{" "}
            </th>
            <th
              className="tableHeaderItemInv"
              colSpan={`${isTabletOrDesktop ? "3" : "0"}`}
            >
              {" "}
              Inventario{" "}
            </th>
            <th
              className="tableHeaderItemInv"
              colSpan={`${isTabletOrDesktop ? "2" : "0"}`}
            >
              {" "}
              Precio(₡){" "}
            </th>
            <th className="tableHeaderItemInv" colSpan={"3"}>
              Categoría
            </th>
            <th className="tableHeaderItemInv">
              <AiFillStar style={{ width: "20px", height: "20px" }} />
            </th>
            <th colSpan="2"> </th>
          </tr>
        </thead>
        <tbody style={{ height: page.length === 0 ? "10px" : null }}>
          {page.length > 0 && (
            <>
              {page.map((product, idx) => {
                const { id, post_title, meta, imagen, category, post_status } =
                  product;
                const { _price, _regular_price, _sku, _stock, _stock_status } =
                  meta;

                const outstanding = category.filter(({ type, id }) => {
                  return type === "product_visibility" && id === 8;
                }).length;

                return (
                  <tr key={`${id}${idx}`}>
                    <td
                      className="tableBodyItemInv"
                      colSpan={`${isTabletOrDesktop ? "2" : "0"}`}
                    >
                      <div className="imagen">
                        <img src={imagen} alt="" />
                      </div>
                    </td>
                    <td className="tableBodyItemInv" colSpan={"3"}>
                      <>
                        <p>
                          {post_status === "draft" && (
                            <strong> (Borrador) </strong>
                          )}
                          {post_status === "trash" && (
                            <strong> (En Papelera) </strong>
                          )}
                          <span># {id}</span>
                          <span>{handleTexSuppress(post_title)}</span>
                        </p>
                        <div className="comentProduct">
                          <FaRegComment className="comentProductIcon" />
                          <span> 1 </span>
                        </div>
                      </>
                    </td>
                    <td
                      className="tableBodyItemInv"
                      colSpan={`${isTabletOrDesktop ? "2" : "0"}`}
                    >
                      {_sku !== null ? _sku : "-"}
                    </td>
                    <td
                      className="tableBodyItemInv"
                      colSpan={`${isTabletOrDesktop ? "3" : "0"}`}
                    >
                      <CircleStatus status={_stock_status} stock={_stock} />
                    </td>
                    <td
                      className="tableBodyItemInv"
                      colSpan={`${isTabletOrDesktop ? "2" : "0"}`}
                    >
                      {_price
                        ? parseFloat(_price).toFixed(2)
                        : _regular_price
                        ? parseFloat(_regular_price).toFixed(2)
                        : 0}
                    </td>
                    <td className="tableBodyItemInv" colSpan={"3"}>
                      <div className="category">
                        {category.map((categ, idx) => {
                          const { id, name, type } = categ;

                          if (type !== "product_cat") return null;

                          return (
                            <div key={`${idx}${id}`} className="categoryItem">
                              <span>
                                {isTabletOrDesktop && name.length >= 13
                                  ? `${name.substring(0, 11)}...`
                                  : name}{" "}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </td>
                    <td className="tableBodyItemInv">
                      <Starts outstanding={outstanding}
                        onClick= {async () => {
                          await toggleOutstanding(outstanding, id)
                          handleListProduct(vendorId)
                        }}
                      />
                    </td>
                    <td className="tableBodyItemInv" colSpan="2">
                      <ActionsInventory
                        isTrash={post_status === "trash" ? true : false}
                        actions1={() => {
                          handleEditProductInventary(product);
                        }}
                        actions2={() => {
                          handleDeleteProductInventary(id);
                        }}
                        actions3={() => {
                          handleRecycleProduct(id);
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </>
          )}
        </tbody>
      </table>
      {page.length === 0 && (
        <div className="inventoryNotFound">
          <div className="inventoryNotFoundContainer">
            <p className="commentNotFoundTittle">No hay productos</p>
            <Button
              classname="primary-lg"
              onClick={() => {
                history.push("/home/new-product");
              }}
            >
              <FiPlus className="inventoryBtnIcon" />
              Agregar
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

const DesingMobile = ({ propsData }) => {
  let { page } = propsData;

  return (
    <div className="containerMobileInventory">
      {page.length > 0 &&
        page.map((product) => {
          return (
            <>
              <div className="line" key={product.id}></div>
              <CollapseHeader product={product} propsData={propsData} />
            </>
          );
        })}
    </div>
  );
};

const CollapseHeader = ({ product, propsData }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  let {
    vendorId,
    handleEditProductInventary,
    handleRecycleProduct,
    handleDeleteProductInventary,
    handleListProduct,
    toggleOutstanding
  } = propsData;
  const { id, post_title, meta, imagen, category, post_status } = product;
  const { _price, _regular_price, _sku, _stock, _stock_status } = meta;

  const outstanding = category.filter(({ type, id }) => {
    return type === "product_visibility" && id === 8;
  }).length;

  const [isActive, setIsActive] = useState(false);
  return (
    <>
      <div
        className="inventaryItemHeaderFather"
        onClick={() => setIsActive(!isActive)}
        key={1}
      >
        <div className={"product-img"}>
          <img src={imagen} alt="" />
        </div>

        <div className="product">
          <div className="product-info">
            <div className={`producto-name`}>
              <p>
                {post_status === "draft"
                  ? "(Borrador)"
                  : post_status === "trash"
                  ? "(En Papelera)"
                  : ""}{" "}
                #{id} {handleTexSuppress(post_title, true)}
              </p>
            </div>
            <div className={`image ${isActive ? "isRotate" : ""}`}>
              <img src={arrowBottom} alt="arrow" />
            </div>
          </div>

          <div className="product-info">
            <div className={`producto-status`}>
              <CircleStatus status={_stock_status} stock={_stock} />
            </div>
            <div className={`producto-favorite`}>
              <Starts outstanding={outstanding} 
                onClick = {async () => {
                  await toggleOutstanding(outstanding, id)
                  handleListProduct(vendorId)
                }}
              />
            </div>
          </div>
          <div className="product-info">
            <div className={`producto-price`}>
              <p>
                {_price
                  ? parseFloat(_price).toFixed(2)
                  : _regular_price
                  ? parseFloat(_regular_price).toFixed(2)
                  : 0}
              </p>
            </div>
            <div className="comentProduct">
              <FaRegComment className="comentProductIcon" />
              <span> 1 </span>
            </div>
          </div>
        </div>
      </div>
      {isActive && (
        <>
          <div className="inventaryItemHeader">
            <div className={`product-sku`}>
              <p>SKU</p>
            </div>
            <p>{_sku !== null ? _sku : "-"}</p>
          </div>
          <div className="inventaryItemHeader" style={{ alignItems: "center" }}>
            <div className={`product-sku`}>
              <p>Categorías</p>
            </div>
            <div className="category" style={{ width: "205px" }}>
              {category.map((categ, idx) => {
                const { id, name, type } = categ;

                if (type !== "product_cat") return null;

                return (
                  <div key={`${idx}${id}`} className="categoryItem">
                    <span>
                      {isMobile && name.length >= 10
                        ? `${name.substring(0, 10)}...`
                        : name}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div style={{ marginTop: "23px", marginBottom:"17px"}}>
            <ActionsInventory
              isTrash={post_status === "trash" ? true : false}
              actions1={() => {
                handleEditProductInventary(product);
              }}
              actions2={() => {
                handleDeleteProductInventary(id);
              }}
              actions3={() => {
                handleRecycleProduct(id);
              }}
            />
          </div>
        </>
      )}
    </>
  );
};

const CircleStatus = ({ status, _stock }) => (
  <>
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="4"
        cy="4"
        r="4"
        fill={handleColorInventary(status, _stock).color}
      />
    </svg>
    <span style={{ marginLeft: "8px" }}>
      {handleColorInventary(status, _stock).name}
    </span>
  </>
);

const Starts = ({ outstanding, onClick=()=>{} }) => {
  let styles = {
    width: "20px",
    height: "20px",
  };
  return (
    <>
      {outstanding > 0 && (
        <AiFillStar
          style={{
            ...styles,
            color: "#FF6D00",
          }}
          onClick = {() => {
            onClick()
          }}
        />
      )}
      {outstanding === 0 && (
        <AiOutlineStar
          style={{
            ...styles,
          }}
          onClick = {() => {
            onClick()
          }}
        />
      )}
    </>
  );
};

const EditChildren = ({ actions1, children }) => (
  <Button classname="primary minWidth" onClick={actions1}>
    {children}
  </Button>
);

const DeleteChildren = ({ actions2, children }) => (
  <Button classname="secondary" onClick={actions2}>
    <RiDeleteBin5Line
      style={{
        width: "20px",
        height: "20px",
        marginRight: "4px",
      }}
    />
    {children}
  </Button>
);

const ActionsInventory = ({ isTrash, actions1, actions2, actions3 }) => (
  <div className="tableActions">
    <EditChildren actions1={actions1}>
      {!isTrash ? (
        <span>Editar</span>
      ) : (
        <span style={{ fontSize: "12px" }}>Restaurar</span>
      )}
    </EditChildren>
    <DeleteChildren actions2={isTrash ? actions2 : actions3}>
      <div className="paperbin">
        {!isTrash ? (
          <>
            <span>Mover</span>
            <span>a Papelera</span>
          </>
        ) : (
          <span>Eliminar</span>
        )}
      </div>
    </DeleteChildren>
  </div>
);

const HeaderOptions = ({ headerOpt, handleOptionsSelected }) => {
  const [selectOption, setSelectOption] = useState("1");

  return (
    <div className="headerOptions">
      <div className="scrollOptions">
        <ul>
          {headerOpt.map((opt) => (
            <li
              key={opt.id}
              className={selectOption === opt.id ? "isActive" : null}
              onClick={() => {
                setSelectOption(opt.id);
                handleOptionsSelected(opt.id);
              }}
            >
              <p> {opt.name} </p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const Search = ({ setActiveEnter, id }) => {
  const [searchText, setSearchText] = useState("");
  return (
    <div className="search">
      <FiSearch className="searchIcon" style={{ cursor: "pointer" }} />
      <input
        className={`searchText`}
        type="text"
        name={`searchText`}
        id={`searchText`}
        placeholder="Buscar"
        onChange={(evt) => {
          //console.log(evt.target.value)
          setSearchText(evt.target.value);
          if (evt.target.value.length === 0) {
            setActiveEnter(" ");
          }
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            setActiveEnter(searchText);
          }
        }}
        value={searchText}
      />
    </div>
  );
};

const Select = ({ handleOptionsInventary }) => {
  return (
    <select
      onChange={(e) => {
        handleOptionsInventary(e.target.value);
      }}
    >
      <option value=""> Todas </option>
      <option value="instock"> En existencia </option>
      <option value="outofstock"> Bajo Inventario </option>
      <option value="onbackorder"> Se puede reservar</option>
    </select>
  );
};

const Button = ({ children, classname, onClick }) => (
  <button className={`inventoryBtn ${classname} `} onClick={onClick}>
    {children}
  </button>
);

export default Inventory;
