import React, { useState, useRef } from "react";
import "./changePassword.css";
import arrowDown from "../../../assets/arrowDown.svg";
import arrowRight from "../../../assets/arrowRight.svg";
import Accordion from "../../../components/accordion/accordion"
import Swal from "sweetalert2";
import { EfikaOfflineFirst } from "../../../efika/efikaOfflineFirst"
import { EfikaEncryptation } from "../../../efika/encryptation"

const ChangePassword = ({setShowLoading, user, history}) => {
  const [lastPassword, setLastPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const eofUserPassword = useRef(new EfikaOfflineFirst("USER_PASSWORD"))
  var encryp = new EfikaEncryptation()

  const changePasswordHandler = async () => {
    setShowLoading(true)
    try {
      const response = await eofUserPassword.current.putAsPromise({
            userId: user.userId, 
            lastPassword: encryp.whirpoolEncrypt(lastPassword), 
            newPassword: encryp.whirpoolEncrypt(newPassword)
        })
      setShowLoading(false)
      if (response.status === 200) {
        await Swal.fire({
          title: "Contraseña cambiada",
          text: "La nueva contraseña se ha establecido correctamente",
          icon: "success",
        })
        history.go(0)
      } else {
        Swal.fire({
          title: "Error",
          text: "Ha ocurrido un error, por favor verifique los datos ingresados",
          icon: "error",
        })
      }
    } catch (error) {
      setShowLoading(false)
      console.log(error)
      Swal.fire({
        title: "Error",
        text: "Ha ocurrido un error desconocido",
        icon: "error",
      })
    }
  }

  return (
    <Accordion 
        title={<span className="changePassword-title">Cambiar Contraseña</span>} 
        hideIcon={<img className="changePassword-icon" src={arrowRight}/>} 
        showIcon={<img className="changePassword-icon" src={arrowDown}/>} 
    >
        <div className="changePassword-general-container">
            <div className="changePassword-input-container">
                <label className="changePassword-input-label">Contraseña actual</label>
                <input
                    className="changePassword-input"
                    type="password"
                    id="lastPassword"
                    name="lastPassword"
                    value={lastPassword}
                    onChange={(event) => setLastPassword(event.target.value)}
                />
            </div>
            <div className="changePassword-input-container">
                <label className="changePassword-input-label">Nueva contraseña</label>
                <input
                    className="changePassword-input"
                    type="password"
                    id="newPassword"
                    name="newPassword"
                    value={newPassword}
                    onChange={(event) => setNewPassword(event.target.value)}
                />
            </div>
            <div className="changePassword-button-container">
                <button className="changePassword-button" onClick={changePasswordHandler}>
                    Cambiar contraseña
                </button>
            </div>
        </div>
    </Accordion>
  )
}

export default ChangePassword;