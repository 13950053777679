import { JSEncrypt } from "jsencrypt"
import aesjs from "aes-js"
import wp from "whirlpool-js"

export function EfikaEncryptation (publicKey) {
  const rsa_ = new JSEncrypt()
  const _publicKey = publicKey

  this.whirpoolEncrypt = (str) => {
    return wp.encSync(str).toUpperCase() // HexWhirlpool(str)
  }

  this.encrypt = function (data) {
    rsa_.setPublicKey(_publicKey)
    var pair = generateAESPairs()
    let textBytes = aesjs.utils.utf8.toBytes(JSON.stringify(data))
    let aesCtr = new aesjs.ModeOfOperation.ctr(pair.k, new aesjs.Counter(pair.s))
    let encryptedBytes = aesCtr.encrypt(textBytes)
    let encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes)
    var returnObj = {
      ld: encryptedHex
    }
    returnObj.lk = rsa_.encrypt(JSON.stringify(pair))

    return returnObj
  }

  function generateAESPairs () {
    var key = []
    var iv
    var k = 0
    for (k = 0; k < 16; k++) {
      key.push(Math.floor(Math.random() * 255))
    }
    iv = Math.floor(Math.random() * 255)

    return { k: key, s: iv }
  }
}
