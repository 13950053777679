const locations = {
  1: {
    codigo: 1,
    provincia: "San Jose",
    cantones: {
      1: {
        codigo: 1,
        canton: "San Jose",
        distritos: {
          1: {
            codigo: 1,
            distrito: "Carmen",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Amon",
              },
              2: {
                codigo: 2,
                barrio: "Aranjuez",
              },
              3: {
                codigo: 3,
                barrio: "California (parte)",
              },
              4: {
                codigo: 4,
                barrio: "Carmen",
              },
              5: {
                codigo: 5,
                barrio: "Empalme",
              },
              6: {
                codigo: 6,
                barrio: "Escalante",
              },
              7: {
                codigo: 7,
                barrio: "Otoya",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "Merced",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bajos de la Union",
              },
              2: {
                codigo: 2,
                barrio: "Claret",
              },
              3: {
                codigo: 3,
                barrio: "Cocacola",
              },
              4: {
                codigo: 4,
                barrio: "Iglesias Flores",
              },
              5: {
                codigo: 5,
                barrio: "Mantica",
              },
              6: {
                codigo: 6,
                barrio: "Mexico",
              },
              7: {
                codigo: 7,
                barrio: "Paso de la Vaca",
              },
              8: {
                codigo: 8,
                barrio: "Pitahaya",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "Hospital",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Almendares",
              },
              2: {
                codigo: 2,
                barrio: "Angeles",
              },
              3: {
                codigo: 3,
                barrio: "Bolivar",
              },
              4: {
                codigo: 4,
                barrio: "Carit",
              },
              5: {
                codigo: 5,
                barrio: "Colon (parte)",
              },
              6: {
                codigo: 6,
                barrio: "Corazon de Jesus",
              },
              7: {
                codigo: 7,
                barrio: "Cristo Rey",
              },
              8: {
                codigo: 8,
                barrio: "Cuba",
              },
              9: {
                codigo: 9,
                barrio: "Dolorosa (parte)",
              },
              10: {
                codigo: 10,
                barrio: "Merced",
              },
              11: {
                codigo: 11,
                barrio: "Pacifico (parte)",
              },
              12: {
                codigo: 12,
                barrio: "Pinos",
              },
              13: {
                codigo: 13,
                barrio: "Salubridad",
              },
              14: {
                codigo: 14,
                barrio: "San Bosco",
              },
              15: {
                codigo: 15,
                barrio: "San Francisco",
              },
              16: {
                codigo: 16,
                barrio: "Santa Lucia",
              },
              17: {
                codigo: 17,
                barrio: "Silos",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "Catedral",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bellavista",
              },
              2: {
                codigo: 2,
                barrio: "California (parte)",
              },
              3: {
                codigo: 3,
                barrio: "Carlos Maria Jimenez",
              },
              4: {
                codigo: 4,
                barrio: "Dolorosa (parte)",
              },
              5: {
                codigo: 5,
                barrio: "Dos Pinos",
              },
              6: {
                codigo: 6,
                barrio: "Francisco Peralta (parte)",
              },
              7: {
                codigo: 7,
                barrio: "Gonzalez Lahmann",
              },
              8: {
                codigo: 8,
                barrio: "Gonzalez Viquez",
              },
              9: {
                codigo: 9,
                barrio: "Guell",
              },
              10: {
                codigo: 10,
                barrio: "La Cruz",
              },
              11: {
                codigo: 11,
                barrio: "Lomas de Ocloro",
              },
              12: {
                codigo: 12,
                barrio: "Lujan",
              },
              13: {
                codigo: 13,
                barrio: "Milflor",
              },
              14: {
                codigo: 14,
                barrio: "Naciones Unidas",
              },
              15: {
                codigo: 15,
                barrio: "Pacifico (parte)",
              },
              16: {
                codigo: 16,
                barrio: "San Cayetano (parte)",
              },
              17: {
                codigo: 17,
                barrio: "Soledad",
              },
              18: {
                codigo: 18,
                barrio: "Tabacalera",
              },
              19: {
                codigo: 19,
                barrio: "Vasconia",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "Zapote",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alborada",
              },
              2: {
                codigo: 2,
                barrio: "Calderon Munoz",
              },
              3: {
                codigo: 3,
                barrio: "Cerrito",
              },
              4: {
                codigo: 4,
                barrio: "Cordoba",
              },
              5: {
                codigo: 5,
                barrio: "Gloria",
              },
              6: {
                codigo: 6,
                barrio: "Jardin",
              },
              7: {
                codigo: 7,
                barrio: "Luisas",
              },
              8: {
                codigo: 8,
                barrio: "Mangos",
              },
              9: {
                codigo: 9,
                barrio: "Montealegre",
              },
              10: {
                codigo: 10,
                barrio: "Moreno Canas",
              },
              11: {
                codigo: 11,
                barrio: "Quesada Duran",
              },
              12: {
                codigo: 12,
                barrio: "San Dimas",
              },
              13: {
                codigo: 13,
                barrio: "San Gerardo (parte)",
              },
              14: {
                codigo: 14,
                barrio: "Trebol",
              },
              15: {
                codigo: 15,
                barrio: "Ujarras",
              },
              16: {
                codigo: 16,
                barrio: "Vista Hermosa",
              },
              17: {
                codigo: 17,
                barrio: "Yoses Sur",
              },
              18: {
                codigo: 18,
                barrio: "Zapote (centro)",
              },
            },
          },
          6: {
            codigo: 6,
            distrito: "San Francisco de Dos Rios",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Ahogados (parte)",
              },
              2: {
                codigo: 2,
                barrio: "Bosque",
              },
              3: {
                codigo: 3,
                barrio: "Cabanas",
              },
              4: {
                codigo: 4,
                barrio: "Camelias",
              },
              5: {
                codigo: 5,
                barrio: "Coopeguaria",
              },
              6: {
                codigo: 6,
                barrio: "Faro",
              },
              7: {
                codigo: 7,
                barrio: "Fatima",
              },
              8: {
                codigo: 8,
                barrio: "Hispano",
              },
              9: {
                codigo: 9,
                barrio: "I Griega",
              },
              10: {
                codigo: 10,
                barrio: "Lincoln",
              },
              11: {
                codigo: 11,
                barrio: "Lomas de San Francisco",
              },
              12: {
                codigo: 12,
                barrio: "Maalot",
              },
              13: {
                codigo: 13,
                barrio: "Mendez",
              },
              14: {
                codigo: 14,
                barrio: "Pacifica",
              },
              15: {
                codigo: 15,
                barrio: "San Francisco de Dos Rios (centro)",
              },
              16: {
                codigo: 16,
                barrio: "Sauces",
              },
              17: {
                codigo: 17,
                barrio: "Saucitos",
              },
              18: {
                codigo: 18,
                barrio: "Zurqui",
              },
            },
          },
          7: {
            codigo: 7,
            distrito: "Uruca",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alborada",
              },
              2: {
                codigo: 2,
                barrio: "Animas",
              },
              3: {
                codigo: 3,
                barrio: "Arboles",
              },
              4: {
                codigo: 4,
                barrio: "Bajos del Torres",
              },
              5: {
                codigo: 5,
                barrio: "Carranza",
              },
              6: {
                codigo: 6,
                barrio: "Corazon de Jesus",
              },
              7: {
                codigo: 7,
                barrio: "Cristal",
              },
              8: {
                codigo: 8,
                barrio: "Carvajal Castro",
              },
              9: {
                codigo: 9,
                barrio: "Jardines de Autopista",
              },
              10: {
                codigo: 10,
                barrio: "La Caja",
              },
              11: {
                codigo: 11,
                barrio: "La Carpio",
              },
              12: {
                codigo: 12,
                barrio: "Magnolia",
              },
              13: {
                codigo: 13,
                barrio: "Marimil",
              },
              14: {
                codigo: 14,
                barrio: "Monserrat",
              },
              15: {
                codigo: 15,
                barrio: "Peregrina",
              },
              16: {
                codigo: 16,
                barrio: "Robledal",
              },
              17: {
                codigo: 17,
                barrio: "Rossiter Carballo",
              },
              18: {
                codigo: 18,
                barrio: "Santander",
              },
              19: {
                codigo: 19,
                barrio: "Saturno",
              },
              20: {
                codigo: 20,
                barrio: "Uruca (centro)",
              },
              21: {
                codigo: 21,
                barrio: "Vuelta del Virilla",
              },
            },
          },
          8: {
            codigo: 8,
            distrito: "Mata Redonda",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Americas",
              },
              2: {
                codigo: 2,
                barrio: "Bajo Canada (parte)",
              },
              3: {
                codigo: 3,
                barrio: "Balcon Verde",
              },
              4: {
                codigo: 4,
                barrio: "Colon (parte)",
              },
              5: {
                codigo: 5,
                barrio: "Del Pino",
              },
              6: {
                codigo: 6,
                barrio: "Holanda",
              },
              7: {
                codigo: 7,
                barrio: "La Luisa",
              },
              8: {
                codigo: 8,
                barrio: "La Salle",
              },
              9: {
                codigo: 9,
                barrio: "Lomalinda",
              },
              10: {
                codigo: 10,
                barrio: "Morenos",
              },
              11: {
                codigo: 11,
                barrio: "Niza",
              },
              12: {
                codigo: 12,
                barrio: "Rancho Luna",
              },
              13: {
                codigo: 13,
                barrio: "Rohrmoser (parte)",
              },
              14: {
                codigo: 14,
                barrio: "Roma",
              },
              15: {
                codigo: 15,
                barrio: "Sabana",
              },
              16: {
                codigo: 16,
                barrio: "Tovar",
              },
            },
          },
          9: {
            codigo: 9,
            distrito: "Pavas",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alfa",
              },
              2: {
                codigo: 2,
                barrio: "Asturias",
              },
              3: {
                codigo: 3,
                barrio: "Asuncion",
              },
              4: {
                codigo: 4,
                barrio: "Bribri",
              },
              5: {
                codigo: 5,
                barrio: "Favorita Norte",
              },
              6: {
                codigo: 6,
                barrio: "Favorita Sur",
              },
              7: {
                codigo: 7,
                barrio: "Galicia",
              },
              8: {
                codigo: 8,
                barrio: "Geroma",
              },
              9: {
                codigo: 9,
                barrio: "Hispania",
              },
              10: {
                codigo: 10,
                barrio: "Libertad",
              },
              11: {
                codigo: 11,
                barrio: "Lomas del Rio",
              },
              12: {
                codigo: 12,
                barrio: "Llanos del Sol",
              },
              13: {
                codigo: 13,
                barrio: "Maria Reina",
              },
              14: {
                codigo: 14,
                barrio: "Metropolis",
              },
              15: {
                codigo: 15,
                barrio: "Navarra",
              },
              16: {
                codigo: 16,
                barrio: "Pavas (centro)",
              },
              17: {
                codigo: 17,
                barrio: "Pueblo Nuevo",
              },
              18: {
                codigo: 18,
                barrio: "Residencial del Oeste",
              },
              19: {
                codigo: 19,
                barrio: "Rincon Grande",
              },
              20: {
                codigo: 20,
                barrio: "Rohrmoser (parte)",
              },
              21: {
                codigo: 21,
                barrio: "Rotonda",
              },
              22: {
                codigo: 22,
                barrio: "San Pedro",
              },
              23: {
                codigo: 23,
                barrio: "Santa Barbara",
              },
              24: {
                codigo: 24,
                barrio: "Santa Catalina",
              },
              25: {
                codigo: 25,
                barrio: "Santa Fe",
              },
              26: {
                codigo: 26,
                barrio: "Triangulo",
              },
              27: {
                codigo: 27,
                barrio: "Villa Esperanza",
              },
            },
          },
          10: {
            codigo: 10,
            distrito: "Hatillo",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bajo Canada (parte)",
              },
              2: {
                codigo: 2,
                barrio: "Belgrano",
              },
              3: {
                codigo: 3,
                barrio: "Hatillo Centro",
              },
              4: {
                codigo: 4,
                barrio: "Hatillo 1",
              },
              5: {
                codigo: 5,
                barrio: "Hatillo 2",
              },
              6: {
                codigo: 6,
                barrio: "Hatillo 3",
              },
              7: {
                codigo: 7,
                barrio: "Hatillo 4",
              },
              8: {
                codigo: 8,
                barrio: "Hatillo 5",
              },
              9: {
                codigo: 9,
                barrio: "Hatillo 6",
              },
              10: {
                codigo: 10,
                barrio: "Hatillo 7",
              },
              11: {
                codigo: 11,
                barrio: "Hatillo 8",
              },
              12: {
                codigo: 12,
                barrio: "Quince de Setiembre",
              },
              13: {
                codigo: 13,
                barrio: "Sagrada Familia",
              },
              14: {
                codigo: 14,
                barrio: "Tiribi",
              },
              15: {
                codigo: 15,
                barrio: "Topacio",
              },
              16: {
                codigo: 16,
                barrio: "Veinticinco de Julio",
              },
              17: {
                codigo: 17,
                barrio: "Vivienda en Marcha",
              },
            },
          },
          11: {
            codigo: 11,
            distrito: "San Sebastian",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bengala",
              },
              2: {
                codigo: 2,
                barrio: "Bilbao",
              },
              3: {
                codigo: 3,
                barrio: "Canada del Sur",
              },
              4: {
                codigo: 4,
                barrio: "Carmen",
              },
              5: {
                codigo: 5,
                barrio: "Cascajal",
              },
              6: {
                codigo: 6,
                barrio: "Cerro Azul",
              },
              7: {
                codigo: 7,
                barrio: "Colombari",
              },
              8: {
                codigo: 8,
                barrio: "Domingo Savio",
              },
              9: {
                codigo: 9,
                barrio: "Guacamaya",
              },
              10: {
                codigo: 10,
                barrio: "Jazmin",
              },
              11: {
                codigo: 11,
                barrio: "Hogar Propio",
              },
              12: {
                codigo: 12,
                barrio: "Kennedy",
              },
              13: {
                codigo: 13,
                barrio: "Lopez Mateos",
              },
              14: {
                codigo: 14,
                barrio: "Luna Park",
              },
              15: {
                codigo: 15,
                barrio: "Martinez",
              },
              16: {
                codigo: 16,
                barrio: "Mojados",
              },
              17: {
                codigo: 17,
                barrio: "Mongito",
              },
              18: {
                codigo: 18,
                barrio: "Monte Azul",
              },
              19: {
                codigo: 19,
                barrio: "Musmanni",
              },
              20: {
                codigo: 20,
                barrio: "Paso Ancho",
              },
              21: {
                codigo: 21,
                barrio: "Presidentes",
              },
              22: {
                codigo: 22,
                barrio: "San Cayetano (parte)",
              },
              23: {
                codigo: 23,
                barrio: "San Martin",
              },
              24: {
                codigo: 24,
                barrio: "San Sebastian (centro)",
              },
              25: {
                codigo: 25,
                barrio: "Santa Rosa",
              },
              26: {
                codigo: 26,
                barrio: "Seminario",
              },
              27: {
                codigo: 27,
                barrio: "Sorobaru",
              },
            },
          },
        },
      },
      2: {
        codigo: 2,
        canton: "Escazu",
        distritos: {
          1: {
            codigo: 1,
            distrito: "Escazu",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto Carrizal",
              },
              2: {
                codigo: 2,
                barrio: "Carrizal (parte)",
              },
              3: {
                codigo: 3,
                barrio: "Faroles",
              },
              4: {
                codigo: 4,
                barrio: "Guapinol",
              },
              5: {
                codigo: 5,
                barrio: "Hulera",
              },
              6: {
                codigo: 6,
                barrio: "Itabas",
              },
              7: {
                codigo: 7,
                barrio: "Jaboncillo",
              },
              8: {
                codigo: 8,
                barrio: "Profesores (parte)",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "San Antonio",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Avellana",
              },
              2: {
                codigo: 2,
                barrio: "Bebedero",
              },
              3: {
                codigo: 3,
                barrio: "Belo Horizonte (parte)",
              },
              4: {
                codigo: 4,
                barrio: "Carrizal (parte)",
              },
              5: {
                codigo: 5,
                barrio: "Curio",
              },
              6: {
                codigo: 6,
                barrio: "Chirca",
              },
              7: {
                codigo: 7,
                barrio: "Chiverral",
              },
              8: {
                codigo: 8,
                barrio: "Entierrillo",
              },
              9: {
                codigo: 9,
                barrio: "Filtros",
              },
              10: {
                codigo: 10,
                barrio: "Guayabos",
              },
              11: {
                codigo: 11,
                barrio: "Hojablanca",
              },
              12: {
                codigo: 12,
                barrio: "Juan Santana",
              },
              13: {
                codigo: 13,
                barrio: "Lajas",
              },
              14: {
                codigo: 14,
                barrio: "Masilla",
              },
              15: {
                codigo: 15,
                barrio: "Muta",
              },
              16: {
                codigo: 16,
                barrio: "Pedrero",
              },
              17: {
                codigo: 17,
                barrio: "Peru",
              },
              18: {
                codigo: 18,
                barrio: "Profesores (parte)",
              },
              19: {
                codigo: 19,
                barrio: "Sabanillas",
              },
              20: {
                codigo: 20,
                barrio: "Salitrillos",
              },
              21: {
                codigo: 21,
                barrio: "Santa Eduvigis",
              },
              22: {
                codigo: 22,
                barrio: "Santa Teresa",
              },
              23: {
                codigo: 23,
                barrio: "Tejarcillos",
              },
              24: {
                codigo: 24,
                barrio: "Torrotillo",
              },
              25: {
                codigo: 25,
                barrio: "Vista de Oro",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "San Rafael",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Anonos",
              },
              2: {
                codigo: 2,
                barrio: "Ayala",
              },
              3: {
                codigo: 3,
                barrio: "Bajo Anonos",
              },
              4: {
                codigo: 4,
                barrio: "Bajo Palomas",
              },
              5: {
                codigo: 5,
                barrio: "Belo Horizonte (parte)",
              },
              6: {
                codigo: 6,
                barrio: "Betina",
              },
              7: {
                codigo: 7,
                barrio: "Ceiba",
              },
              8: {
                codigo: 8,
                barrio: "Facio Castro",
              },
              9: {
                codigo: 9,
                barrio: "Guachipelin",
              },
              10: {
                codigo: 10,
                barrio: "Herrera",
              },
              11: {
                codigo: 11,
                barrio: "Laureles",
              },
              12: {
                codigo: 12,
                barrio: "Leon",
              },
              13: {
                codigo: 13,
                barrio: "Loma Real",
              },
              14: {
                codigo: 14,
                barrio: "Matapalo",
              },
              15: {
                codigo: 15,
                barrio: "Maynard",
              },
              16: {
                codigo: 16,
                barrio: "Mirador",
              },
              17: {
                codigo: 17,
                barrio: "Miravalles",
              },
              18: {
                codigo: 18,
                barrio: "Palermo",
              },
              19: {
                codigo: 19,
                barrio: "Palma de Mallorca",
              },
              20: {
                codigo: 20,
                barrio: "Pinar",
              },
              21: {
                codigo: 21,
                barrio: "Primavera",
              },
              22: {
                codigo: 22,
                barrio: "Quesada",
              },
              23: {
                codigo: 23,
                barrio: "Real de Pereira (parte)",
              },
              24: {
                codigo: 24,
                barrio: "Santa Marta",
              },
              25: {
                codigo: 25,
                barrio: "Tena",
              },
              26: {
                codigo: 26,
                barrio: "Trejos Montealegre",
              },
              27: {
                codigo: 27,
                barrio: "Vista Alegre",
              },
            },
          },
        },
      },
      3: {
        codigo: 3,
        canton: "Desamparados",
        distritos: {
          1: {
            codigo: 1,
            distrito: "Desamparados",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Altamira",
              },
              2: {
                codigo: 2,
                barrio: "Bellavista",
              },
              3: {
                codigo: 3,
                barrio: "Calle Fallas",
              },
              4: {
                codigo: 4,
                barrio: "Camaquiri",
              },
              5: {
                codigo: 5,
                barrio: "Capilla",
              },
              6: {
                codigo: 6,
                barrio: "Centro de Amigos",
              },
              7: {
                codigo: 7,
                barrio: "Ceramica",
              },
              8: {
                codigo: 8,
                barrio: "Colonia del Sur",
              },
              9: {
                codigo: 9,
                barrio: "Contadores",
              },
              10: {
                codigo: 10,
                barrio: "Cruce",
              },
              11: {
                codigo: 11,
                barrio: "Cucubres",
              },
              12: {
                codigo: 12,
                barrio: "Dorados",
              },
              13: {
                codigo: 13,
                barrio: "Florita",
              },
              14: {
                codigo: 14,
                barrio: "Fortuna",
              },
              15: {
                codigo: 15,
                barrio: "Jardin",
              },
              16: {
                codigo: 16,
                barrio: "Loto",
              },
              17: {
                codigo: 17,
                barrio: "Metropoli",
              },
              18: {
                codigo: 18,
                barrio: "Monsenor Sanabria",
              },
              19: {
                codigo: 19,
                barrio: "Monteclaro",
              },
              20: {
                codigo: 20,
                barrio: "Palogrande",
              },
              21: {
                codigo: 21,
                barrio: "Pinos",
              },
              22: {
                codigo: 22,
                barrio: "Retonos",
              },
              23: {
                codigo: 23,
                barrio: "Rio Jorco",
              },
              24: {
                codigo: 24,
                barrio: "Sabara",
              },
              25: {
                codigo: 25,
                barrio: "San Esteban Rey",
              },
              26: {
                codigo: 26,
                barrio: "San Jeronimo",
              },
              27: {
                codigo: 27,
                barrio: "San Jose",
              },
              28: {
                codigo: 28,
                barrio: "San Roque",
              },
              29: {
                codigo: 29,
                barrio: "Tauros",
              },
              30: {
                codigo: 30,
                barrio: "Torremolinos",
              },
              31: {
                codigo: 31,
                barrio: "Venecia",
              },
              32: {
                codigo: 32,
                barrio: "Vista Verde",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "San Miguel",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Angeles",
              },
              2: {
                codigo: 2,
                barrio: "Capri",
              },
              3: {
                codigo: 3,
                barrio: "Damas Israelitas",
              },
              4: {
                codigo: 4,
                barrio: "Girasol",
              },
              5: {
                codigo: 5,
                barrio: "Higuito",
              },
              6: {
                codigo: 6,
                barrio: "Lindavista",
              },
              7: {
                codigo: 7,
                barrio: "Lomas de Jorco",
              },
              8: {
                codigo: 8,
                barrio: "Llano",
              },
              9: {
                codigo: 9,
                barrio: "Meseguer",
              },
              10: {
                codigo: 10,
                barrio: "Olivos",
              },
              11: {
                codigo: 11,
                barrio: "Orquideas",
              },
              12: {
                codigo: 12,
                barrio: "Penascal",
              },
              13: {
                codigo: 13,
                barrio: "Rinconada",
              },
              14: {
                codigo: 14,
                barrio: "Rodillal",
              },
              15: {
                codigo: 15,
                barrio: "Sabanilla",
              },
              16: {
                codigo: 16,
                barrio: "San Martin",
              },
              17: {
                codigo: 17,
                barrio: "Santa Eduvigis",
              },
              18: {
                codigo: 18,
                barrio: "Valverde",
              },
              19: {
                codigo: 19,
                barrio: "Alto Alumbre",
              },
              20: {
                codigo: 20,
                barrio: "Hoyo",
              },
              21: {
                codigo: 21,
                barrio: "Jerico",
              },
              22: {
                codigo: 22,
                barrio: "Manzano",
              },
              23: {
                codigo: 23,
                barrio: "Pacaya",
              },
              24: {
                codigo: 24,
                barrio: "Roblar",
              },
              25: {
                codigo: 25,
                barrio: "Ticalpes (parte)",
              },
              26: {
                codigo: 26,
                barrio: "Calle Naranjos",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "San Juan de Dios",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Calabacitas",
              },
              2: {
                codigo: 2,
                barrio: "Calle Comun",
              },
              3: {
                codigo: 3,
                barrio: "Cruz Roja",
              },
              4: {
                codigo: 4,
                barrio: "Itaipu",
              },
              5: {
                codigo: 5,
                barrio: "Maquinas",
              },
              6: {
                codigo: 6,
                barrio: "Mota",
              },
              7: {
                codigo: 7,
                barrio: "Novedades",
              },
              8: {
                codigo: 8,
                barrio: "Pedrito Monge",
              },
              9: {
                codigo: 9,
                barrio: "Rio",
              },
              10: {
                codigo: 10,
                barrio: "Robles",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "San Rafael Arriba",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alpino",
              },
              2: {
                codigo: 2,
                barrio: "Arco Iris",
              },
              3: {
                codigo: 3,
                barrio: "Bambu",
              },
              4: {
                codigo: 4,
                barrio: "Berlay",
              },
              5: {
                codigo: 5,
                barrio: "Guaria",
              },
              6: {
                codigo: 6,
                barrio: "Huaso (parte)",
              },
              7: {
                codigo: 7,
                barrio: "Juncales",
              },
              8: {
                codigo: 8,
                barrio: "Lajas",
              },
              9: {
                codigo: 9,
                barrio: "Macarena",
              },
              10: {
                codigo: 10,
                barrio: "Maiquetia",
              },
              11: {
                codigo: 11,
                barrio: "Mendez",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "San Antonio",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Acacias",
              },
              2: {
                codigo: 2,
                barrio: "Calle Amador",
              },
              3: {
                codigo: 3,
                barrio: "Constancia",
              },
              4: {
                codigo: 4,
                barrio: "Churuca",
              },
              5: {
                codigo: 5,
                barrio: "Huetares",
              },
              6: {
                codigo: 6,
                barrio: "Plazoleta",
              },
              7: {
                codigo: 7,
                barrio: "Pueblo Nuevo",
              },
              8: {
                codigo: 8,
                barrio: "Rio Damas",
              },
              9: {
                codigo: 9,
                barrio: "Rotondas",
              },
              10: {
                codigo: 10,
                barrio: "Solar",
              },
            },
          },
          6: {
            codigo: 6,
            distrito: "Frailes",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bajos de Tarrazu",
              },
              2: {
                codigo: 2,
                barrio: "Bustamante",
              },
              3: {
                codigo: 3,
                barrio: "Santa Elena (parte)",
              },
              4: {
                codigo: 4,
                barrio: "Violeta",
              },
            },
          },
          7: {
            codigo: 7,
            distrito: "Patarra",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Aguacate",
              },
              2: {
                codigo: 2,
                barrio: "Balneario",
              },
              3: {
                codigo: 3,
                barrio: "Don Bosco",
              },
              4: {
                codigo: 4,
                barrio: "Guatuso",
              },
              5: {
                codigo: 5,
                barrio: "Guizaro",
              },
              6: {
                codigo: 6,
                barrio: "Jerusalen",
              },
              7: {
                codigo: 7,
                barrio: "Lince",
              },
              8: {
                codigo: 8,
                barrio: "Mesas",
              },
              9: {
                codigo: 9,
                barrio: "Quebrada Honda",
              },
              10: {
                codigo: 10,
                barrio: "Ticalpes (parte)",
              },
            },
          },
          8: {
            codigo: 8,
            distrito: "San Cristobal",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Empalme (parte)",
              },
              2: {
                codigo: 2,
                barrio: "Lucha (parte)",
              },
              3: {
                codigo: 3,
                barrio: "San Cristobal Sur",
              },
              4: {
                codigo: 4,
                barrio: "Sierra",
              },
            },
          },
          9: {
            codigo: 9,
            distrito: "Rosario",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bajo Tigre",
              },
              2: {
                codigo: 2,
                barrio: "Chirogres",
              },
              3: {
                codigo: 3,
                barrio: "Guadarrama",
              },
              4: {
                codigo: 4,
                barrio: "Joya",
              },
              5: {
                codigo: 5,
                barrio: "La Fila (parte)",
              },
              6: {
                codigo: 6,
                barrio: "Llano bonito",
              },
              7: {
                codigo: 7,
                barrio: "Quebrada Honda",
              },
              8: {
                codigo: 8,
                barrio: "Trinidad (parte)",
              },
            },
          },
          10: {
            codigo: 10,
            distrito: "Damas",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Cajita",
              },
              2: {
                codigo: 2,
                barrio: "Dorado",
              },
              3: {
                codigo: 3,
                barrio: "Dos Cercas",
              },
              4: {
                codigo: 4,
                barrio: "Fomentera",
              },
              5: {
                codigo: 5,
                barrio: "Nuestra Senora de la Esperanza",
              },
              6: {
                codigo: 6,
                barrio: "San Lorenzo",
              },
            },
          },
          11: {
            codigo: 11,
            distrito: "San Rafael Abajo",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Angeles",
              },
              2: {
                codigo: 2,
                barrio: "Autofores",
              },
              3: {
                codigo: 3,
                barrio: "Balboa",
              },
              4: {
                codigo: 4,
                barrio: "Coopelot",
              },
              5: {
                codigo: 5,
                barrio: "Gardenia",
              },
              6: {
                codigo: 6,
                barrio: "Higuerones",
              },
              7: {
                codigo: 7,
                barrio: "Leo",
              },
              8: {
                codigo: 8,
                barrio: "Monaco",
              },
              9: {
                codigo: 9,
                barrio: "Sagitario",
              },
              10: {
                codigo: 10,
                barrio: "Santa Cecilia",
              },
              11: {
                codigo: 11,
                barrio: "Tejar (parte)",
              },
              12: {
                codigo: 12,
                barrio: "Treviso",
              },
              13: {
                codigo: 13,
                barrio: "Unidas",
              },
              14: {
                codigo: 14,
                barrio: "Valencia",
              },
              15: {
                codigo: 15,
                barrio: "Vizcaya",
              },
            },
          },
          12: {
            codigo: 12,
            distrito: "Gravilias",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Cartonera",
              },
              2: {
                codigo: 2,
                barrio: "Claveles",
              },
              3: {
                codigo: 3,
                barrio: "Damasco",
              },
              4: {
                codigo: 4,
                barrio: "Diamante",
              },
              5: {
                codigo: 5,
                barrio: "Esmeraldas",
              },
              6: {
                codigo: 6,
                barrio: "Fortuna",
              },
              7: {
                codigo: 7,
                barrio: "Fortunita",
              },
              8: {
                codigo: 8,
                barrio: "Porvenir",
              },
              9: {
                codigo: 9,
                barrio: "Raya",
              },
              10: {
                codigo: 10,
                barrio: "Riberalta",
              },
              11: {
                codigo: 11,
                barrio: "Villanueva",
              },
            },
          },
          13: {
            codigo: 13,
            distrito: "Los Guido",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Letras",
              },
              2: {
                codigo: 2,
                barrio: "Balcon Verde",
              },
            },
          },
        },
      },
      4: {
        codigo: 4,
        canton: "Puriscal",
        distritos: {
          1: {
            codigo: 1,
            distrito: "Santiago",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Angeles",
              },
              2: {
                codigo: 2,
                barrio: "Buenos Aires",
              },
              3: {
                codigo: 3,
                barrio: "Corazon de Maria",
              },
              4: {
                codigo: 4,
                barrio: "Jarasal",
              },
              5: {
                codigo: 5,
                barrio: "Junquillo Arriba",
              },
              6: {
                codigo: 6,
                barrio: "Pueblo Nuevo",
              },
              7: {
                codigo: 7,
                barrio: "San Isidro",
              },
              8: {
                codigo: 8,
                barrio: "Bajo Badilla",
              },
              9: {
                codigo: 9,
                barrio: "Bajo Moras",
              },
              10: {
                codigo: 10,
                barrio: "Canales Abajo",
              },
              11: {
                codigo: 11,
                barrio: "Canales Arriba",
              },
              12: {
                codigo: 12,
                barrio: "Carit",
              },
              13: {
                codigo: 13,
                barrio: "Charcon (parte)",
              },
              14: {
                codigo: 14,
                barrio: "Cirri",
              },
              15: {
                codigo: 15,
                barrio: "Junquillo Abajo",
              },
              16: {
                codigo: 16,
                barrio: "Pozos",
              },
              17: {
                codigo: 17,
                barrio: "San Francisco",
              },
              18: {
                codigo: 18,
                barrio: "San Martin",
              },
              19: {
                codigo: 19,
                barrio: "Zapote",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "Mercedes Sur",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto Palma",
              },
              2: {
                codigo: 2,
                barrio: "Bajo Lanas",
              },
              3: {
                codigo: 3,
                barrio: "Bajo Legua",
              },
              4: {
                codigo: 4,
                barrio: "Bajo Leguita",
              },
              5: {
                codigo: 5,
                barrio: "Bajo Quesada",
              },
              6: {
                codigo: 6,
                barrio: "Bocana",
              },
              7: {
                codigo: 7,
                barrio: "Carmona",
              },
              8: {
                codigo: 8,
                barrio: "Cerbatana",
              },
              9: {
                codigo: 9,
                barrio: "Charquillos",
              },
              10: {
                codigo: 10,
                barrio: "Jilgueral",
              },
              11: {
                codigo: 11,
                barrio: "Llano Grande",
              },
              12: {
                codigo: 12,
                barrio: "Mercedes Norte",
              },
              13: {
                codigo: 13,
                barrio: "Potenciana",
              },
              14: {
                codigo: 14,
                barrio: "Quebrada Honda",
              },
              15: {
                codigo: 15,
                barrio: "Quivel",
              },
              16: {
                codigo: 16,
                barrio: "Rancho Largo",
              },
              17: {
                codigo: 17,
                barrio: "Salitrales",
              },
              18: {
                codigo: 18,
                barrio: "Santa Marta",
              },
              19: {
                codigo: 19,
                barrio: "Tufares",
              },
              20: {
                codigo: 20,
                barrio: "Tulin",
              },
              21: {
                codigo: 21,
                barrio: "Vibora",
              },
              22: {
                codigo: 22,
                barrio: "Zapotal",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "Barbacoas",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto Barbacoas",
              },
              2: {
                codigo: 2,
                barrio: "Bajo Burgos",
              },
              3: {
                codigo: 3,
                barrio: "Cortezal",
              },
              4: {
                codigo: 4,
                barrio: "Guatuso",
              },
              5: {
                codigo: 5,
                barrio: "Piedades",
              },
              6: {
                codigo: 6,
                barrio: "San Juan",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "Grifo Alto",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Cacao",
              },
              2: {
                codigo: 2,
                barrio: "Cuesta Mora",
              },
              3: {
                codigo: 3,
                barrio: "Grifo Bajo",
              },
              4: {
                codigo: 4,
                barrio: "Poro",
              },
              5: {
                codigo: 5,
                barrio: "Pueblo Nuevo",
              },
              6: {
                codigo: 6,
                barrio: "Salitrillo",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "San Rafael",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bijagual",
              },
              2: {
                codigo: 2,
                barrio: "Floralia",
              },
              3: {
                codigo: 3,
                barrio: "Punta de Lanza",
              },
              4: {
                codigo: 4,
                barrio: "San Rafael Abajo",
              },
            },
          },
          6: {
            codigo: 6,
            distrito: "Candelarita",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto Cebadilla",
              },
              2: {
                codigo: 2,
                barrio: "Bajo Chacones",
              },
              3: {
                codigo: 3,
                barrio: "Copalar",
              },
              4: {
                codigo: 4,
                barrio: "Pedernal",
              },
              5: {
                codigo: 5,
                barrio: "Polca",
              },
              6: {
                codigo: 6,
                barrio: "Sabanas",
              },
            },
          },
          7: {
            codigo: 7,
            distrito: "Desamparaditos",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bajo Guevara",
              },
              2: {
                codigo: 2,
                barrio: "Planta",
              },
              3: {
                codigo: 3,
                barrio: "Rinconada",
              },
            },
          },
          8: {
            codigo: 8,
            distrito: "San Antonio",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bajo Herrera",
              },
              2: {
                codigo: 2,
                barrio: "Calle Herrera",
              },
              3: {
                codigo: 3,
                barrio: "Cruce Guanacaste",
              },
              4: {
                codigo: 4,
                barrio: "Charcon (parte)",
              },
              5: {
                codigo: 5,
                barrio: "Estero",
              },
              6: {
                codigo: 6,
                barrio: "Rio Viejo",
              },
              7: {
                codigo: 7,
                barrio: "Salitral",
              },
              8: {
                codigo: 8,
                barrio: "Tinamaste",
              },
            },
          },
          9: {
            codigo: 9,
            distrito: "Chires",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto Concepcion",
              },
              2: {
                codigo: 2,
                barrio: "Alto Perez Astua",
              },
              3: {
                codigo: 3,
                barrio: "Angeles",
              },
              4: {
                codigo: 4,
                barrio: "Angostura (parte)",
              },
              5: {
                codigo: 5,
                barrio: "Arenal",
              },
              6: {
                codigo: 6,
                barrio: "Bajo Chires",
              },
              7: {
                codigo: 7,
                barrio: "Bajo de Guarumal",
              },
              8: {
                codigo: 8,
                barrio: "Bajo el Rey",
              },
              9: {
                codigo: 9,
                barrio: "Bajo Vega",
              },
              10: {
                codigo: 10,
                barrio: "Cerdas",
              },
              11: {
                codigo: 11,
                barrio: "Fila Aguacate",
              },
              12: {
                codigo: 12,
                barrio: "Gamalotillo 1 (Colonia)",
              },
              13: {
                codigo: 13,
                barrio: "Gamalotillo 2 (Gamalotillo)",
              },
              14: {
                codigo: 14,
                barrio: "Gamalotillo 3 (Tierra Fertil)",
              },
              15: {
                codigo: 15,
                barrio: "Gloria",
              },
              16: {
                codigo: 16,
                barrio: "Guarumal",
              },
              17: {
                codigo: 17,
                barrio: "Guarumalito",
              },
              18: {
                codigo: 18,
                barrio: "Mastatal",
              },
              19: {
                codigo: 19,
                barrio: "Pericos",
              },
              20: {
                codigo: 20,
                barrio: "Rio Negro (parte)",
              },
              21: {
                codigo: 21,
                barrio: "San Miguel",
              },
              22: {
                codigo: 22,
                barrio: "San Vicente",
              },
              23: {
                codigo: 23,
                barrio: "Santa Rosa",
              },
              24: {
                codigo: 24,
                barrio: "Vista de Mar",
              },
              25: {
                codigo: 25,
                barrio: "Zapaton",
              },
            },
          },
        },
      },
      5: {
        codigo: 5,
        canton: "Tarrazu",
        distritos: {
          1: {
            codigo: 1,
            distrito: "San Marcos",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Corea",
              },
              2: {
                codigo: 2,
                barrio: "I Griega",
              },
              3: {
                codigo: 3,
                barrio: "Las Tres Marias",
              },
              4: {
                codigo: 4,
                barrio: "Santa Cecilia",
              },
              5: {
                codigo: 5,
                barrio: "Rodeo",
              },
              6: {
                codigo: 6,
                barrio: "Sitio",
              },
              7: {
                codigo: 7,
                barrio: "Alto Pastora",
              },
              8: {
                codigo: 8,
                barrio: "Bajo Canet",
              },
              9: {
                codigo: 9,
                barrio: "Bajo San Juan",
              },
              10: {
                codigo: 10,
                barrio: "Canet",
              },
              11: {
                codigo: 11,
                barrio: "Cedral (parte)",
              },
              12: {
                codigo: 12,
                barrio: "Guadalupe",
              },
              13: {
                codigo: 13,
                barrio: "Llano Piedra",
              },
              14: {
                codigo: 14,
                barrio: "San Cayetano",
              },
              15: {
                codigo: 15,
                barrio: "San Guillermo",
              },
              16: {
                codigo: 16,
                barrio: "Sabana (parte)",
              },
              17: {
                codigo: 17,
                barrio: "San Pedro",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "San Lorenzo",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto Guarumal",
              },
              2: {
                codigo: 2,
                barrio: "Alto Portal",
              },
              3: {
                codigo: 3,
                barrio: "Alto Zapotal",
              },
              4: {
                codigo: 4,
                barrio: "Ardilla",
              },
              5: {
                codigo: 5,
                barrio: "Bajo Quebrada Honda",
              },
              6: {
                codigo: 6,
                barrio: "Bajo Reyes",
              },
              7: {
                codigo: 7,
                barrio: "Bajo Zapotal",
              },
              8: {
                codigo: 8,
                barrio: "Cerro Nara",
              },
              9: {
                codigo: 9,
                barrio: "Concepcion",
              },
              10: {
                codigo: 10,
                barrio: "Chilamate",
              },
              11: {
                codigo: 11,
                barrio: "Delicias",
              },
              12: {
                codigo: 12,
                barrio: "Esperanza",
              },
              13: {
                codigo: 13,
                barrio: "Esquipulas",
              },
              14: {
                codigo: 14,
                barrio: "La Pacaya",
              },
              15: {
                codigo: 15,
                barrio: "Las Pavas",
              },
              16: {
                codigo: 16,
                barrio: "Mata de Cana",
              },
              17: {
                codigo: 17,
                barrio: "Miramar",
              },
              18: {
                codigo: 18,
                barrio: "Napoles",
              },
              19: {
                codigo: 19,
                barrio: "Naranjillo",
              },
              20: {
                codigo: 20,
                barrio: "Palma",
              },
              21: {
                codigo: 21,
                barrio: "Quebrada Arroyo",
              },
              22: {
                codigo: 22,
                barrio: "Rodeo",
              },
              23: {
                codigo: 23,
                barrio: "Sabana (parte)",
              },
              24: {
                codigo: 24,
                barrio: "Salado",
              },
              25: {
                codigo: 25,
                barrio: "San Bernardo",
              },
              26: {
                codigo: 26,
                barrio: "San Francisco",
              },
              27: {
                codigo: 27,
                barrio: "San Martin",
              },
              28: {
                codigo: 28,
                barrio: "Santa Cecilia",
              },
              29: {
                codigo: 29,
                barrio: "Santa Marta",
              },
              30: {
                codigo: 30,
                barrio: "Santa Rosa",
              },
              31: {
                codigo: 31,
                barrio: "Zapotal",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "San Carlos",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto Chiral",
              },
              2: {
                codigo: 2,
                barrio: "San Juan (Alto San Juan)",
              },
              3: {
                codigo: 3,
                barrio: "Bajo Jenaro",
              },
              4: {
                codigo: 4,
                barrio: "Bajo San Jose",
              },
              5: {
                codigo: 5,
                barrio: "Jamaica",
              },
              6: {
                codigo: 6,
                barrio: "Quebrada Seca (Santa Ana)",
              },
              7: {
                codigo: 7,
                barrio: "San Jeronimo",
              },
              8: {
                codigo: 8,
                barrio: "San Josecito",
              },
            },
          },
        },
      },
      6: {
        codigo: 6,
        canton: "Aserri",
        distritos: {
          1: {
            codigo: 1,
            distrito: "Aserri",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alfonso XIII",
              },
              2: {
                codigo: 2,
                barrio: "Barro",
              },
              3: {
                codigo: 3,
                barrio: "Cinco Esquinas (parte)",
              },
              4: {
                codigo: 4,
                barrio: "Corazon de Jesus",
              },
              5: {
                codigo: 5,
                barrio: "Guapinol",
              },
              6: {
                codigo: 6,
                barrio: "Las Mercedes",
              },
              7: {
                codigo: 7,
                barrio: "Lomas de Aserri",
              },
              8: {
                codigo: 8,
                barrio: "Lourdes",
              },
              9: {
                codigo: 9,
                barrio: "Maria Auxiliadora",
              },
              10: {
                codigo: 10,
                barrio: "Mesas",
              },
              11: {
                codigo: 11,
                barrio: "Poas",
              },
              12: {
                codigo: 12,
                barrio: "Santa Rita",
              },
              13: {
                codigo: 13,
                barrio: "Saurez",
              },
              14: {
                codigo: 14,
                barrio: "Tres Marias",
              },
              15: {
                codigo: 15,
                barrio: "Vereda",
              },
              16: {
                codigo: 16,
                barrio: "Guatuso",
              },
              17: {
                codigo: 17,
                barrio: "Mirador",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "Tarbaca",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bajos de Praga",
              },
              2: {
                codigo: 2,
                barrio: "Maquina Vieja",
              },
              3: {
                codigo: 3,
                barrio: "Tigre",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "Vuelta del Jorco",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Calvario",
              },
              2: {
                codigo: 2,
                barrio: "Ceiba Alta (parte)",
              },
              3: {
                codigo: 3,
                barrio: "Jocotal",
              },
              4: {
                codigo: 4,
                barrio: "Legua de Naranjo",
              },
              5: {
                codigo: 5,
                barrio: "Mangos",
              },
              6: {
                codigo: 6,
                barrio: "Meseta",
              },
              7: {
                codigo: 7,
                barrio: "Monte Redondo",
              },
              8: {
                codigo: 8,
                barrio: "Ojo de Agua",
              },
              9: {
                codigo: 9,
                barrio: "Rosalia",
              },
              10: {
                codigo: 10,
                barrio: "Uruca",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "San Gabriel",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Pueblo Nuevo",
              },
              2: {
                codigo: 2,
                barrio: "La Fila (parte)",
              },
              3: {
                codigo: 3,
                barrio: "Limonal",
              },
              4: {
                codigo: 4,
                barrio: "Los Solano",
              },
              5: {
                codigo: 5,
                barrio: "Rancho Grande",
              },
              6: {
                codigo: 6,
                barrio: "Salitral",
              },
              7: {
                codigo: 7,
                barrio: "Tranquerillas",
              },
              8: {
                codigo: 8,
                barrio: "Trinidad (parte)",
              },
              9: {
                codigo: 9,
                barrio: "Villanueva",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "Legua",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto Buenavista",
              },
              2: {
                codigo: 2,
                barrio: "Altos del Aguacate",
              },
              3: {
                codigo: 3,
                barrio: "Bajo Bijagual",
              },
              4: {
                codigo: 4,
                barrio: "Bajo Maquinas",
              },
              5: {
                codigo: 5,
                barrio: "Bajo Venegas (parte)",
              },
              6: {
                codigo: 6,
                barrio: "Carmen (parte)",
              },
            },
          },
          6: {
            codigo: 6,
            distrito: "Monterrey",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Ojo de Agua (parte)",
              },
              2: {
                codigo: 2,
                barrio: "Portuguez",
              },
            },
          },
          7: {
            codigo: 7,
            distrito: "Salitrillos",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Cinco Esquinas (parte)",
              },
              2: {
                codigo: 2,
                barrio: "Santa Teresita",
              },
              3: {
                codigo: 3,
                barrio: "Cerro",
              },
              4: {
                codigo: 4,
                barrio: "Cuesta Grande",
              },
              5: {
                codigo: 5,
                barrio: "Guinealillo",
              },
              6: {
                codigo: 6,
                barrio: "Huaso (parte)",
              },
              7: {
                codigo: 7,
                barrio: "Lagunillas",
              },
              8: {
                codigo: 8,
                barrio: "Palo Blanco",
              },
              9: {
                codigo: 9,
                barrio: "Quebradas",
              },
              10: {
                codigo: 10,
                barrio: "Rincon",
              },
            },
          },
        },
      },
      7: {
        codigo: 7,
        canton: "Mora",
        distritos: {
          1: {
            codigo: 1,
            distrito: "Mora",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alhambra",
              },
              2: {
                codigo: 2,
                barrio: "Brasil",
              },
              3: {
                codigo: 3,
                barrio: "Carreras",
              },
              4: {
                codigo: 4,
                barrio: "Colonia del Prado",
              },
              5: {
                codigo: 5,
                barrio: "Michoacan",
              },
              6: {
                codigo: 6,
                barrio: "Nuevo Brasil",
              },
              7: {
                codigo: 7,
                barrio: "Pinal",
              },
              8: {
                codigo: 8,
                barrio: "San Bosco",
              },
              9: {
                codigo: 9,
                barrio: "San Vicente",
              },
              10: {
                codigo: 10,
                barrio: "Tablera",
              },
              11: {
                codigo: 11,
                barrio: "Cabriola",
              },
              12: {
                codigo: 12,
                barrio: "Cedral ",
              },
              13: {
                codigo: 13,
                barrio: "Cuesta Achiotal ",
              },
              14: {
                codigo: 14,
                barrio: "Llano Leon",
              },
              15: {
                codigo: 15,
                barrio: "Llano Limon",
              },
              16: {
                codigo: 16,
                barrio: "Quebrada Honda",
              },
              17: {
                codigo: 17,
                barrio: "Rodeo",
              },
              18: {
                codigo: 18,
                barrio: "Santisima Trinidad",
              },
              19: {
                codigo: 19,
                barrio: "Ticufres",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "Guayabo",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bajo Claras",
              },
              2: {
                codigo: 2,
                barrio: "Bajo Morado",
              },
              3: {
                codigo: 3,
                barrio: "Corrogres",
              },
              4: {
                codigo: 4,
                barrio: "Monte Negro",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "Tabarcia",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bajo Bustamante",
              },
              2: {
                codigo: 2,
                barrio: "Bajo Lima",
              },
              3: {
                codigo: 3,
                barrio: "Bajo Loaiza",
              },
              4: {
                codigo: 4,
                barrio: "Canas",
              },
              5: {
                codigo: 5,
                barrio: "Corralar",
              },
              6: {
                codigo: 6,
                barrio: "Morado",
              },
              7: {
                codigo: 7,
                barrio: "Piedras Blancas",
              },
              8: {
                codigo: 8,
                barrio: "Salto",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "Piedras Negras",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Chile",
              },
              2: {
                codigo: 2,
                barrio: "Danta",
              },
              3: {
                codigo: 3,
                barrio: "Palma",
              },
              4: {
                codigo: 4,
                barrio: "Quebrada Grande",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "Picagres",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Cordel",
              },
              2: {
                codigo: 2,
                barrio: "Chucas",
              },
              3: {
                codigo: 3,
                barrio: "Jateo",
              },
              4: {
                codigo: 4,
                barrio: "Llano Grande",
              },
              5: {
                codigo: 5,
                barrio: "Monte Frio (Potrerillos)",
              },
            },
          },
          6: {
            codigo: 6,
            distrito: "Jaris",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Pito",
              },
            },
          },
          7: {
            codigo: 7,
            distrito: "Quitirrisi",
            barrios: {
              1: {
                codigo: 1,
                barrio: "San Juan",
              },
              2: {
                codigo: 2,
                barrio: "San Martin",
              },
              3: {
                codigo: 3,
                barrio: "Quebrada Honda",
              },
            },
          },
        },
      },
      8: {
        codigo: 8,
        canton: "Goicochea",
        distritos: {
          1: {
            codigo: 1,
            distrito: "Guadalupe",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Arboles",
              },
              2: {
                codigo: 2,
                barrio: "Colonia del Rio",
              },
              3: {
                codigo: 3,
                barrio: "El Alto (parte)",
              },
              4: {
                codigo: 4,
                barrio: "Fatima",
              },
              5: {
                codigo: 5,
                barrio: "Independencia",
              },
              6: {
                codigo: 6,
                barrio: "Jardin",
              },
              7: {
                codigo: 7,
                barrio: "Magnolia",
              },
              8: {
                codigo: 8,
                barrio: "Maravilla",
              },
              9: {
                codigo: 9,
                barrio: "Margarita",
              },
              10: {
                codigo: 10,
                barrio: "Minerva",
              },
              11: {
                codigo: 11,
                barrio: "Moreno Canas",
              },
              12: {
                codigo: 12,
                barrio: "Orquidea",
              },
              13: {
                codigo: 13,
                barrio: "Pilar Jimenez",
              },
              14: {
                codigo: 14,
                barrio: "Rothe",
              },
              15: {
                codigo: 15,
                barrio: "San Gerardo",
              },
              16: {
                codigo: 16,
                barrio: "Santa Cecilia",
              },
              17: {
                codigo: 17,
                barrio: "Santa Eduvigis",
              },
              18: {
                codigo: 18,
                barrio: "Santo Cristo",
              },
              19: {
                codigo: 19,
                barrio: "Union",
              },
              20: {
                codigo: 20,
                barrio: "Yurusti",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "San Francisco",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Carlos Maria Ulloa",
              },
              2: {
                codigo: 2,
                barrio: "San Francisco (centro)",
              },
              3: {
                codigo: 3,
                barrio: "Tournon",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "Calle Blancos",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Calle Blancos (centro)",
              },
              2: {
                codigo: 2,
                barrio: "Cipres",
              },
              3: {
                codigo: 3,
                barrio: "Encanto",
              },
              4: {
                codigo: 4,
                barrio: "Esquivel Bonilla",
              },
              5: {
                codigo: 5,
                barrio: "Montelimar",
              },
              6: {
                codigo: 6,
                barrio: "Pinos",
              },
              7: {
                codigo: 7,
                barrio: "San Antonio",
              },
              8: {
                codigo: 8,
                barrio: "San Gabriel",
              },
              9: {
                codigo: 9,
                barrio: "Santo Tomas",
              },
              10: {
                codigo: 10,
                barrio: "Volio",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "Mata de Platano",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bruncas",
              },
              2: {
                codigo: 2,
                barrio: "Carmen",
              },
              3: {
                codigo: 3,
                barrio: "Claraval",
              },
              4: {
                codigo: 4,
                barrio: "Cruz",
              },
              5: {
                codigo: 5,
                barrio: "Cuesta Grande (parte)",
              },
              6: {
                codigo: 6,
                barrio: "Estefana (parte)",
              },
              7: {
                codigo: 7,
                barrio: "Hortensias",
              },
              8: {
                codigo: 8,
                barrio: "Jaboncillal",
              },
              9: {
                codigo: 9,
                barrio: "Jardines de la Paz",
              },
              10: {
                codigo: 10,
                barrio: "Lourdes",
              },
              11: {
                codigo: 11,
                barrio: "Praderas",
              },
              12: {
                codigo: 12,
                barrio: "Tepeyac",
              },
              13: {
                codigo: 13,
                barrio: "Terraba",
              },
              14: {
                codigo: 14,
                barrio: "Villalta",
              },
              15: {
                codigo: 15,
                barrio: "Villaverde",
              },
              16: {
                codigo: 16,
                barrio: "Vista del Valle",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "Ipis",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Angeles",
              },
              2: {
                codigo: 2,
                barrio: "El Alto (parte)",
              },
              3: {
                codigo: 3,
                barrio: "Floresta",
              },
              4: {
                codigo: 4,
                barrio: "Korobo",
              },
              5: {
                codigo: 5,
                barrio: "La Mora",
              },
              6: {
                codigo: 6,
                barrio: "Morita",
              },
              7: {
                codigo: 7,
                barrio: "Mozotal",
              },
              8: {
                codigo: 8,
                barrio: "Nazareno",
              },
              9: {
                codigo: 9,
                barrio: "Orquidea",
              },
              10: {
                codigo: 10,
                barrio: "Rodrigo Facio",
              },
              11: {
                codigo: 11,
                barrio: "Santa Clara (parte)",
              },
              12: {
                codigo: 12,
                barrio: "Setillal",
              },
              13: {
                codigo: 13,
                barrio: "Vista del Monte",
              },
            },
          },
          6: {
            codigo: 6,
            distrito: "Rancho Redondo",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Mirador ",
              },
              2: {
                codigo: 2,
                barrio: "Corralillo",
              },
              3: {
                codigo: 3,
                barrio: "Guayabillos",
              },
              4: {
                codigo: 4,
                barrio: "Isla",
              },
              5: {
                codigo: 5,
                barrio: "San Miguel",
              },
              6: {
                codigo: 6,
                barrio: "Vista de Mar",
              },
            },
          },
          7: {
            codigo: 7,
            distrito: "Purral",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Ana Frank",
              },
              2: {
                codigo: 2,
                barrio: "Castores",
              },
              3: {
                codigo: 3,
                barrio: "Cuadros",
              },
              4: {
                codigo: 4,
                barrio: "Don Carlos",
              },
              5: {
                codigo: 5,
                barrio: "El Alto (parte)",
              },
              6: {
                codigo: 6,
                barrio: "Flor de Liz",
              },
              7: {
                codigo: 7,
                barrio: "Kuru",
              },
              8: {
                codigo: 8,
                barrio: "Lomas de Tepeyac",
              },
              9: {
                codigo: 9,
                barrio: "Lupita",
              },
              10: {
                codigo: 10,
                barrio: "Montesol",
              },
              11: {
                codigo: 11,
                barrio: "Nogales",
              },
              12: {
                codigo: 12,
                barrio: "Pueblo",
              },
              13: {
                codigo: 13,
                barrio: "Violetas",
              },
            },
          },
        },
      },
      9: {
        codigo: 9,
        canton: "Santa Ana",
        distritos: {
          1: {
            codigo: 1,
            distrito: "Santa Ana",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Aguas Lindas",
              },
              2: {
                codigo: 2,
                barrio: "Cabanas",
              },
              3: {
                codigo: 3,
                barrio: "Casa Blanca",
              },
              4: {
                codigo: 4,
                barrio: "Lajas (parte)",
              },
              5: {
                codigo: 5,
                barrio: "Obando",
              },
              6: {
                codigo: 6,
                barrio: "Paso Machete (parte)",
              },
              7: {
                codigo: 7,
                barrio: "San Rafael",
              },
              8: {
                codigo: 8,
                barrio: "Corrogres",
              },
              9: {
                codigo: 9,
                barrio: "Pilas",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "Salitral",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Paso Machete (parte)",
              },
              2: {
                codigo: 2,
                barrio: "Robalillo",
              },
              3: {
                codigo: 3,
                barrio: "Alto Raicero",
              },
              4: {
                codigo: 4,
                barrio: "Chirracal",
              },
              5: {
                codigo: 5,
                barrio: "Matinilla",
              },
              6: {
                codigo: 6,
                barrio: "Pabellon",
              },
              7: {
                codigo: 7,
                barrio: "Perico",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "Pozos",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto Palomas",
              },
              2: {
                codigo: 2,
                barrio: "Concepcion",
              },
              3: {
                codigo: 3,
                barrio: "Cuevas",
              },
              4: {
                codigo: 4,
                barrio: "Chispa",
              },
              5: {
                codigo: 5,
                barrio: "Gavilanes",
              },
              6: {
                codigo: 6,
                barrio: "Honduras",
              },
              7: {
                codigo: 7,
                barrio: "Lajas (parte)",
              },
              8: {
                codigo: 8,
                barrio: "Lindora",
              },
              9: {
                codigo: 9,
                barrio: "Manantial",
              },
              10: {
                codigo: 10,
                barrio: "Real de Pereira (parte)",
              },
              11: {
                codigo: 11,
                barrio: "Valle del sol",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "Uruca",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Chimba",
              },
              2: {
                codigo: 2,
                barrio: "Guapinol",
              },
              3: {
                codigo: 3,
                barrio: "Mata Grande",
              },
              4: {
                codigo: 4,
                barrio: "Mina",
              },
              5: {
                codigo: 5,
                barrio: "Paso Machete (parte)",
              },
              6: {
                codigo: 6,
                barrio: "Rio Uruca",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "Piedades",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Carana",
              },
              2: {
                codigo: 2,
                barrio: "Cebadilla",
              },
              3: {
                codigo: 3,
                barrio: "Finquitas",
              },
              4: {
                codigo: 4,
                barrio: "Montana del Sol",
              },
              5: {
                codigo: 5,
                barrio: "Rincon San Marcos",
              },
              6: {
                codigo: 6,
                barrio: "Triunfo",
              },
            },
          },
          6: {
            codigo: 6,
            distrito: "Piedades",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Canjel",
              },
              2: {
                codigo: 2,
                barrio: "Copey",
              },
            },
          },
        },
      },
      10: {
        codigo: 10,
        canton: "Alajuelita",
        distritos: {
          1: {
            codigo: 1,
            distrito: "Alajuelita",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bellavista",
              },
              2: {
                codigo: 2,
                barrio: "Chorotega",
              },
              3: {
                codigo: 3,
                barrio: "Lagunilla",
              },
              4: {
                codigo: 4,
                barrio: "Macha",
              },
              5: {
                codigo: 5,
                barrio: "Madrigal",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "San Josecito",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Aguilar Machado",
              },
              2: {
                codigo: 2,
                barrio: "Cochea",
              },
              3: {
                codigo: 3,
                barrio: "El Alto",
              },
              4: {
                codigo: 4,
                barrio: "Faro del Suroeste",
              },
              5: {
                codigo: 5,
                barrio: "Filtros",
              },
              6: {
                codigo: 6,
                barrio: "Pueblo Escondido",
              },
              7: {
                codigo: 7,
                barrio: "Vistas de Alajuelita",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "San Antonio",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Caracas",
              },
              2: {
                codigo: 2,
                barrio: "Cascabela",
              },
              3: {
                codigo: 3,
                barrio: "Llano",
              },
              4: {
                codigo: 4,
                barrio: "Piedra de fuego",
              },
              5: {
                codigo: 5,
                barrio: "Lamparas",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "Concepcion",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Almendros",
              },
              2: {
                codigo: 2,
                barrio: "Boca del Monte",
              },
              3: {
                codigo: 3,
                barrio: "Chirivico",
              },
              4: {
                codigo: 4,
                barrio: "Monte Alto",
              },
              5: {
                codigo: 5,
                barrio: "Once de Abril",
              },
              6: {
                codigo: 6,
                barrio: "Progreso",
              },
              7: {
                codigo: 7,
                barrio: "Tejar (parte)",
              },
              8: {
                codigo: 8,
                barrio: "Vista de San Jose",
              },
              9: {
                codigo: 9,
                barrio: "Vista Real",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "San Felipe",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Aurora",
              },
              2: {
                codigo: 2,
                barrio: "Corina Rodriguez",
              },
              3: {
                codigo: 3,
                barrio: "Esquipulas Dos",
              },
              4: {
                codigo: 4,
                barrio: "La Guapil",
              },
              5: {
                codigo: 5,
                barrio: "Peralta",
              },
              6: {
                codigo: 6,
                barrio: "Verbena",
              },
              7: {
                codigo: 7,
                barrio: "Palo Campano",
              },
            },
          },
        },
      },
      11: {
        codigo: 11,
        canton: "Vasquez de Coronado",
        distritos: {
          1: {
            codigo: 1,
            distrito: "San Isidro",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alamos",
              },
              2: {
                codigo: 2,
                barrio: "Alpes",
              },
              3: {
                codigo: 3,
                barrio: "Arias",
              },
              4: {
                codigo: 4,
                barrio: "Brisa",
              },
              5: {
                codigo: 5,
                barrio: "Cedros",
              },
              6: {
                codigo: 6,
                barrio: "Corazon de Jesus",
              },
              7: {
                codigo: 7,
                barrio: "Durazno",
              },
              8: {
                codigo: 8,
                barrio: "Girasoles (parte)",
              },
              9: {
                codigo: 9,
                barrio: "Huacas",
              },
              10: {
                codigo: 10,
                barrio: "Magnolias",
              },
              11: {
                codigo: 11,
                barrio: "Mercedes",
              },
              12: {
                codigo: 12,
                barrio: "Monte Azul",
              },
              13: {
                codigo: 13,
                barrio: "San Francisco",
              },
              14: {
                codigo: 14,
                barrio: "San Martin",
              },
              15: {
                codigo: 15,
                barrio: "Villa Solidarista",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "San Rafael",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Fanguillo",
              },
              2: {
                codigo: 2,
                barrio: "I Griega",
              },
              3: {
                codigo: 3,
                barrio: "Loma Bonita",
              },
              4: {
                codigo: 4,
                barrio: "Nubes",
              },
              5: {
                codigo: 5,
                barrio: "Patio de Agua",
              },
              6: {
                codigo: 6,
                barrio: "Villa Emaus",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "Dulce Nombre de Jesus",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Calera",
              },
              2: {
                codigo: 2,
                barrio: "Carmen",
              },
              3: {
                codigo: 3,
                barrio: "Gemelas",
              },
              4: {
                codigo: 4,
                barrio: "Josue",
              },
              5: {
                codigo: 5,
                barrio: "Manzanos",
              },
              6: {
                codigo: 6,
                barrio: "Murtal",
              },
              7: {
                codigo: 7,
                barrio: "Sitrae",
              },
              8: {
                codigo: 8,
                barrio: "Valle Feliz",
              },
              9: {
                codigo: 9,
                barrio: "Alto Palma (parte)",
              },
              10: {
                codigo: 10,
                barrio: "Platanares",
              },
              11: {
                codigo: 11,
                barrio: "Rodeo (parte)",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "Patalillo",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Cornelia",
              },
              2: {
                codigo: 2,
                barrio: "Girasoles (parte)",
              },
              3: {
                codigo: 3,
                barrio: "Horizontes",
              },
              4: {
                codigo: 4,
                barrio: "Irazu",
              },
              5: {
                codigo: 5,
                barrio: "Jardines",
              },
              6: {
                codigo: 6,
                barrio: "Labrador",
              },
              7: {
                codigo: 7,
                barrio: "Patalillo",
              },
              8: {
                codigo: 8,
                barrio: "Santa Marta",
              },
              9: {
                codigo: 9,
                barrio: "Trapiche",
              },
              10: {
                codigo: 10,
                barrio: "Villalinda",
              },
              11: {
                codigo: 11,
                barrio: "Villanova",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "Cascajal",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Aviles",
              },
              2: {
                codigo: 2,
                barrio: "Cerro Indio",
              },
              3: {
                codigo: 3,
                barrio: "Guaba",
              },
              4: {
                codigo: 4,
                barrio: "Rojizo",
              },
              5: {
                codigo: 5,
                barrio: "Sinai",
              },
              6: {
                codigo: 6,
                barrio: "Canoa",
              },
              7: {
                codigo: 7,
                barrio: "Cascajal",
              },
              8: {
                codigo: 8,
                barrio: "Choco",
              },
              9: {
                codigo: 9,
                barrio: "Isla",
              },
              10: {
                codigo: 10,
                barrio: "Monserrat",
              },
              11: {
                codigo: 11,
                barrio: "Patillos",
              },
              12: {
                codigo: 12,
                barrio: "Rodeo (parte)",
              },
              13: {
                codigo: 13,
                barrio: "Santa Rita de Casia",
              },
              14: {
                codigo: 14,
                barrio: "Tierras Morenas",
              },
              15: {
                codigo: 15,
                barrio: "Vegas de Cajon",
              },
              16: {
                codigo: 16,
                barrio: "Venita",
              },
            },
          },
        },
      },
      12: {
        codigo: 12,
        canton: "Acosta",
        distritos: {
          1: {
            codigo: 1,
            distrito: "San Ignacio",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Abarca",
              },
              2: {
                codigo: 2,
                barrio: "Corral",
              },
              3: {
                codigo: 3,
                barrio: "Maria Auxiliadora",
              },
              4: {
                codigo: 4,
                barrio: "Ortiga",
              },
              5: {
                codigo: 5,
                barrio: "Pozos",
              },
              6: {
                codigo: 6,
                barrio: "San Martin (San Gerardo)",
              },
              7: {
                codigo: 7,
                barrio: "San Luis",
              },
              8: {
                codigo: 8,
                barrio: "Turrujal",
              },
              9: {
                codigo: 9,
                barrio: "Vereda",
              },
              10: {
                codigo: 10,
                barrio: "Aguablanca (parte)",
              },
              11: {
                codigo: 11,
                barrio: "Alto Escalera",
              },
              12: {
                codigo: 12,
                barrio: "Alto Los Mora",
              },
              13: {
                codigo: 13,
                barrio: "Angeles",
              },
              14: {
                codigo: 14,
                barrio: "Chirraca (parte)",
              },
              15: {
                codigo: 15,
                barrio: "Esperanza",
              },
              16: {
                codigo: 16,
                barrio: "Potrerillos",
              },
              17: {
                codigo: 17,
                barrio: "Resbalon",
              },
              18: {
                codigo: 18,
                barrio: "Tablazo",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "Guaitil",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto Sierra",
              },
              2: {
                codigo: 2,
                barrio: "Alto Vigia",
              },
              3: {
                codigo: 3,
                barrio: "Bajo Arias",
              },
              4: {
                codigo: 4,
                barrio: "Bajo Bermudez",
              },
              5: {
                codigo: 5,
                barrio: "Bajo Calvo",
              },
              6: {
                codigo: 6,
                barrio: "Bajo Cardenas",
              },
              7: {
                codigo: 7,
                barrio: "Bajo Moras",
              },
              8: {
                codigo: 8,
                barrio: "Coyolar",
              },
              9: {
                codigo: 9,
                barrio: "Hondonada",
              },
              10: {
                codigo: 10,
                barrio: "La Cruz",
              },
              11: {
                codigo: 11,
                barrio: "Lagunillas (parte)",
              },
              12: {
                codigo: 12,
                barrio: "Ococa",
              },
              13: {
                codigo: 13,
                barrio: "Toledo",
              },
              14: {
                codigo: 14,
                barrio: "Zapote",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "Palmichal",
            barrios: {
              1: {
                codigo: 1,
                barrio: "San Pablo",
              },
              2: {
                codigo: 2,
                barrio: "Agua Blanca (parte)",
              },
              3: {
                codigo: 3,
                barrio: "Bajo Cerdas",
              },
              4: {
                codigo: 4,
                barrio: "Bajos de Jorco",
              },
              5: {
                codigo: 5,
                barrio: "Bolivar",
              },
              6: {
                codigo: 6,
                barrio: "Canadas",
              },
              7: {
                codigo: 7,
                barrio: "Caragral",
              },
              8: {
                codigo: 8,
                barrio: "Corazon de Jesus",
              },
              9: {
                codigo: 9,
                barrio: "Charcalillo",
              },
              10: {
                codigo: 10,
                barrio: "Chirraca (parte)",
              },
              11: {
                codigo: 11,
                barrio: "Fila",
              },
              12: {
                codigo: 12,
                barrio: "Jaular",
              },
              13: {
                codigo: 13,
                barrio: "Lagunillas (parte)",
              },
              14: {
                codigo: 14,
                barrio: "La Mina",
              },
              15: {
                codigo: 15,
                barrio: "La Pita",
              },
              16: {
                codigo: 16,
                barrio: "Los Monge",
              },
              17: {
                codigo: 17,
                barrio: "Playa",
              },
              18: {
                codigo: 18,
                barrio: "San Pablo",
              },
              19: {
                codigo: 19,
                barrio: "Sevilla",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "Cangrejal",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bajo Los Cruces",
              },
              2: {
                codigo: 2,
                barrio: "Ceiba Alta (parte)",
              },
              3: {
                codigo: 3,
                barrio: "Ceiba Baja",
              },
              4: {
                codigo: 4,
                barrio: "Ceiba Este",
              },
              5: {
                codigo: 5,
                barrio: "Escuadra",
              },
              6: {
                codigo: 6,
                barrio: "Gravilias",
              },
              7: {
                codigo: 7,
                barrio: "Lindavista",
              },
              8: {
                codigo: 8,
                barrio: "Llano Bonito",
              },
              9: {
                codigo: 9,
                barrio: "Mesa",
              },
              10: {
                codigo: 10,
                barrio: "Naranjal",
              },
              11: {
                codigo: 11,
                barrio: "Perpetuo Socorro",
              },
              12: {
                codigo: 12,
                barrio: "Tejar",
              },
              13: {
                codigo: 13,
                barrio: "Tiquires",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "Sabanillas",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto Parriton",
              },
              2: {
                codigo: 2,
                barrio: "Bajo Palma",
              },
              3: {
                codigo: 3,
                barrio: "Bajo Perez",
              },
              4: {
                codigo: 4,
                barrio: "Bijagual",
              },
              5: {
                codigo: 5,
                barrio: "Brenon",
              },
              6: {
                codigo: 6,
                barrio: "Caspirola",
              },
              7: {
                codigo: 7,
                barrio: "Colorado",
              },
              8: {
                codigo: 8,
                barrio: "Cuesta Aguacate",
              },
              9: {
                codigo: 9,
                barrio: "Limas",
              },
              10: {
                codigo: 10,
                barrio: "Parriton",
              },
              11: {
                codigo: 11,
                barrio: "Plomo",
              },
              12: {
                codigo: 12,
                barrio: "Sabanas",
              },
              13: {
                codigo: 13,
                barrio: "San Jeronimo",
              },
              14: {
                codigo: 14,
                barrio: "Soledad",
              },
              15: {
                codigo: 15,
                barrio: "Teruel",
              },
              16: {
                codigo: 16,
                barrio: "Tiquiritos",
              },
              17: {
                codigo: 17,
                barrio: "Uruca",
              },
              18: {
                codigo: 18,
                barrio: "Zoncuano",
              },
            },
          },
        },
      },
      13: {
        codigo: 13,
        canton: "Tibas",
        distritos: {
          1: {
            codigo: 1,
            distrito: "San Juan",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Acacias",
              },
              2: {
                codigo: 2,
                barrio: "Arboleda",
              },
              3: {
                codigo: 3,
                barrio: "Asturias",
              },
              4: {
                codigo: 4,
                barrio: "Estudiantes",
              },
              5: {
                codigo: 5,
                barrio: "Florida",
              },
              6: {
                codigo: 6,
                barrio: "Gonzalez Truque",
              },
              7: {
                codigo: 7,
                barrio: "Jesus Jimenez Zamora",
              },
              8: {
                codigo: 8,
                barrio: "Lindavista",
              },
              9: {
                codigo: 9,
                barrio: "Rosas",
              },
              10: {
                codigo: 10,
                barrio: "San Jeronimo",
              },
              11: {
                codigo: 11,
                barrio: "Santa Eduvigis",
              },
              12: {
                codigo: 12,
                barrio: "Valle",
              },
              13: {
                codigo: 13,
                barrio: "Versalles",
              },
              14: {
                codigo: 14,
                barrio: "Villafranca",
              },
              15: {
                codigo: 15,
                barrio: "Virginia",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "Cinco Esquinas",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bajo Piuses",
              },
              2: {
                codigo: 2,
                barrio: "Copey",
              },
              3: {
                codigo: 3,
                barrio: "Leiva Urcuyo",
              },
              4: {
                codigo: 4,
                barrio: "Lilas",
              },
              5: {
                codigo: 5,
                barrio: "Lomas del Pinar",
              },
              6: {
                codigo: 6,
                barrio: "Montecarlo",
              },
              7: {
                codigo: 7,
                barrio: "Santa Teresa",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "Anselmo Llorente",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Apolo",
              },
              2: {
                codigo: 2,
                barrio: "Dalia",
              },
              3: {
                codigo: 3,
                barrio: "Estancia",
              },
              4: {
                codigo: 4,
                barrio: "Fletcher",
              },
              5: {
                codigo: 5,
                barrio: "Franjo",
              },
              6: {
                codigo: 6,
                barrio: "Jardines de Tibas",
              },
              7: {
                codigo: 7,
                barrio: "Jardines La Trinidad",
              },
              8: {
                codigo: 8,
                barrio: "Monterreal",
              },
              9: {
                codigo: 9,
                barrio: "Palmeras",
              },
              10: {
                codigo: 10,
                barrio: "Santa Monica",
              },
              11: {
                codigo: 11,
                barrio: "Talamanca",
              },
              12: {
                codigo: 12,
                barrio: "Vergel",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "Leon XIII",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Dona Fabiola",
              },
              2: {
                codigo: 2,
                barrio: "Garabito",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "Colima",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Anselmo Alvarado",
              },
              2: {
                codigo: 2,
                barrio: "Balsa",
              },
              3: {
                codigo: 3,
                barrio: "Cuatro Reinas",
              },
              4: {
                codigo: 4,
                barrio: "Orquideas",
              },
              5: {
                codigo: 5,
                barrio: "Rey",
              },
              6: {
                codigo: 6,
                barrio: "San Agustin",
              },
            },
          },
        },
      },
      14: {
        codigo: 14,
        canton: "Moravia",
        distritos: {
          1: {
            codigo: 1,
            distrito: "San Vicente",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alondra",
              },
              2: {
                codigo: 2,
                barrio: "Americano",
              },
              3: {
                codigo: 3,
                barrio: "Americas",
              },
              4: {
                codigo: 4,
                barrio: "Bajo Isla",
              },
              5: {
                codigo: 5,
                barrio: "Bajo Varelas",
              },
              6: {
                codigo: 6,
                barrio: "Barro de Olla",
              },
              7: {
                codigo: 7,
                barrio: "Caragua",
              },
              8: {
                codigo: 8,
                barrio: "Carmen",
              },
              9: {
                codigo: 9,
                barrio: "Colegios",
              },
              10: {
                codigo: 10,
                barrio: "Colegios Norte",
              },
              11: {
                codigo: 11,
                barrio: "Chaves",
              },
              12: {
                codigo: 12,
                barrio: "El Alto (parte)",
              },
              13: {
                codigo: 13,
                barrio: "Flor",
              },
              14: {
                codigo: 14,
                barrio: "Florencia",
              },
              15: {
                codigo: 15,
                barrio: "Guaria",
              },
              16: {
                codigo: 16,
                barrio: "Guaria Oriental",
              },
              17: {
                codigo: 17,
                barrio: "Isla",
              },
              18: {
                codigo: 18,
                barrio: "Jardines de Moravia",
              },
              19: {
                codigo: 19,
                barrio: "La Casa",
              },
              20: {
                codigo: 20,
                barrio: "Ladrillera",
              },
              21: {
                codigo: 21,
                barrio: "Robles",
              },
              22: {
                codigo: 22,
                barrio: "Romeral",
              },
              23: {
                codigo: 23,
                barrio: "Sagrado Corazon",
              },
              24: {
                codigo: 24,
                barrio: "San Blas",
              },
              25: {
                codigo: 25,
                barrio: "San Jorge",
              },
              26: {
                codigo: 26,
                barrio: "San Martin",
              },
              27: {
                codigo: 27,
                barrio: "San Rafael",
              },
              28: {
                codigo: 28,
                barrio: "Santa Clara (parte)",
              },
              29: {
                codigo: 29,
                barrio: "Santo Tomas",
              },
              30: {
                codigo: 30,
                barrio: "Saprissa",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "San Jeronimo",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto Palma (parte)",
              },
              2: {
                codigo: 2,
                barrio: "Platanares",
              },
              3: {
                codigo: 3,
                barrio: "Tornillal",
              },
              4: {
                codigo: 4,
                barrio: "Torre",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "Trinidad",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Altos de Trinidad",
              },
              2: {
                codigo: 2,
                barrio: "Cipreses",
              },
              3: {
                codigo: 3,
                barrio: "Colonia",
              },
              4: {
                codigo: 4,
                barrio: "Moral",
              },
              5: {
                codigo: 5,
                barrio: "Nispero",
              },
              6: {
                codigo: 6,
                barrio: "Rosal",
              },
              7: {
                codigo: 7,
                barrio: "Ruano",
              },
              8: {
                codigo: 8,
                barrio: "Sitios",
              },
              9: {
                codigo: 9,
                barrio: "Tanques",
              },
              10: {
                codigo: 10,
                barrio: "Virilla",
              },
            },
          },
        },
      },
      15: {
        codigo: 15,
        canton: "Montes de Oca",
        distritos: {
          1: {
            codigo: 1,
            distrito: "San Pedro",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alhambra",
              },
              2: {
                codigo: 2,
                barrio: "Azaleas",
              },
              3: {
                codigo: 3,
                barrio: "Carmiol",
              },
              4: {
                codigo: 4,
                barrio: "Cedral",
              },
              5: {
                codigo: 5,
                barrio: "Dent (parte)",
              },
              6: {
                codigo: 6,
                barrio: "Francisco Peralta (parte)",
              },
              7: {
                codigo: 7,
                barrio: "Fuentes",
              },
              8: {
                codigo: 8,
                barrio: "Granja",
              },
              9: {
                codigo: 9,
                barrio: "Kezia",
              },
              10: {
                codigo: 10,
                barrio: "Lourdes",
              },
              11: {
                codigo: 11,
                barrio: "Monterrey",
              },
              12: {
                codigo: 12,
                barrio: "Nadori",
              },
              13: {
                codigo: 13,
                barrio: "Oriente",
              },
              14: {
                codigo: 14,
                barrio: "Pinto",
              },
              15: {
                codigo: 15,
                barrio: "Prados del Este",
              },
              16: {
                codigo: 16,
                barrio: "Roosevelt",
              },
              17: {
                codigo: 17,
                barrio: "San Gerardo (parte)",
              },
              18: {
                codigo: 18,
                barrio: "Santa Marta",
              },
              19: {
                codigo: 19,
                barrio: "Saprissa",
              },
              20: {
                codigo: 20,
                barrio: "Vargas Araya",
              },
              21: {
                codigo: 21,
                barrio: "Yoses",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "Sabanilla",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Arboleda",
              },
              2: {
                codigo: 2,
                barrio: "Bloquera",
              },
              3: {
                codigo: 3,
                barrio: "Cedros",
              },
              4: {
                codigo: 4,
                barrio: "El Cristo (parte)",
              },
              5: {
                codigo: 5,
                barrio: "Espanolita",
              },
              6: {
                codigo: 6,
                barrio: "Luciana",
              },
              7: {
                codigo: 7,
                barrio: "Marsella",
              },
              8: {
                codigo: 8,
                barrio: "Maravilla",
              },
              9: {
                codigo: 9,
                barrio: "Rodeo",
              },
              10: {
                codigo: 10,
                barrio: "Rosales",
              },
              11: {
                codigo: 11,
                barrio: "San Marino",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "Betania",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alma Mater",
              },
              2: {
                codigo: 2,
                barrio: "Damiana",
              },
              3: {
                codigo: 3,
                barrio: "Dent (parte)",
              },
              4: {
                codigo: 4,
                barrio: "Guaymi",
              },
              5: {
                codigo: 5,
                barrio: "Paso Hondo",
              },
              6: {
                codigo: 6,
                barrio: "Paulina",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "San Rafael",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alameda",
              },
              2: {
                codigo: 2,
                barrio: "Andromeda",
              },
              3: {
                codigo: 3,
                barrio: "Begonia",
              },
              4: {
                codigo: 4,
                barrio: "Cuesta Grande (parte)",
              },
              5: {
                codigo: 5,
                barrio: "El Cristo (parte)",
              },
              6: {
                codigo: 6,
                barrio: "Estefana (parte)",
              },
              7: {
                codigo: 7,
                barrio: "Europa",
              },
              8: {
                codigo: 8,
                barrio: "Liburgia",
              },
              9: {
                codigo: 9,
                barrio: "Mansiones (parte)",
              },
              10: {
                codigo: 10,
                barrio: "Maruz",
              },
              11: {
                codigo: 11,
                barrio: "Salitrillos",
              },
            },
          },
        },
      },
      16: {
        codigo: 16,
        canton: "Turrubares",
        distritos: {
          1: {
            codigo: 1,
            distrito: "San Pablo",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto Poro",
              },
              2: {
                codigo: 2,
                barrio: "Bolson",
              },
              3: {
                codigo: 3,
                barrio: "Purires",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "San Pedro",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto Limon",
              },
              2: {
                codigo: 2,
                barrio: "Florecilla",
              },
              3: {
                codigo: 3,
                barrio: "Limon",
              },
              4: {
                codigo: 4,
                barrio: "Palmar",
              },
              5: {
                codigo: 5,
                barrio: "Pita Villa Colina",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "San Juan de Mata",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bajo Laguna",
              },
              2: {
                codigo: 2,
                barrio: "El Barro",
              },
              3: {
                codigo: 3,
                barrio: "Llano Bonito (San Juan de Mata)",
              },
              4: {
                codigo: 4,
                barrio: "Molenillo",
              },
              5: {
                codigo: 5,
                barrio: "Paso Agres",
              },
              6: {
                codigo: 6,
                barrio: "Surtubal",
              },
              7: {
                codigo: 7,
                barrio: "Tronco Negro",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "San Luis",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Pital",
              },
              2: {
                codigo: 2,
                barrio: "Potenciana Arriba",
              },
              3: {
                codigo: 3,
                barrio: "Quebrada Azul",
              },
              4: {
                codigo: 4,
                barrio: "San Francisco",
              },
              5: {
                codigo: 5,
                barrio: "San Rafael",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "Carara",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto Espavel",
              },
              2: {
                codigo: 2,
                barrio: "Angostura (parte)",
              },
              3: {
                codigo: 3,
                barrio: "Bijagualito",
              },
              4: {
                codigo: 4,
                barrio: "Bola",
              },
              5: {
                codigo: 5,
                barrio: "Carara",
              },
              6: {
                codigo: 6,
                barrio: "Cima",
              },
              7: {
                codigo: 7,
                barrio: "Delicias",
              },
              8: {
                codigo: 8,
                barrio: "El Sur",
              },
              9: {
                codigo: 9,
                barrio: "Esperanza",
              },
              10: {
                codigo: 10,
                barrio: "Fila Negra",
              },
              11: {
                codigo: 11,
                barrio: "Galan",
              },
              12: {
                codigo: 12,
                barrio: "La Trampa",
              },
              13: {
                codigo: 13,
                barrio: "Lajas",
              },
              14: {
                codigo: 14,
                barrio: "Mata de Platano",
              },
              15: {
                codigo: 15,
                barrio: "Montelimar",
              },
              16: {
                codigo: 16,
                barrio: "Pacayal",
              },
              17: {
                codigo: 17,
                barrio: "Pavona",
              },
              18: {
                codigo: 18,
                barrio: "Quina",
              },
              19: {
                codigo: 19,
                barrio: "Rio Negro",
              },
              20: {
                codigo: 20,
                barrio: "Rio Seco",
              },
              21: {
                codigo: 21,
                barrio: "San Francisco",
              },
              22: {
                codigo: 22,
                barrio: "San Gabriel",
              },
              23: {
                codigo: 23,
                barrio: "San Isidro",
              },
              24: {
                codigo: 24,
                barrio: "San Jeronimo",
              },
              25: {
                codigo: 25,
                barrio: "Tulin",
              },
            },
          },
        },
      },
      17: {
        codigo: 17,
        canton: "Dota",
        distritos: {
          1: {
            codigo: 1,
            distrito: "Santa Maria",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bandera",
              },
              2: {
                codigo: 2,
                barrio: "Higueronal",
              },
              3: {
                codigo: 3,
                barrio: "Llano",
              },
              4: {
                codigo: 4,
                barrio: "Nubes",
              },
              5: {
                codigo: 5,
                barrio: "San Rafael",
              },
              6: {
                codigo: 6,
                barrio: "Botella",
              },
              7: {
                codigo: 7,
                barrio: "Cedral",
              },
              8: {
                codigo: 8,
                barrio: "Guaria",
              },
              9: {
                codigo: 9,
                barrio: "Naranjo",
              },
              10: {
                codigo: 10,
                barrio: "Quebrada Grande (parte)",
              },
              11: {
                codigo: 11,
                barrio: "Reyes",
              },
              12: {
                codigo: 12,
                barrio: "San Joaquin",
              },
              13: {
                codigo: 13,
                barrio: "San Lucas",
              },
              14: {
                codigo: 14,
                barrio: "Sukia",
              },
              15: {
                codigo: 15,
                barrio: "Vapor",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "Jardin",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Cabeceras de Tarrazu",
              },
              2: {
                codigo: 2,
                barrio: "Chonta (parte)",
              },
              3: {
                codigo: 3,
                barrio: "Quebradillas",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "Copey",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bernardo Urena",
              },
              2: {
                codigo: 2,
                barrio: "Alto Canazo",
              },
              3: {
                codigo: 3,
                barrio: "Alto indio",
              },
              4: {
                codigo: 4,
                barrio: "Alto Miramar",
              },
              5: {
                codigo: 5,
                barrio: "Canon (parte)",
              },
              6: {
                codigo: 6,
                barrio: "Cima",
              },
              7: {
                codigo: 7,
                barrio: "Cruce Chinchilla",
              },
              8: {
                codigo: 8,
                barrio: "Florida",
              },
              9: {
                codigo: 9,
                barrio: "Garrafa",
              },
              10: {
                codigo: 10,
                barrio: "Jaboncillo",
              },
              11: {
                codigo: 11,
                barrio: "Madreselva",
              },
              12: {
                codigo: 12,
                barrio: "Ojo de Agua (parte)",
              },
              13: {
                codigo: 13,
                barrio: "Paso Macho (parte)",
              },
              14: {
                codigo: 14,
                barrio: "Pedregoso",
              },
              15: {
                codigo: 15,
                barrio: "Providencia",
              },
              16: {
                codigo: 16,
                barrio: "Quebrada Grande (parte)",
              },
              17: {
                codigo: 17,
                barrio: "Quebrador",
              },
              18: {
                codigo: 18,
                barrio: "Rio Blanco",
              },
              19: {
                codigo: 19,
                barrio: "Salsipuedes (parte)",
              },
              20: {
                codigo: 20,
                barrio: "San Carlos",
              },
              21: {
                codigo: 21,
                barrio: "San Gerardo",
              },
              22: {
                codigo: 22,
                barrio: "Trinidad",
              },
              23: {
                codigo: 23,
                barrio: "Vueltas",
              },
            },
          },
        },
      },
      18: {
        codigo: 18,
        canton: "Curridabat",
        distritos: {
          1: {
            codigo: 1,
            distrito: "Curridabat",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Ahogados (parte)",
              },
              2: {
                codigo: 2,
                barrio: "Aromatico",
              },
              3: {
                codigo: 3,
                barrio: "Cipreses",
              },
              4: {
                codigo: 4,
                barrio: "Chapultepec",
              },
              5: {
                codigo: 5,
                barrio: "Dorados",
              },
              6: {
                codigo: 6,
                barrio: "Guayabos",
              },
              7: {
                codigo: 7,
                barrio: "Hacienda Vieja",
              },
              8: {
                codigo: 8,
                barrio: "Hogar",
              },
              9: {
                codigo: 9,
                barrio: "Jose Maria Zeledon",
              },
              10: {
                codigo: 10,
                barrio: "Laguna",
              },
              11: {
                codigo: 11,
                barrio: "La Lia",
              },
              12: {
                codigo: 12,
                barrio: "Mallorca",
              },
              13: {
                codigo: 13,
                barrio: "Maria Auxiliadora",
              },
              14: {
                codigo: 14,
                barrio: "Miramontes",
              },
              15: {
                codigo: 15,
                barrio: "Nopalera",
              },
              16: {
                codigo: 16,
                barrio: "Plaza del Sol",
              },
              17: {
                codigo: 17,
                barrio: "Prado",
              },
              18: {
                codigo: 18,
                barrio: "San Jose",
              },
              19: {
                codigo: 19,
                barrio: "Santa Cecilia",
              },
              20: {
                codigo: 20,
                barrio: "Tacaco",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "Granadilla",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Biarquiria",
              },
              2: {
                codigo: 2,
                barrio: "Eucalipto",
              },
              3: {
                codigo: 3,
                barrio: "Freses",
              },
              4: {
                codigo: 4,
                barrio: "Granadilla Norte",
              },
              5: {
                codigo: 5,
                barrio: "Granadilla Sur",
              },
              6: {
                codigo: 6,
                barrio: "Montana Rusa (parte)",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "Sanchez",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Araucauria (parte)",
              },
              2: {
                codigo: 2,
                barrio: "Lomas de Ayarco",
              },
              3: {
                codigo: 3,
                barrio: "Pinares",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "Tirrases",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Colina",
              },
              2: {
                codigo: 2,
                barrio: "Lomas de San Pancracio",
              },
              3: {
                codigo: 3,
                barrio: "Ponderosa",
              },
              4: {
                codigo: 4,
                barrio: "Quince de Agosto",
              },
            },
          },
        },
      },
      19: {
        codigo: 19,
        canton: "Perez Zeledon",
        distritos: {
          1: {
            codigo: 1,
            distrito: "San Isidro de El General",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Aeropuerto",
              },
              2: {
                codigo: 2,
                barrio: "Alto Alonso",
              },
              3: {
                codigo: 3,
                barrio: "Boruca",
              },
              4: {
                codigo: 4,
                barrio: "Boston",
              },
              5: {
                codigo: 5,
                barrio: "Cementerio",
              },
              6: {
                codigo: 6,
                barrio: "Cooperativa",
              },
              7: {
                codigo: 7,
                barrio: "Cristo Rey",
              },
              8: {
                codigo: 8,
                barrio: "Doce de Marzo",
              },
              9: {
                codigo: 9,
                barrio: "Dorotea",
              },
              10: {
                codigo: 10,
                barrio: "Duran Picado",
              },
              11: {
                codigo: 11,
                barrio: "Espana",
              },
              12: {
                codigo: 12,
                barrio: "Estadio",
              },
              13: {
                codigo: 13,
                barrio: "Evans Gordon Wilson",
              },
              14: {
                codigo: 14,
                barrio: "Gonzalez",
              },
              15: {
                codigo: 15,
                barrio: "Hospital",
              },
              16: {
                codigo: 16,
                barrio: "Hoyon",
              },
              17: {
                codigo: 17,
                barrio: "I Griega",
              },
              18: {
                codigo: 18,
                barrio: "Las Americas",
              },
              19: {
                codigo: 19,
                barrio: "Lomas de Cocori",
              },
              20: {
                codigo: 20,
                barrio: "Luis Monge",
              },
              21: {
                codigo: 21,
                barrio: "Morazan",
              },
              22: {
                codigo: 22,
                barrio: "Pavones",
              },
              23: {
                codigo: 23,
                barrio: "Pedregoso",
              },
              24: {
                codigo: 24,
                barrio: "Pocito",
              },
              25: {
                codigo: 25,
                barrio: "Prado",
              },
              26: {
                codigo: 26,
                barrio: "Romero",
              },
              27: {
                codigo: 27,
                barrio: "Sagrada Familia",
              },
              28: {
                codigo: 28,
                barrio: "San Andres",
              },
              29: {
                codigo: 29,
                barrio: "San Luis",
              },
              30: {
                codigo: 30,
                barrio: "San Rafael Sur",
              },
              31: {
                codigo: 31,
                barrio: "San Vicente",
              },
              32: {
                codigo: 32,
                barrio: "Santa Cecilia",
              },
              33: {
                codigo: 33,
                barrio: "Sinai",
              },
              34: {
                codigo: 34,
                barrio: "Tierra Prometida",
              },
              35: {
                codigo: 35,
                barrio: "Tormenta",
              },
              36: {
                codigo: 36,
                barrio: "Unesco",
              },
              37: {
                codigo: 37,
                barrio: "Valverde",
              },
              38: {
                codigo: 38,
                barrio: "Alto Ceibo",
              },
              39: {
                codigo: 39,
                barrio: "Alto Huacas",
              },
              40: {
                codigo: 40,
                barrio: "Alto Sajaral",
              },
              41: {
                codigo: 41,
                barrio: "Alto San Juan",
              },
              42: {
                codigo: 42,
                barrio: "Alto Tumbas",
              },
              43: {
                codigo: 43,
                barrio: "Angostura",
              },
              44: {
                codigo: 44,
                barrio: "Bajo Ceibo",
              },
              45: {
                codigo: 45,
                barrio: "Bajo Esperanzas",
              },
              46: {
                codigo: 46,
                barrio: "Bajo Mora",
              },
              47: {
                codigo: 47,
                barrio: "Bijaguales",
              },
              48: {
                codigo: 48,
                barrio: "Bocana",
              },
              49: {
                codigo: 49,
                barrio: "Bonita",
              },
              50: {
                codigo: 50,
                barrio: "Ceibo",
              },
              51: {
                codigo: 51,
                barrio: "Ceniza",
              },
              52: {
                codigo: 52,
                barrio: "Dorado",
              },
              53: {
                codigo: 53,
                barrio: "Esperanzas",
              },
              54: {
                codigo: 54,
                barrio: "Guadalupe",
              },
              55: {
                codigo: 55,
                barrio: "Guaria",
              },
              56: {
                codigo: 56,
                barrio: "Higuerones",
              },
              57: {
                codigo: 57,
                barrio: "Jilguero",
              },
              58: {
                codigo: 58,
                barrio: "Jilguero Sur",
              },
              59: {
                codigo: 59,
                barrio: "Los Guayabos",
              },
              60: {
                codigo: 60,
                barrio: "Maria Auxiliadora",
              },
              61: {
                codigo: 61,
                barrio: "Miravalles",
              },
              62: {
                codigo: 62,
                barrio: "Morete",
              },
              63: {
                codigo: 63,
                barrio: "Ojo de Agua",
              },
              64: {
                codigo: 64,
                barrio: "Ocho de Diciembre",
              },
              65: {
                codigo: 65,
                barrio: "Pacuarito",
              },
              66: {
                codigo: 66,
                barrio: "Palma",
              },
              67: {
                codigo: 67,
                barrio: "Paso Beita",
              },
              68: {
                codigo: 68,
                barrio: "Paso Lagarto",
              },
              69: {
                codigo: 69,
                barrio: "Quebrada Honda (parte)",
              },
              70: {
                codigo: 70,
                barrio: "Quebrada Vueltas",
              },
              71: {
                codigo: 71,
                barrio: "Quebradas",
              },
              72: {
                codigo: 72,
                barrio: "Roble",
              },
              73: {
                codigo: 73,
                barrio: "Rosario",
              },
              74: {
                codigo: 74,
                barrio: "San Agustin",
              },
              75: {
                codigo: 75,
                barrio: "San Jorge",
              },
              76: {
                codigo: 76,
                barrio: "San Juan de Miramar",
              },
              77: {
                codigo: 77,
                barrio: "San Lorenzo",
              },
              78: {
                codigo: 78,
                barrio: "San Rafael Norte",
              },
              79: {
                codigo: 79,
                barrio: "Santa Fe",
              },
              80: {
                codigo: 80,
                barrio: "Santa Marta",
              },
              81: {
                codigo: 81,
                barrio: "Suiza (parte)",
              },
              82: {
                codigo: 82,
                barrio: "Tajo",
              },
              83: {
                codigo: 83,
                barrio: "Toledo",
              },
              84: {
                codigo: 84,
                barrio: "Tronconales",
              },
              85: {
                codigo: 85,
                barrio: "Tuis",
              },
              86: {
                codigo: 86,
                barrio: "Villanueva",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "General",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Arepa",
              },
              2: {
                codigo: 2,
                barrio: "Carmen",
              },
              3: {
                codigo: 3,
                barrio: "Chanchos",
              },
              4: {
                codigo: 4,
                barrio: "Hermosa",
              },
              5: {
                codigo: 5,
                barrio: "Linda",
              },
              6: {
                codigo: 6,
                barrio: "Linda Arriba",
              },
              7: {
                codigo: 7,
                barrio: "Miraflores",
              },
              8: {
                codigo: 8,
                barrio: "Paraiso de la Tierra",
              },
              9: {
                codigo: 9,
                barrio: "Penas Blancas",
              },
              10: {
                codigo: 10,
                barrio: "Quizarra (parte)",
              },
              11: {
                codigo: 11,
                barrio: "San Blas",
              },
              12: {
                codigo: 12,
                barrio: "San Luis",
              },
              13: {
                codigo: 13,
                barrio: "Santa Cruz",
              },
              14: {
                codigo: 14,
                barrio: "Santa Elena",
              },
              15: {
                codigo: 15,
                barrio: "Trinidad",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "Daniel Flores",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto Brisas",
              },
              2: {
                codigo: 2,
                barrio: "Angeles",
              },
              3: {
                codigo: 3,
                barrio: "Aurora",
              },
              4: {
                codigo: 4,
                barrio: "Chiles",
              },
              5: {
                codigo: 5,
                barrio: "Crematorio",
              },
              6: {
                codigo: 6,
                barrio: "Daniel Flores",
              },
              7: {
                codigo: 7,
                barrio: "Laboratorio",
              },
              8: {
                codigo: 8,
                barrio: "Los Pinos",
              },
              9: {
                codigo: 9,
                barrio: "Loma Verde",
              },
              10: {
                codigo: 10,
                barrio: "Lourdes",
              },
              11: {
                codigo: 11,
                barrio: "Rosas",
              },
              12: {
                codigo: 12,
                barrio: "Rosa Iris",
              },
              13: {
                codigo: 13,
                barrio: "San Francisco",
              },
              14: {
                codigo: 14,
                barrio: "Santa Margarita",
              },
              15: {
                codigo: 15,
                barrio: "Trocha",
              },
              16: {
                codigo: 16,
                barrio: "Villa Ligia",
              },
              17: {
                codigo: 17,
                barrio: "Aguas Buenas (parte)",
              },
              18: {
                codigo: 18,
                barrio: "Bajos de Pacuar",
              },
              19: {
                codigo: 19,
                barrio: "Concepcion (parte)",
              },
              20: {
                codigo: 20,
                barrio: "Corazon de Jesus",
              },
              21: {
                codigo: 21,
                barrio: "Juntas de Pacuar",
              },
              22: {
                codigo: 22,
                barrio: "Paso Bote",
              },
              23: {
                codigo: 23,
                barrio: "Patio de Agua",
              },
              24: {
                codigo: 24,
                barrio: "Peje",
              },
              25: {
                codigo: 25,
                barrio: "Percal",
              },
              26: {
                codigo: 26,
                barrio: "Pinar del Rio",
              },
              27: {
                codigo: 27,
                barrio: "Quebrada Honda (parte)",
              },
              28: {
                codigo: 28,
                barrio: "Repunta",
              },
              29: {
                codigo: 29,
                barrio: "Reyes",
              },
              30: {
                codigo: 30,
                barrio: "Ribera",
              },
              31: {
                codigo: 31,
                barrio: "Suiza (parte)",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "Rivas",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Linda Vista",
              },
              2: {
                codigo: 2,
                barrio: "Lourdes",
              },
              3: {
                codigo: 3,
                barrio: "Alaska",
              },
              4: {
                codigo: 4,
                barrio: "Altamira",
              },
              5: {
                codigo: 5,
                barrio: "Alto Jaular",
              },
              6: {
                codigo: 6,
                barrio: "Angeles",
              },
              7: {
                codigo: 7,
                barrio: "Boquete",
              },
              8: {
                codigo: 8,
                barrio: "Buenavista",
              },
              9: {
                codigo: 9,
                barrio: "Canaan",
              },
              10: {
                codigo: 10,
                barrio: "Chimirol",
              },
              11: {
                codigo: 11,
                barrio: "Chispa",
              },
              12: {
                codigo: 12,
                barrio: "Chuma",
              },
              13: {
                codigo: 13,
                barrio: "Division (parte)",
              },
              14: {
                codigo: 14,
                barrio: "Guadalupe",
              },
              15: {
                codigo: 15,
                barrio: "Herradura",
              },
              16: {
                codigo: 16,
                barrio: "La Bambu",
              },
              17: {
                codigo: 17,
                barrio: "Los Monges",
              },
              18: {
                codigo: 18,
                barrio: "Monterrey",
              },
              19: {
                codigo: 19,
                barrio: "Palmital",
              },
              20: {
                codigo: 20,
                barrio: "Piedra Alta",
              },
              21: {
                codigo: 21,
                barrio: "Playa Quesada",
              },
              22: {
                codigo: 22,
                barrio: "Playas",
              },
              23: {
                codigo: 23,
                barrio: "Pueblo Nuevo",
              },
              24: {
                codigo: 24,
                barrio: "Rio Blanco",
              },
              25: {
                codigo: 25,
                barrio: "San Antonio",
              },
              26: {
                codigo: 26,
                barrio: "San Gerardo",
              },
              27: {
                codigo: 27,
                barrio: "San Jose",
              },
              28: {
                codigo: 28,
                barrio: "San Juan Norte",
              },
              29: {
                codigo: 29,
                barrio: "Siberia (parte)",
              },
              30: {
                codigo: 30,
                barrio: "Tirra",
              },
              31: {
                codigo: 31,
                barrio: "Zapotal",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "San Pedro",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Cruz Roja",
              },
              2: {
                codigo: 2,
                barrio: "Arenilla (Junta)",
              },
              3: {
                codigo: 3,
                barrio: "Alto Calderon",
              },
              4: {
                codigo: 4,
                barrio: "Cedral (parte)",
              },
              5: {
                codigo: 5,
                barrio: "Fatima",
              },
              6: {
                codigo: 6,
                barrio: "Fortuna",
              },
              7: {
                codigo: 7,
                barrio: "Guaria",
              },
              8: {
                codigo: 8,
                barrio: "Laguna",
              },
              9: {
                codigo: 9,
                barrio: "Rinconada Vega",
              },
              10: {
                codigo: 10,
                barrio: "San Jeronimo",
              },
              11: {
                codigo: 11,
                barrio: "San Juan",
              },
              12: {
                codigo: 12,
                barrio: "San Juancito",
              },
              13: {
                codigo: 13,
                barrio: "San Rafael",
              },
              14: {
                codigo: 14,
                barrio: "Santa Ana",
              },
              15: {
                codigo: 15,
                barrio: "Santo Domingo",
              },
              16: {
                codigo: 16,
                barrio: "Tambor",
              },
              17: {
                codigo: 17,
                barrio: "Union",
              },
              18: {
                codigo: 18,
                barrio: "Zapotal",
              },
            },
          },
          6: {
            codigo: 6,
            distrito: "Platanares",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Aguas Buenas (parte)",
              },
              2: {
                codigo: 2,
                barrio: "Bajo Bonitas",
              },
              3: {
                codigo: 3,
                barrio: "Bajo Espinoza",
              },
              4: {
                codigo: 4,
                barrio: "Bolivia",
              },
              5: {
                codigo: 5,
                barrio: "Bonitas",
              },
              6: {
                codigo: 6,
                barrio: "Buenos Aires",
              },
              7: {
                codigo: 7,
                barrio: "Concepcion (parte)",
              },
              8: {
                codigo: 8,
                barrio: "Cristo Rey",
              },
              9: {
                codigo: 9,
                barrio: "La Sierra",
              },
              10: {
                codigo: 10,
                barrio: "Lourdes",
              },
              11: {
                codigo: 11,
                barrio: "Mastatal",
              },
              12: {
                codigo: 12,
                barrio: "Mollejoncito",
              },
              13: {
                codigo: 13,
                barrio: "Mollejones",
              },
              14: {
                codigo: 14,
                barrio: "Naranjos",
              },
              15: {
                codigo: 15,
                barrio: "Oratorio",
              },
              16: {
                codigo: 16,
                barrio: "San Carlos",
              },
              17: {
                codigo: 17,
                barrio: "San Pablito",
              },
              18: {
                codigo: 18,
                barrio: "San Pablo",
              },
              19: {
                codigo: 19,
                barrio: "San Roque (parte)",
              },
              20: {
                codigo: 20,
                barrio: "Socorro",
              },
              21: {
                codigo: 21,
                barrio: "Surtubal (parte)",
              },
              22: {
                codigo: 22,
                barrio: "Villa Argentina",
              },
              23: {
                codigo: 23,
                barrio: "Villa Flor",
              },
              24: {
                codigo: 24,
                barrio: "Vista de Mar",
              },
            },
          },
          7: {
            codigo: 7,
            distrito: "Pejibaye",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Achiotal",
              },
              2: {
                codigo: 2,
                barrio: "Aguila",
              },
              3: {
                codigo: 3,
                barrio: "Alto Trinidad (Punal)",
              },
              4: {
                codigo: 4,
                barrio: "Bajo Caliente",
              },
              5: {
                codigo: 5,
                barrio: "Bajo Minas",
              },
              6: {
                codigo: 6,
                barrio: "Barrionuevo",
              },
              7: {
                codigo: 7,
                barrio: "Bellavista",
              },
              8: {
                codigo: 8,
                barrio: "Calientillo",
              },
              9: {
                codigo: 9,
                barrio: "Corralillo",
              },
              10: {
                codigo: 10,
                barrio: "China Kicha",
              },
              11: {
                codigo: 11,
                barrio: "Delicias",
              },
              12: {
                codigo: 12,
                barrio: "Desamparados",
              },
              13: {
                codigo: 13,
                barrio: "El Progreso",
              },
              14: {
                codigo: 14,
                barrio: "Gibre",
              },
              15: {
                codigo: 15,
                barrio: "Guadalupe",
              },
              16: {
                codigo: 16,
                barrio: "Las Cruces",
              },
              17: {
                codigo: 17,
                barrio: "Mesas",
              },
              18: {
                codigo: 18,
                barrio: "Minas",
              },
              19: {
                codigo: 19,
                barrio: "Montezuma",
              },
              20: {
                codigo: 20,
                barrio: "Paraiso",
              },
              21: {
                codigo: 21,
                barrio: "San Antonio",
              },
              22: {
                codigo: 22,
                barrio: "San Gabriel",
              },
              23: {
                codigo: 23,
                barrio: "San Marcos",
              },
              24: {
                codigo: 24,
                barrio: "San Martin",
              },
              25: {
                codigo: 25,
                barrio: "San Miguel",
              },
              26: {
                codigo: 26,
                barrio: "San Roque (parte)",
              },
              27: {
                codigo: 27,
                barrio: "Santa Cecilia",
              },
              28: {
                codigo: 28,
                barrio: "Santa Fe",
              },
              29: {
                codigo: 29,
                barrio: "Santa Luisa",
              },
              30: {
                codigo: 30,
                barrio: "Surtubal (parte)",
              },
              31: {
                codigo: 31,
                barrio: "Trinidad",
              },
              32: {
                codigo: 32,
                barrio: "Veracruz",
              },
              33: {
                codigo: 33,
                barrio: "Zapote",
              },
            },
          },
          8: {
            codigo: 8,
            distrito: "Cajon",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Cedral (parte)",
              },
              2: {
                codigo: 2,
                barrio: "El Quemado",
              },
              3: {
                codigo: 3,
                barrio: "Gloria",
              },
              4: {
                codigo: 4,
                barrio: "Las Brisas",
              },
              5: {
                codigo: 5,
                barrio: "Las Vegas",
              },
              6: {
                codigo: 6,
                barrio: "Mercedes",
              },
              7: {
                codigo: 7,
                barrio: "Montecarlo",
              },
              8: {
                codigo: 8,
                barrio: "Navajuelar",
              },
              9: {
                codigo: 9,
                barrio: "Nubes",
              },
              10: {
                codigo: 10,
                barrio: "Paraiso",
              },
              11: {
                codigo: 11,
                barrio: "Pilar",
              },
              12: {
                codigo: 12,
                barrio: "Pueblo Nuevo",
              },
              13: {
                codigo: 13,
                barrio: "Quizarra (parte)",
              },
              14: {
                codigo: 14,
                barrio: "Salitrales",
              },
              15: {
                codigo: 15,
                barrio: "San Francisco",
              },
              16: {
                codigo: 16,
                barrio: "San Ignacio",
              },
              17: {
                codigo: 17,
                barrio: "San Pedrito",
              },
              18: {
                codigo: 18,
                barrio: "Santa Maria",
              },
              19: {
                codigo: 19,
                barrio: "Santa Teresa",
              },
            },
          },
          9: {
            codigo: 9,
            distrito: "Baru",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alfombra",
              },
              2: {
                codigo: 2,
                barrio: "Alto Perla",
              },
              3: {
                codigo: 3,
                barrio: "Bajos",
              },
              4: {
                codigo: 4,
                barrio: "Bajos de Zapotal",
              },
              5: {
                codigo: 5,
                barrio: "Baru",
              },
              6: {
                codigo: 6,
                barrio: "Barucito",
              },
              7: {
                codigo: 7,
                barrio: "Cacao",
              },
              8: {
                codigo: 8,
                barrio: "Camarones",
              },
              9: {
                codigo: 9,
                barrio: "Canablanca",
              },
              10: {
                codigo: 10,
                barrio: "Ceiba",
              },
              11: {
                codigo: 11,
                barrio: "Chontales",
              },
              12: {
                codigo: 12,
                barrio: "Farallas",
              },
              13: {
                codigo: 13,
                barrio: "Florida",
              },
              14: {
                codigo: 14,
                barrio: "San Juan de Dios (Guabo)",
              },
              15: {
                codigo: 15,
                barrio: "Libano",
              },
              16: {
                codigo: 16,
                barrio: "Magnolia",
              },
              17: {
                codigo: 17,
                barrio: "Pozos",
              },
              18: {
                codigo: 18,
                barrio: "Reina",
              },
              19: {
                codigo: 19,
                barrio: "San Marcos",
              },
              20: {
                codigo: 20,
                barrio: "San Salvador",
              },
              21: {
                codigo: 21,
                barrio: "Santa Juana",
              },
              22: {
                codigo: 22,
                barrio: "Santo Cristo",
              },
              23: {
                codigo: 23,
                barrio: "Tinamaste (San Cristobal)",
              },
              24: {
                codigo: 24,
                barrio: "Torito",
              },
              25: {
                codigo: 25,
                barrio: "Tres Piedras (parte)",
              },
              26: {
                codigo: 26,
                barrio: "Tumbas",
              },
              27: {
                codigo: 27,
                barrio: "Villabonita Vista Mar",
              },
            },
          },
          10: {
            codigo: 10,
            distrito: "Rio Nuevo",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto Mena",
              },
              2: {
                codigo: 2,
                barrio: "Brujo",
              },
              3: {
                codigo: 3,
                barrio: "Calle Los Mora",
              },
              4: {
                codigo: 4,
                barrio: "Chiricano",
              },
              5: {
                codigo: 5,
                barrio: "Gloria",
              },
              6: {
                codigo: 6,
                barrio: "Loma Guacal",
              },
              7: {
                codigo: 7,
                barrio: "Llano",
              },
              8: {
                codigo: 8,
                barrio: "Paraiso",
              },
              9: {
                codigo: 9,
                barrio: "Providencia (Parte)",
              },
              10: {
                codigo: 10,
                barrio: "Purruja",
              },
              11: {
                codigo: 11,
                barrio: "Rio Nuevo",
              },
              12: {
                codigo: 12,
                barrio: "San Antonio",
              },
              13: {
                codigo: 13,
                barrio: "Santa Marta",
              },
              14: {
                codigo: 14,
                barrio: "Savegre Abajo",
              },
              15: {
                codigo: 15,
                barrio: "Viento Fresco",
              },
            },
          },
          11: {
            codigo: 11,
            distrito: "Paramo",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto Macho Mora",
              },
              2: {
                codigo: 2,
                barrio: "Angeles",
              },
              3: {
                codigo: 3,
                barrio: "Berlin",
              },
              4: {
                codigo: 4,
                barrio: "Chanchera",
              },
              5: {
                codigo: 5,
                barrio: "Division (parte)",
              },
              6: {
                codigo: 6,
                barrio: "Fortuna",
              },
              7: {
                codigo: 7,
                barrio: "Hortensia",
              },
              8: {
                codigo: 8,
                barrio: "La Ese",
              },
              9: {
                codigo: 9,
                barrio: "La Piedra",
              },
              10: {
                codigo: 10,
                barrio: "Lira",
              },
              11: {
                codigo: 11,
                barrio: "Matasanos",
              },
              12: {
                codigo: 12,
                barrio: "Palma",
              },
              13: {
                codigo: 13,
                barrio: "Pedregosito",
              },
              14: {
                codigo: 14,
                barrio: "Providencia (Parte)",
              },
              15: {
                codigo: 15,
                barrio: "San Ramon Norte",
              },
              16: {
                codigo: 16,
                barrio: "Santa Eduvigis",
              },
              17: {
                codigo: 17,
                barrio: "Santo Tomas",
              },
              18: {
                codigo: 18,
                barrio: "Siberia (parte)",
              },
              19: {
                codigo: 19,
                barrio: "Valencia",
              },
            },
          },
          12: {
            codigo: 12,
            distrito: "La Amistad",
            barrios: {},
          },
        },
      },
      20: {
        codigo: 20,
        canton: "Leon Cortes",
        distritos: {
          1: {
            codigo: 1,
            distrito: "San Pablo",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Estadio",
              },
              2: {
                codigo: 2,
                barrio: "La Clara",
              },
              3: {
                codigo: 3,
                barrio: "La Virgen",
              },
              4: {
                codigo: 4,
                barrio: "Los Angeles",
              },
              5: {
                codigo: 5,
                barrio: "Sagrada Familia",
              },
              6: {
                codigo: 6,
                barrio: "Abejonal",
              },
              7: {
                codigo: 7,
                barrio: "Carrizales",
              },
              8: {
                codigo: 8,
                barrio: "Los Navarro",
              },
              9: {
                codigo: 9,
                barrio: "Montes de Oro",
              },
              10: {
                codigo: 10,
                barrio: "Rosario",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "San Andres",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Angostura (parte)",
              },
              2: {
                codigo: 2,
                barrio: "Bajo Gamboa",
              },
              3: {
                codigo: 3,
                barrio: "Higueron",
              },
              4: {
                codigo: 4,
                barrio: "Llano Grande",
              },
              5: {
                codigo: 5,
                barrio: "Ojo de Agua (parte)",
              },
              6: {
                codigo: 6,
                barrio: "Rastrojales",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "Llano Bonito",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bajo Mora",
              },
              2: {
                codigo: 2,
                barrio: "Bajo Venegas (parte)",
              },
              3: {
                codigo: 3,
                barrio: "Concepcion",
              },
              4: {
                codigo: 4,
                barrio: "San Francisco",
              },
              5: {
                codigo: 5,
                barrio: "San Luis",
              },
              6: {
                codigo: 6,
                barrio: "San Rafael Abajo",
              },
              7: {
                codigo: 7,
                barrio: "Santa Juana",
              },
              8: {
                codigo: 8,
                barrio: "Santa Rosa (parte)",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "San Isidro",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto Carrizal",
              },
              2: {
                codigo: 2,
                barrio: "Loma de la Altura",
              },
              3: {
                codigo: 3,
                barrio: "Santa Rosa (parte)",
              },
              4: {
                codigo: 4,
                barrio: "Trinidad",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "Santa Cruz",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Cedral (parte)",
              },
              2: {
                codigo: 2,
                barrio: "Lucha (parte)",
              },
              3: {
                codigo: 3,
                barrio: "San Martin",
              },
              4: {
                codigo: 4,
                barrio: "Rincon Gamboa",
              },
            },
          },
          6: {
            codigo: 6,
            distrito: "San Antonio",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Angostura (parte)",
              },
              2: {
                codigo: 2,
                barrio: "Cuesta",
              },
            },
          },
        },
      },
    },
  },
  2: {
    codigo: 2,
    provincia: "Alajuela",
    cantones: {
      1: {
        codigo: 1,
        canton: "ALAJUELA",
        distritos: {
          1: {
            codigo: 1,
            distrito: "ALAJUELA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Acequia Grande (parte)",
              },
              2: {
                codigo: 2,
                barrio: "Agonia",
              },
              3: {
                codigo: 3,
                barrio: "Arroyo",
              },
              4: {
                codigo: 4,
                barrio: "Bajo Cornizal",
              },
              5: {
                codigo: 5,
                barrio: "Brasil (parte)",
              },
              6: {
                codigo: 6,
                barrio: "Cafetal",
              },
              7: {
                codigo: 7,
                barrio: "Canoas",
              },
              8: {
                codigo: 8,
                barrio: "Carmen",
              },
              9: {
                codigo: 9,
                barrio: "Cementerio",
              },
              10: {
                codigo: 10,
                barrio: "Concepcion",
              },
              11: {
                codigo: 11,
                barrio: "Ciruelas",
              },
              12: {
                codigo: 12,
                barrio: "Corazon de Jesus",
              },
              13: {
                codigo: 13,
                barrio: "Cristo Rey",
              },
              14: {
                codigo: 14,
                barrio: "Gregorio Jose Ramirez",
              },
              15: {
                codigo: 15,
                barrio: "Guadalupe",
              },
              16: {
                codigo: 16,
                barrio: "Higuerones",
              },
              17: {
                codigo: 17,
                barrio: "Hospital",
              },
              18: {
                codigo: 18,
                barrio: "Llano",
              },
              19: {
                codigo: 19,
                barrio: "Llobet",
              },
              20: {
                codigo: 20,
                barrio: "Molinos",
              },
              21: {
                codigo: 21,
                barrio: "Montecillos (parte)",
              },
              22: {
                codigo: 22,
                barrio: "Montenegro",
              },
              23: {
                codigo: 23,
                barrio: "Monserrat (parte)",
              },
              24: {
                codigo: 24,
                barrio: "Paso Flores",
              },
              25: {
                codigo: 25,
                barrio: "Providencia",
              },
              26: {
                codigo: 26,
                barrio: "Retiro",
              },
              27: {
                codigo: 27,
                barrio: "San Luis",
              },
              28: {
                codigo: 28,
                barrio: "Tomas Guardia",
              },
              29: {
                codigo: 29,
                barrio: "Tropicana",
              },
              30: {
                codigo: 30,
                barrio: "Villabonita (parte)",
              },
              31: {
                codigo: 31,
                barrio: "Villahermosa",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "SAN JOSE",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Amistad",
              },
              2: {
                codigo: 2,
                barrio: "Botanica",
              },
              3: {
                codigo: 3,
                barrio: "Copablanca",
              },
              4: {
                codigo: 4,
                barrio: "Flores",
              },
              5: {
                codigo: 5,
                barrio: "Jardines",
              },
              6: {
                codigo: 6,
                barrio: "Jocote",
              },
              7: {
                codigo: 7,
                barrio: "Juan Santamaria",
              },
              8: {
                codigo: 8,
                barrio: "Lagunilla",
              },
              9: {
                codigo: 9,
                barrio: "Mandarina (parte)",
              },
              10: {
                codigo: 10,
                barrio: "Maravilla (parte)",
              },
              11: {
                codigo: 11,
                barrio: "Montesol (parte)",
              },
              12: {
                codigo: 12,
                barrio: "Pueblo Nuevo",
              },
              13: {
                codigo: 13,
                barrio: "San Rafael",
              },
              14: {
                codigo: 14,
                barrio: "Santa Rita",
              },
              15: {
                codigo: 15,
                barrio: "Tigra",
              },
              16: {
                codigo: 16,
                barrio: "Torre",
              },
              17: {
                codigo: 17,
                barrio: "Trinidad",
              },
              18: {
                codigo: 18,
                barrio: "Tuetal Sur",
              },
              19: {
                codigo: 19,
                barrio: "Coyol",
              },
              20: {
                codigo: 20,
                barrio: "Flores",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "CARRIZAL",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto Pavas",
              },
              2: {
                codigo: 2,
                barrio: "Bambu",
              },
              3: {
                codigo: 3,
                barrio: "Cinco Esquinas",
              },
              4: {
                codigo: 4,
                barrio: "Concordia",
              },
              5: {
                codigo: 5,
                barrio: "Domingas",
              },
              6: {
                codigo: 6,
                barrio: "El Plan",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "SAN ANTONIO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Acequia Grande (parte)",
              },
              2: {
                codigo: 2,
                barrio: "Bajo Monge",
              },
              3: {
                codigo: 3,
                barrio: "Canada",
              },
              4: {
                codigo: 4,
                barrio: "Montecillos (parte)",
              },
              5: {
                codigo: 5,
                barrio: "Monserrat (parte)",
              },
              6: {
                codigo: 6,
                barrio: "Puente Arena (parte)",
              },
              7: {
                codigo: 7,
                barrio: "Tejar",
              },
              8: {
                codigo: 8,
                barrio: "Vegas",
              },
              9: {
                codigo: 9,
                barrio: "Villabonita (parte)",
              },
              10: {
                codigo: 10,
                barrio: "Ciruelas",
              },
              11: {
                codigo: 11,
                barrio: "Roble",
              },
              12: {
                codigo: 12,
                barrio: "Sanchez",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "GUACIMA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bajo Tejar",
              },
              2: {
                codigo: 2,
                barrio: "Cacao",
              },
              3: {
                codigo: 3,
                barrio: "Canada",
              },
              4: {
                codigo: 4,
                barrio: "Coco",
              },
              5: {
                codigo: 5,
                barrio: "El Bajo",
              },
              6: {
                codigo: 6,
                barrio: "Hacienda Los Reyes",
              },
              7: {
                codigo: 7,
                barrio: "Nuestro Amo",
              },
              8: {
                codigo: 8,
                barrio: "Rincon Chiquito",
              },
              9: {
                codigo: 9,
                barrio: "Rincon de Herrera",
              },
              10: {
                codigo: 10,
                barrio: "Rincon de Monge",
              },
              11: {
                codigo: 11,
                barrio: "Ventanas",
              },
              12: {
                codigo: 12,
                barrio: "Vueltas",
              },
            },
          },
          6: {
            codigo: 6,
            distrito: "SAN ISIDRO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Aguilar",
              },
              2: {
                codigo: 2,
                barrio: "Ceiba",
              },
              3: {
                codigo: 3,
                barrio: "San Martin",
              },
              4: {
                codigo: 4,
                barrio: "Alto Pilas",
              },
              5: {
                codigo: 5,
                barrio: "Burios",
              },
              6: {
                codigo: 6,
                barrio: "Carbonal",
              },
              7: {
                codigo: 7,
                barrio: "Cerrillal",
              },
              8: {
                codigo: 8,
                barrio: "Dulce Nombre",
              },
              9: {
                codigo: 9,
                barrio: "Espino (parte)",
              },
              10: {
                codigo: 10,
                barrio: "Itiquis",
              },
              11: {
                codigo: 11,
                barrio: "Laguna",
              },
              12: {
                codigo: 12,
                barrio: "Loria",
              },
              13: {
                codigo: 13,
                barrio: "Maravilla (parte)",
              },
              14: {
                codigo: 14,
                barrio: "Montana",
              },
              15: {
                codigo: 15,
                barrio: "Pilas",
              },
              16: {
                codigo: 16,
                barrio: "Potrerillos",
              },
              17: {
                codigo: 17,
                barrio: "San Jeronimo",
              },
              18: {
                codigo: 18,
                barrio: "San Rafael",
              },
              19: {
                codigo: 19,
                barrio: "Tacacori",
              },
              20: {
                codigo: 20,
                barrio: "Tuetal Norte (parte)",
              },
              21: {
                codigo: 21,
                barrio: "Villas de la Ceiba",
              },
            },
          },
          7: {
            codigo: 7,
            distrito: "SABANILLA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto del Desengano",
              },
              2: {
                codigo: 2,
                barrio: "Angeles",
              },
              3: {
                codigo: 3,
                barrio: "Bajo Santa Barbara",
              },
              4: {
                codigo: 4,
                barrio: "Cerro",
              },
              5: {
                codigo: 5,
                barrio: "Doka",
              },
              6: {
                codigo: 6,
                barrio: "Espino (parte)",
              },
              7: {
                codigo: 7,
                barrio: "Fraijanes",
              },
              8: {
                codigo: 8,
                barrio: "Lajas",
              },
              9: {
                codigo: 9,
                barrio: "Poasito",
              },
              10: {
                codigo: 10,
                barrio: "San Luis",
              },
              11: {
                codigo: 11,
                barrio: "San Rafael",
              },
              12: {
                codigo: 12,
                barrio: "Vargas (parte)",
              },
            },
          },
          8: {
            codigo: 8,
            distrito: "SAN RAFAEL",
            barrios: {
              1: {
                codigo: 1,
                barrio: "La Paz",
              },
              2: {
                codigo: 2,
                barrio: "Perla",
              },
              3: {
                codigo: 3,
                barrio: "Canada",
              },
              4: {
                codigo: 4,
                barrio: "Ojo de Agua",
              },
              5: {
                codigo: 5,
                barrio: "Paires",
              },
              6: {
                codigo: 6,
                barrio: "Potrerillos",
              },
              7: {
                codigo: 7,
                barrio: "Rincon Venegas",
              },
            },
          },
          9: {
            codigo: 9,
            distrito: "RIO SEGUNDO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Angeles",
              },
              2: {
                codigo: 2,
                barrio: "Bajo Canas (parte)",
              },
              3: {
                codigo: 3,
                barrio: "Bajo La Sorda",
              },
              4: {
                codigo: 4,
                barrio: "Cacique",
              },
              5: {
                codigo: 5,
                barrio: "California",
              },
              6: {
                codigo: 6,
                barrio: "Canas (parte)",
              },
              7: {
                codigo: 7,
                barrio: "Guayabo",
              },
              8: {
                codigo: 8,
                barrio: "Monserrat (parte)",
              },
              9: {
                codigo: 9,
                barrio: "Puente Arena (parte)",
              },
              10: {
                codigo: 10,
                barrio: "Villalobos",
              },
              11: {
                codigo: 11,
                barrio: "Viquez",
              },
            },
          },
          10: {
            codigo: 10,
            distrito: "DESAMPARADOS",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bajo Canas (parte)",
              },
              2: {
                codigo: 2,
                barrio: "Bellavista",
              },
              3: {
                codigo: 3,
                barrio: "Brasil (parte)",
              },
              4: {
                codigo: 4,
                barrio: "Calicanto",
              },
              5: {
                codigo: 5,
                barrio: "Canas (parte)",
              },
              6: {
                codigo: 6,
                barrio: "Erizo",
              },
              7: {
                codigo: 7,
                barrio: "Mojon",
              },
              8: {
                codigo: 8,
                barrio: "Pasito",
              },
              9: {
                codigo: 9,
                barrio: "Rincon",
              },
              10: {
                codigo: 10,
                barrio: "Rosales (parte)",
              },
              11: {
                codigo: 11,
                barrio: "Targuases",
              },
              12: {
                codigo: 12,
                barrio: "Tres Piedras",
              },
            },
          },
          11: {
            codigo: 11,
            distrito: "TURRUCARES",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bajo Pita",
              },
              2: {
                codigo: 2,
                barrio: "Granja",
              },
              3: {
                codigo: 3,
                barrio: "Morera",
              },
              4: {
                codigo: 4,
                barrio: "San Martin",
              },
              5: {
                codigo: 5,
                barrio: "Santa Rita",
              },
              6: {
                codigo: 6,
                barrio: "Turrucarena",
              },
              7: {
                codigo: 7,
                barrio: "Villacares",
              },
              8: {
                codigo: 8,
                barrio: "Bajo San Miguel",
              },
              9: {
                codigo: 9,
                barrio: "Candelaria",
              },
              10: {
                codigo: 10,
                barrio: "Carrera Buena",
              },
              11: {
                codigo: 11,
                barrio: "Cebadilla",
              },
              12: {
                codigo: 12,
                barrio: "Cerrillos (San Miguel)",
              },
              13: {
                codigo: 13,
                barrio: "Conejo",
              },
              14: {
                codigo: 14,
                barrio: "Juntas",
              },
              15: {
                codigo: 15,
                barrio: "Siquiares",
              },
              16: {
                codigo: 16,
                barrio: "Tamarindo",
              },
            },
          },
          12: {
            codigo: 12,
            distrito: "TAMBOR",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Cacao",
              },
              2: {
                codigo: 2,
                barrio: "Calle Liles",
              },
              3: {
                codigo: 3,
                barrio: "Gonzalez",
              },
              4: {
                codigo: 4,
                barrio: "Quebradas",
              },
              5: {
                codigo: 5,
                barrio: "Rincon Cacao",
              },
              6: {
                codigo: 6,
                barrio: "Tuetal Norte (parte)",
              },
              7: {
                codigo: 7,
                barrio: "Vargas (parte)",
              },
            },
          },
          13: {
            codigo: 13,
            distrito: "GARITA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Animas",
              },
              2: {
                codigo: 2,
                barrio: "Cuesta Colorada",
              },
              3: {
                codigo: 3,
                barrio: "Copeyal",
              },
              4: {
                codigo: 4,
                barrio: "Horcones",
              },
              5: {
                codigo: 5,
                barrio: "Lagos del Coyol",
              },
              6: {
                codigo: 6,
                barrio: "Llanos",
              },
              7: {
                codigo: 7,
                barrio: "Mandarina (parte)",
              },
              8: {
                codigo: 8,
                barrio: "Manolos",
              },
              9: {
                codigo: 9,
                barrio: "Mina",
              },
              10: {
                codigo: 10,
                barrio: "Montesol (parte)",
              },
              11: {
                codigo: 11,
                barrio: "Monticel",
              },
              12: {
                codigo: 12,
                barrio: "Saltillo",
              },
            },
          },
          14: {
            codigo: 14,
            distrito: "SAN MIGUEL DE SARAPIQUI",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bajo Latas",
              },
              2: {
                codigo: 2,
                barrio: "Cariblanco",
              },
              3: {
                codigo: 3,
                barrio: "Corazon de Jesus",
              },
              4: {
                codigo: 4,
                barrio: "Isla Bonita",
              },
              5: {
                codigo: 5,
                barrio: "Nueva Cinchona",
              },
              6: {
                codigo: 6,
                barrio: "San Antonio",
              },
              7: {
                codigo: 7,
                barrio: "Paraiso",
              },
              8: {
                codigo: 8,
                barrio: "Punta Mala",
              },
              9: {
                codigo: 9,
                barrio: "Ujarras",
              },
              10: {
                codigo: 10,
                barrio: "Virgen del Socorro (parte)",
              },
            },
          },
        },
      },
      2: {
        codigo: 2,
        canton: "SAN RAMON",
        distritos: {
          1: {
            codigo: 1,
            distrito: "SAN RAMON",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bajo Ladrillera",
              },
              2: {
                codigo: 2,
                barrio: "Cachera",
              },
              3: {
                codigo: 3,
                barrio: "Lisimaco Chavarria",
              },
              4: {
                codigo: 4,
                barrio: "Sabana (parte)",
              },
              5: {
                codigo: 5,
                barrio: "San Jose",
              },
              6: {
                codigo: 6,
                barrio: "Tremedal (parte)",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "SANTIAGO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Arias",
              },
              2: {
                codigo: 2,
                barrio: "Montserrat",
              },
              3: {
                codigo: 3,
                barrio: "Alto Santiago",
              },
              4: {
                codigo: 4,
                barrio: "Alto Salas",
              },
              5: {
                codigo: 5,
                barrio: "Angostura",
              },
              6: {
                codigo: 6,
                barrio: "Balboa",
              },
              7: {
                codigo: 7,
                barrio: "Cambronero",
              },
              8: {
                codigo: 8,
                barrio: "Constancia",
              },
              9: {
                codigo: 9,
                barrio: "Cuesta del Toro",
              },
              10: {
                codigo: 10,
                barrio: "Empalme",
              },
              11: {
                codigo: 11,
                barrio: "La Ese",
              },
              12: {
                codigo: 12,
                barrio: "Leon",
              },
              13: {
                codigo: 13,
                barrio: "Magallanes",
              },
              14: {
                codigo: 14,
                barrio: "Moncada",
              },
              15: {
                codigo: 15,
                barrio: "Rio Jesus",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "SAN JUAN",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Americana",
              },
              2: {
                codigo: 2,
                barrio: "Bajo Tajos",
              },
              3: {
                codigo: 3,
                barrio: "Belen",
              },
              4: {
                codigo: 4,
                barrio: "Cipreses",
              },
              5: {
                codigo: 5,
                barrio: "Lirios",
              },
              6: {
                codigo: 6,
                barrio: "Llamaron",
              },
              7: {
                codigo: 7,
                barrio: "Pueblo Nuevo",
              },
              8: {
                codigo: 8,
                barrio: "Tanque",
              },
              9: {
                codigo: 9,
                barrio: "Tejar",
              },
              10: {
                codigo: 10,
                barrio: "Vicente Badilla",
              },
              11: {
                codigo: 11,
                barrio: "Juntas (parte)",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "PIEDADES NORTE",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Copan",
              },
              2: {
                codigo: 2,
                barrio: "Araya",
              },
              3: {
                codigo: 3,
                barrio: "Bajo Matamoros (parte)",
              },
              4: {
                codigo: 4,
                barrio: "Bolivar",
              },
              5: {
                codigo: 5,
                barrio: "Campos",
              },
              6: {
                codigo: 6,
                barrio: "Esperanza",
              },
              7: {
                codigo: 7,
                barrio: "La Paz",
              },
              8: {
                codigo: 8,
                barrio: "Lomas",
              },
              9: {
                codigo: 9,
                barrio: "Piedades Noroeste",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "PIEDADES SUR",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bajo Barranca",
              },
              2: {
                codigo: 2,
                barrio: "Barranca",
              },
              3: {
                codigo: 3,
                barrio: "Bureal",
              },
              4: {
                codigo: 4,
                barrio: "Carmen",
              },
              5: {
                codigo: 5,
                barrio: "Chassoul",
              },
              6: {
                codigo: 6,
                barrio: "Guaria",
              },
              7: {
                codigo: 7,
                barrio: "Nagatac",
              },
              8: {
                codigo: 8,
                barrio: "Palma",
              },
              9: {
                codigo: 9,
                barrio: "Potrerillos",
              },
              10: {
                codigo: 10,
                barrio: "Quebradillas",
              },
              11: {
                codigo: 11,
                barrio: "Salvador",
              },
              12: {
                codigo: 12,
                barrio: "San Bosco",
              },
              13: {
                codigo: 13,
                barrio: "San Francisco",
              },
              14: {
                codigo: 14,
                barrio: "San Miguel",
              },
              15: {
                codigo: 15,
                barrio: "Sardinal",
              },
              16: {
                codigo: 16,
                barrio: "Socorro",
              },
            },
          },
          6: {
            codigo: 6,
            distrito: "SAN RAFAEL",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Tres Marias",
              },
              2: {
                codigo: 2,
                barrio: "Union",
              },
              3: {
                codigo: 3,
                barrio: "Alto Llano",
              },
              4: {
                codigo: 4,
                barrio: "Berlin",
              },
              5: {
                codigo: 5,
                barrio: "Calera",
              },
              6: {
                codigo: 6,
                barrio: "Chavarria",
              },
              7: {
                codigo: 7,
                barrio: "Llano Brenes",
              },
              8: {
                codigo: 8,
                barrio: "Orlich",
              },
              9: {
                codigo: 9,
                barrio: "Orozco",
              },
              10: {
                codigo: 10,
                barrio: "Pata de Gallo",
              },
              11: {
                codigo: 11,
                barrio: "Rincon de Mora",
              },
              12: {
                codigo: 12,
                barrio: "Rincon Orozco",
              },
              13: {
                codigo: 13,
                barrio: "San Joaquin",
              },
              14: {
                codigo: 14,
                barrio: "Zamora",
              },
            },
          },
          7: {
            codigo: 7,
            distrito: "SAN ISIDRO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Progreso",
              },
              2: {
                codigo: 2,
                barrio: "Bajo Ramirez",
              },
              3: {
                codigo: 3,
                barrio: "Fernandez",
              },
              4: {
                codigo: 4,
                barrio: "Guaria",
              },
              5: {
                codigo: 5,
                barrio: "Varela",
              },
            },
          },
          8: {
            codigo: 8,
            distrito: "ANGELES",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Los Jardines",
              },
              2: {
                codigo: 2,
                barrio: "Ranchera",
              },
              3: {
                codigo: 3,
                barrio: "Angeles Norte",
              },
              4: {
                codigo: 4,
                barrio: "Balsa",
              },
              5: {
                codigo: 5,
                barrio: "Juntas (parte)",
              },
              6: {
                codigo: 6,
                barrio: "Silencio",
              },
              7: {
                codigo: 7,
                barrio: "Valle Azul",
              },
              8: {
                codigo: 8,
                barrio: "Zuniga",
              },
            },
          },
          9: {
            codigo: 9,
            distrito: "ALFARO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Badilla Alpizar",
              },
              2: {
                codigo: 2,
                barrio: "Sabana (parte)",
              },
              3: {
                codigo: 3,
                barrio: "Tremedal (parte)",
              },
              4: {
                codigo: 4,
                barrio: "Bajo Matamoros (parte)",
              },
              5: {
                codigo: 5,
                barrio: "Catarata",
              },
              6: {
                codigo: 6,
                barrio: "San Pedro",
              },
              7: {
                codigo: 7,
                barrio: "Valverde",
              },
            },
          },
          10: {
            codigo: 10,
            distrito: "VOLIO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto Villegas",
              },
              2: {
                codigo: 2,
                barrio: "Bajo Tejares",
              },
              3: {
                codigo: 3,
                barrio: "Dulce Nombre",
              },
              4: {
                codigo: 4,
                barrio: "Sifon",
              },
            },
          },
          11: {
            codigo: 11,
            distrito: "CONCEPCION",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Canuela",
              },
              2: {
                codigo: 2,
                barrio: "Concepcion Arriba",
              },
              3: {
                codigo: 3,
                barrio: "Chaparral",
              },
              4: {
                codigo: 4,
                barrio: "Perez",
              },
            },
          },
          12: {
            codigo: 12,
            distrito: "ZAPOTAL",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bajo Castillo",
              },
              2: {
                codigo: 2,
                barrio: "Bajos",
              },
              3: {
                codigo: 3,
                barrio: "Barranquilla",
              },
              4: {
                codigo: 4,
                barrio: "Carrera Buena",
              },
              5: {
                codigo: 5,
                barrio: "Jabonal",
              },
              6: {
                codigo: 6,
                barrio: "Jabonalito",
              },
              7: {
                codigo: 7,
                barrio: "Rincon Chaves",
              },
              8: {
                codigo: 8,
                barrio: "Victoria",
              },
              9: {
                codigo: 9,
                barrio: "Zapotal (parte)",
              },
            },
          },
          13: {
            codigo: 13,
            distrito: "SAN ISIDRO DE PENAS BLANCAS",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Abanico",
              },
              2: {
                codigo: 2,
                barrio: "Altura",
              },
              3: {
                codigo: 3,
                barrio: "Bosque",
              },
              4: {
                codigo: 4,
                barrio: "Burrito",
              },
              5: {
                codigo: 5,
                barrio: "Cairo",
              },
              6: {
                codigo: 6,
                barrio: "Castillo (parte)",
              },
              7: {
                codigo: 7,
                barrio: "Castillo Nuevo",
              },
              8: {
                codigo: 8,
                barrio: "Colonia Trinidad",
              },
              9: {
                codigo: 9,
                barrio: "Chachagua",
              },
              10: {
                codigo: 10,
                barrio: "La Cruz",
              },
              11: {
                codigo: 11,
                barrio: "Pocosol",
              },
              12: {
                codigo: 12,
                barrio: "San Carlos",
              },
              13: {
                codigo: 13,
                barrio: "San Rafael",
              },
              14: {
                codigo: 14,
                barrio: "Sector Angeles",
              },
            },
          },
          14: {
            codigo: 14,
            distrito: "SAN LORENZO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bajo Cordoba",
              },
              2: {
                codigo: 2,
                barrio: "Bajo Rodriguez",
              },
              3: {
                codigo: 3,
                barrio: "Cataratas",
              },
              4: {
                codigo: 4,
                barrio: "Colonia Palmarena",
              },
              5: {
                codigo: 5,
                barrio: "Coopezamora",
              },
              6: {
                codigo: 6,
                barrio: "Criques",
              },
              7: {
                codigo: 7,
                barrio: "Kooper",
              },
              8: {
                codigo: 8,
                barrio: "Los Lagos",
              },
              9: {
                codigo: 9,
                barrio: "Las Rocas",
              },
              10: {
                codigo: 10,
                barrio: "San Jorge",
              },
            },
          },
        },
      },
      3: {
        codigo: 3,
        canton: "GRECIA",
        distritos: {
          1: {
            codigo: 1,
            distrito: "GRECIA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Carmona",
              },
              2: {
                codigo: 2,
                barrio: "Chavarria",
              },
              3: {
                codigo: 3,
                barrio: "Colon",
              },
              4: {
                codigo: 4,
                barrio: "Jimenez",
              },
              5: {
                codigo: 5,
                barrio: "Pinos",
              },
              6: {
                codigo: 6,
                barrio: "Rincon de Arias",
              },
              7: {
                codigo: 7,
                barrio: "San Antonio",
              },
              8: {
                codigo: 8,
                barrio: "San Vicente",
              },
              9: {
                codigo: 9,
                barrio: "Celina",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "SAN ISIDRO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Barrio (Grecia)(Primavera)",
              },
              2: {
                codigo: 2,
                barrio: "Alfaro",
              },
              3: {
                codigo: 3,
                barrio: "Bajo Achiote",
              },
              4: {
                codigo: 4,
                barrio: "Camejo",
              },
              5: {
                codigo: 5,
                barrio: "Coopevictoria",
              },
              6: {
                codigo: 6,
                barrio: "Corinto",
              },
              7: {
                codigo: 7,
                barrio: "Higuerones",
              },
              8: {
                codigo: 8,
                barrio: "Meson",
              },
              9: {
                codigo: 9,
                barrio: "Mojon",
              },
              10: {
                codigo: 10,
                barrio: "Quizarrazal",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "SAN JOSE",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Arena",
              },
              2: {
                codigo: 2,
                barrio: "Cedro",
              },
              3: {
                codigo: 3,
                barrio: "Delicias (parte)",
              },
              4: {
                codigo: 4,
                barrio: "Guayabal (parte)",
              },
              5: {
                codigo: 5,
                barrio: "Loma",
              },
              6: {
                codigo: 6,
                barrio: "Rodriguez",
              },
              7: {
                codigo: 7,
                barrio: "Santa Gertrudis Norte",
              },
              8: {
                codigo: 8,
                barrio: "Santa Gertrudis Sur",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "SAN ROQUE",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Agualote",
              },
              2: {
                codigo: 2,
                barrio: "Bajo Sapera",
              },
              3: {
                codigo: 3,
                barrio: "Casillas",
              },
              4: {
                codigo: 4,
                barrio: "Latino",
              },
              5: {
                codigo: 5,
                barrio: "San Miguel Arriba",
              },
              6: {
                codigo: 6,
                barrio: "Cabuyal",
              },
              7: {
                codigo: 7,
                barrio: "Carbonal",
              },
              8: {
                codigo: 8,
                barrio: "Coyotera",
              },
              9: {
                codigo: 9,
                barrio: "San Miguel",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "TACARES",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Pinto",
              },
              2: {
                codigo: 2,
                barrio: "Bodegas",
              },
              3: {
                codigo: 3,
                barrio: "Cataluna",
              },
              4: {
                codigo: 4,
                barrio: "Cerdas",
              },
              5: {
                codigo: 5,
                barrio: "Delicias (parte)",
              },
              6: {
                codigo: 6,
                barrio: "Flores",
              },
              7: {
                codigo: 7,
                barrio: "Guayabal (parte)",
              },
              8: {
                codigo: 8,
                barrio: "Pilas",
              },
              9: {
                codigo: 9,
                barrio: "Planta",
              },
              10: {
                codigo: 10,
                barrio: "Porvenir",
              },
              11: {
                codigo: 11,
                barrio: "Yoses",
              },
            },
          },
          6: {
            codigo: 6,
            distrito: "PUENTE DE PIEDRA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "(Grecia)Poro",
              },
              2: {
                codigo: 2,
                barrio: "Sevilla",
              },
              3: {
                codigo: 3,
                barrio: "Altos de Peralta",
              },
              4: {
                codigo: 4,
                barrio: "Argentina",
              },
              5: {
                codigo: 5,
                barrio: "Bajo Cedros",
              },
              6: {
                codigo: 6,
                barrio: "Lomas",
              },
              7: {
                codigo: 7,
                barrio: "Montezuma",
              },
              8: {
                codigo: 8,
                barrio: "Puerto Escondido",
              },
              9: {
                codigo: 9,
                barrio: "Raiceros",
              },
              10: {
                codigo: 10,
                barrio: "Rincon de Salas",
              },
              11: {
                codigo: 11,
                barrio: "Rosales",
              },
            },
          },
          7: {
            codigo: 7,
            distrito: "BOLIVAR",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Cajon",
              },
              2: {
                codigo: 2,
                barrio: "Cocobolo",
              },
              3: {
                codigo: 3,
                barrio: "Murillo",
              },
              4: {
                codigo: 4,
                barrio: "San Juan",
              },
              5: {
                codigo: 5,
                barrio: "San Luis",
              },
              6: {
                codigo: 6,
                barrio: "Virgencita",
              },
            },
          },
        },
      },
      4: {
        codigo: 4,
        canton: "SAN MATEO",
        distritos: {
          1: {
            codigo: 1,
            distrito: "SAN MATEO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Agua Agria",
              },
              2: {
                codigo: 2,
                barrio: "Calera",
              },
              3: {
                codigo: 3,
                barrio: "Cenizaro",
              },
              4: {
                codigo: 4,
                barrio: "Centeno",
              },
              5: {
                codigo: 5,
                barrio: "Desamparados",
              },
              6: {
                codigo: 6,
                barrio: "Dulce Nombre",
              },
              7: {
                codigo: 7,
                barrio: "Higuito",
              },
              8: {
                codigo: 8,
                barrio: "Izarco",
              },
              9: {
                codigo: 9,
                barrio: "Maderal",
              },
              10: {
                codigo: 10,
                barrio: "Ramadas",
              },
              11: {
                codigo: 11,
                barrio: "San Juan de Dios",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "DESMONTE",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Cuesta Colorada",
              },
              2: {
                codigo: 2,
                barrio: "Libertad",
              },
              3: {
                codigo: 3,
                barrio: "Quebrada Honda",
              },
              4: {
                codigo: 4,
                barrio: "Limon",
              },
              5: {
                codigo: 5,
                barrio: "Patio de Agua Norte",
              },
              6: {
                codigo: 6,
                barrio: "Sacra Familia",
              },
              7: {
                codigo: 7,
                barrio: "San Juan Uno",
              },
              8: {
                codigo: 8,
                barrio: "Zapote",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "JESUS MARIA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Garabito",
              },
              2: {
                codigo: 2,
                barrio: "Quebrada Grande (parte)",
              },
              3: {
                codigo: 3,
                barrio: "Quinta",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "LABRADOR",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Altamira",
              },
              2: {
                codigo: 2,
                barrio: "Oricuajo",
              },
              3: {
                codigo: 3,
                barrio: "Poza Redonda",
              },
              4: {
                codigo: 4,
                barrio: "Quebrada Grande (parte)",
              },
            },
          },
        },
      },
      5: {
        codigo: 5,
        canton: "ATENAS",
        distritos: {
          1: {
            codigo: 1,
            distrito: "ATENAS",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Angeles",
              },
              2: {
                codigo: 2,
                barrio: "Atenea",
              },
              3: {
                codigo: 3,
                barrio: "Boqueron",
              },
              4: {
                codigo: 4,
                barrio: "Escorpio",
              },
              5: {
                codigo: 5,
                barrio: "Guizaro",
              },
              6: {
                codigo: 6,
                barrio: "Oasis",
              },
              7: {
                codigo: 7,
                barrio: "Olivo",
              },
              8: {
                codigo: 8,
                barrio: "Matias",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "JESUS",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Guacalillo",
              },
              2: {
                codigo: 2,
                barrio: "Sabanalarga",
              },
              3: {
                codigo: 3,
                barrio: "San Vicente",
              },
              4: {
                codigo: 4,
                barrio: "Barroeta",
              },
              5: {
                codigo: 5,
                barrio: "Boca del Monte",
              },
              6: {
                codigo: 6,
                barrio: "Cuajiniquil",
              },
              7: {
                codigo: 7,
                barrio: "Estanquillo",
              },
              8: {
                codigo: 8,
                barrio: "Pato de Agua (parte)",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "MERCEDES",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Cajon",
              },
              2: {
                codigo: 2,
                barrio: "Callao",
              },
              3: {
                codigo: 3,
                barrio: "Plancillo",
              },
              4: {
                codigo: 4,
                barrio: "Plazoleta",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "SAN ISIDRO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto Naranjo",
              },
              2: {
                codigo: 2,
                barrio: "Bajo Cacao",
              },
              3: {
                codigo: 3,
                barrio: "Morazan",
              },
              4: {
                codigo: 4,
                barrio: "Pato de Agua (parte)",
              },
              5: {
                codigo: 5,
                barrio: "Pavas",
              },
              6: {
                codigo: 6,
                barrio: "Rincon",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "CONCEPCION",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Rio Grande",
              },
              2: {
                codigo: 2,
                barrio: "Balsa",
              },
              3: {
                codigo: 3,
                barrio: "Calle Garita",
              },
              4: {
                codigo: 4,
                barrio: "Coyoles",
              },
              5: {
                codigo: 5,
                barrio: "Pan de Azucar",
              },
              6: {
                codigo: 6,
                barrio: "Tornos",
              },
            },
          },
          6: {
            codigo: 6,
            distrito: "SAN JOSE",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto Cima",
              },
              2: {
                codigo: 2,
                barrio: "Alto Lopez",
              },
              3: {
                codigo: 3,
                barrio: "Legua",
              },
              4: {
                codigo: 4,
                barrio: "San Jose Norte",
              },
              5: {
                codigo: 5,
                barrio: "Torunes",
              },
              6: {
                codigo: 6,
                barrio: "Vainilla",
              },
            },
          },
          7: {
            codigo: 7,
            distrito: "SANTA EULALIA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Rincon Rodriguez",
              },
            },
          },
          8: {
            codigo: 8,
            distrito: "ESCOBAL",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Cerrillo",
              },
              2: {
                codigo: 2,
                barrio: "Guacimos",
              },
              3: {
                codigo: 3,
                barrio: "Kilometro 51",
              },
              4: {
                codigo: 4,
                barrio: "Lapas",
              },
              5: {
                codigo: 5,
                barrio: "Mangos",
              },
              6: {
                codigo: 6,
                barrio: "Quebradas",
              },
              7: {
                codigo: 7,
                barrio: "Vuelta Herrera",
              },
            },
          },
        },
      },
      6: {
        codigo: 6,
        canton: "NARANJO",
        distritos: {
          1: {
            codigo: 1,
            distrito: "NARANJO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bajo Pilas",
              },
              2: {
                codigo: 2,
                barrio: "Belen",
              },
              3: {
                codigo: 3,
                barrio: "Cana Dura",
              },
              4: {
                codigo: 4,
                barrio: "Gradas",
              },
              5: {
                codigo: 5,
                barrio: "Maria Auxiliadora",
              },
              6: {
                codigo: 6,
                barrio: "Muro",
              },
              7: {
                codigo: 7,
                barrio: "Pueblo Nuevo",
              },
              8: {
                codigo: 8,
                barrio: "Sacramento",
              },
              9: {
                codigo: 9,
                barrio: "San Lucas (Carmen)",
              },
              10: {
                codigo: 10,
                barrio: "San Rafael",
              },
              11: {
                codigo: 11,
                barrio: "Candelaria",
              },
              12: {
                codigo: 12,
                barrio: "Cantarrana (parte)",
              },
              13: {
                codigo: 13,
                barrio: "Cinco Esquinas (parte)",
              },
              14: {
                codigo: 14,
                barrio: "Cipres",
              },
              15: {
                codigo: 15,
                barrio: "Comun",
              },
              16: {
                codigo: 16,
                barrio: "Dulce Nombre",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "SAN MIGUEL",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Planes",
              },
              2: {
                codigo: 2,
                barrio: "Bajo Seevers",
              },
              3: {
                codigo: 3,
                barrio: "Bajos",
              },
              4: {
                codigo: 4,
                barrio: "Palmas",
              },
              5: {
                codigo: 5,
                barrio: "Quesera",
              },
              6: {
                codigo: 6,
                barrio: "San Francisco",
              },
              7: {
                codigo: 7,
                barrio: "San Miguel Oeste",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "SAN JOSE",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Barranca",
              },
              2: {
                codigo: 2,
                barrio: "Canuela Arriba",
              },
              3: {
                codigo: 3,
                barrio: "Cuesta Venada",
              },
              4: {
                codigo: 4,
                barrio: "Desamparados",
              },
              5: {
                codigo: 5,
                barrio: "Isla",
              },
              6: {
                codigo: 6,
                barrio: "San Antonio de Barranca",
              },
              7: {
                codigo: 7,
                barrio: "San Pedro",
              },
              8: {
                codigo: 8,
                barrio: "Solis (parte)",
              },
              9: {
                codigo: 9,
                barrio: "Vuelta San Gerardo",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "CIRRI SUR",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bajo Arrieta",
              },
              2: {
                codigo: 2,
                barrio: "Bajo Valverde",
              },
              3: {
                codigo: 3,
                barrio: "Bajo Zuniga",
              },
              4: {
                codigo: 4,
                barrio: "Cruce",
              },
              5: {
                codigo: 5,
                barrio: "Isla Cocora",
              },
              6: {
                codigo: 6,
                barrio: "Lourdes",
              },
              7: {
                codigo: 7,
                barrio: "Llano Bonito",
              },
              8: {
                codigo: 8,
                barrio: "Llano Bonito Sur",
              },
              9: {
                codigo: 9,
                barrio: "Palmita",
              },
              10: {
                codigo: 10,
                barrio: "Quebrada Honda",
              },
              11: {
                codigo: 11,
                barrio: "Rincon",
              },
              12: {
                codigo: 12,
                barrio: "Solis (parte)",
              },
              13: {
                codigo: 13,
                barrio: "Zapote",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "SAN JERONIMO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Puebla",
              },
              2: {
                codigo: 2,
                barrio: "Robles",
              },
              3: {
                codigo: 3,
                barrio: "Tacacal",
              },
            },
          },
          6: {
            codigo: 6,
            distrito: "SAN JUAN",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bajo Murcielago",
              },
              2: {
                codigo: 2,
                barrio: "Cueva",
              },
              3: {
                codigo: 3,
                barrio: "Guarumal",
              },
              4: {
                codigo: 4,
                barrio: "Rincon Elizondo",
              },
              5: {
                codigo: 5,
                barrio: "San Antonio",
              },
              6: {
                codigo: 6,
                barrio: "Yoses",
              },
            },
          },
          7: {
            codigo: 7,
            distrito: "ROSARIO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Perez",
              },
              2: {
                codigo: 2,
                barrio: "Hornos",
              },
              3: {
                codigo: 3,
                barrio: "Llano",
              },
              4: {
                codigo: 4,
                barrio: "Santa Margarita",
              },
              5: {
                codigo: 5,
                barrio: "Vistas del Valle",
              },
            },
          },
          8: {
            codigo: 8,
            distrito: "PALMITOS",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto Murillo",
              },
              2: {
                codigo: 2,
                barrio: "Alto Palmas",
              },
              3: {
                codigo: 3,
                barrio: "Cantarrana (parte)",
              },
              4: {
                codigo: 4,
                barrio: "Cinco Esquinas (parte)",
              },
              5: {
                codigo: 5,
                barrio: "Concepcion",
              },
              6: {
                codigo: 6,
                barrio: "Roquillo",
              },
              7: {
                codigo: 7,
                barrio: "San Roque",
              },
            },
          },
        },
      },
      7: {
        codigo: 7,
        canton: "PALMARES",
        distritos: {
          1: {
            codigo: 1,
            distrito: "PALMARES",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Santa Fe",
              },
              2: {
                codigo: 2,
                barrio: "San Vicente",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "ZARAGOZA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Cocaleca (parte)",
              },
              2: {
                codigo: 2,
                barrio: "Quebrada",
              },
              3: {
                codigo: 3,
                barrio: "Rincon (Quebrada)",
              },
              4: {
                codigo: 4,
                barrio: "Rincon de Zaragoza",
              },
              5: {
                codigo: 5,
                barrio: "Vargas",
              },
              6: {
                codigo: 6,
                barrio: "Vasquez",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "BUENOS AIRES",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bajo Cabra",
              },
              2: {
                codigo: 2,
                barrio: "Barreal",
              },
              3: {
                codigo: 3,
                barrio: "Tres Marias",
              },
              4: {
                codigo: 4,
                barrio: "Valle",
              },
              5: {
                codigo: 5,
                barrio: "Victoria",
              },
              6: {
                codigo: 6,
                barrio: "Calle Ramirez",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "SANTIAGO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Pinos (parte)",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "CANDELARIA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Pinos (parte)",
              },
            },
          },
          6: {
            codigo: 6,
            distrito: "ESQUIPULAS",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Calle Roble",
              },
              2: {
                codigo: 2,
                barrio: "Cocaleca (parte)",
              },
              3: {
                codigo: 3,
                barrio: "Peraza",
              },
              4: {
                codigo: 4,
                barrio: "Rincon de Salas",
              },
            },
          },
          7: {
            codigo: 7,
            distrito: "GRANJA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Amistad",
              },
            },
          },
        },
      },
      8: {
        codigo: 8,
        canton: "POAS",
        distritos: {
          1: {
            codigo: 1,
            distrito: "SAN PEDRO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Santa Cecilia (Bajo Piedras)",
              },
              2: {
                codigo: 2,
                barrio: "Rastro",
              },
              3: {
                codigo: 3,
                barrio: "Cerro",
              },
              4: {
                codigo: 4,
                barrio: "Chilamate",
              },
              5: {
                codigo: 5,
                barrio: "Hilda",
              },
              6: {
                codigo: 6,
                barrio: "Sitio (parte)",
              },
              7: {
                codigo: 7,
                barrio: "Zamora",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "SAN JUAN",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Altura (parte)",
              },
              2: {
                codigo: 2,
                barrio: "Corazon de Jesus",
              },
              3: {
                codigo: 3,
                barrio: "Guapinol",
              },
              4: {
                codigo: 4,
                barrio: "Mastate",
              },
              5: {
                codigo: 5,
                barrio: "San Juan Norte",
              },
              6: {
                codigo: 6,
                barrio: "Tablones (parte)",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "SAN RAFAEL",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bajo Zamora",
              },
              2: {
                codigo: 2,
                barrio: "Churuca",
              },
              3: {
                codigo: 3,
                barrio: "Guatuza",
              },
              4: {
                codigo: 4,
                barrio: "Monte",
              },
              5: {
                codigo: 5,
                barrio: "Potrero Chiquito",
              },
              6: {
                codigo: 6,
                barrio: "Santa Rosa",
              },
              7: {
                codigo: 7,
                barrio: "Sitio (parte)",
              },
              8: {
                codigo: 8,
                barrio: "Solis",
              },
              9: {
                codigo: 9,
                barrio: "Tablones (parte)",
              },
              10: {
                codigo: 10,
                barrio: "Volcan",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "CARRILLOS",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bajo Barahona",
              },
              2: {
                codigo: 2,
                barrio: "Cuatro Esquinas",
              },
              3: {
                codigo: 3,
                barrio: "Santisima Trinidad",
              },
              4: {
                codigo: 4,
                barrio: "Carrillos Alto",
              },
              5: {
                codigo: 5,
                barrio: "Senda",
              },
              6: {
                codigo: 6,
                barrio: "Sonora",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "SABANA REDONDA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Altura (parte)",
              },
              2: {
                codigo: 2,
                barrio: "Bajos del Tigre",
              },
            },
          },
        },
      },
      9: {
        codigo: 9,
        canton: "OROTINA",
        distritos: {
          1: {
            codigo: 1,
            distrito: "OROTINA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Aguacate",
              },
              2: {
                codigo: 2,
                barrio: "Arboleda",
              },
              3: {
                codigo: 3,
                barrio: "Carmen",
              },
              4: {
                codigo: 4,
                barrio: "Cortezal",
              },
              5: {
                codigo: 5,
                barrio: "Cuatro Esquinas Norte",
              },
              6: {
                codigo: 6,
                barrio: "Jesus",
              },
              7: {
                codigo: 7,
                barrio: "Kilometro",
              },
              8: {
                codigo: 8,
                barrio: "Lopez",
              },
              9: {
                codigo: 9,
                barrio: "Miraflores",
              },
              10: {
                codigo: 10,
                barrio: "Rastro Viejo",
              },
              11: {
                codigo: 11,
                barrio: "San Rafael",
              },
              12: {
                codigo: 12,
                barrio: "San Vicente",
              },
              13: {
                codigo: 13,
                barrio: "Tres Marias",
              },
              14: {
                codigo: 14,
                barrio: "Villa los Reyes",
              },
              15: {
                codigo: 15,
                barrio: "Alto Vindas",
              },
              16: {
                codigo: 16,
                barrio: "Esperanza",
              },
              17: {
                codigo: 17,
                barrio: "Tigre",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "MASTATE",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Cuatro Esquinas Este",
              },
              2: {
                codigo: 2,
                barrio: "Piedra Azul",
              },
              3: {
                codigo: 3,
                barrio: "Guayabal",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "HACIENDA VIEJA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Marichal",
              },
              2: {
                codigo: 2,
                barrio: "Concepcion",
              },
              3: {
                codigo: 3,
                barrio: "Dantas",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "COYOLAR",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Corazon de Maria",
              },
              2: {
                codigo: 2,
                barrio: "Bajos del Coyote",
              },
              3: {
                codigo: 3,
                barrio: "Cebadilla",
              },
              4: {
                codigo: 4,
                barrio: "Guapiles",
              },
              5: {
                codigo: 5,
                barrio: "Limonal",
              },
              6: {
                codigo: 6,
                barrio: "Mangos",
              },
              7: {
                codigo: 7,
                barrio: "Mollejones",
              },
              8: {
                codigo: 8,
                barrio: "Piedras de Fuego",
              },
              9: {
                codigo: 9,
                barrio: "Pozon",
              },
              10: {
                codigo: 10,
                barrio: "San Jeronimo",
              },
              11: {
                codigo: 11,
                barrio: "Santa Rita",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "CEIBA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Angostura Matamoros",
              },
              2: {
                codigo: 2,
                barrio: "Cascajal",
              },
              3: {
                codigo: 3,
                barrio: "Cuesta Pitahaya",
              },
              4: {
                codigo: 4,
                barrio: "Guacimo",
              },
              5: {
                codigo: 5,
                barrio: "Hidalgo",
              },
              6: {
                codigo: 6,
                barrio: "Kilometro 81",
              },
              7: {
                codigo: 7,
                barrio: "Machuca",
              },
              8: {
                codigo: 8,
                barrio: "Matamoros",
              },
              9: {
                codigo: 9,
                barrio: "Tunel",
              },
              10: {
                codigo: 10,
                barrio: "Uvita",
              },
              11: {
                codigo: 11,
                barrio: "Zapote",
              },
            },
          },
        },
      },
      10: {
        codigo: 10,
        canton: "SAN CARLOS",
        distritos: {
          1: {
            codigo: 1,
            distrito: "QUESADA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto Cruz",
              },
              2: {
                codigo: 2,
                barrio: "Ana Mercedes",
              },
              3: {
                codigo: 3,
                barrio: "Angeles",
              },
              4: {
                codigo: 4,
                barrio: "Arco Iris",
              },
              5: {
                codigo: 5,
                barrio: "Bajo Los Arce",
              },
              6: {
                codigo: 6,
                barrio: "Bajo Lourdes",
              },
              7: {
                codigo: 7,
                barrio: "Baltazar Quesada",
              },
              8: {
                codigo: 8,
                barrio: "Bellavista",
              },
              9: {
                codigo: 9,
                barrio: "Carmen",
              },
              10: {
                codigo: 10,
                barrio: "Casilda Matamoros",
              },
              11: {
                codigo: 11,
                barrio: "Cementerio",
              },
              12: {
                codigo: 12,
                barrio: "Coocique",
              },
              13: {
                codigo: 13,
                barrio: "Colina 1",
              },
              14: {
                codigo: 14,
                barrio: "Colina 2",
              },
              15: {
                codigo: 15,
                barrio: "Corazon de Jesus",
              },
              16: {
                codigo: 16,
                barrio: "Corobici",
              },
              17: {
                codigo: 17,
                barrio: "Don Victorino",
              },
              18: {
                codigo: 18,
                barrio: "El Campo",
              },
              19: {
                codigo: 19,
                barrio: "Gamonales",
              },
              20: {
                codigo: 20,
                barrio: "Guadalupe",
              },
              21: {
                codigo: 21,
                barrio: "La Cruz",
              },
              22: {
                codigo: 22,
                barrio: "La Leila",
              },
              23: {
                codigo: 23,
                barrio: "La Margarita",
              },
              24: {
                codigo: 24,
                barrio: "La Roca",
              },
              25: {
                codigo: 25,
                barrio: "La Torre",
              },
              26: {
                codigo: 26,
                barrio: "Los Abuelos",
              },
              27: {
                codigo: 27,
                barrio: "Lomas del Norte",
              },
              28: {
                codigo: 28,
                barrio: "Lutz",
              },
              29: {
                codigo: 29,
                barrio: "Meco",
              },
              30: {
                codigo: 30,
                barrio: "Mercedes",
              },
              31: {
                codigo: 31,
                barrio: "Peje",
              },
              32: {
                codigo: 32,
                barrio: "San Antonio",
              },
              33: {
                codigo: 33,
                barrio: "San Gerardo",
              },
              34: {
                codigo: 34,
                barrio: "San Martin",
              },
              35: {
                codigo: 35,
                barrio: "San Pablo",
              },
              36: {
                codigo: 36,
                barrio: "San Roque",
              },
              37: {
                codigo: 37,
                barrio: "Santa Fe",
              },
              38: {
                codigo: 38,
                barrio: "Selva Verde",
              },
              39: {
                codigo: 39,
                barrio: "Union",
              },
              40: {
                codigo: 40,
                barrio: "Villarreal",
              },
              41: {
                codigo: 41,
                barrio: "Abundancia",
              },
              42: {
                codigo: 42,
                barrio: "Brumas",
              },
              43: {
                codigo: 43,
                barrio: "Calle Guerrero",
              },
              44: {
                codigo: 44,
                barrio: "San Ramon (Cariblanca)",
              },
              45: {
                codigo: 45,
                barrio: "Cedral Norte",
              },
              46: {
                codigo: 46,
                barrio: "Cedral Sur",
              },
              47: {
                codigo: 47,
                barrio: "Colon",
              },
              48: {
                codigo: 48,
                barrio: "Dulce Nombre",
              },
              49: {
                codigo: 49,
                barrio: "Leones",
              },
              50: {
                codigo: 50,
                barrio: "Lindavista",
              },
              51: {
                codigo: 51,
                barrio: "Manzanos",
              },
              52: {
                codigo: 52,
                barrio: "Montanitas",
              },
              53: {
                codigo: 53,
                barrio: "Palmas",
              },
              54: {
                codigo: 54,
                barrio: "Porvenir",
              },
              55: {
                codigo: 55,
                barrio: "San Juan (Quebrada Palo)",
              },
              56: {
                codigo: 56,
                barrio: "Ronron Abajo",
              },
              57: {
                codigo: 57,
                barrio: "Ronron Arriba",
              },
              58: {
                codigo: 58,
                barrio: "San Isidro",
              },
              59: {
                codigo: 59,
                barrio: "San Jose de la Montana",
              },
              60: {
                codigo: 60,
                barrio: "San Luis",
              },
              61: {
                codigo: 61,
                barrio: "San Rafael",
              },
              62: {
                codigo: 62,
                barrio: "San Vicente",
              },
              63: {
                codigo: 63,
                barrio: "Sucre",
              },
              64: {
                codigo: 64,
                barrio: "Tesalia",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "FLORENCIA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto Gloria",
              },
              2: {
                codigo: 2,
                barrio: "Aquilea (San Francisco)",
              },
              3: {
                codigo: 3,
                barrio: "Bonanza",
              },
              4: {
                codigo: 4,
                barrio: "Caimitos",
              },
              5: {
                codigo: 5,
                barrio: "Chaparral",
              },
              6: {
                codigo: 6,
                barrio: "Cuestillas",
              },
              7: {
                codigo: 7,
                barrio: "Jabillos (parte)",
              },
              8: {
                codigo: 8,
                barrio: "Molino",
              },
              9: {
                codigo: 9,
                barrio: "Muelle de San Carlos",
              },
              10: {
                codigo: 10,
                barrio: "Pejeviejo",
              },
              11: {
                codigo: 11,
                barrio: "Penjamo",
              },
              12: {
                codigo: 12,
                barrio: "Platanar",
              },
              13: {
                codigo: 13,
                barrio: "Puente Casa",
              },
              14: {
                codigo: 14,
                barrio: "Puerto Escondido",
              },
              15: {
                codigo: 15,
                barrio: "Quebrada Azul",
              },
              16: {
                codigo: 16,
                barrio: "San Juan",
              },
              17: {
                codigo: 17,
                barrio: "San Luis",
              },
              18: {
                codigo: 18,
                barrio: "San Rafael",
              },
              19: {
                codigo: 19,
                barrio: "Santa Clara",
              },
              20: {
                codigo: 20,
                barrio: "Santa Maria",
              },
              21: {
                codigo: 21,
                barrio: "Santa Rita",
              },
              22: {
                codigo: 22,
                barrio: "Sapera",
              },
              23: {
                codigo: 23,
                barrio: "Ulima",
              },
              24: {
                codigo: 24,
                barrio: "Vega",
              },
              25: {
                codigo: 25,
                barrio: "Vieja",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "BUENAVISTA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Culebra",
              },
              2: {
                codigo: 2,
                barrio: "Quina (parte)",
              },
              3: {
                codigo: 3,
                barrio: "San Antonio",
              },
              4: {
                codigo: 4,
                barrio: "San Bosco",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "AGUAS ZARCAS",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Latino",
              },
              2: {
                codigo: 2,
                barrio: "Manantial",
              },
              3: {
                codigo: 3,
                barrio: "Montecristo",
              },
              4: {
                codigo: 4,
                barrio: "Nazareth",
              },
              5: {
                codigo: 5,
                barrio: "San Bosco",
              },
              6: {
                codigo: 6,
                barrio: "San Gerardo Viento Fresco",
              },
              7: {
                codigo: 7,
                barrio: "Vistas de la Llanura",
              },
              8: {
                codigo: 8,
                barrio: "Altamira",
              },
              9: {
                codigo: 9,
                barrio: "Alto Jimenez (Montecristo)",
              },
              10: {
                codigo: 10,
                barrio: "Bijagual",
              },
              11: {
                codigo: 11,
                barrio: "Boca los Chiles",
              },
              12: {
                codigo: 12,
                barrio: "Cantarrana (Santa Fe)",
              },
              13: {
                codigo: 13,
                barrio: "Coopesanjuan",
              },
              14: {
                codigo: 14,
                barrio: "Cano Negro",
              },
              15: {
                codigo: 15,
                barrio: "Cerrito",
              },
              16: {
                codigo: 16,
                barrio: "Cerro Cortes",
              },
              17: {
                codigo: 17,
                barrio: "Concepcion",
              },
              18: {
                codigo: 18,
                barrio: "Chiles",
              },
              19: {
                codigo: 19,
                barrio: "Danta",
              },
              20: {
                codigo: 20,
                barrio: "Delicias",
              },
              21: {
                codigo: 21,
                barrio: "Faroles",
              },
              22: {
                codigo: 22,
                barrio: "Gloria",
              },
              23: {
                codigo: 23,
                barrio: "Guabo",
              },
              24: {
                codigo: 24,
                barrio: "Llanos de Altamirita",
              },
              25: {
                codigo: 25,
                barrio: "Pitalito Norte (Esquipulas)",
              },
              26: {
                codigo: 26,
                barrio: "Pitalito",
              },
              27: {
                codigo: 27,
                barrio: "San Jose",
              },
              28: {
                codigo: 28,
                barrio: "Valle Hermoso",
              },
              29: {
                codigo: 29,
                barrio: "Vasconia",
              },
              30: {
                codigo: 30,
                barrio: "Vuelta de Kooper",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "VENECIA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Carmen",
              },
              2: {
                codigo: 2,
                barrio: "Corazon de Jesus",
              },
              3: {
                codigo: 3,
                barrio: "El Ceibo",
              },
              4: {
                codigo: 4,
                barrio: "Jardin",
              },
              5: {
                codigo: 5,
                barrio: "La Gloria",
              },
              6: {
                codigo: 6,
                barrio: "Alpes",
              },
              7: {
                codigo: 7,
                barrio: "Brisas",
              },
              8: {
                codigo: 8,
                barrio: "Buenos Aires",
              },
              9: {
                codigo: 9,
                barrio: "Guayabo",
              },
              10: {
                codigo: 10,
                barrio: "Latas",
              },
              11: {
                codigo: 11,
                barrio: "Marsella",
              },
              12: {
                codigo: 12,
                barrio: "Mesen",
              },
              13: {
                codigo: 13,
                barrio: "Nazareth",
              },
              14: {
                codigo: 14,
                barrio: "Negritos",
              },
              15: {
                codigo: 15,
                barrio: "Paraiso",
              },
              16: {
                codigo: 16,
                barrio: "Pueblo Viejo",
              },
              17: {
                codigo: 17,
                barrio: "San Cayetano",
              },
              18: {
                codigo: 18,
                barrio: "San Martin",
              },
              19: {
                codigo: 19,
                barrio: "Union",
              },
            },
          },
          6: {
            codigo: 6,
            distrito: "PITAL",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bosque",
              },
              2: {
                codigo: 2,
                barrio: "Comarca",
              },
              3: {
                codigo: 3,
                barrio: "San Cristobal",
              },
              4: {
                codigo: 4,
                barrio: "Angeles",
              },
              5: {
                codigo: 5,
                barrio: "Boca Sahino",
              },
              6: {
                codigo: 6,
                barrio: "Boca Tapada",
              },
              7: {
                codigo: 7,
                barrio: "Boca Tres Amigos",
              },
              8: {
                codigo: 8,
                barrio: "Cabra",
              },
              9: {
                codigo: 9,
                barrio: "Canacas",
              },
              10: {
                codigo: 10,
                barrio: "Cano Chu",
              },
              11: {
                codigo: 11,
                barrio: "Cerro Blanco (San Marcos)",
              },
              12: {
                codigo: 12,
                barrio: "Cuatro Esquinas",
              },
              13: {
                codigo: 13,
                barrio: "Chaparron",
              },
              14: {
                codigo: 14,
                barrio: "Chirivico (Coopeisabel)",
              },
              15: {
                codigo: 15,
                barrio: "Encanto",
              },
              16: {
                codigo: 16,
                barrio: "Fama (Carmen)",
              },
              17: {
                codigo: 17,
                barrio: "Flor",
              },
              18: {
                codigo: 18,
                barrio: "I Griega",
              },
              19: {
                codigo: 19,
                barrio: "Josefina",
              },
              20: {
                codigo: 20,
                barrio: "Legua",
              },
              21: {
                codigo: 21,
                barrio: "Ojoche",
              },
              22: {
                codigo: 22,
                barrio: "Ojochito",
              },
              23: {
                codigo: 23,
                barrio: "Palmar",
              },
              24: {
                codigo: 24,
                barrio: "Pegon",
              },
              25: {
                codigo: 25,
                barrio: "Piedra Alegre",
              },
              26: {
                codigo: 26,
                barrio: "Puerto Escondido",
              },
              27: {
                codigo: 27,
                barrio: "Quebrada Grande",
              },
              28: {
                codigo: 28,
                barrio: "Sahino",
              },
              29: {
                codigo: 29,
                barrio: "San Luis",
              },
              30: {
                codigo: 30,
                barrio: "Santa Elena",
              },
              31: {
                codigo: 31,
                barrio: "Tigre",
              },
              32: {
                codigo: 32,
                barrio: "Trinchera",
              },
              33: {
                codigo: 33,
                barrio: "Vegas",
              },
              34: {
                codigo: 34,
                barrio: "Veracruz",
              },
              35: {
                codigo: 35,
                barrio: "Vuelta Bolson (parte)",
              },
              36: {
                codigo: 36,
                barrio: "Vuelta Tablon",
              },
              37: {
                codigo: 37,
                barrio: "Yucatan",
              },
            },
          },
          7: {
            codigo: 7,
            distrito: "FORTUNA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Dora",
              },
              2: {
                codigo: 2,
                barrio: "Manolos",
              },
              3: {
                codigo: 3,
                barrio: "Olivos",
              },
              4: {
                codigo: 4,
                barrio: "Pastoral",
              },
              5: {
                codigo: 5,
                barrio: "Pito",
              },
              6: {
                codigo: 6,
                barrio: "San Isidro",
              },
              7: {
                codigo: 7,
                barrio: "Sinai",
              },
              8: {
                codigo: 8,
                barrio: "Agua Azul",
              },
              9: {
                codigo: 9,
                barrio: "Alamo",
              },
              10: {
                codigo: 10,
                barrio: "Angeles",
              },
              11: {
                codigo: 11,
                barrio: "Burio",
              },
              12: {
                codigo: 12,
                barrio: "Castillo (parte)",
              },
              13: {
                codigo: 13,
                barrio: "Guaria",
              },
              14: {
                codigo: 14,
                barrio: "El Campo (Guayabal)",
              },
              15: {
                codigo: 15,
                barrio: "Jilguero",
              },
              16: {
                codigo: 16,
                barrio: "Llano Verde",
              },
              17: {
                codigo: 17,
                barrio: "Orquideas",
              },
              18: {
                codigo: 18,
                barrio: "Palma",
              },
              19: {
                codigo: 19,
                barrio: "Perla",
              },
              20: {
                codigo: 20,
                barrio: "San Isidro",
              },
              21: {
                codigo: 21,
                barrio: "San Jorge",
              },
              22: {
                codigo: 22,
                barrio: "Santa Eduviges",
              },
              23: {
                codigo: 23,
                barrio: "Sonafluca",
              },
              24: {
                codigo: 24,
                barrio: "Tanque",
              },
              25: {
                codigo: 25,
                barrio: "Tres Esquinas",
              },
              26: {
                codigo: 26,
                barrio: "Zeta Trece",
              },
            },
          },
          8: {
            codigo: 8,
            distrito: "TIGRA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Angeles",
              },
              2: {
                codigo: 2,
                barrio: "Palmas",
              },
              3: {
                codigo: 3,
                barrio: "Cerritos",
              },
              4: {
                codigo: 4,
                barrio: "Esperanza",
              },
              5: {
                codigo: 5,
                barrio: "Futuro",
              },
              6: {
                codigo: 6,
                barrio: "Jabillos (parte)",
              },
              7: {
                codigo: 7,
                barrio: "Lucha",
              },
              8: {
                codigo: 8,
                barrio: "San Gerardo",
              },
              9: {
                codigo: 9,
                barrio: "San Isidro",
              },
              10: {
                codigo: 10,
                barrio: "San Jose",
              },
              11: {
                codigo: 11,
                barrio: "San Miguel",
              },
              12: {
                codigo: 12,
                barrio: "San Pedro",
              },
              13: {
                codigo: 13,
                barrio: "San Rafael",
              },
            },
          },
          9: {
            codigo: 9,
            distrito: "PALMERA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Altura",
              },
              2: {
                codigo: 2,
                barrio: "Calle Damas",
              },
              3: {
                codigo: 3,
                barrio: "Corea (Concepcion)",
              },
              4: {
                codigo: 4,
                barrio: "Corte",
              },
              5: {
                codigo: 5,
                barrio: "Loma",
              },
              6: {
                codigo: 6,
                barrio: "Lourdes",
              },
              7: {
                codigo: 7,
                barrio: "Marina",
              },
              8: {
                codigo: 8,
                barrio: "Marinita",
              },
              9: {
                codigo: 9,
                barrio: "San Rafael",
              },
              10: {
                codigo: 10,
                barrio: "San Rafael Sur",
              },
              11: {
                codigo: 11,
                barrio: "Santa Rosa",
              },
              12: {
                codigo: 12,
                barrio: "Surtubal",
              },
              13: {
                codigo: 13,
                barrio: "Union",
              },
              14: {
                codigo: 14,
                barrio: "Vacablanca (San Francisco)",
              },
              15: {
                codigo: 15,
                barrio: "Villa Maria",
              },
            },
          },
          10: {
            codigo: 10,
            distrito: "VENADO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Altamirita",
              },
              2: {
                codigo: 2,
                barrio: "Alto Blanca Lucia",
              },
              3: {
                codigo: 3,
                barrio: "Burio",
              },
              4: {
                codigo: 4,
                barrio: "Cacao",
              },
              5: {
                codigo: 5,
                barrio: "Delicias",
              },
              6: {
                codigo: 6,
                barrio: "Esperanza",
              },
              7: {
                codigo: 7,
                barrio: "Jicarito",
              },
              8: {
                codigo: 8,
                barrio: "Lindavista",
              },
              9: {
                codigo: 9,
                barrio: "Poma (parte)",
              },
              10: {
                codigo: 10,
                barrio: "Puerto Seco",
              },
              11: {
                codigo: 11,
                barrio: "San Isidro",
              },
              12: {
                codigo: 12,
                barrio: "Santa Eulalia (parte)",
              },
              13: {
                codigo: 13,
                barrio: "Santa Lucia",
              },
              14: {
                codigo: 14,
                barrio: "Tigra",
              },
            },
          },
          11: {
            codigo: 11,
            distrito: "CUTRIS",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Almendros",
              },
              2: {
                codigo: 2,
                barrio: "Bellavista",
              },
              3: {
                codigo: 3,
                barrio: "Betania",
              },
              4: {
                codigo: 4,
                barrio: "Boca de San Carlos",
              },
              5: {
                codigo: 5,
                barrio: "Boca Providencia (parte)",
              },
              6: {
                codigo: 6,
                barrio: "Cabeceras de Aguas Zarquitas",
              },
              7: {
                codigo: 7,
                barrio: "Carmen",
              },
              8: {
                codigo: 8,
                barrio: "Cascada",
              },
              9: {
                codigo: 9,
                barrio: "Castelmare",
              },
              10: {
                codigo: 10,
                barrio: "Cocobolo",
              },
              11: {
                codigo: 11,
                barrio: "Coopevega",
              },
              12: {
                codigo: 12,
                barrio: "Corazon de Jesus",
              },
              13: {
                codigo: 13,
                barrio: "Crucitas",
              },
              14: {
                codigo: 14,
                barrio: "Chamorrito",
              },
              15: {
                codigo: 15,
                barrio: "Chamorro",
              },
              16: {
                codigo: 16,
                barrio: "Chorreras",
              },
              17: {
                codigo: 17,
                barrio: "Hebron",
              },
              18: {
                codigo: 18,
                barrio: "Isla del Cura",
              },
              19: {
                codigo: 19,
                barrio: "Isla Pobres",
              },
              20: {
                codigo: 20,
                barrio: "Isla Sabalo",
              },
              21: {
                codigo: 21,
                barrio: "Jardin",
              },
              22: {
                codigo: 22,
                barrio: "Kopper",
              },
              23: {
                codigo: 23,
                barrio: "La Cajeta",
              },
              24: {
                codigo: 24,
                barrio: "Laurel Galan",
              },
              25: {
                codigo: 25,
                barrio: "Limoncito",
              },
              26: {
                codigo: 26,
                barrio: "Moravia",
              },
              27: {
                codigo: 27,
                barrio: "Patastillo",
              },
              28: {
                codigo: 28,
                barrio: "Pueblo Nuevo",
              },
              29: {
                codigo: 29,
                barrio: "Recreo",
              },
              30: {
                codigo: 30,
                barrio: "Rio Tico",
              },
              31: {
                codigo: 31,
                barrio: "Roble",
              },
              32: {
                codigo: 32,
                barrio: "San Antonio",
              },
              33: {
                codigo: 33,
                barrio: "San Fernando",
              },
              34: {
                codigo: 34,
                barrio: "San Francisco",
              },
              35: {
                codigo: 35,
                barrio: "San Joaquin",
              },
              36: {
                codigo: 36,
                barrio: "San Jorge",
              },
              37: {
                codigo: 37,
                barrio: "San Jose",
              },
              38: {
                codigo: 38,
                barrio: "San Marcos",
              },
              39: {
                codigo: 39,
                barrio: "Santa Rita",
              },
              40: {
                codigo: 40,
                barrio: "Santa Teresa",
              },
              41: {
                codigo: 41,
                barrio: "San Vito",
              },
              42: {
                codigo: 42,
                barrio: "Tabla Grande (San Pedro)",
              },
              43: {
                codigo: 43,
                barrio: "Terron Colorado (parte)",
              },
              44: {
                codigo: 44,
                barrio: "Ventanas",
              },
              45: {
                codigo: 45,
                barrio: "Vuelta Bolson (parte)",
              },
              46: {
                codigo: 46,
                barrio: "Vuelta Millonarios",
              },
              47: {
                codigo: 47,
                barrio: "Vuelta Ruedas",
              },
            },
          },
          12: {
            codigo: 12,
            distrito: "MONTERREY",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Pericos",
              },
              2: {
                codigo: 2,
                barrio: "Bajillo",
              },
              3: {
                codigo: 3,
                barrio: "Cano Ciego",
              },
              4: {
                codigo: 4,
                barrio: "Cedros",
              },
              5: {
                codigo: 5,
                barrio: "Chambacu",
              },
              6: {
                codigo: 6,
                barrio: "Delicias",
              },
              7: {
                codigo: 7,
                barrio: "La Union",
              },
              8: {
                codigo: 8,
                barrio: "Maquencal",
              },
              9: {
                codigo: 9,
                barrio: "Mirador",
              },
              10: {
                codigo: 10,
                barrio: "Montelimar",
              },
              11: {
                codigo: 11,
                barrio: "Monterrey",
              },
              12: {
                codigo: 12,
                barrio: "Orquidea",
              },
              13: {
                codigo: 13,
                barrio: "Pataste Arriba",
              },
              14: {
                codigo: 14,
                barrio: "Sabalito",
              },
              15: {
                codigo: 15,
                barrio: "San Andres",
              },
              16: {
                codigo: 16,
                barrio: "San Antonio",
              },
              17: {
                codigo: 17,
                barrio: "San Cristobal",
              },
              18: {
                codigo: 18,
                barrio: "San Miguel",
              },
              19: {
                codigo: 19,
                barrio: "Santa Eulalia (parte)",
              },
              20: {
                codigo: 20,
                barrio: "Santa Marta",
              },
            },
          },
          13: {
            codigo: 13,
            distrito: "POCOSOL",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Fatima",
              },
              2: {
                codigo: 2,
                barrio: "Parajeles",
              },
              3: {
                codigo: 3,
                barrio: "Tres Perlas",
              },
              4: {
                codigo: 4,
                barrio: "Valle Hermoso",
              },
              5: {
                codigo: 5,
                barrio: "Acapulco",
              },
              6: {
                codigo: 6,
                barrio: "Aldea",
              },
              7: {
                codigo: 7,
                barrio: "Angeles",
              },
              8: {
                codigo: 8,
                barrio: "Azucena",
              },
              9: {
                codigo: 9,
                barrio: "Banderas",
              },
              10: {
                codigo: 10,
                barrio: "Boca Providencia (parte)",
              },
              11: {
                codigo: 11,
                barrio: "Brisas",
              },
              12: {
                codigo: 12,
                barrio: "Buenavista",
              },
              13: {
                codigo: 13,
                barrio: "Buenos Aires",
              },
              14: {
                codigo: 14,
                barrio: "Carrizal",
              },
              15: {
                codigo: 15,
                barrio: "Ceiba",
              },
              16: {
                codigo: 16,
                barrio: "Conchito",
              },
              17: {
                codigo: 17,
                barrio: "Concho",
              },
              18: {
                codigo: 18,
                barrio: "Cuatro Esquinas",
              },
              19: {
                codigo: 19,
                barrio: "Esterito",
              },
              20: {
                codigo: 20,
                barrio: "Estero",
              },
              21: {
                codigo: 21,
                barrio: "Estrella",
              },
              22: {
                codigo: 22,
                barrio: "Guaria",
              },
              23: {
                codigo: 23,
                barrio: "Jazmin",
              },
              24: {
                codigo: 24,
                barrio: "Jocote",
              },
              25: {
                codigo: 25,
                barrio: "Juanilama",
              },
              26: {
                codigo: 26,
                barrio: "Luisa",
              },
              27: {
                codigo: 27,
                barrio: "Llano Verde",
              },
              28: {
                codigo: 28,
                barrio: "Morazan",
              },
              29: {
                codigo: 29,
                barrio: "Nieves",
              },
              30: {
                codigo: 30,
                barrio: "Paraiso",
              },
              31: {
                codigo: 31,
                barrio: "Paso Real",
              },
              32: {
                codigo: 32,
                barrio: "Plomo",
              },
              33: {
                codigo: 33,
                barrio: "Pocosol",
              },
              34: {
                codigo: 34,
                barrio: "Providencia (San Luis)",
              },
              35: {
                codigo: 35,
                barrio: "Pueblo Nuevo",
              },
              36: {
                codigo: 36,
                barrio: "Pueblo Santo",
              },
              37: {
                codigo: 37,
                barrio: "Rancho Quemado",
              },
              38: {
                codigo: 38,
                barrio: "Rubi",
              },
              39: {
                codigo: 39,
                barrio: "San Alejo",
              },
              40: {
                codigo: 40,
                barrio: "San Bosco",
              },
              41: {
                codigo: 41,
                barrio: "San Cristobal",
              },
              42: {
                codigo: 42,
                barrio: "San Diego",
              },
              43: {
                codigo: 43,
                barrio: "San Gerardo",
              },
              44: {
                codigo: 44,
                barrio: "San Isidro",
              },
              45: {
                codigo: 45,
                barrio: "San Martin",
              },
              46: {
                codigo: 46,
                barrio: "San Rafael",
              },
              47: {
                codigo: 47,
                barrio: "Santa Cecilia",
              },
              48: {
                codigo: 48,
                barrio: "Santa Esperanza",
              },
              49: {
                codigo: 49,
                barrio: "Santa Maria",
              },
              50: {
                codigo: 50,
                barrio: "Terron Colorado (parte)",
              },
              51: {
                codigo: 51,
                barrio: "Tiricias",
              },
              52: {
                codigo: 52,
                barrio: "Tres y Tres",
              },
            },
          },
        },
      },
      11: {
        codigo: 11,
        canton: "ALFARO RUIZ",
        distritos: {
          1: {
            codigo: 1,
            distrito: "ZARCERO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Cantarranas",
              },
              2: {
                codigo: 2,
                barrio: "Santa Teresita",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "LAGUNA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Pena",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "TAPEZCO",
            barrios: {},
          },
          4: {
            codigo: 4,
            distrito: "GUADALUPE",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Anateri",
              },
              2: {
                codigo: 2,
                barrio: "Bellavista",
              },
              3: {
                codigo: 3,
                barrio: "Morelos",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "PALMIRA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Picada",
              },
            },
          },
          6: {
            codigo: 6,
            distrito: "ZAPOTE",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Quina (parte)",
              },
              2: {
                codigo: 2,
                barrio: "San Juan de Lajas",
              },
              3: {
                codigo: 3,
                barrio: "Santa Elena",
              },
            },
          },
          7: {
            codigo: 7,
            distrito: "BRISAS",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Angeles",
              },
              2: {
                codigo: 2,
                barrio: "Brisa",
              },
              3: {
                codigo: 3,
                barrio: "Legua",
              },
            },
          },
        },
      },
      12: {
        codigo: 12,
        canton: "VALVERDE VEGA",
        distritos: {
          1: {
            codigo: 1,
            distrito: "SARCHI NORTE",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Angeles",
              },
              2: {
                codigo: 2,
                barrio: "Bajo Raimundo",
              },
              3: {
                codigo: 3,
                barrio: "Canto",
              },
              4: {
                codigo: 4,
                barrio: "Eva",
              },
              5: {
                codigo: 5,
                barrio: "Luisa",
              },
              6: {
                codigo: 6,
                barrio: "San Rafael (Rincon Colorado)",
              },
              7: {
                codigo: 7,
                barrio: "Sahinal",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "SARCHI SUR",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bajo Trapiche",
              },
              2: {
                codigo: 2,
                barrio: "Ranera",
              },
              3: {
                codigo: 3,
                barrio: "Alto Castro",
              },
              4: {
                codigo: 4,
                barrio: "Coopeoctava",
              },
              5: {
                codigo: 5,
                barrio: "Ratoncillal",
              },
              6: {
                codigo: 6,
                barrio: "Rincon de Alpizar",
              },
              7: {
                codigo: 7,
                barrio: "Rincon de Ulate",
              },
              8: {
                codigo: 8,
                barrio: "San Miguel",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "TORO AMARILLO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto Palomo",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "SAN PEDRO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Castro",
              },
              2: {
                codigo: 2,
                barrio: "Concha",
              },
              3: {
                codigo: 3,
                barrio: "Pueblo Seco",
              },
              4: {
                codigo: 4,
                barrio: "Talolinga",
              },
              5: {
                codigo: 5,
                barrio: "Trojas",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "RODRIGUEZ",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bambu",
              },
              2: {
                codigo: 2,
                barrio: "Sabanilla",
              },
            },
          },
        },
      },
      13: {
        codigo: 13,
        canton: "UPALA",
        distritos: {
          1: {
            codigo: 1,
            distrito: "UPALA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Don Chu",
              },
              2: {
                codigo: 2,
                barrio: "La Union",
              },
              3: {
                codigo: 3,
                barrio: "Las Palmas",
              },
              4: {
                codigo: 4,
                barrio: "Venecia",
              },
              5: {
                codigo: 5,
                barrio: "Angeles (parte)",
              },
              6: {
                codigo: 6,
                barrio: "Carmen",
              },
              7: {
                codigo: 7,
                barrio: "Colonia Puntarenas",
              },
              8: {
                codigo: 8,
                barrio: "Corteza",
              },
              9: {
                codigo: 9,
                barrio: "Fosforo",
              },
              10: {
                codigo: 10,
                barrio: "Jazmines",
              },
              11: {
                codigo: 11,
                barrio: "Llano Azul",
              },
              12: {
                codigo: 12,
                barrio: "Maravilla",
              },
              13: {
                codigo: 13,
                barrio: "Miravalles",
              },
              14: {
                codigo: 14,
                barrio: "Moreno Canas",
              },
              15: {
                codigo: 15,
                barrio: "Recreo",
              },
              16: {
                codigo: 16,
                barrio: "San Fernando",
              },
              17: {
                codigo: 17,
                barrio: "San Luis",
              },
              18: {
                codigo: 18,
                barrio: "San Martin",
              },
              19: {
                codigo: 19,
                barrio: "Santa Cecilia",
              },
              20: {
                codigo: 20,
                barrio: "Santa Rosa",
              },
              21: {
                codigo: 21,
                barrio: "Verbena (parte)",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "AGUAS CLARAS",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Ceiba",
              },
              2: {
                codigo: 2,
                barrio: "Golfo",
              },
              3: {
                codigo: 3,
                barrio: "Porras",
              },
              4: {
                codigo: 4,
                barrio: "Aguas Claras",
              },
              5: {
                codigo: 5,
                barrio: "Buenos Aires",
              },
              6: {
                codigo: 6,
                barrio: "Colonia Blanca",
              },
              7: {
                codigo: 7,
                barrio: "Colonia Libertad",
              },
              8: {
                codigo: 8,
                barrio: "Cuatro Bocas",
              },
              9: {
                codigo: 9,
                barrio: "Chepa (Angeles)",
              },
              10: {
                codigo: 10,
                barrio: "Guayabal",
              },
              11: {
                codigo: 11,
                barrio: "Guinea",
              },
              12: {
                codigo: 12,
                barrio: "La Gloria",
              },
              13: {
                codigo: 13,
                barrio: "Porvenir",
              },
              14: {
                codigo: 14,
                barrio: "Rio Negro",
              },
              15: {
                codigo: 15,
                barrio: "Tigra",
              },
              16: {
                codigo: 16,
                barrio: "Torre",
              },
              17: {
                codigo: 17,
                barrio: "Vuelta San Pedro",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "SAN JOSE O PIZOTE",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Camelias",
              },
              2: {
                codigo: 2,
                barrio: "Libano (Finanzas)",
              },
              3: {
                codigo: 3,
                barrio: "Nazareno",
              },
              4: {
                codigo: 4,
                barrio: "Angeles (parte)",
              },
              5: {
                codigo: 5,
                barrio: "Betania",
              },
              6: {
                codigo: 6,
                barrio: "Cano Blanco",
              },
              7: {
                codigo: 7,
                barrio: "Cartagos Norte",
              },
              8: {
                codigo: 8,
                barrio: "Cartagos Sur",
              },
              9: {
                codigo: 9,
                barrio: "Copey (Santa Lucia)",
              },
              10: {
                codigo: 10,
                barrio: "Delirio",
              },
              11: {
                codigo: 11,
                barrio: "Fatima",
              },
              12: {
                codigo: 12,
                barrio: "Jesus Maria",
              },
              13: {
                codigo: 13,
                barrio: "Linda Vista",
              },
              14: {
                codigo: 14,
                barrio: "Mango",
              },
              15: {
                codigo: 15,
                barrio: "Papagayo",
              },
              16: {
                codigo: 16,
                barrio: "Pinol",
              },
              17: {
                codigo: 17,
                barrio: "Pizotillo",
              },
              18: {
                codigo: 18,
                barrio: "Popoyuapa",
              },
              19: {
                codigo: 19,
                barrio: "Progreso",
              },
              20: {
                codigo: 20,
                barrio: "Pueblo Nuevo",
              },
              21: {
                codigo: 21,
                barrio: "San Bosco",
              },
              22: {
                codigo: 22,
                barrio: "San Pedro",
              },
              23: {
                codigo: 23,
                barrio: "San Ramon",
              },
              24: {
                codigo: 24,
                barrio: "Santa Clara Norte (parte)",
              },
              25: {
                codigo: 25,
                barrio: "Union",
              },
              26: {
                codigo: 26,
                barrio: "Valle",
              },
              27: {
                codigo: 27,
                barrio: "Valle Bonito",
              },
              28: {
                codigo: 28,
                barrio: "Victoria (parte)",
              },
              29: {
                codigo: 29,
                barrio: "Villahermosa",
              },
              30: {
                codigo: 30,
                barrio: "Villanueva",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "BIJAGUA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Altamira",
              },
              2: {
                codigo: 2,
                barrio: "Carlos Vargas",
              },
              3: {
                codigo: 3,
                barrio: "Angeles",
              },
              4: {
                codigo: 4,
                barrio: "Achiote",
              },
              5: {
                codigo: 5,
                barrio: "Cuesta Pichardo",
              },
              6: {
                codigo: 6,
                barrio: "Chorros",
              },
              7: {
                codigo: 7,
                barrio: "Florecitas",
              },
              8: {
                codigo: 8,
                barrio: "Flores",
              },
              9: {
                codigo: 9,
                barrio: "Higueron",
              },
              10: {
                codigo: 10,
                barrio: "Jardin",
              },
              11: {
                codigo: 11,
                barrio: "Macho",
              },
              12: {
                codigo: 12,
                barrio: "Pata de Gallo (San Cristobal) (parte)",
              },
              13: {
                codigo: 13,
                barrio: "Pueblo Nuevo",
              },
              14: {
                codigo: 14,
                barrio: "Reserva",
              },
              15: {
                codigo: 15,
                barrio: "San Miguel",
              },
              16: {
                codigo: 16,
                barrio: "Santo Domingo",
              },
              17: {
                codigo: 17,
                barrio: "Zapote",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "DELICIAS",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Camelias",
              },
              2: {
                codigo: 2,
                barrio: "La Cruz",
              },
              3: {
                codigo: 3,
                barrio: "Mexico",
              },
              4: {
                codigo: 4,
                barrio: "Mocoron",
              },
              5: {
                codigo: 5,
                barrio: "Pavas",
              },
              6: {
                codigo: 6,
                barrio: "Perla",
              },
              7: {
                codigo: 7,
                barrio: "Quebradon",
              },
              8: {
                codigo: 8,
                barrio: "Santa Clara Norte (parte)",
              },
              9: {
                codigo: 9,
                barrio: "Santa Clara Sur",
              },
              10: {
                codigo: 10,
                barrio: "Trapiche",
              },
              11: {
                codigo: 11,
                barrio: "Victoria (parte)",
              },
            },
          },
          6: {
            codigo: 6,
            distrito: "DOS RIOS",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Birmania",
              },
              2: {
                codigo: 2,
                barrio: "Brasilia",
              },
              3: {
                codigo: 3,
                barrio: "Colonia",
              },
              4: {
                codigo: 4,
                barrio: "Gavilan",
              },
              5: {
                codigo: 5,
                barrio: "Jabalina",
              },
              6: {
                codigo: 6,
                barrio: "Progreso",
              },
              7: {
                codigo: 7,
                barrio: "San Luis",
              },
            },
          },
          7: {
            codigo: 7,
            distrito: "YOLILLAL",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Cabanga",
              },
              2: {
                codigo: 2,
                barrio: "Campo Verde",
              },
              3: {
                codigo: 3,
                barrio: "Carmen",
              },
              4: {
                codigo: 4,
                barrio: "Cinco Esquinas",
              },
              5: {
                codigo: 5,
                barrio: "Chimurria Abajo",
              },
              6: {
                codigo: 6,
                barrio: "Flores",
              },
              7: {
                codigo: 7,
                barrio: "Jobo",
              },
              8: {
                codigo: 8,
                barrio: "Montecristo",
              },
              9: {
                codigo: 9,
                barrio: "Nazareth",
              },
              10: {
                codigo: 10,
                barrio: "Quebrada Grande",
              },
              11: {
                codigo: 11,
                barrio: "San Gabriel",
              },
              12: {
                codigo: 12,
                barrio: "San Jorge",
              },
              13: {
                codigo: 13,
                barrio: "Socorro",
              },
              14: {
                codigo: 14,
                barrio: "Virgen",
              },
              15: {
                codigo: 15,
                barrio: "Yolillal (San Antonio)",
              },
            },
          },
          8: {
            codigo: 8,
            distrito: "CANALETE",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Armenias",
              },
              2: {
                codigo: 2,
                barrio: "Las Brisas",
              },
              3: {
                codigo: 3,
                barrio: "Buenavista",
              },
              4: {
                codigo: 4,
                barrio: "Cuatro Cruces",
              },
              5: {
                codigo: 5,
                barrio: "Guacalito",
              },
              6: {
                codigo: 6,
                barrio: "Milpas",
              },
              7: {
                codigo: 7,
                barrio: "Miramar",
              },
              8: {
                codigo: 8,
                barrio: "Pata de Gallo (San Cristobal) (parte)",
              },
              9: {
                codigo: 9,
                barrio: "Rosario",
              },
              10: {
                codigo: 10,
                barrio: "Verbena (parte)",
              },
            },
          },
        },
      },
      14: {
        codigo: 14,
        canton: "LOS CHILES",
        distritos: {
          1: {
            codigo: 1,
            distrito: "LOS CHILES",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Loma",
              },
              2: {
                codigo: 2,
                barrio: "Porton",
              },
              3: {
                codigo: 3,
                barrio: "Arco Iris",
              },
              4: {
                codigo: 4,
                barrio: "Berlin",
              },
              5: {
                codigo: 5,
                barrio: "Brisas",
              },
              6: {
                codigo: 6,
                barrio: "Buenos Aires",
              },
              7: {
                codigo: 7,
                barrio: "Cabro",
              },
              8: {
                codigo: 8,
                barrio: "Cachito",
              },
              9: {
                codigo: 9,
                barrio: "Cana Castilla",
              },
              10: {
                codigo: 10,
                barrio: "Combate",
              },
              11: {
                codigo: 11,
                barrio: "Coquital",
              },
              12: {
                codigo: 12,
                barrio: "Cristo Rey",
              },
              13: {
                codigo: 13,
                barrio: "Cuacas",
              },
              14: {
                codigo: 14,
                barrio: "Cuatro Esquinas",
              },
              15: {
                codigo: 15,
                barrio: "Delicias",
              },
              16: {
                codigo: 16,
                barrio: "El Cruce",
              },
              17: {
                codigo: 17,
                barrio: "Escaleras",
              },
              18: {
                codigo: 18,
                barrio: "Esperanza",
              },
              19: {
                codigo: 19,
                barrio: "Estrella",
              },
              20: {
                codigo: 20,
                barrio: "Hernandez",
              },
              21: {
                codigo: 21,
                barrio: "Isla Chica",
              },
              22: {
                codigo: 22,
                barrio: "Las Nubes",
              },
              23: {
                codigo: 23,
                barrio: "Maramba",
              },
              24: {
                codigo: 24,
                barrio: "Masaya",
              },
              25: {
                codigo: 25,
                barrio: "Medio Queso",
              },
              26: {
                codigo: 26,
                barrio: "Parque",
              },
              27: {
                codigo: 27,
                barrio: "Playuelitas",
              },
              28: {
                codigo: 28,
                barrio: "Primavera",
              },
              29: {
                codigo: 29,
                barrio: "Pueblo Nuevo",
              },
              30: {
                codigo: 30,
                barrio: "Punta Cortes",
              },
              31: {
                codigo: 31,
                barrio: "Rampla",
              },
              32: {
                codigo: 32,
                barrio: "Refugio",
              },
              33: {
                codigo: 33,
                barrio: "Roble",
              },
              34: {
                codigo: 34,
                barrio: "San Alejandro",
              },
              35: {
                codigo: 35,
                barrio: "San Gerardo",
              },
              36: {
                codigo: 36,
                barrio: "San Jeronimo",
              },
              37: {
                codigo: 37,
                barrio: "San Pablo",
              },
              38: {
                codigo: 38,
                barrio: "Santa Elena",
              },
              39: {
                codigo: 39,
                barrio: "Santa Fe",
              },
              40: {
                codigo: 40,
                barrio: "Santa Rita",
              },
              41: {
                codigo: 41,
                barrio: "Santa Rosa",
              },
              42: {
                codigo: 42,
                barrio: "Solanos",
              },
              43: {
                codigo: 43,
                barrio: "Trocha",
              },
              44: {
                codigo: 44,
                barrio: "Virgen",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "CANO NEGRO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Aguas Negras",
              },
              2: {
                codigo: 2,
                barrio: "Islas Cubas",
              },
              3: {
                codigo: 3,
                barrio: "Nueva Esperanza",
              },
              4: {
                codigo: 4,
                barrio: "Pilones",
              },
              5: {
                codigo: 5,
                barrio: "Playuelas",
              },
              6: {
                codigo: 6,
                barrio: "Porvenir",
              },
              7: {
                codigo: 7,
                barrio: "San Antonio",
              },
              8: {
                codigo: 8,
                barrio: "San Emilio",
              },
              9: {
                codigo: 9,
                barrio: "Veracruz",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "EL AMPARO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto los Reyes",
              },
              2: {
                codigo: 2,
                barrio: "Cano Ciego",
              },
              3: {
                codigo: 3,
                barrio: "Cobano",
              },
              4: {
                codigo: 4,
                barrio: "Corozo",
              },
              5: {
                codigo: 5,
                barrio: "Corrales",
              },
              6: {
                codigo: 6,
                barrio: "Coyol",
              },
              7: {
                codigo: 7,
                barrio: "Dos Aguas",
              },
              8: {
                codigo: 8,
                barrio: "Gallito",
              },
              9: {
                codigo: 9,
                barrio: "Gallo Pinto (parte)",
              },
              10: {
                codigo: 10,
                barrio: "Montealegre",
              },
              11: {
                codigo: 11,
                barrio: "Nueva Lucha",
              },
              12: {
                codigo: 12,
                barrio: "Pavon",
              },
              13: {
                codigo: 13,
                barrio: "Quebrada Grande",
              },
              14: {
                codigo: 14,
                barrio: "Sabogal",
              },
              15: {
                codigo: 15,
                barrio: "San Antonio",
              },
              16: {
                codigo: 16,
                barrio: "San Francisco",
              },
              17: {
                codigo: 17,
                barrio: "San Isidro",
              },
              18: {
                codigo: 18,
                barrio: "San Jose del Amparo",
              },
              19: {
                codigo: 19,
                barrio: "San Macario",
              },
              20: {
                codigo: 20,
                barrio: "Santa Cecilia",
              },
              21: {
                codigo: 21,
                barrio: "Trinidad",
              },
              22: {
                codigo: 22,
                barrio: "Vasconia",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "SAN JORGE",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Botijo",
              },
              2: {
                codigo: 2,
                barrio: "Chimurria",
              },
              3: {
                codigo: 3,
                barrio: "Colonia Paris",
              },
              4: {
                codigo: 4,
                barrio: "Coquitales",
              },
              5: {
                codigo: 5,
                barrio: "Gallo Pinto (Parte)",
              },
              6: {
                codigo: 6,
                barrio: "Lirios",
              },
              7: {
                codigo: 7,
                barrio: "Lucha",
              },
              8: {
                codigo: 8,
                barrio: "Montealegre (Parte)",
              },
              9: {
                codigo: 9,
                barrio: "Pueblo Nuevo",
              },
              10: {
                codigo: 10,
                barrio: "San Humberto",
              },
              11: {
                codigo: 11,
                barrio: "San Isidro",
              },
              12: {
                codigo: 12,
                barrio: "San Jorge",
              },
              13: {
                codigo: 13,
                barrio: "San Rafael",
              },
              14: {
                codigo: 14,
                barrio: "Terranova",
              },
              15: {
                codigo: 15,
                barrio: "Tigra",
              },
              16: {
                codigo: 16,
                barrio: "Zamba",
              },
            },
          },
        },
      },
      15: {
        codigo: 15,
        canton: "GUATUSO",
        distritos: {
          1: {
            codigo: 1,
            distrito: "SAN RAFAEL",
            barrios: {
              1: {
                codigo: 1,
                barrio: "SAN RAFAEL",
              },
              2: {
                codigo: 2,
                barrio: "Betania",
              },
              3: {
                codigo: 3,
                barrio: "Buenos Aires",
              },
              4: {
                codigo: 4,
                barrio: "Curirre",
              },
              5: {
                codigo: 5,
                barrio: "El Eden",
              },
              6: {
                codigo: 6,
                barrio: "El Silencio",
              },
              7: {
                codigo: 7,
                barrio: "El Sol",
              },
              8: {
                codigo: 8,
                barrio: "Flor",
              },
              9: {
                codigo: 9,
                barrio: "Garita",
              },
              10: {
                codigo: 10,
                barrio: "Guayabo",
              },
              11: {
                codigo: 11,
                barrio: "Jade",
              },
              12: {
                codigo: 12,
                barrio: "La Granja",
              },
              13: {
                codigo: 13,
                barrio: "La Rivera",
              },
              14: {
                codigo: 14,
                barrio: "Los Angeles",
              },
              15: {
                codigo: 15,
                barrio: "Maquencal",
              },
              16: {
                codigo: 16,
                barrio: "Margarita",
              },
              17: {
                codigo: 17,
                barrio: "Montealegre (Parte)",
              },
              18: {
                codigo: 18,
                barrio: "Moravia",
              },
              19: {
                codigo: 19,
                barrio: "Verde",
              },
              20: {
                codigo: 20,
                barrio: "Muerte",
              },
              21: {
                codigo: 21,
                barrio: "Palmera",
              },
              22: {
                codigo: 22,
                barrio: "Pataste",
              },
              23: {
                codigo: 23,
                barrio: "Pataste Abajo",
              },
              24: {
                codigo: 24,
                barrio: "Poma (parte)",
              },
              25: {
                codigo: 25,
                barrio: "Puerto Nuevo",
              },
              26: {
                codigo: 26,
                barrio: "Samen Arriba",
              },
              27: {
                codigo: 27,
                barrio: "San Juan",
              },
              28: {
                codigo: 28,
                barrio: "San Luis",
              },
              29: {
                codigo: 29,
                barrio: "San Miguel",
              },
              30: {
                codigo: 30,
                barrio: "Santa Fe",
              },
              31: {
                codigo: 31,
                barrio: "Santa Marta",
              },
              32: {
                codigo: 32,
                barrio: "Tonjibe",
              },
              33: {
                codigo: 33,
                barrio: "Treminio",
              },
              34: {
                codigo: 34,
                barrio: "Triunfo",
              },
              35: {
                codigo: 35,
                barrio: "Viento Fresco",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "BUENA VISTA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Cabana (parte)",
              },
              2: {
                codigo: 2,
                barrio: "Costa Ana",
              },
              3: {
                codigo: 3,
                barrio: "El Cruce",
              },
              4: {
                codigo: 4,
                barrio: "Guayabito",
              },
              5: {
                codigo: 5,
                barrio: "Monico",
              },
              6: {
                codigo: 6,
                barrio: "Samen Abajo",
              },
              7: {
                codigo: 7,
                barrio: "San Jose",
              },
              8: {
                codigo: 8,
                barrio: "Thiales",
              },
              9: {
                codigo: 9,
                barrio: "Valle del Rio",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "COTE",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Altagracia",
              },
              2: {
                codigo: 2,
                barrio: "Alto Sahino",
              },
              3: {
                codigo: 3,
                barrio: "Bajo Cartagos",
              },
              4: {
                codigo: 4,
                barrio: "Pato",
              },
              5: {
                codigo: 5,
                barrio: "Pejibaye",
              },
              6: {
                codigo: 6,
                barrio: "Pimiento",
              },
              7: {
                codigo: 7,
                barrio: "Quebradon",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "KATIRA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Cabana (parte)",
              },
              2: {
                codigo: 2,
                barrio: "Colonia Naranjena",
              },
              3: {
                codigo: 3,
                barrio: "El Valle",
              },
              4: {
                codigo: 4,
                barrio: "Florida",
              },
              5: {
                codigo: 5,
                barrio: "Las Letras",
              },
              6: {
                codigo: 6,
                barrio: "La Paz",
              },
              7: {
                codigo: 7,
                barrio: "La Union",
              },
              8: {
                codigo: 8,
                barrio: "Llano Bonito 1",
              },
              9: {
                codigo: 9,
                barrio: "Llano Bonito 2",
              },
              10: {
                codigo: 10,
                barrio: "Palmera",
              },
              11: {
                codigo: 11,
                barrio: "Rio Celeste",
              },
              12: {
                codigo: 12,
                barrio: "Tujankir 1",
              },
              13: {
                codigo: 13,
                barrio: "Tujankir 2",
              },
            },
          },
        },
      },
      16: {
        codigo: 16,
        canton: "RIO CUARTO",
        distritos: {
          1: {
            codigo: 1,
            distrito: "RIO CUARTO",
            barrios: {},
          },
        },
      },
    },
  },
  3: {
    codigo: 3,
    provincia: "Cartago",
    cantones: {
      1: {
        codigo: 1,
        canton: "CARTAGO",
        distritos: {
          1: {
            codigo: 1,
            distrito: "ORIENTAL (Ciudad de Cartago parte)",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Angeles",
              },
              2: {
                codigo: 2,
                barrio: "Asis",
              },
              3: {
                codigo: 3,
                barrio: "Brisas",
              },
              4: {
                codigo: 4,
                barrio: "Calvario",
              },
              5: {
                codigo: 5,
                barrio: "Cerrillos",
              },
              6: {
                codigo: 6,
                barrio: "Corazon de Jesus",
              },
              7: {
                codigo: 7,
                barrio: "Cortinas",
              },
              8: {
                codigo: 8,
                barrio: "Cruz de Caravaca (parte)",
              },
              9: {
                codigo: 9,
                barrio: "Estadio",
              },
              10: {
                codigo: 10,
                barrio: "Galera",
              },
              11: {
                codigo: 11,
                barrio: "Hospital (parte)",
              },
              12: {
                codigo: 12,
                barrio: "Istaru",
              },
              13: {
                codigo: 13,
                barrio: "Jesus Jimenez (parte)",
              },
              14: {
                codigo: 14,
                barrio: "Matamoros",
              },
              15: {
                codigo: 15,
                barrio: "Montelimar",
              },
              16: {
                codigo: 16,
                barrio: "Puebla",
              },
              17: {
                codigo: 17,
                barrio: "Soledad",
              },
              18: {
                codigo: 18,
                barrio: "Telles",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "OCCIDENTAL (Ciudad de Cartago parte)",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Cinco Esquinas",
              },
              2: {
                codigo: 2,
                barrio: "Fatima",
              },
              3: {
                codigo: 3,
                barrio: "Hospital (parte)",
              },
              4: {
                codigo: 4,
                barrio: "Jesus Jimenez (parte)",
              },
              5: {
                codigo: 5,
                barrio: "Laborio",
              },
              6: {
                codigo: 6,
                barrio: "Molino",
              },
              7: {
                codigo: 7,
                barrio: "Murillo",
              },
              8: {
                codigo: 8,
                barrio: "Palmas",
              },
              9: {
                codigo: 9,
                barrio: "San Cayetano",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "CARMEN",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alpes",
              },
              2: {
                codigo: 2,
                barrio: "Asilo",
              },
              3: {
                codigo: 3,
                barrio: "Cruz de Caravaca (parte)",
              },
              4: {
                codigo: 4,
                barrio: "Diques",
              },
              5: {
                codigo: 5,
                barrio: "Fontana",
              },
              6: {
                codigo: 6,
                barrio: "Jora",
              },
              7: {
                codigo: 7,
                barrio: "Lopez",
              },
              8: {
                codigo: 8,
                barrio: "San Blas",
              },
              9: {
                codigo: 9,
                barrio: "Santa Eduvigis",
              },
              10: {
                codigo: 10,
                barrio: "Santa Fe",
              },
              11: {
                codigo: 11,
                barrio: "Solano",
              },
              12: {
                codigo: 12,
                barrio: "Turbina",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "SAN NICOLAS",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto de Ochomogo",
              },
              2: {
                codigo: 2,
                barrio: "Caracol",
              },
              3: {
                codigo: 3,
                barrio: "Cooperrosales",
              },
              4: {
                codigo: 4,
                barrio: "Cruz",
              },
              5: {
                codigo: 5,
                barrio: "Espinal",
              },
              6: {
                codigo: 6,
                barrio: "Johnson",
              },
              7: {
                codigo: 7,
                barrio: "Lima",
              },
              8: {
                codigo: 8,
                barrio: "Loyola",
              },
              9: {
                codigo: 9,
                barrio: "Nazareth",
              },
              10: {
                codigo: 10,
                barrio: "Ochomogo",
              },
              11: {
                codigo: 11,
                barrio: "Orontes",
              },
              12: {
                codigo: 12,
                barrio: "Pedregal",
              },
              13: {
                codigo: 13,
                barrio: "Quircot",
              },
              14: {
                codigo: 14,
                barrio: "Ronda",
              },
              15: {
                codigo: 15,
                barrio: "Rosas",
              },
              16: {
                codigo: 16,
                barrio: "San Nicolas",
              },
              17: {
                codigo: 17,
                barrio: "Violin",
              },
              18: {
                codigo: 18,
                barrio: "Alto de Ochomogo",
              },
              19: {
                codigo: 19,
                barrio: "Angelina",
              },
              20: {
                codigo: 20,
                barrio: "Banderilla",
              },
              21: {
                codigo: 21,
                barrio: "Kerkua",
              },
              22: {
                codigo: 22,
                barrio: "Molina",
              },
              23: {
                codigo: 23,
                barrio: "Poroses",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "AGUACALIENTE o SAN FRANCISCO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Cocori",
              },
              2: {
                codigo: 2,
                barrio: "Coronado",
              },
              3: {
                codigo: 3,
                barrio: "Guayabal (parte)",
              },
              4: {
                codigo: 4,
                barrio: "Hervidero",
              },
              5: {
                codigo: 5,
                barrio: "Lopez",
              },
              6: {
                codigo: 6,
                barrio: "Lourdes",
              },
              7: {
                codigo: 7,
                barrio: "Padua",
              },
              8: {
                codigo: 8,
                barrio: "Pitahaya",
              },
              9: {
                codigo: 9,
                barrio: "Barro Morado",
              },
              10: {
                codigo: 10,
                barrio: "Cenicero",
              },
              11: {
                codigo: 11,
                barrio: "Muneco",
              },
              12: {
                codigo: 12,
                barrio: "Navarrito",
              },
            },
          },
          6: {
            codigo: 6,
            distrito: "GUADALUPE",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Americas",
              },
              2: {
                codigo: 2,
                barrio: "Higueron",
              },
              3: {
                codigo: 3,
                barrio: "Joya",
              },
              4: {
                codigo: 4,
                barrio: "Marias",
              },
              5: {
                codigo: 5,
                barrio: "Las Palmas",
              },
            },
          },
          7: {
            codigo: 7,
            distrito: "CORRALILLO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alumbre",
              },
              2: {
                codigo: 2,
                barrio: "Bajo Amador",
              },
              3: {
                codigo: 3,
                barrio: "Calle Valverdes",
              },
              4: {
                codigo: 4,
                barrio: "Guaria",
              },
              5: {
                codigo: 5,
                barrio: "Hortensia",
              },
              6: {
                codigo: 6,
                barrio: "Loma Larga",
              },
              7: {
                codigo: 7,
                barrio: "Llano Angeles",
              },
              8: {
                codigo: 8,
                barrio: "Palangana",
              },
              9: {
                codigo: 9,
                barrio: "Rincon de Abarca",
              },
              10: {
                codigo: 10,
                barrio: "Rio Conejo",
              },
              11: {
                codigo: 11,
                barrio: "Salitrillo",
              },
              12: {
                codigo: 12,
                barrio: "San Antonio",
              },
              13: {
                codigo: 13,
                barrio: "San Joaquin",
              },
              14: {
                codigo: 14,
                barrio: "San Juan Norte",
              },
              15: {
                codigo: 15,
                barrio: "San Juan Sur",
              },
              16: {
                codigo: 16,
                barrio: "Santa Elena (parte)",
              },
              17: {
                codigo: 17,
                barrio: "Santa Elena Arriba",
              },
            },
          },
          8: {
            codigo: 8,
            distrito: "TIERRA BLANCA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Cuesta de Piedra",
              },
              2: {
                codigo: 2,
                barrio: "Mision Norte",
              },
              3: {
                codigo: 3,
                barrio: "Mision Sur",
              },
              4: {
                codigo: 4,
                barrio: "Ortiga",
              },
              5: {
                codigo: 5,
                barrio: "Rodeo",
              },
              6: {
                codigo: 6,
                barrio: "Sabanilla",
              },
              7: {
                codigo: 7,
                barrio: "Sabanillas",
              },
              8: {
                codigo: 8,
                barrio: "Santisima Trinidad",
              },
            },
          },
          9: {
            codigo: 9,
            distrito: "DULCE NOMBRE",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Caballo Blanco (parte)",
              },
              2: {
                codigo: 2,
                barrio: "San Jose",
              },
              3: {
                codigo: 3,
                barrio: "Concavas",
              },
              4: {
                codigo: 4,
                barrio: "Navarro",
              },
              5: {
                codigo: 5,
                barrio: "Perlas",
              },
              6: {
                codigo: 6,
                barrio: "Rio Claro",
              },
            },
          },
          10: {
            codigo: 10,
            distrito: "LLANO GRANDE",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Azahar",
              },
              2: {
                codigo: 2,
                barrio: "Avance",
              },
              3: {
                codigo: 3,
                barrio: "Barquero",
              },
              4: {
                codigo: 4,
                barrio: "Canada",
              },
              5: {
                codigo: 5,
                barrio: "Laguna",
              },
              6: {
                codigo: 6,
                barrio: "Penjamo",
              },
              7: {
                codigo: 7,
                barrio: "Rodeo",
              },
            },
          },
          11: {
            codigo: 11,
            distrito: "QUEBRADILLA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto Quebradilla",
              },
              2: {
                codigo: 2,
                barrio: "Azahar",
              },
              3: {
                codigo: 3,
                barrio: "Bermejo",
              },
              4: {
                codigo: 4,
                barrio: "Canada",
              },
              5: {
                codigo: 5,
                barrio: "Copalchi",
              },
              6: {
                codigo: 6,
                barrio: "Coris",
              },
              7: {
                codigo: 7,
                barrio: "Garita (parte)",
              },
              8: {
                codigo: 8,
                barrio: "Rueda",
              },
              9: {
                codigo: 9,
                barrio: "Valle Verde",
              },
            },
          },
        },
      },
      2: {
        codigo: 2,
        canton: "PARAISO",
        distritos: {
          1: {
            codigo: 1,
            distrito: "PARAISO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Barro Hondo",
              },
              2: {
                codigo: 2,
                barrio: "Cruz Roja",
              },
              3: {
                codigo: 3,
                barrio: "Cucaracho",
              },
              4: {
                codigo: 4,
                barrio: "Chiverre",
              },
              5: {
                codigo: 5,
                barrio: "Estacion",
              },
              6: {
                codigo: 6,
                barrio: "Joya",
              },
              7: {
                codigo: 7,
                barrio: "Pandora",
              },
              8: {
                codigo: 8,
                barrio: "Piedra Grande",
              },
              9: {
                codigo: 9,
                barrio: "Solares",
              },
              10: {
                codigo: 10,
                barrio: "Soledad",
              },
              11: {
                codigo: 11,
                barrio: "Veintiocho de Diciembre",
              },
              12: {
                codigo: 12,
                barrio: "Alto Birrisito",
              },
              13: {
                codigo: 13,
                barrio: "Birrisito",
              },
              14: {
                codigo: 14,
                barrio: "Chiral",
              },
              15: {
                codigo: 15,
                barrio: "Luisiana",
              },
              16: {
                codigo: 16,
                barrio: "Sanchiri",
              },
              17: {
                codigo: 17,
                barrio: "Ujarras",
              },
              18: {
                codigo: 18,
                barrio: "Villa Isabel",
              },
              19: {
                codigo: 19,
                barrio: "Rincon",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "SANTIAGO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Acevedo",
              },
              2: {
                codigo: 2,
                barrio: "Ajenjal",
              },
              3: {
                codigo: 3,
                barrio: "Arrabara",
              },
              4: {
                codigo: 4,
                barrio: "Birris (este)",
              },
              5: {
                codigo: 5,
                barrio: "Cuscares",
              },
              6: {
                codigo: 6,
                barrio: "Flor",
              },
              7: {
                codigo: 7,
                barrio: "Lapuente",
              },
              8: {
                codigo: 8,
                barrio: "Mesas",
              },
              9: {
                codigo: 9,
                barrio: "Mesitas",
              },
              10: {
                codigo: 10,
                barrio: "Nueva Ujarras",
              },
              11: {
                codigo: 11,
                barrio: "Pedregal",
              },
              12: {
                codigo: 12,
                barrio: "Piedra Azul",
              },
              13: {
                codigo: 13,
                barrio: "Puente Fajardo",
              },
              14: {
                codigo: 14,
                barrio: "Rio Regado",
              },
              15: {
                codigo: 15,
                barrio: "Talolinga",
              },
              16: {
                codigo: 16,
                barrio: "Yas",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "OROSI",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alegria",
              },
              2: {
                codigo: 2,
                barrio: "Alto Araya",
              },
              3: {
                codigo: 3,
                barrio: "Calle Juco",
              },
              4: {
                codigo: 4,
                barrio: "Hotel",
              },
              5: {
                codigo: 5,
                barrio: "Nubes",
              },
              6: {
                codigo: 6,
                barrio: "Palomas",
              },
              7: {
                codigo: 7,
                barrio: "Palomo",
              },
              8: {
                codigo: 8,
                barrio: "Patillos",
              },
              9: {
                codigo: 9,
                barrio: "Puente Negro",
              },
              10: {
                codigo: 10,
                barrio: "Purisil",
              },
              11: {
                codigo: 11,
                barrio: "Queveri",
              },
              12: {
                codigo: 12,
                barrio: "Rio Macho",
              },
              13: {
                codigo: 13,
                barrio: "San Rafael",
              },
              14: {
                codigo: 14,
                barrio: "Sitio",
              },
              15: {
                codigo: 15,
                barrio: "Tapanti",
              },
              16: {
                codigo: 16,
                barrio: "Troya",
              },
              17: {
                codigo: 17,
                barrio: "Villa Mills",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "CACHI",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Penas Blancas",
              },
              2: {
                codigo: 2,
                barrio: "Pueblo Nuevo",
              },
              3: {
                codigo: 3,
                barrio: "Bajos de Dorotea",
              },
              4: {
                codigo: 4,
                barrio: "Bajos de Urasca",
              },
              5: {
                codigo: 5,
                barrio: "Guabata",
              },
              6: {
                codigo: 6,
                barrio: "Guatusito",
              },
              7: {
                codigo: 7,
                barrio: "Hamaca (parte)",
              },
              8: {
                codigo: 8,
                barrio: "Joyas",
              },
              9: {
                codigo: 9,
                barrio: "Loaiza",
              },
              10: {
                codigo: 10,
                barrio: "San Jeronimo",
              },
              11: {
                codigo: 11,
                barrio: "Urasca",
              },
              12: {
                codigo: 12,
                barrio: "Volio",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "LLANOS DE SANTA LUCIA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Ayala",
              },
              2: {
                codigo: 2,
                barrio: "Paez (parte)",
              },
              3: {
                codigo: 3,
                barrio: "Salvador",
              },
            },
          },
        },
      },
      3: {
        codigo: 3,
        canton: "LA UNION",
        distritos: {
          1: {
            codigo: 1,
            distrito: "TRES RIOS",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Antigua",
              },
              2: {
                codigo: 2,
                barrio: "Villas",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "SAN DIEGO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Eulalia",
              },
              2: {
                codigo: 2,
                barrio: "Florencio del Castillo",
              },
              3: {
                codigo: 3,
                barrio: "Jirales",
              },
              4: {
                codigo: 4,
                barrio: "Mariana",
              },
              5: {
                codigo: 5,
                barrio: "Tacora",
              },
              6: {
                codigo: 6,
                barrio: "Rincon Mesen (parte)",
              },
              7: {
                codigo: 7,
                barrio: "Santiago del Monte",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "SAN JUAN",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Araucarias (parte)",
              },
              2: {
                codigo: 2,
                barrio: "Danza del Sol",
              },
              3: {
                codigo: 3,
                barrio: "Herran",
              },
              4: {
                codigo: 4,
                barrio: "Loma Verde",
              },
              5: {
                codigo: 5,
                barrio: "Union",
              },
              6: {
                codigo: 6,
                barrio: "Villas de Ayarco",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "SAN RAFAEL",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Carpintera",
              },
              2: {
                codigo: 2,
                barrio: "San Miguel",
              },
              3: {
                codigo: 3,
                barrio: "San Vicente",
              },
              4: {
                codigo: 4,
                barrio: "Pilarica",
              },
              5: {
                codigo: 5,
                barrio: "Quebrada del Fierro",
              },
              6: {
                codigo: 6,
                barrio: "Fierro",
              },
              7: {
                codigo: 7,
                barrio: "Yerbabuena",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "CONCEPCION",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Cima",
              },
              2: {
                codigo: 2,
                barrio: "Cuadrante",
              },
              3: {
                codigo: 3,
                barrio: "Lirios",
              },
              4: {
                codigo: 4,
                barrio: "Llanos de Concepcion",
              },
              5: {
                codigo: 5,
                barrio: "Naranjal (parte)",
              },
              6: {
                codigo: 6,
                barrio: "Poro",
              },
              7: {
                codigo: 7,
                barrio: "Salitrillo",
              },
              8: {
                codigo: 8,
                barrio: "San Francisco",
              },
              9: {
                codigo: 9,
                barrio: "San Josecito (parte)",
              },
            },
          },
          6: {
            codigo: 6,
            distrito: "DULCE NOMBRE",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto del Carmen",
              },
              2: {
                codigo: 2,
                barrio: "Tirra",
              },
            },
          },
          7: {
            codigo: 7,
            distrito: "SAN RAMON",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bellomonte",
              },
              2: {
                codigo: 2,
                barrio: "Cerrillo",
              },
              3: {
                codigo: 3,
                barrio: "Cumbres",
              },
              4: {
                codigo: 4,
                barrio: "Holandes",
              },
              5: {
                codigo: 5,
                barrio: "Mansiones (parte)",
              },
              6: {
                codigo: 6,
                barrio: "Montana Rusa (parte)",
              },
              7: {
                codigo: 7,
                barrio: "Naranjal (parte)",
              },
              8: {
                codigo: 8,
                barrio: "San Josecito (parte)",
              },
            },
          },
          8: {
            codigo: 8,
            distrito: "RIO AZUL",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Lindavista (Loma Gobierno)",
              },
              2: {
                codigo: 2,
                barrio: "Quebradas",
              },
              3: {
                codigo: 3,
                barrio: "Rincon Mesen (parte)",
              },
            },
          },
        },
      },
      4: {
        codigo: 4,
        canton: "JIMENEZ",
        distritos: {
          1: {
            codigo: 1,
            distrito: "JUAN VINAS",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alpes",
              },
              2: {
                codigo: 2,
                barrio: "Buenos Aires",
              },
              3: {
                codigo: 3,
                barrio: "Maravilla",
              },
              4: {
                codigo: 4,
                barrio: "Naranjito",
              },
              5: {
                codigo: 5,
                barrio: "Naranjo",
              },
              6: {
                codigo: 6,
                barrio: "San Martin",
              },
              7: {
                codigo: 7,
                barrio: "Duran",
              },
              8: {
                codigo: 8,
                barrio: "Gloria",
              },
              9: {
                codigo: 9,
                barrio: "Quebrada Honda",
              },
              10: {
                codigo: 10,
                barrio: "Santa Elena",
              },
              11: {
                codigo: 11,
                barrio: "Santa Marta",
              },
              12: {
                codigo: 12,
                barrio: "Victoria (Alto Victoria)",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "TUCURRIQUE",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto Campos",
              },
              2: {
                codigo: 2,
                barrio: "Bajo Congo",
              },
              3: {
                codigo: 3,
                barrio: "Congo",
              },
              4: {
                codigo: 4,
                barrio: "Duan",
              },
              5: {
                codigo: 5,
                barrio: "Esperanza",
              },
              6: {
                codigo: 6,
                barrio: "Hamaca (parte)",
              },
              7: {
                codigo: 7,
                barrio: "Sabanilla",
              },
              8: {
                codigo: 8,
                barrio: "San Antonio del Monte",
              },
              9: {
                codigo: 9,
                barrio: "Volconda",
              },
              10: {
                codigo: 10,
                barrio: "Vueltas",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "PEJIBAYE",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto Humo",
              },
              2: {
                codigo: 2,
                barrio: "Cacao",
              },
              3: {
                codigo: 3,
                barrio: "Cantarrana",
              },
              4: {
                codigo: 4,
                barrio: "Casa de Teja",
              },
              5: {
                codigo: 5,
                barrio: "Ceiba",
              },
              6: {
                codigo: 6,
                barrio: "Chucuyo",
              },
              7: {
                codigo: 7,
                barrio: "Esperanza",
              },
              8: {
                codigo: 8,
                barrio: "Gato",
              },
              9: {
                codigo: 9,
                barrio: "Humo",
              },
              10: {
                codigo: 10,
                barrio: "Joyas",
              },
              11: {
                codigo: 11,
                barrio: "Juray",
              },
              12: {
                codigo: 12,
                barrio: "Omega",
              },
              13: {
                codigo: 13,
                barrio: "Oriente",
              },
              14: {
                codigo: 14,
                barrio: "San Gerardo",
              },
              15: {
                codigo: 15,
                barrio: "Selva",
              },
              16: {
                codigo: 16,
                barrio: "Taus",
              },
              17: {
                codigo: 17,
                barrio: "Tausito",
              },
              18: {
                codigo: 18,
                barrio: "Yolanda",
              },
              19: {
                codigo: 19,
                barrio: "Zapote",
              },
            },
          },
        },
      },
      5: {
        codigo: 5,
        canton: "TURRIALBA",
        distritos: {
          1: {
            codigo: 1,
            distrito: "TURRIALBA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Americas",
              },
              2: {
                codigo: 2,
                barrio: "Angeles",
              },
              3: {
                codigo: 3,
                barrio: "Cabiria",
              },
              4: {
                codigo: 4,
                barrio: "Campabadal",
              },
              5: {
                codigo: 5,
                barrio: "Castro Salazar",
              },
              6: {
                codigo: 6,
                barrio: "Cementerio",
              },
              7: {
                codigo: 7,
                barrio: "Clorito Picado",
              },
              8: {
                codigo: 8,
                barrio: "Dominica",
              },
              9: {
                codigo: 9,
                barrio: "El Silencio",
              },
              10: {
                codigo: 10,
                barrio: "Guaria",
              },
              11: {
                codigo: 11,
                barrio: "Haciendita",
              },
              12: {
                codigo: 12,
                barrio: "Margot",
              },
              13: {
                codigo: 13,
                barrio: "Nochebuena",
              },
              14: {
                codigo: 14,
                barrio: "Numa",
              },
              15: {
                codigo: 15,
                barrio: "Pastor",
              },
              16: {
                codigo: 16,
                barrio: "Poro",
              },
              17: {
                codigo: 17,
                barrio: "Pueblo Nuevo",
              },
              18: {
                codigo: 18,
                barrio: "Repasto",
              },
              19: {
                codigo: 19,
                barrio: "San Cayetano",
              },
              20: {
                codigo: 20,
                barrio: "San Rafael",
              },
              21: {
                codigo: 21,
                barrio: "Sictaya",
              },
              22: {
                codigo: 22,
                barrio: "Bajo Barrientos",
              },
              23: {
                codigo: 23,
                barrio: "Canaveral",
              },
              24: {
                codigo: 24,
                barrio: "Colorado",
              },
              25: {
                codigo: 25,
                barrio: "Chiz",
              },
              26: {
                codigo: 26,
                barrio: "Esmeralda",
              },
              27: {
                codigo: 27,
                barrio: "Florencia",
              },
              28: {
                codigo: 28,
                barrio: "Murcia",
              },
              29: {
                codigo: 29,
                barrio: "Pavas",
              },
              30: {
                codigo: 30,
                barrio: "Recreo",
              },
              31: {
                codigo: 31,
                barrio: "Roncha",
              },
              32: {
                codigo: 32,
                barrio: "San Juan Norte",
              },
              33: {
                codigo: 33,
                barrio: "San Juan Sur",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "LA SUIZA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Leona (parte)",
              },
              2: {
                codigo: 2,
                barrio: "Abelardo Rojas",
              },
              3: {
                codigo: 3,
                barrio: "Alto Alemania",
              },
              4: {
                codigo: 4,
                barrio: "Atirro",
              },
              5: {
                codigo: 5,
                barrio: "Balalaica",
              },
              6: {
                codigo: 6,
                barrio: "Buenos Aires",
              },
              7: {
                codigo: 7,
                barrio: "Canada",
              },
              8: {
                codigo: 8,
                barrio: "Carmen",
              },
              9: {
                codigo: 9,
                barrio: "Cruzada",
              },
              10: {
                codigo: 10,
                barrio: "Danta",
              },
              11: {
                codigo: 11,
                barrio: "Gaviotas",
              },
              12: {
                codigo: 12,
                barrio: "Guadalupe",
              },
              13: {
                codigo: 13,
                barrio: "Maquina Vieja",
              },
              14: {
                codigo: 14,
                barrio: "Margarita",
              },
              15: {
                codigo: 15,
                barrio: "Mollejones",
              },
              16: {
                codigo: 16,
                barrio: "Pacayitas",
              },
              17: {
                codigo: 17,
                barrio: "Pacuare",
              },
              18: {
                codigo: 18,
                barrio: "Piedra Grande",
              },
              19: {
                codigo: 19,
                barrio: "Porvenir de la Esperanza",
              },
              20: {
                codigo: 20,
                barrio: "Puente Alto",
              },
              21: {
                codigo: 21,
                barrio: "San Vicente",
              },
              22: {
                codigo: 22,
                barrio: "Selva (parte)",
              },
              23: {
                codigo: 23,
                barrio: "Silencio",
              },
              24: {
                codigo: 24,
                barrio: "Sonia",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "PERALTA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "El Seis",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "SANTA CRUZ",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bajos de Bonilla",
              },
              2: {
                codigo: 2,
                barrio: "Bolson (parte)",
              },
              3: {
                codigo: 3,
                barrio: "Bonilla",
              },
              4: {
                codigo: 4,
                barrio: "Buenos Aires",
              },
              5: {
                codigo: 5,
                barrio: "Calle Vargas",
              },
              6: {
                codigo: 6,
                barrio: "Carmen (parte)",
              },
              7: {
                codigo: 7,
                barrio: "Esperanza",
              },
              8: {
                codigo: 8,
                barrio: "Guayabo Arriba",
              },
              9: {
                codigo: 9,
                barrio: "La Central",
              },
              10: {
                codigo: 10,
                barrio: "La Fuente",
              },
              11: {
                codigo: 11,
                barrio: "Pastora",
              },
              12: {
                codigo: 12,
                barrio: "Picada",
              },
              13: {
                codigo: 13,
                barrio: "Raicero",
              },
              14: {
                codigo: 14,
                barrio: "Reunion",
              },
              15: {
                codigo: 15,
                barrio: "San Antonio",
              },
              16: {
                codigo: 16,
                barrio: "San Diego",
              },
              17: {
                codigo: 17,
                barrio: "Torito (parte)",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "SANTA TERESITA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Cooperativa",
              },
              2: {
                codigo: 2,
                barrio: "Bonilla Arriba",
              },
              3: {
                codigo: 3,
                barrio: "Buenavista",
              },
              4: {
                codigo: 4,
                barrio: "Cas",
              },
              5: {
                codigo: 5,
                barrio: "Cimarrones",
              },
              6: {
                codigo: 6,
                barrio: "Colonia Guayabo",
              },
              7: {
                codigo: 7,
                barrio: "Colonia San Ramon",
              },
              8: {
                codigo: 8,
                barrio: "Corralon",
              },
              9: {
                codigo: 9,
                barrio: "Dulce Nombre",
              },
              10: {
                codigo: 10,
                barrio: "El Dos",
              },
              11: {
                codigo: 11,
                barrio: "Fuente",
              },
              12: {
                codigo: 12,
                barrio: "Guayabo (parte)",
              },
              13: {
                codigo: 13,
                barrio: "Libano",
              },
              14: {
                codigo: 14,
                barrio: "Nueva Flor",
              },
              15: {
                codigo: 15,
                barrio: "Palomo",
              },
              16: {
                codigo: 16,
                barrio: "Pradera",
              },
              17: {
                codigo: 17,
                barrio: "Sandoval",
              },
              18: {
                codigo: 18,
                barrio: "Santa Tecla",
              },
              19: {
                codigo: 19,
                barrio: "Sauce",
              },
              20: {
                codigo: 20,
                barrio: "Torito (parte)",
              },
              21: {
                codigo: 21,
                barrio: "Torito (sur)",
              },
            },
          },
          6: {
            codigo: 6,
            distrito: "PAVONES",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Angostura",
              },
              2: {
                codigo: 2,
                barrio: "Boveda",
              },
              3: {
                codigo: 3,
                barrio: "Buenavista",
              },
              4: {
                codigo: 4,
                barrio: "Chitaria",
              },
              5: {
                codigo: 5,
                barrio: "Eslabon",
              },
              6: {
                codigo: 6,
                barrio: "Isla Bonita (parte)",
              },
              7: {
                codigo: 7,
                barrio: "Jabillos",
              },
              8: {
                codigo: 8,
                barrio: "San Rafael",
              },
              9: {
                codigo: 9,
                barrio: "Sitio Mata",
              },
              10: {
                codigo: 10,
                barrio: "Yama",
              },
            },
          },
          7: {
            codigo: 7,
            distrito: "TUIS",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Leona (parte)",
              },
              2: {
                codigo: 2,
                barrio: "Alto Surtubal",
              },
              3: {
                codigo: 3,
                barrio: "Angeles",
              },
              4: {
                codigo: 4,
                barrio: "Bajo Pacuare (norte)",
              },
              5: {
                codigo: 5,
                barrio: "Cabeza de Buey",
              },
              6: {
                codigo: 6,
                barrio: "Cien Manzanas",
              },
              7: {
                codigo: 7,
                barrio: "Colonia San Lucas",
              },
              8: {
                codigo: 8,
                barrio: "Colonia Silencio",
              },
              9: {
                codigo: 9,
                barrio: "Colonias",
              },
              10: {
                codigo: 10,
                barrio: "Mata de Guineo",
              },
              11: {
                codigo: 11,
                barrio: "Nubes",
              },
              12: {
                codigo: 12,
                barrio: "Paulina",
              },
              13: {
                codigo: 13,
                barrio: "Sacro",
              },
              14: {
                codigo: 14,
                barrio: "San Bosco",
              },
              15: {
                codigo: 15,
                barrio: "Selva (parte)",
              },
            },
          },
          8: {
            codigo: 8,
            distrito: "TAYUTIC",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bajo Pacuare (sur)",
              },
              2: {
                codigo: 2,
                barrio: "Dos Amigos",
              },
              3: {
                codigo: 3,
                barrio: "Dulce Nombre",
              },
              4: {
                codigo: 4,
                barrio: "Guineal",
              },
              5: {
                codigo: 5,
                barrio: "Jicotea",
              },
              6: {
                codigo: 6,
                barrio: "Mina",
              },
              7: {
                codigo: 7,
                barrio: "Morado",
              },
              8: {
                codigo: 8,
                barrio: "Quebradas",
              },
              9: {
                codigo: 9,
                barrio: "San Martin",
              },
              10: {
                codigo: 10,
                barrio: "San Rafael",
              },
              11: {
                codigo: 11,
                barrio: "Tacotal",
              },
            },
          },
          9: {
            codigo: 9,
            distrito: "SANTA ROSA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Aquiares",
              },
              2: {
                codigo: 2,
                barrio: "Bolson (parte)",
              },
              3: {
                codigo: 3,
                barrio: "Carmen (parte)",
              },
              4: {
                codigo: 4,
                barrio: "Rio Claro",
              },
              5: {
                codigo: 5,
                barrio: "San Rafael",
              },
              6: {
                codigo: 6,
                barrio: "Verbena Norte",
              },
              7: {
                codigo: 7,
                barrio: "Verbena Sur",
              },
            },
          },
          10: {
            codigo: 10,
            distrito: "TRES EQUIS",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto June",
              },
              2: {
                codigo: 2,
                barrio: "Corozal",
              },
              3: {
                codigo: 3,
                barrio: "Flor",
              },
              4: {
                codigo: 4,
                barrio: "Guanacasteca",
              },
              5: {
                codigo: 5,
                barrio: "Isla Bonita(parte)",
              },
              6: {
                codigo: 6,
                barrio: "Pilon de Azucar",
              },
              7: {
                codigo: 7,
                barrio: "San Pablo",
              },
              8: {
                codigo: 8,
                barrio: "Sol",
              },
            },
          },
          11: {
            codigo: 11,
            distrito: "ISABEL",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Azul",
              },
              2: {
                codigo: 2,
                barrio: "Animas",
              },
              3: {
                codigo: 3,
                barrio: "Alto de Varas (Alto Varal)",
              },
              4: {
                codigo: 4,
                barrio: "Guayabo (parte)",
              },
              5: {
                codigo: 5,
                barrio: "Jesus Maria",
              },
              6: {
                codigo: 6,
                barrio: "La Isabel",
              },
              7: {
                codigo: 7,
                barrio: "San Martin",
              },
            },
          },
          12: {
            codigo: 12,
            distrito: "CHIRRIPO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Carolina",
              },
              2: {
                codigo: 2,
                barrio: "Chirripo Abajo (parte)",
              },
              3: {
                codigo: 3,
                barrio: "Chirripo Arriba",
              },
              4: {
                codigo: 4,
                barrio: "Damaris",
              },
              5: {
                codigo: 5,
                barrio: "Fortuna",
              },
              6: {
                codigo: 6,
                barrio: "Jekui",
              },
              7: {
                codigo: 7,
                barrio: "Moravia",
              },
              8: {
                codigo: 8,
                barrio: "Namaldi",
              },
              9: {
                codigo: 9,
                barrio: "Pacuare arriba",
              },
              10: {
                codigo: 10,
                barrio: "Paso Marcos",
              },
              11: {
                codigo: 11,
                barrio: "Tsipiri (Platanillo)",
              },
              12: {
                codigo: 12,
                barrio: "Playa Hermosa",
              },
              13: {
                codigo: 13,
                barrio: "Porvenir",
              },
              14: {
                codigo: 14,
                barrio: "Raiz de Hule",
              },
              15: {
                codigo: 15,
                barrio: "Rio Blanco",
              },
              16: {
                codigo: 16,
                barrio: "Santubal",
              },
              17: {
                codigo: 17,
                barrio: "Suri",
              },
              18: {
                codigo: 18,
                barrio: "Vereh",
              },
              19: {
                codigo: 19,
                barrio: "Quetzal",
              },
            },
          },
        },
      },
      6: {
        codigo: 6,
        canton: "ALVARADO",
        distritos: {
          1: {
            codigo: 1,
            distrito: "PACAYAS",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Lourdes",
              },
              2: {
                codigo: 2,
                barrio: "Patalillo",
              },
              3: {
                codigo: 3,
                barrio: "Buenavista",
              },
              4: {
                codigo: 4,
                barrio: "Buenos Aires",
              },
              5: {
                codigo: 5,
                barrio: "Charcalillos",
              },
              6: {
                codigo: 6,
                barrio: "Encierrillo",
              },
              7: {
                codigo: 7,
                barrio: "Los Pinos (Coliblanco)",
              },
              8: {
                codigo: 8,
                barrio: "Llano Grande",
              },
              9: {
                codigo: 9,
                barrio: "Pascon",
              },
              10: {
                codigo: 10,
                barrio: "Pastora",
              },
              11: {
                codigo: 11,
                barrio: "Planton",
              },
              12: {
                codigo: 12,
                barrio: "San Martin (Irazu Sur)",
              },
              13: {
                codigo: 13,
                barrio: "San Rafael de Irazu",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "CERVANTES",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bajo Malanga",
              },
              2: {
                codigo: 2,
                barrio: "Aguas (parte)",
              },
              3: {
                codigo: 3,
                barrio: "Bajo Solano",
              },
              4: {
                codigo: 4,
                barrio: "Ciudad del Cielo",
              },
              5: {
                codigo: 5,
                barrio: "Descanso",
              },
              6: {
                codigo: 6,
                barrio: "El Alto",
              },
              7: {
                codigo: 7,
                barrio: "Mata de Guineo",
              },
              8: {
                codigo: 8,
                barrio: "Monticel",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "CAPELLADES",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bajo Abarca",
              },
              2: {
                codigo: 2,
                barrio: "Lourdes (Callejon)",
              },
              3: {
                codigo: 3,
                barrio: "Coliblanco",
              },
              4: {
                codigo: 4,
                barrio: "Santa Teresa",
              },
            },
          },
        },
      },
      7: {
        codigo: 7,
        canton: "OREAMUNO",
        distritos: {
          1: {
            codigo: 1,
            distrito: "SAN RAFAEL",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto Cerrillos (Corazon de Jesus)",
              },
              2: {
                codigo: 2,
                barrio: "Artavia",
              },
              3: {
                codigo: 3,
                barrio: "Barrial",
              },
              4: {
                codigo: 4,
                barrio: "Bosque",
              },
              5: {
                codigo: 5,
                barrio: "Brenas",
              },
              6: {
                codigo: 6,
                barrio: "Caballo Blanco (parte)",
              },
              7: {
                codigo: 7,
                barrio: "Chircagre",
              },
              8: {
                codigo: 8,
                barrio: "Flores",
              },
              9: {
                codigo: 9,
                barrio: "Gamboa",
              },
              10: {
                codigo: 10,
                barrio: "Jose Jesus Mendez",
              },
              11: {
                codigo: 11,
                barrio: "Juan Pablo II",
              },
              12: {
                codigo: 12,
                barrio: "Sagrada Familia",
              },
              13: {
                codigo: 13,
                barrio: "Monsenor Sanabria",
              },
              14: {
                codigo: 14,
                barrio: "Cuesta Chinchilla",
              },
              15: {
                codigo: 15,
                barrio: "Llano",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "COT",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Mata de Mora",
              },
              2: {
                codigo: 2,
                barrio: "Paez (parte)",
              },
              3: {
                codigo: 3,
                barrio: "Paso Ancho",
              },
              4: {
                codigo: 4,
                barrio: "San Cayetano",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "POTRERO CERRADO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Maya",
              },
              2: {
                codigo: 2,
                barrio: "Cruce",
              },
              3: {
                codigo: 3,
                barrio: "Pisco",
              },
              4: {
                codigo: 4,
                barrio: "Sanabria",
              },
              5: {
                codigo: 5,
                barrio: "San Juan de Chicua",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "CIPRESES",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Aguas (parte)",
              },
              2: {
                codigo: 2,
                barrio: "Barrionuevo",
              },
              3: {
                codigo: 3,
                barrio: "Boqueron",
              },
              4: {
                codigo: 4,
                barrio: "Capira",
              },
              5: {
                codigo: 5,
                barrio: "Oratorio",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "SANTA ROSA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Cuesta Quemados",
              },
              2: {
                codigo: 2,
                barrio: "Pasqui",
              },
              3: {
                codigo: 3,
                barrio: "Platanillal",
              },
              4: {
                codigo: 4,
                barrio: "San Gerardo",
              },
              5: {
                codigo: 5,
                barrio: "San Juan",
              },
              6: {
                codigo: 6,
                barrio: "San Martin",
              },
              7: {
                codigo: 7,
                barrio: "San Pablo",
              },
              8: {
                codigo: 8,
                barrio: "Titoral",
              },
            },
          },
        },
      },
      8: {
        codigo: 8,
        canton: "EL GUARCO",
        distritos: {
          1: {
            codigo: 1,
            distrito: "TEJAR",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Asuncion",
              },
              2: {
                codigo: 2,
                barrio: "Barahona",
              },
              3: {
                codigo: 3,
                barrio: "Barrio Nuevo",
              },
              4: {
                codigo: 4,
                barrio: "Colonia",
              },
              5: {
                codigo: 5,
                barrio: "Chavarria",
              },
              6: {
                codigo: 6,
                barrio: "Sabana",
              },
              7: {
                codigo: 7,
                barrio: "Sabana Grande",
              },
              8: {
                codigo: 8,
                barrio: "San Rafael",
              },
              9: {
                codigo: 9,
                barrio: "Santa Gertrudis",
              },
              10: {
                codigo: 10,
                barrio: "Sauces",
              },
              11: {
                codigo: 11,
                barrio: "Silo",
              },
              12: {
                codigo: 12,
                barrio: "Viento Fresco",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "SAN ISIDRO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Guatuso",
              },
              2: {
                codigo: 2,
                barrio: "Higuito",
              },
              3: {
                codigo: 3,
                barrio: "Potrerillos",
              },
              4: {
                codigo: 4,
                barrio: "Altamiradas",
              },
              5: {
                codigo: 5,
                barrio: "Alto San Francisco",
              },
              6: {
                codigo: 6,
                barrio: "Bajo Gloria",
              },
              7: {
                codigo: 7,
                barrio: "Bajos de Leon",
              },
              8: {
                codigo: 8,
                barrio: "Barrancas (parte)",
              },
              9: {
                codigo: 9,
                barrio: "Cangreja",
              },
              10: {
                codigo: 10,
                barrio: "Canon (parte)",
              },
              11: {
                codigo: 11,
                barrio: "Casablanca",
              },
              12: {
                codigo: 12,
                barrio: "Casamata",
              },
              13: {
                codigo: 13,
                barrio: "Cascajal",
              },
              14: {
                codigo: 14,
                barrio: "Conventillo",
              },
              15: {
                codigo: 15,
                barrio: "Cruces",
              },
              16: {
                codigo: 16,
                barrio: "Cruz",
              },
              17: {
                codigo: 17,
                barrio: "Chonta (parte)",
              },
              18: {
                codigo: 18,
                barrio: "Damita",
              },
              19: {
                codigo: 19,
                barrio: "Dos Amigos",
              },
              20: {
                codigo: 20,
                barrio: "Empalme (parte)",
              },
              21: {
                codigo: 21,
                barrio: "Esperanza",
              },
              22: {
                codigo: 22,
                barrio: "Estrella",
              },
              23: {
                codigo: 23,
                barrio: "Guayabal (parte)",
              },
              24: {
                codigo: 24,
                barrio: "La Luchita",
              },
              25: {
                codigo: 25,
                barrio: "La Paz",
              },
              26: {
                codigo: 26,
                barrio: "Macho Gaff",
              },
              27: {
                codigo: 27,
                barrio: "Montserrat",
              },
              28: {
                codigo: 28,
                barrio: "Ojo de Agua (parte)",
              },
              29: {
                codigo: 29,
                barrio: "Palmital",
              },
              30: {
                codigo: 30,
                barrio: "Palmital Sur",
              },
              31: {
                codigo: 31,
                barrio: "Palo Verde",
              },
              32: {
                codigo: 32,
                barrio: "Paso Macho (parte)",
              },
              33: {
                codigo: 33,
                barrio: "Purires (parte)",
              },
              34: {
                codigo: 34,
                barrio: "Salsipuedes (parte)",
              },
              35: {
                codigo: 35,
                barrio: "San Cayetano",
              },
              36: {
                codigo: 36,
                barrio: "Surtubal",
              },
              37: {
                codigo: 37,
                barrio: "Tres de Junio",
              },
              38: {
                codigo: 38,
                barrio: "Vara del Roble",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "TOBOSI",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Achiotillo",
              },
              2: {
                codigo: 2,
                barrio: "Barrancas (parte)",
              },
              3: {
                codigo: 3,
                barrio: "Bodocal",
              },
              4: {
                codigo: 4,
                barrio: "Garita (parte)",
              },
              5: {
                codigo: 5,
                barrio: "Purires (parte)",
              },
              6: {
                codigo: 6,
                barrio: "Tablon",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "PATIO DE AGUA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bajo Zopilote",
              },
              2: {
                codigo: 2,
                barrio: "Caragral",
              },
              3: {
                codigo: 3,
                barrio: "Comun",
              },
            },
          },
        },
      },
    },
  },
  4: {
    codigo: 4,
    provincia: "Heredia",
    cantones: {
      1: {
        codigo: 1,
        canton: "HEREDIA",
        distritos: {
          1: {
            codigo: 1,
            distrito: "HEREDIA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Angeles",
              },
              2: {
                codigo: 2,
                barrio: "Carmen",
              },
              3: {
                codigo: 3,
                barrio: "Corazon de Jesus",
              },
              4: {
                codigo: 4,
                barrio: "Chino",
              },
              5: {
                codigo: 5,
                barrio: "Estadio",
              },
              6: {
                codigo: 6,
                barrio: "Fatima",
              },
              7: {
                codigo: 7,
                barrio: "Guayabal",
              },
              8: {
                codigo: 8,
                barrio: "La India",
              },
              9: {
                codigo: 9,
                barrio: "Lourdes",
              },
              10: {
                codigo: 10,
                barrio: "Hospital",
              },
              11: {
                codigo: 11,
                barrio: "Maria Auxiliadora (parte)",
              },
              12: {
                codigo: 12,
                barrio: "Oriente",
              },
              13: {
                codigo: 13,
                barrio: "Pirro",
              },
              14: {
                codigo: 14,
                barrio: "Puebla (parte)",
              },
              15: {
                codigo: 15,
                barrio: "Rancho Chico",
              },
              16: {
                codigo: 16,
                barrio: "San Fernando",
              },
              17: {
                codigo: 17,
                barrio: "San Vicente",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "MERCEDES",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Burio",
              },
              2: {
                codigo: 2,
                barrio: "Carbonal",
              },
              3: {
                codigo: 3,
                barrio: "Cubujuqui",
              },
              4: {
                codigo: 4,
                barrio: "Espana",
              },
              5: {
                codigo: 5,
                barrio: "Labrador",
              },
              6: {
                codigo: 6,
                barrio: "Mercedes Sur",
              },
              7: {
                codigo: 7,
                barrio: "Monte Bello",
              },
              8: {
                codigo: 8,
                barrio: "San Jorge",
              },
              9: {
                codigo: 9,
                barrio: "Santa Ines",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "SAN FRANCISCO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Aries",
              },
              2: {
                codigo: 2,
                barrio: "Aurora (parte)",
              },
              3: {
                codigo: 3,
                barrio: "Bernardo Benavides",
              },
              4: {
                codigo: 4,
                barrio: "Chucos",
              },
              5: {
                codigo: 5,
                barrio: "El Cristo (parte)",
              },
              6: {
                codigo: 6,
                barrio: "Esmeralda",
              },
              7: {
                codigo: 7,
                barrio: "Esperanza",
              },
              8: {
                codigo: 8,
                barrio: "Granada",
              },
              9: {
                codigo: 9,
                barrio: "Gran Samaria",
              },
              10: {
                codigo: 10,
                barrio: "Guarari",
              },
              11: {
                codigo: 11,
                barrio: "Lagos",
              },
              12: {
                codigo: 12,
                barrio: "Malinches",
              },
              13: {
                codigo: 13,
                barrio: "Mayorga (parte)",
              },
              14: {
                codigo: 14,
                barrio: "Nisperos 3",
              },
              15: {
                codigo: 15,
                barrio: "Palma",
              },
              16: {
                codigo: 16,
                barrio: "Trebol",
              },
              17: {
                codigo: 17,
                barrio: "Tropical",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "ULLOA O BARREAL",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Arcos",
              },
              2: {
                codigo: 2,
                barrio: "Aurora (parte)",
              },
              3: {
                codigo: 3,
                barrio: "Bajos del Virilla (San Rafael)",
              },
              4: {
                codigo: 4,
                barrio: "Cariari (parte)",
              },
              5: {
                codigo: 5,
                barrio: "Carpintera",
              },
              6: {
                codigo: 6,
                barrio: "El Cristo (parte)",
              },
              7: {
                codigo: 7,
                barrio: "Lagunilla",
              },
              8: {
                codigo: 8,
                barrio: "Linda del Norte",
              },
              9: {
                codigo: 9,
                barrio: "Mayorga (parte)",
              },
              10: {
                codigo: 10,
                barrio: "Monterrey",
              },
              11: {
                codigo: 11,
                barrio: "Pitahaya",
              },
              12: {
                codigo: 12,
                barrio: "Pueblo Nuevo",
              },
              13: {
                codigo: 13,
                barrio: "Valencia (parte)",
              },
              14: {
                codigo: 14,
                barrio: "Vista Nosara",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "VARA BLANCA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Jesus Maria",
              },
              2: {
                codigo: 2,
                barrio: "Legua",
              },
              3: {
                codigo: 3,
                barrio: "Legua de Barva",
              },
              4: {
                codigo: 4,
                barrio: "Montana Azul",
              },
              5: {
                codigo: 5,
                barrio: "San Rafael",
              },
              6: {
                codigo: 6,
                barrio: "Virgen del Socorro (parte)",
              },
            },
          },
        },
      },
      2: {
        codigo: 2,
        canton: "BARVA",
        distritos: {
          1: {
            codigo: 1,
            distrito: "BARVA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Don Abraham",
              },
              2: {
                codigo: 2,
                barrio: "San Bartolome",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "SAN PEDRO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bosque",
              },
              2: {
                codigo: 2,
                barrio: "Calle Amada",
              },
              3: {
                codigo: 3,
                barrio: "Calle Los Naranjos",
              },
              4: {
                codigo: 4,
                barrio: "Espinos",
              },
              5: {
                codigo: 5,
                barrio: "Maquina",
              },
              6: {
                codigo: 6,
                barrio: "Mirador",
              },
              7: {
                codigo: 7,
                barrio: "Morazan",
              },
              8: {
                codigo: 8,
                barrio: "Puente Salas",
              },
              9: {
                codigo: 9,
                barrio: "Segura",
              },
              10: {
                codigo: 10,
                barrio: "Vista Llana",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "SAN PABLO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Cementerio",
              },
              2: {
                codigo: 2,
                barrio: "Ibis",
              },
              3: {
                codigo: 3,
                barrio: "Buenavista",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "SAN ROQUE",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bello Higueron",
              },
              2: {
                codigo: 2,
                barrio: "Los Luises",
              },
              3: {
                codigo: 3,
                barrio: "Plantacion",
              },
              4: {
                codigo: 4,
                barrio: "Portico",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "SANTA LUCIA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Dona Iris",
              },
              2: {
                codigo: 2,
                barrio: "Jardines del Beneficio",
              },
              3: {
                codigo: 3,
                barrio: "Paso Viga (parte)",
              },
              4: {
                codigo: 4,
                barrio: "Pedregal",
              },
              5: {
                codigo: 5,
                barrio: "Getsemani (parte)",
              },
              6: {
                codigo: 6,
                barrio: "Palmar (parte)",
              },
            },
          },
          6: {
            codigo: 6,
            distrito: "SAN JOSE DE LA MONTANA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Gallito",
              },
              2: {
                codigo: 2,
                barrio: "Monte Alto",
              },
              3: {
                codigo: 3,
                barrio: "Cipresal",
              },
              4: {
                codigo: 4,
                barrio: "Dona Blanca",
              },
              5: {
                codigo: 5,
                barrio: "Dona Elena",
              },
              6: {
                codigo: 6,
                barrio: "Higueron",
              },
              7: {
                codigo: 7,
                barrio: "Huacalillo",
              },
              8: {
                codigo: 8,
                barrio: "El Collado",
              },
              9: {
                codigo: 9,
                barrio: "Meseta",
              },
              10: {
                codigo: 10,
                barrio: "Paso Llano",
              },
              11: {
                codigo: 11,
                barrio: "Plan de Birri",
              },
              12: {
                codigo: 12,
                barrio: "Porrosati",
              },
              13: {
                codigo: 13,
                barrio: "Roblealto",
              },
              14: {
                codigo: 14,
                barrio: "Sacramento",
              },
              15: {
                codigo: 15,
                barrio: "San Martin",
              },
              16: {
                codigo: 16,
                barrio: "San Miguel",
              },
              17: {
                codigo: 17,
                barrio: "Santa Clara",
              },
              18: {
                codigo: 18,
                barrio: "Zapata",
              },
            },
          },
        },
      },
      3: {
        codigo: 3,
        canton: "SANTO DOMINGO",
        distritos: {
          1: {
            codigo: 1,
            distrito: "SANTO DOMINGO",
            barrios: {},
          },
          2: {
            codigo: 2,
            distrito: "SAN VICENTE",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Barro de Olla",
              },
              2: {
                codigo: 2,
                barrio: "Calle Don Pedro",
              },
              3: {
                codigo: 3,
                barrio: "Quintana",
              },
              4: {
                codigo: 4,
                barrio: "Yurusti",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "SAN MIGUEL",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Canoa (parte)",
              },
              2: {
                codigo: 2,
                barrio: "Castilla",
              },
              3: {
                codigo: 3,
                barrio: "Cuesta Rojas",
              },
              4: {
                codigo: 4,
                barrio: "Montero",
              },
              5: {
                codigo: 5,
                barrio: "Socorro",
              },
              6: {
                codigo: 6,
                barrio: "Villa Rossi",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "PARACITO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Represa",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "SANTO TOMAS",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Barquero",
              },
              2: {
                codigo: 2,
                barrio: "Higinia",
              },
              3: {
                codigo: 3,
                barrio: "Pacifica",
              },
            },
          },
          6: {
            codigo: 6,
            distrito: "SANTA ROSA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "La Cooperativa",
              },
              2: {
                codigo: 2,
                barrio: "Pedro Leon",
              },
              3: {
                codigo: 3,
                barrio: "Primero de Mayo",
              },
              4: {
                codigo: 4,
                barrio: "Quisqueya",
              },
              5: {
                codigo: 5,
                barrio: "Rinconada",
              },
              6: {
                codigo: 6,
                barrio: "Valencia (parte)",
              },
            },
          },
          7: {
            codigo: 7,
            distrito: "TURES",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Calle Vieja",
              },
              2: {
                codigo: 2,
                barrio: "Lourdes",
              },
              3: {
                codigo: 3,
                barrio: "Quebradas (parte)",
              },
            },
          },
          8: {
            codigo: 8,
            distrito: "PARA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Caballero",
              },
              2: {
                codigo: 2,
                barrio: "Canoa (parte)",
              },
            },
          },
        },
      },
      4: {
        codigo: 4,
        canton: "SANTA BARBARA",
        distritos: {
          1: {
            codigo: 1,
            distrito: "SANTA BARBARA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Trompezon",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "SAN PEDRO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Betania",
              },
              2: {
                codigo: 2,
                barrio: "Rosales (parte)",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "SAN JUAN",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Cinco Esquinas",
              },
              2: {
                codigo: 2,
                barrio: "Lotes Moreira",
              },
              3: {
                codigo: 3,
                barrio: "San Juan Arriba",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "JESUS",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Altagracia",
              },
              2: {
                codigo: 2,
                barrio: "Birri",
              },
              3: {
                codigo: 3,
                barrio: "Calle Quiros (parte)",
              },
              4: {
                codigo: 4,
                barrio: "Catalina",
              },
              5: {
                codigo: 5,
                barrio: "Comun (parte)",
              },
              6: {
                codigo: 6,
                barrio: "Cuesta Colorada",
              },
              7: {
                codigo: 7,
                barrio: "Guachipelines",
              },
              8: {
                codigo: 8,
                barrio: "Guaracha",
              },
              9: {
                codigo: 9,
                barrio: "Ulises",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "SANTO DOMINGO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Amapola",
              },
              2: {
                codigo: 2,
                barrio: "Cartagos",
              },
              3: {
                codigo: 3,
                barrio: "Chaguite",
              },
              4: {
                codigo: 4,
                barrio: "Giralda",
              },
              5: {
                codigo: 5,
                barrio: "Guarari",
              },
              6: {
                codigo: 6,
                barrio: "Tranquera",
              },
            },
          },
          6: {
            codigo: 6,
            distrito: "PURABA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Marias",
              },
              2: {
                codigo: 2,
                barrio: "Puraba",
              },
              3: {
                codigo: 3,
                barrio: "San Bosco",
              },
              4: {
                codigo: 4,
                barrio: "Calle Quiros (parte)",
              },
              5: {
                codigo: 5,
                barrio: "Comun (parte)",
              },
              6: {
                codigo: 6,
                barrio: "Lajas",
              },
            },
          },
        },
      },
      5: {
        codigo: 5,
        canton: "SAN RAFAEL",
        distritos: {
          1: {
            codigo: 1,
            distrito: "SAN RAFAEL",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Amistad",
              },
              2: {
                codigo: 2,
                barrio: "Matasano (parte)",
              },
              3: {
                codigo: 3,
                barrio: "Paso Viga (parte)",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "SAN JOSECITO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bajo Molinos",
              },
              2: {
                codigo: 2,
                barrio: "Joya",
              },
              3: {
                codigo: 3,
                barrio: "Matasano (parte)",
              },
              4: {
                codigo: 4,
                barrio: "Peralta",
              },
              5: {
                codigo: 5,
                barrio: "Santisima Trinidad",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "SANTIAGO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Jardines de Roma",
              },
              2: {
                codigo: 2,
                barrio: "Jardines Universitarios",
              },
              3: {
                codigo: 3,
                barrio: "Suiza",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "ANGELES",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Paso Viga (parte)",
              },
              2: {
                codigo: 2,
                barrio: "Saca",
              },
              3: {
                codigo: 3,
                barrio: "Calle Hernandez (parte)",
              },
              4: {
                codigo: 4,
                barrio: "Castillo",
              },
              5: {
                codigo: 5,
                barrio: "Cerro Redondo",
              },
              6: {
                codigo: 6,
                barrio: "Getsemani (parte)",
              },
              7: {
                codigo: 7,
                barrio: "Joaquina",
              },
              8: {
                codigo: 8,
                barrio: "Lobos",
              },
              9: {
                codigo: 9,
                barrio: "Montecito",
              },
              10: {
                codigo: 10,
                barrio: "Palmar (parte)",
              },
              11: {
                codigo: 11,
                barrio: "Puente Piedra (parte)",
              },
              12: {
                codigo: 12,
                barrio: "Quintanar de la Sierra",
              },
              13: {
                codigo: 13,
                barrio: "Uvita",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "CONCEPCION",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Palenque",
              },
              2: {
                codigo: 2,
                barrio: "Anonos",
              },
              3: {
                codigo: 3,
                barrio: "Burial",
              },
              4: {
                codigo: 4,
                barrio: "Calle Chavez (parte)",
              },
              5: {
                codigo: 5,
                barrio: "Calle Hernandez (parte)",
              },
              6: {
                codigo: 6,
                barrio: "Cienagas",
              },
              7: {
                codigo: 7,
                barrio: "Charquillo",
              },
              8: {
                codigo: 8,
                barrio: "Mora",
              },
              9: {
                codigo: 9,
                barrio: "Pilas",
              },
              10: {
                codigo: 10,
                barrio: "Puente Piedra (parte)",
              },
              11: {
                codigo: 11,
                barrio: "Tierra Blanca (parte)",
              },
              12: {
                codigo: 12,
                barrio: "Turu",
              },
            },
          },
        },
      },
      6: {
        codigo: 6,
        canton: "SAN ISIDRO",
        distritos: {
          1: {
            codigo: 1,
            distrito: "SAN ISIDRO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Calle Cementerio",
              },
              2: {
                codigo: 2,
                barrio: "Colonia Isidrena",
              },
              3: {
                codigo: 3,
                barrio: "Cooperativa",
              },
              4: {
                codigo: 4,
                barrio: "Cristo Rey",
              },
              5: {
                codigo: 5,
                barrio: "El Volador",
              },
              6: {
                codigo: 6,
                barrio: "Villaval",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "SAN JOSE",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Santa Cruz",
              },
              2: {
                codigo: 2,
                barrio: "El Arroyo",
              },
              3: {
                codigo: 3,
                barrio: "Huacalillos",
              },
              4: {
                codigo: 4,
                barrio: "Santa Cecilia (parte)",
              },
              5: {
                codigo: 5,
                barrio: "Santa Elena",
              },
              6: {
                codigo: 6,
                barrio: "Trapiche",
              },
              7: {
                codigo: 7,
                barrio: "Yerbabuena",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "CONCEPCION",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alhajas",
              },
              2: {
                codigo: 2,
                barrio: "Calle Caricias",
              },
              3: {
                codigo: 3,
                barrio: "Calle Chavez (parte)",
              },
              4: {
                codigo: 4,
                barrio: "Santa Cecilia",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "SAN FRANCISO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Aguacate",
              },
              2: {
                codigo: 2,
                barrio: "Astillero",
              },
              3: {
                codigo: 3,
                barrio: "Quebradas (parte)",
              },
              4: {
                codigo: 4,
                barrio: "Rinconada",
              },
              5: {
                codigo: 5,
                barrio: "Tierra Blanca (parte)",
              },
              6: {
                codigo: 6,
                barrio: "Vallevistar",
              },
              7: {
                codigo: 7,
                barrio: "Viento Fresco",
              },
            },
          },
        },
      },
      7: {
        codigo: 7,
        canton: "BELEN",
        distritos: {
          1: {
            codigo: 1,
            distrito: "SAN ANTONIO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Chompipes (parte)",
              },
              2: {
                codigo: 2,
                barrio: "Escobal",
              },
              3: {
                codigo: 3,
                barrio: "Labores (parte)",
              },
              4: {
                codigo: 4,
                barrio: "San Vicente",
              },
              5: {
                codigo: 5,
                barrio: "Zaiqui",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "RIVERA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Fuente",
              },
              2: {
                codigo: 2,
                barrio: "Labores (parte)",
              },
              3: {
                codigo: 3,
                barrio: "Vista Linda",
              },
              4: {
                codigo: 4,
                barrio: "Cristo Rey (parte)",
              },
              5: {
                codigo: 5,
                barrio: "Echeverria (parte)",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "ASUNCION",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Arbolito",
              },
              2: {
                codigo: 2,
                barrio: "Bonanza",
              },
              3: {
                codigo: 3,
                barrio: "Bosques de Dona Rosa",
              },
              4: {
                codigo: 4,
                barrio: "Cariari (parte)",
              },
              5: {
                codigo: 5,
                barrio: "Chompipes (parte)",
              },
              6: {
                codigo: 6,
                barrio: "Cristo Rey (parte)",
              },
            },
          },
        },
      },
      8: {
        codigo: 8,
        canton: "FLORES",
        distritos: {
          1: {
            codigo: 1,
            distrito: "SAN JOAQUIN",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Campanario",
              },
              2: {
                codigo: 2,
                barrio: "Joaquinenos",
              },
              3: {
                codigo: 3,
                barrio: "Luisiana",
              },
              4: {
                codigo: 4,
                barrio: "Santa Marta",
              },
              5: {
                codigo: 5,
                barrio: "Trinidad",
              },
              6: {
                codigo: 6,
                barrio: "Villa Lico",
              },
              7: {
                codigo: 7,
                barrio: "Villa Maria",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "BARRANTES",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Barrantes",
              },
              2: {
                codigo: 2,
                barrio: "Ugalde",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "LLORENTE",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Angeles",
              },
              2: {
                codigo: 2,
                barrio: "Ano 2000",
              },
              3: {
                codigo: 3,
                barrio: "Cristo Rey (parte)",
              },
              4: {
                codigo: 4,
                barrio: "Geranios",
              },
              5: {
                codigo: 5,
                barrio: "Las Hadas",
              },
              6: {
                codigo: 6,
                barrio: "Santa Elena",
              },
              7: {
                codigo: 7,
                barrio: "Siglo Veintiuno",
              },
              8: {
                codigo: 8,
                barrio: "Echeverria (parte)",
              },
            },
          },
        },
      },
      9: {
        codigo: 9,
        canton: "SAN PABLO",
        distritos: {
          1: {
            codigo: 1,
            distrito: "SAN PABLO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Acapulco",
              },
              2: {
                codigo: 2,
                barrio: "Amada",
              },
              3: {
                codigo: 3,
                barrio: "Asovigui",
              },
              4: {
                codigo: 4,
                barrio: "Colonial",
              },
              5: {
                codigo: 5,
                barrio: "Cruces",
              },
              6: {
                codigo: 6,
                barrio: "Dona Nina",
              },
              7: {
                codigo: 7,
                barrio: "Irazu",
              },
              8: {
                codigo: 8,
                barrio: "Irma",
              },
              9: {
                codigo: 9,
                barrio: "July",
              },
              10: {
                codigo: 10,
                barrio: "Maria Auxiliadora (parte)",
              },
              11: {
                codigo: 11,
                barrio: "Nueva Jerusalen",
              },
              12: {
                codigo: 12,
                barrio: "Pastoras",
              },
              13: {
                codigo: 13,
                barrio: "Puebla (parte)",
              },
              14: {
                codigo: 14,
                barrio: "Santa Isabel",
              },
              15: {
                codigo: 15,
                barrio: "Villa Cortes",
              },
              16: {
                codigo: 16,
                barrio: "Villa Dolores",
              },
              17: {
                codigo: 17,
                barrio: "Villa Quintana",
              },
              18: {
                codigo: 18,
                barrio: "Uriche",
              },
              19: {
                codigo: 19,
                barrio: "Uruca",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "RINCON DE SABANILLA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Corobici",
              },
              2: {
                codigo: 2,
                barrio: "Estrella",
              },
              3: {
                codigo: 3,
                barrio: "Rincon de Ricardo",
              },
              4: {
                codigo: 4,
                barrio: "Santa Fe",
              },
            },
          },
        },
      },
      10: {
        codigo: 10,
        canton: "SARAPIQUI",
        distritos: {
          1: {
            codigo: 1,
            distrito: "PUERTO VIEJO SARAPIQUI",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Colina",
              },
              2: {
                codigo: 2,
                barrio: "Esperanza",
              },
              3: {
                codigo: 3,
                barrio: "Jardin",
              },
              4: {
                codigo: 4,
                barrio: "Loma Linda",
              },
              5: {
                codigo: 5,
                barrio: "Progreso",
              },
              6: {
                codigo: 6,
                barrio: "Achiote",
              },
              7: {
                codigo: 7,
                barrio: "Ahogados",
              },
              8: {
                codigo: 8,
                barrio: "Arbolitos (parte)",
              },
              9: {
                codigo: 9,
                barrio: "Arrepentidos",
              },
              10: {
                codigo: 10,
                barrio: "Boca Ceiba",
              },
              11: {
                codigo: 11,
                barrio: "Boca Rio Sucio",
              },
              12: {
                codigo: 12,
                barrio: "Bun",
              },
              13: {
                codigo: 13,
                barrio: "Cabezas",
              },
              14: {
                codigo: 14,
                barrio: "Canfin",
              },
              15: {
                codigo: 15,
                barrio: "Cano Negro",
              },
              16: {
                codigo: 16,
                barrio: "Cerro Negro (Parte)",
              },
              17: {
                codigo: 17,
                barrio: "Colonia San Jose",
              },
              18: {
                codigo: 18,
                barrio: "Coyol",
              },
              19: {
                codigo: 19,
                barrio: "Cristo Rey",
              },
              20: {
                codigo: 20,
                barrio: "Chilamate",
              },
              21: {
                codigo: 21,
                barrio: "El Progreso",
              },
              22: {
                codigo: 22,
                barrio: "Esperanza",
              },
              23: {
                codigo: 23,
                barrio: "Estrellales",
              },
              24: {
                codigo: 24,
                barrio: "Guaria",
              },
              25: {
                codigo: 25,
                barrio: "Jardin",
              },
              26: {
                codigo: 26,
                barrio: "Jormo",
              },
              27: {
                codigo: 27,
                barrio: "Las Marias",
              },
              28: {
                codigo: 28,
                barrio: "Las Orquideas",
              },
              29: {
                codigo: 29,
                barrio: "Los Lirios",
              },
              30: {
                codigo: 30,
                barrio: "Malinche",
              },
              31: {
                codigo: 31,
                barrio: "Media Vuelta",
              },
              32: {
                codigo: 32,
                barrio: "Medias (parte)",
              },
              33: {
                codigo: 33,
                barrio: "Muelle",
              },
              34: {
                codigo: 34,
                barrio: "Naranjal",
              },
              35: {
                codigo: 35,
                barrio: "Nogal",
              },
              36: {
                codigo: 36,
                barrio: "Pavas",
              },
              37: {
                codigo: 37,
                barrio: "Rojomaca",
              },
              38: {
                codigo: 38,
                barrio: "San Jose",
              },
              39: {
                codigo: 39,
                barrio: "San Julian",
              },
              40: {
                codigo: 40,
                barrio: "Tres Rosales",
              },
              41: {
                codigo: 41,
                barrio: "Vega de Sardinal (parte)",
              },
              42: {
                codigo: 42,
                barrio: "Zapote",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "LA VIRGEN",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Angeles",
              },
              2: {
                codigo: 2,
                barrio: "Arbolitos (parte)",
              },
              3: {
                codigo: 3,
                barrio: "Bajos de Chilamate",
              },
              4: {
                codigo: 4,
                barrio: "Boca Sardinal",
              },
              5: {
                codigo: 5,
                barrio: "Bosque",
              },
              6: {
                codigo: 6,
                barrio: "Bufalo",
              },
              7: {
                codigo: 7,
                barrio: "Delicias",
              },
              8: {
                codigo: 8,
                barrio: "El Uno",
              },
              9: {
                codigo: 9,
                barrio: "Esquipulas",
              },
              10: {
                codigo: 10,
                barrio: "Las Palmitas",
              },
              11: {
                codigo: 11,
                barrio: "Laqui",
              },
              12: {
                codigo: 12,
                barrio: "Lomas",
              },
              13: {
                codigo: 13,
                barrio: "Llano Grande",
              },
              14: {
                codigo: 14,
                barrio: "Magsaysay",
              },
              15: {
                codigo: 15,
                barrio: "Masaya",
              },
              16: {
                codigo: 16,
                barrio: "Medias (parte)",
              },
              17: {
                codigo: 17,
                barrio: "Pangola",
              },
              18: {
                codigo: 18,
                barrio: "Pozo Azul",
              },
              19: {
                codigo: 19,
                barrio: "Quebrada Grande",
              },
              20: {
                codigo: 20,
                barrio: "Rio Magdaleno",
              },
              21: {
                codigo: 21,
                barrio: "Roble",
              },
              22: {
                codigo: 22,
                barrio: "San Gerardo (parte)",
              },
              23: {
                codigo: 23,
                barrio: "San Isidro",
              },
              24: {
                codigo: 24,
                barrio: "San Jose Sur",
              },
              25: {
                codigo: 25,
                barrio: "San Ramon",
              },
              26: {
                codigo: 26,
                barrio: "La Delia",
              },
              27: {
                codigo: 27,
                barrio: "Sardinal",
              },
              28: {
                codigo: 28,
                barrio: "Tirimbina",
              },
              29: {
                codigo: 29,
                barrio: "Vega de Sardinal (parte)",
              },
              30: {
                codigo: 30,
                barrio: "Venados",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "HORQUETAS",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bambuzal",
              },
              2: {
                codigo: 2,
                barrio: "Cerro Negro (parte)",
              },
              3: {
                codigo: 3,
                barrio: "Colonia Bambu",
              },
              4: {
                codigo: 4,
                barrio: "Colonia Colegio",
              },
              5: {
                codigo: 5,
                barrio: "Colonia Esperanza",
              },
              6: {
                codigo: 6,
                barrio: "Colonia Huetar",
              },
              7: {
                codigo: 7,
                barrio: "Colonia Nazareth",
              },
              8: {
                codigo: 8,
                barrio: "Colonia Victoria",
              },
              9: {
                codigo: 9,
                barrio: "Colonia Villalobos",
              },
              10: {
                codigo: 10,
                barrio: "Cubujuqui",
              },
              11: {
                codigo: 11,
                barrio: "Chiripa",
              },
              12: {
                codigo: 12,
                barrio: "Fatima",
              },
              13: {
                codigo: 13,
                barrio: "Flaminia",
              },
              14: {
                codigo: 14,
                barrio: "Finca Uno",
              },
              15: {
                codigo: 15,
                barrio: "Finca Dos",
              },
              16: {
                codigo: 16,
                barrio: "Finca Tres",
              },
              17: {
                codigo: 17,
                barrio: "Finca Cinco",
              },
              18: {
                codigo: 18,
                barrio: "Finca Agua",
              },
              19: {
                codigo: 19,
                barrio: "Finca Zona Siete",
              },
              20: {
                codigo: 20,
                barrio: "Finca Zona Ocho",
              },
              21: {
                codigo: 21,
                barrio: "Finca Zona Diez",
              },
              22: {
                codigo: 22,
                barrio: "Finca Zona Once",
              },
              23: {
                codigo: 23,
                barrio: "Isla",
              },
              24: {
                codigo: 24,
                barrio: "Isla Grande",
              },
              25: {
                codigo: 25,
                barrio: "Isla Israel",
              },
              26: {
                codigo: 26,
                barrio: "La Conquista",
              },
              27: {
                codigo: 27,
                barrio: "La Chavez",
              },
              28: {
                codigo: 28,
                barrio: "La Vuelta",
              },
              29: {
                codigo: 29,
                barrio: "Rambla",
              },
              30: {
                codigo: 30,
                barrio: "Pedernales",
              },
              31: {
                codigo: 31,
                barrio: "Platanera",
              },
              32: {
                codigo: 32,
                barrio: "Rio Frio",
              },
              33: {
                codigo: 33,
                barrio: "San Bernardino",
              },
              34: {
                codigo: 34,
                barrio: "San Luis",
              },
              35: {
                codigo: 35,
                barrio: "Santa Clara",
              },
              36: {
                codigo: 36,
                barrio: "Tapa Viento",
              },
              37: {
                codigo: 37,
                barrio: "Ticari",
              },
              38: {
                codigo: 38,
                barrio: "Tigre",
              },
              39: {
                codigo: 39,
                barrio: "Villa Isabel",
              },
              40: {
                codigo: 40,
                barrio: "Villa Nueva",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "LLANURAS DEL GASPAR",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Cano San Luis",
              },
              2: {
                codigo: 2,
                barrio: "Chimurria",
              },
              3: {
                codigo: 3,
                barrio: "Chirriposito",
              },
              4: {
                codigo: 4,
                barrio: "Delta",
              },
              5: {
                codigo: 5,
                barrio: "Gaspar",
              },
              6: {
                codigo: 6,
                barrio: "Lagunilla",
              },
              7: {
                codigo: 7,
                barrio: "La Lucha",
              },
              8: {
                codigo: 8,
                barrio: "Tigra",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "CURENA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Cano Tambor",
              },
              2: {
                codigo: 2,
                barrio: "Copalchi",
              },
              3: {
                codigo: 3,
                barrio: "Curena",
              },
              4: {
                codigo: 4,
                barrio: "Paloseco",
              },
              5: {
                codigo: 5,
                barrio: "Remolinito",
              },
              6: {
                codigo: 6,
                barrio: "Tambor",
              },
              7: {
                codigo: 7,
                barrio: "Tierrabuena",
              },
              8: {
                codigo: 8,
                barrio: "Trinidad",
              },
              9: {
                codigo: 9,
                barrio: "Union del Toro",
              },
              10: {
                codigo: 10,
                barrio: "Vuelta Cabo de Hornos",
              },
            },
          },
        },
      },
    },
  },
  5: {
    codigo: 5,
    provincia: "Guanacaste",
    cantones: {
      1: {
        codigo: 1,
        canton: "LIBERIA",
        distritos: {
          1: {
            codigo: 1,
            distrito: "LIBERIA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alaska",
              },
              2: {
                codigo: 2,
                barrio: "Angeles",
              },
              3: {
                codigo: 3,
                barrio: "Buenos Aires",
              },
              4: {
                codigo: 4,
                barrio: "Capulin",
              },
              5: {
                codigo: 5,
                barrio: "Cerros",
              },
              6: {
                codigo: 6,
                barrio: "Condega",
              },
              7: {
                codigo: 7,
                barrio: "Corazon de Jesus",
              },
              8: {
                codigo: 8,
                barrio: "Curime",
              },
              9: {
                codigo: 9,
                barrio: "Choricera",
              },
              10: {
                codigo: 10,
                barrio: "Chorotega",
              },
              11: {
                codigo: 11,
                barrio: "Gallera",
              },
              12: {
                codigo: 12,
                barrio: "Guaria",
              },
              13: {
                codigo: 13,
                barrio: "Jicaro",
              },
              14: {
                codigo: 14,
                barrio: "La Carreta",
              },
              15: {
                codigo: 15,
                barrio: "Llano La Cruz",
              },
              16: {
                codigo: 16,
                barrio: "Mocho (Santa Lucia)",
              },
              17: {
                codigo: 17,
                barrio: "Moracia",
              },
              18: {
                codigo: 18,
                barrio: "Nazareth",
              },
              19: {
                codigo: 19,
                barrio: "Pueblo Nuevo",
              },
              20: {
                codigo: 20,
                barrio: "Sabanero",
              },
              21: {
                codigo: 21,
                barrio: "San Miguel",
              },
              22: {
                codigo: 22,
                barrio: "San Roque",
              },
              23: {
                codigo: 23,
                barrio: "Sitio",
              },
              24: {
                codigo: 24,
                barrio: "Veinticinco de Julio",
              },
              25: {
                codigo: 25,
                barrio: "Victoria",
              },
              26: {
                codigo: 26,
                barrio: "Villanueva",
              },
              27: {
                codigo: 27,
                barrio: "Arena",
              },
              28: {
                codigo: 28,
                barrio: "Carana",
              },
              29: {
                codigo: 29,
                barrio: "Isleta (parte)",
              },
              30: {
                codigo: 30,
                barrio: "Juanilama",
              },
              31: {
                codigo: 31,
                barrio: "Montanita",
              },
              32: {
                codigo: 32,
                barrio: "Paso Tempisque (parte)",
              },
              33: {
                codigo: 33,
                barrio: "Pelon de la Bajura",
              },
              34: {
                codigo: 34,
                barrio: "Polvazales",
              },
              35: {
                codigo: 35,
                barrio: "Roble de Sabana",
              },
              36: {
                codigo: 36,
                barrio: "Rodeito",
              },
              37: {
                codigo: 37,
                barrio: "Salto (parte)",
              },
              38: {
                codigo: 38,
                barrio: "San Benito",
              },
              39: {
                codigo: 39,
                barrio: "San Hernan",
              },
              40: {
                codigo: 40,
                barrio: "San Lucas",
              },
              41: {
                codigo: 41,
                barrio: "Santa Ana",
              },
              42: {
                codigo: 42,
                barrio: "Terreros",
              },
              43: {
                codigo: 43,
                barrio: "Zanjita",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "CANAS DULCES",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alcantaro",
              },
              2: {
                codigo: 2,
                barrio: "Buenavista",
              },
              3: {
                codigo: 3,
                barrio: "Guayacan",
              },
              4: {
                codigo: 4,
                barrio: "Pochote",
              },
              5: {
                codigo: 5,
                barrio: "Brisas",
              },
              6: {
                codigo: 6,
                barrio: "Cedro",
              },
              7: {
                codigo: 7,
                barrio: "Congo",
              },
              8: {
                codigo: 8,
                barrio: "Cueva",
              },
              9: {
                codigo: 9,
                barrio: "Fortuna",
              },
              10: {
                codigo: 10,
                barrio: "Irigaray",
              },
              11: {
                codigo: 11,
                barrio: "Lilas",
              },
              12: {
                codigo: 12,
                barrio: "Pacayales",
              },
              13: {
                codigo: 13,
                barrio: "Panamacito",
              },
              14: {
                codigo: 14,
                barrio: "Pedregal",
              },
              15: {
                codigo: 15,
                barrio: "Pital",
              },
              16: {
                codigo: 16,
                barrio: "Pueblo Nuevo",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "MAYORGA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Lourdes",
              },
              2: {
                codigo: 2,
                barrio: "San Antonio",
              },
              3: {
                codigo: 3,
                barrio: "Angeles",
              },
              4: {
                codigo: 4,
                barrio: "Argentina",
              },
              5: {
                codigo: 5,
                barrio: "Buenavista",
              },
              6: {
                codigo: 6,
                barrio: "Consuelo",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "NACASCOLO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bejuco",
              },
              2: {
                codigo: 2,
                barrio: "Los Lagos",
              },
              3: {
                codigo: 3,
                barrio: "Nacascolo",
              },
              4: {
                codigo: 4,
                barrio: "Oratorio",
              },
              5: {
                codigo: 5,
                barrio: "Puerto Culebra",
              },
              6: {
                codigo: 6,
                barrio: "Triunfo",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "CURUBANDE",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Gallo",
              },
              2: {
                codigo: 2,
                barrio: "San Rafael",
              },
              3: {
                codigo: 3,
                barrio: "Colorado",
              },
              4: {
                codigo: 4,
                barrio: "Curubande",
              },
              5: {
                codigo: 5,
                barrio: "Porvenir",
              },
            },
          },
        },
      },
      2: {
        codigo: 2,
        canton: "NICOYA",
        distritos: {
          1: {
            codigo: 1,
            distrito: "NICOYA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Los Angeles",
              },
              2: {
                codigo: 2,
                barrio: "Barro Negro",
              },
              3: {
                codigo: 3,
                barrio: "Cananga",
              },
              4: {
                codigo: 4,
                barrio: "Carmen",
              },
              5: {
                codigo: 5,
                barrio: "Chorotega",
              },
              6: {
                codigo: 6,
                barrio: "Guadalupe",
              },
              7: {
                codigo: 7,
                barrio: "Granja",
              },
              8: {
                codigo: 8,
                barrio: "San Martin",
              },
              9: {
                codigo: 9,
                barrio: "Santa Lucia",
              },
              10: {
                codigo: 10,
                barrio: "Virginia",
              },
              11: {
                codigo: 11,
                barrio: "Cabeceras",
              },
              12: {
                codigo: 12,
                barrio: "Caimital",
              },
              13: {
                codigo: 13,
                barrio: "Carreta",
              },
              14: {
                codigo: 14,
                barrio: "Casitas",
              },
              15: {
                codigo: 15,
                barrio: "Cerro Verde",
              },
              16: {
                codigo: 16,
                barrio: "Cerro Redondo",
              },
              17: {
                codigo: 17,
                barrio: "Cola de Gallo",
              },
              18: {
                codigo: 18,
                barrio: "Cuesta",
              },
              19: {
                codigo: 19,
                barrio: "Cuesta Buenos Aires",
              },
              20: {
                codigo: 20,
                barrio: "Curime",
              },
              21: {
                codigo: 21,
                barrio: "Chivo",
              },
              22: {
                codigo: 22,
                barrio: "Dulce Nombre",
              },
              23: {
                codigo: 23,
                barrio: "Esperanza Norte",
              },
              24: {
                codigo: 24,
                barrio: "Estrella",
              },
              25: {
                codigo: 25,
                barrio: "Gamalotal",
              },
              26: {
                codigo: 26,
                barrio: "Garcimunoz",
              },
              27: {
                codigo: 27,
                barrio: "Guaitil",
              },
              28: {
                codigo: 28,
                barrio: "Guastomatal",
              },
              29: {
                codigo: 29,
                barrio: "Guineas",
              },
              30: {
                codigo: 30,
                barrio: "Hondores",
              },
              31: {
                codigo: 31,
                barrio: "Jobo",
              },
              32: {
                codigo: 32,
                barrio: "Juan Diaz",
              },
              33: {
                codigo: 33,
                barrio: "Lajas",
              },
              34: {
                codigo: 34,
                barrio: "Loma Caucela",
              },
              35: {
                codigo: 35,
                barrio: "Miramar (noroeste)",
              },
              36: {
                codigo: 36,
                barrio: "Nambi",
              },
              37: {
                codigo: 37,
                barrio: "Oriente",
              },
              38: {
                codigo: 38,
                barrio: "Los Planes",
              },
              39: {
                codigo: 39,
                barrio: "Pedernal",
              },
              40: {
                codigo: 40,
                barrio: "Picudas",
              },
              41: {
                codigo: 41,
                barrio: "Pilahonda",
              },
              42: {
                codigo: 42,
                barrio: "Pilas",
              },
              43: {
                codigo: 43,
                barrio: "Pilas Blancas",
              },
              44: {
                codigo: 44,
                barrio: "Piragua",
              },
              45: {
                codigo: 45,
                barrio: "Ponedero",
              },
              46: {
                codigo: 46,
                barrio: "Quiriman",
              },
              47: {
                codigo: 47,
                barrio: "Quirimancito",
              },
              48: {
                codigo: 48,
                barrio: "Sabana Grande",
              },
              49: {
                codigo: 49,
                barrio: "Santa Ana",
              },
              50: {
                codigo: 50,
                barrio: "Sitio Botija",
              },
              51: {
                codigo: 51,
                barrio: "Tierra Blanca",
              },
              52: {
                codigo: 52,
                barrio: "Tres Quebradas",
              },
              53: {
                codigo: 53,
                barrio: "Varillas (Zapotillo)",
              },
              54: {
                codigo: 54,
                barrio: "Virginia",
              },
              55: {
                codigo: 55,
                barrio: "Zompopa",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "MANSION",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Acoyapa",
              },
              2: {
                codigo: 2,
                barrio: "Boquete",
              },
              3: {
                codigo: 3,
                barrio: "Camarones",
              },
              4: {
                codigo: 4,
                barrio: "Guastomatal",
              },
              5: {
                codigo: 5,
                barrio: "Iguanita",
              },
              6: {
                codigo: 6,
                barrio: "Lapas",
              },
              7: {
                codigo: 7,
                barrio: "Limonal",
              },
              8: {
                codigo: 8,
                barrio: "Matambuguito",
              },
              9: {
                codigo: 9,
                barrio: "Matina",
              },
              10: {
                codigo: 10,
                barrio: "Mercedes",
              },
              11: {
                codigo: 11,
                barrio: "Monte Alto",
              },
              12: {
                codigo: 12,
                barrio: "Morote Norte",
              },
              13: {
                codigo: 13,
                barrio: "Nacaome",
              },
              14: {
                codigo: 14,
                barrio: "Obispo",
              },
              15: {
                codigo: 15,
                barrio: "Pital",
              },
              16: {
                codigo: 16,
                barrio: "Polvazales",
              },
              17: {
                codigo: 17,
                barrio: "Pueblo Viejo",
              },
              18: {
                codigo: 18,
                barrio: "Puente Guillermina",
              },
              19: {
                codigo: 19,
                barrio: "Puerto Jesus",
              },
              20: {
                codigo: 20,
                barrio: "Rio Vueltas",
              },
              21: {
                codigo: 21,
                barrio: "San Joaquin",
              },
              22: {
                codigo: 22,
                barrio: "San Juan (parte)",
              },
              23: {
                codigo: 23,
                barrio: "Uvita (parte)",
              },
              24: {
                codigo: 24,
                barrio: "Vigia",
              },
              25: {
                codigo: 25,
                barrio: "Yerbabuena (parte)",
              },
              26: {
                codigo: 26,
                barrio: "Zapandi",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "SAN ANTONIO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Guayabal",
              },
              2: {
                codigo: 2,
                barrio: "Biscoyol",
              },
              3: {
                codigo: 3,
                barrio: "Bolsa",
              },
              4: {
                codigo: 4,
                barrio: "Boquete",
              },
              5: {
                codigo: 5,
                barrio: "Buenos Aires",
              },
              6: {
                codigo: 6,
                barrio: "Canal",
              },
              7: {
                codigo: 7,
                barrio: "Carao",
              },
              8: {
                codigo: 8,
                barrio: "Cerro Mesas",
              },
              9: {
                codigo: 9,
                barrio: "Conchal",
              },
              10: {
                codigo: 10,
                barrio: "Corral de Piedra",
              },
              11: {
                codigo: 11,
                barrio: "Corralillo",
              },
              12: {
                codigo: 12,
                barrio: "Coyolar",
              },
              13: {
                codigo: 13,
                barrio: "Cuba",
              },
              14: {
                codigo: 14,
                barrio: "Cuesta Madrono",
              },
              15: {
                codigo: 15,
                barrio: "Chira",
              },
              16: {
                codigo: 16,
                barrio: "Flor",
              },
              17: {
                codigo: 17,
                barrio: "Florida",
              },
              18: {
                codigo: 18,
                barrio: "Guayabo",
              },
              19: {
                codigo: 19,
                barrio: "Loma Ayote",
              },
              20: {
                codigo: 20,
                barrio: "Matamba",
              },
              21: {
                codigo: 21,
                barrio: "Mexico",
              },
              22: {
                codigo: 22,
                barrio: "Montanita",
              },
              23: {
                codigo: 23,
                barrio: "Monte Galan",
              },
              24: {
                codigo: 24,
                barrio: "Moracia",
              },
              25: {
                codigo: 25,
                barrio: "Ojo de Agua",
              },
              26: {
                codigo: 26,
                barrio: "Palos Negros",
              },
              27: {
                codigo: 27,
                barrio: "Piave",
              },
              28: {
                codigo: 28,
                barrio: "Piedras Blancas",
              },
              29: {
                codigo: 29,
                barrio: "Pozas",
              },
              30: {
                codigo: 30,
                barrio: "Pozo de Agua",
              },
              31: {
                codigo: 31,
                barrio: "Pueblo Nuevo",
              },
              32: {
                codigo: 32,
                barrio: "Puerto Humo",
              },
              33: {
                codigo: 33,
                barrio: "Rosario",
              },
              34: {
                codigo: 34,
                barrio: "San Lazaro",
              },
              35: {
                codigo: 35,
                barrio: "San Vicente",
              },
              36: {
                codigo: 36,
                barrio: "Silencio",
              },
              37: {
                codigo: 37,
                barrio: "Talolinga",
              },
              38: {
                codigo: 38,
                barrio: "Tamarindo",
              },
              39: {
                codigo: 39,
                barrio: "Zapote",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "QUEBRADA HONDA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Tortuguero",
              },
              2: {
                codigo: 2,
                barrio: "Botija",
              },
              3: {
                codigo: 3,
                barrio: "Caballito",
              },
              4: {
                codigo: 4,
                barrio: "Embarcadero",
              },
              5: {
                codigo: 5,
                barrio: "Copal",
              },
              6: {
                codigo: 6,
                barrio: "Loma Bonita",
              },
              7: {
                codigo: 7,
                barrio: "Millal",
              },
              8: {
                codigo: 8,
                barrio: "Paraiso",
              },
              9: {
                codigo: 9,
                barrio: "Paso Guabo",
              },
              10: {
                codigo: 10,
                barrio: "Pochote",
              },
              11: {
                codigo: 11,
                barrio: "Puerto Moreno",
              },
              12: {
                codigo: 12,
                barrio: "Roblar",
              },
              13: {
                codigo: 13,
                barrio: "San Juan (parte)",
              },
              14: {
                codigo: 14,
                barrio: "Sombrero",
              },
              15: {
                codigo: 15,
                barrio: "Sonzapote",
              },
              16: {
                codigo: 16,
                barrio: "Tres Esquinas",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "SAMARA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Matapalo",
              },
              2: {
                codigo: 2,
                barrio: "Mala Noche",
              },
              3: {
                codigo: 3,
                barrio: "Bajo Escondido",
              },
              4: {
                codigo: 4,
                barrio: "Barco Quebrado",
              },
              5: {
                codigo: 5,
                barrio: "Buenavista",
              },
              6: {
                codigo: 6,
                barrio: "Buenos Aires",
              },
              7: {
                codigo: 7,
                barrio: "Cambutes",
              },
              8: {
                codigo: 8,
                barrio: "Cangrejal",
              },
              9: {
                codigo: 9,
                barrio: "Cantarrana",
              },
              10: {
                codigo: 10,
                barrio: "Chinampas",
              },
              11: {
                codigo: 11,
                barrio: "Esterones",
              },
              12: {
                codigo: 12,
                barrio: "Galilea",
              },
              13: {
                codigo: 13,
                barrio: "Palmar",
              },
              14: {
                codigo: 14,
                barrio: "Panama",
              },
              15: {
                codigo: 15,
                barrio: "Pavones",
              },
              16: {
                codigo: 16,
                barrio: "Playa Buenavista",
              },
              17: {
                codigo: 17,
                barrio: "Primavera",
              },
              18: {
                codigo: 18,
                barrio: "Pueblo Nuevo",
              },
              19: {
                codigo: 19,
                barrio: "Samaria",
              },
              20: {
                codigo: 20,
                barrio: "San Fernando",
              },
              21: {
                codigo: 21,
                barrio: "Santo Domingo",
              },
              22: {
                codigo: 22,
                barrio: "Taranta",
              },
              23: {
                codigo: 23,
                barrio: "Terciopelo",
              },
              24: {
                codigo: 24,
                barrio: "Torito",
              },
            },
          },
          6: {
            codigo: 6,
            distrito: "NOSARA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Angeles de Garza",
              },
              2: {
                codigo: 2,
                barrio: "Bijagua",
              },
              3: {
                codigo: 3,
                barrio: "Cabeceras de Garza",
              },
              4: {
                codigo: 4,
                barrio: "Coyoles",
              },
              5: {
                codigo: 5,
                barrio: "Cuesta Winch",
              },
              6: {
                codigo: 6,
                barrio: "Delicias",
              },
              7: {
                codigo: 7,
                barrio: "Esperanza Sur",
              },
              8: {
                codigo: 8,
                barrio: "Flores",
              },
              9: {
                codigo: 9,
                barrio: "Garza",
              },
              10: {
                codigo: 10,
                barrio: "Guiones",
              },
              11: {
                codigo: 11,
                barrio: "Ligia",
              },
              12: {
                codigo: 12,
                barrio: "Nosara",
              },
              13: {
                codigo: 13,
                barrio: "Playa Nosara",
              },
              14: {
                codigo: 14,
                barrio: "Playa Pelada",
              },
              15: {
                codigo: 15,
                barrio: "Portal",
              },
              16: {
                codigo: 16,
                barrio: "Rio Montana",
              },
              17: {
                codigo: 17,
                barrio: "San Juan",
              },
              18: {
                codigo: 18,
                barrio: "Santa Marta",
              },
              19: {
                codigo: 19,
                barrio: "Santa Teresa",
              },
            },
          },
          7: {
            codigo: 7,
            distrito: "BELEN DE NOSARITA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Arcos",
              },
              2: {
                codigo: 2,
                barrio: "Balsal",
              },
              3: {
                codigo: 3,
                barrio: "Caimitalito",
              },
              4: {
                codigo: 4,
                barrio: "Cuajiniquil",
              },
              5: {
                codigo: 5,
                barrio: "Cuesta Grande",
              },
              6: {
                codigo: 6,
                barrio: "Chumburan",
              },
              7: {
                codigo: 7,
                barrio: "Juntas",
              },
              8: {
                codigo: 8,
                barrio: "Maquenco",
              },
              9: {
                codigo: 9,
                barrio: "Minas",
              },
              10: {
                codigo: 10,
                barrio: "Miramar Sureste",
              },
              11: {
                codigo: 11,
                barrio: "Naranjal",
              },
              12: {
                codigo: 12,
                barrio: "Naranjalito",
              },
              13: {
                codigo: 13,
                barrio: "Nosarita",
              },
              14: {
                codigo: 14,
                barrio: "Platanillo",
              },
              15: {
                codigo: 15,
                barrio: "Quebrada Bonita",
              },
              16: {
                codigo: 16,
                barrio: "Santa Elena (parte)",
              },
              17: {
                codigo: 17,
                barrio: "Zaragoza",
              },
            },
          },
        },
      },
      3: {
        codigo: 3,
        canton: "SANTA CRUZ",
        distritos: {
          1: {
            codigo: 1,
            distrito: "SANTA CRUZ",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Buenos Aires",
              },
              2: {
                codigo: 2,
                barrio: "Camarenos",
              },
              3: {
                codigo: 3,
                barrio: "Catalo Rojas",
              },
              4: {
                codigo: 4,
                barrio: "Corobici",
              },
              5: {
                codigo: 5,
                barrio: "Chorotega",
              },
              6: {
                codigo: 6,
                barrio: "Esquipulas",
              },
              7: {
                codigo: 7,
                barrio: "Estocolmo",
              },
              8: {
                codigo: 8,
                barrio: "Flores",
              },
              9: {
                codigo: 9,
                barrio: "Garua",
              },
              10: {
                codigo: 10,
                barrio: "Guabo",
              },
              11: {
                codigo: 11,
                barrio: "Lajas",
              },
              12: {
                codigo: 12,
                barrio: "Los Amigos",
              },
              13: {
                codigo: 13,
                barrio: "Malinches",
              },
              14: {
                codigo: 14,
                barrio: "Manchon",
              },
              15: {
                codigo: 15,
                barrio: "Panama",
              },
              16: {
                codigo: 16,
                barrio: "Pepe Lujan",
              },
              17: {
                codigo: 17,
                barrio: "Sagamat",
              },
              18: {
                codigo: 18,
                barrio: "San Martin",
              },
              19: {
                codigo: 19,
                barrio: "Santa Cecilia",
              },
              20: {
                codigo: 20,
                barrio: "Tenorio",
              },
              21: {
                codigo: 21,
                barrio: "Tulita Sandino",
              },
              22: {
                codigo: 22,
                barrio: "Angeles",
              },
              23: {
                codigo: 23,
                barrio: "Arado",
              },
              24: {
                codigo: 24,
                barrio: "Bernabela",
              },
              25: {
                codigo: 25,
                barrio: "Cacao",
              },
              26: {
                codigo: 26,
                barrio: "Caimito",
              },
              27: {
                codigo: 27,
                barrio: "Congal",
              },
              28: {
                codigo: 28,
                barrio: "Cuatro Esquinas",
              },
              29: {
                codigo: 29,
                barrio: "Chibola",
              },
              30: {
                codigo: 30,
                barrio: "Chirco",
              },
              31: {
                codigo: 31,
                barrio: "Chumico (parte)",
              },
              32: {
                codigo: 32,
                barrio: "Guayabal",
              },
              33: {
                codigo: 33,
                barrio: "Hato Viejo",
              },
              34: {
                codigo: 34,
                barrio: "Lagunilla",
              },
              35: {
                codigo: 35,
                barrio: "Lechuza",
              },
              36: {
                codigo: 36,
                barrio: "Limon",
              },
              37: {
                codigo: 37,
                barrio: "Moya",
              },
              38: {
                codigo: 38,
                barrio: "Puente Negro",
              },
              39: {
                codigo: 39,
                barrio: "Retallano (parte)",
              },
              40: {
                codigo: 40,
                barrio: "Rincon",
              },
              41: {
                codigo: 41,
                barrio: "Rio Canas Viejo",
              },
              42: {
                codigo: 42,
                barrio: "San Juan",
              },
              43: {
                codigo: 43,
                barrio: "San Pedro",
              },
              44: {
                codigo: 44,
                barrio: "San Pedro Viejo",
              },
              45: {
                codigo: 45,
                barrio: "Vista al Mar",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "BOLSON",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Ortega",
              },
              2: {
                codigo: 2,
                barrio: "Ballena (parte)",
              },
              3: {
                codigo: 3,
                barrio: "Lagartero",
              },
              4: {
                codigo: 4,
                barrio: "Pochotada",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "VEINTISIETE DE ABRIL",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Jobos",
              },
              2: {
                codigo: 2,
                barrio: "Aguacate",
              },
              3: {
                codigo: 3,
                barrio: "Avellana",
              },
              4: {
                codigo: 4,
                barrio: "Barrosa",
              },
              5: {
                codigo: 5,
                barrio: "Brisas",
              },
              6: {
                codigo: 6,
                barrio: "Bruno",
              },
              7: {
                codigo: 7,
                barrio: "Cacaovano",
              },
              8: {
                codigo: 8,
                barrio: "Camones",
              },
              9: {
                codigo: 9,
                barrio: "Canas Gordas",
              },
              10: {
                codigo: 10,
                barrio: "Ceiba Mocha",
              },
              11: {
                codigo: 11,
                barrio: "Cerro Brujo",
              },
              12: {
                codigo: 12,
                barrio: "Congo",
              },
              13: {
                codigo: 13,
                barrio: "Delicias",
              },
              14: {
                codigo: 14,
                barrio: "Espavelar",
              },
              15: {
                codigo: 15,
                barrio: "Florida",
              },
              16: {
                codigo: 16,
                barrio: "Gongolona",
              },
              17: {
                codigo: 17,
                barrio: "Guachipelin",
              },
              18: {
                codigo: 18,
                barrio: "Guapote",
              },
              19: {
                codigo: 19,
                barrio: "Hatillo",
              },
              20: {
                codigo: 20,
                barrio: "Isla Verde",
              },
              21: {
                codigo: 21,
                barrio: "Junquillal",
              },
              22: {
                codigo: 22,
                barrio: "Junta de Rio Verde",
              },
              23: {
                codigo: 23,
                barrio: "Mesas",
              },
              24: {
                codigo: 24,
                barrio: "Montana",
              },
              25: {
                codigo: 25,
                barrio: "Monteverde",
              },
              26: {
                codigo: 26,
                barrio: "Nispero",
              },
              27: {
                codigo: 27,
                barrio: "Paraiso",
              },
              28: {
                codigo: 28,
                barrio: "Pargos",
              },
              29: {
                codigo: 29,
                barrio: "Paso Hondo",
              },
              30: {
                codigo: 30,
                barrio: "Pilas",
              },
              31: {
                codigo: 31,
                barrio: "Playa Lagartillo",
              },
              32: {
                codigo: 32,
                barrio: "Playa Negra",
              },
              33: {
                codigo: 33,
                barrio: "Pochotes",
              },
              34: {
                codigo: 34,
                barrio: "Ranchos",
              },
              35: {
                codigo: 35,
                barrio: "Retallano (parte)",
              },
              36: {
                codigo: 36,
                barrio: "Rio Seco",
              },
              37: {
                codigo: 37,
                barrio: "Rio Tabaco",
              },
              38: {
                codigo: 38,
                barrio: "San Francisco",
              },
              39: {
                codigo: 39,
                barrio: "San Jeronimo",
              },
              40: {
                codigo: 40,
                barrio: "Soncoyo",
              },
              41: {
                codigo: 41,
                barrio: "Tieso (San Rafael)",
              },
              42: {
                codigo: 42,
                barrio: "Trapiche",
              },
              43: {
                codigo: 43,
                barrio: "Venado",
              },
              44: {
                codigo: 44,
                barrio: "Vergel",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "TEMPATE",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Paraiso",
              },
              2: {
                codigo: 2,
                barrio: "Bejuco",
              },
              3: {
                codigo: 3,
                barrio: "Chiles",
              },
              4: {
                codigo: 4,
                barrio: "El Llano",
              },
              5: {
                codigo: 5,
                barrio: "Huacas",
              },
              6: {
                codigo: 6,
                barrio: "Portegolpe",
              },
              7: {
                codigo: 7,
                barrio: "Potrero",
              },
              8: {
                codigo: 8,
                barrio: "Rincon",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "CARTAGENA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Eden",
              },
              2: {
                codigo: 2,
                barrio: "Toyosa",
              },
              3: {
                codigo: 3,
                barrio: "Canafistula",
              },
              4: {
                codigo: 4,
                barrio: "Corocitos",
              },
              5: {
                codigo: 5,
                barrio: "Higueron",
              },
              6: {
                codigo: 6,
                barrio: "Jobo",
              },
              7: {
                codigo: 7,
                barrio: "Lorena",
              },
              8: {
                codigo: 8,
                barrio: "Oratorio",
              },
              9: {
                codigo: 9,
                barrio: "Sacatipe",
              },
            },
          },
          6: {
            codigo: 6,
            distrito: "CUAJINIQUIL",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alemania",
              },
              2: {
                codigo: 2,
                barrio: "Bolillos",
              },
              3: {
                codigo: 3,
                barrio: "Cuajiniquil",
              },
              4: {
                codigo: 4,
                barrio: "Chiquero",
              },
              5: {
                codigo: 5,
                barrio: "Fortuna",
              },
              6: {
                codigo: 6,
                barrio: "Frijolar",
              },
              7: {
                codigo: 7,
                barrio: "Jazminal",
              },
              8: {
                codigo: 8,
                barrio: "Lagarto",
              },
              9: {
                codigo: 9,
                barrio: "Libertad",
              },
              10: {
                codigo: 10,
                barrio: "Limonal",
              },
              11: {
                codigo: 11,
                barrio: "Manzanillo",
              },
              12: {
                codigo: 12,
                barrio: "Marbella",
              },
              13: {
                codigo: 13,
                barrio: "Ostional",
              },
              14: {
                codigo: 14,
                barrio: "Palmares",
              },
              15: {
                codigo: 15,
                barrio: "Piedras Amarillas",
              },
              16: {
                codigo: 16,
                barrio: "Progreso",
              },
              17: {
                codigo: 17,
                barrio: "Punta Caliente",
              },
              18: {
                codigo: 18,
                barrio: "Quebrada Seca",
              },
              19: {
                codigo: 19,
                barrio: "Quebrada Zapote",
              },
              20: {
                codigo: 20,
                barrio: "Rayo",
              },
              21: {
                codigo: 21,
                barrio: "Roble",
              },
              22: {
                codigo: 22,
                barrio: "Rosario",
              },
              23: {
                codigo: 23,
                barrio: "Santa Cecilia",
              },
              24: {
                codigo: 24,
                barrio: "Santa Elena",
              },
              25: {
                codigo: 25,
                barrio: "Socorro",
              },
              26: {
                codigo: 26,
                barrio: "Union",
              },
              27: {
                codigo: 27,
                barrio: "Veracruz",
              },
            },
          },
          7: {
            codigo: 7,
            distrito: "DIRIA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Angeles",
              },
              2: {
                codigo: 2,
                barrio: "Duendes",
              },
              3: {
                codigo: 3,
                barrio: "Lomitas",
              },
              4: {
                codigo: 4,
                barrio: "Oriente",
              },
              5: {
                codigo: 5,
                barrio: "Calle Vieja",
              },
              6: {
                codigo: 6,
                barrio: "Coyolar",
              },
              7: {
                codigo: 7,
                barrio: "Chumico (parte)",
              },
              8: {
                codigo: 8,
                barrio: "Diria",
              },
              9: {
                codigo: 9,
                barrio: "Guaitil",
              },
              10: {
                codigo: 10,
                barrio: "Polvazal",
              },
              11: {
                codigo: 11,
                barrio: "Sequeira",
              },
              12: {
                codigo: 12,
                barrio: "Talolinguita",
              },
              13: {
                codigo: 13,
                barrio: "Trompillal",
              },
            },
          },
          8: {
            codigo: 8,
            distrito: "CABO VELAS",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Brasilito",
              },
              2: {
                codigo: 2,
                barrio: "Buen Pastor",
              },
              3: {
                codigo: 3,
                barrio: "Conchal",
              },
              4: {
                codigo: 4,
                barrio: "Flamenco",
              },
              5: {
                codigo: 5,
                barrio: "Garita Vieja",
              },
              6: {
                codigo: 6,
                barrio: "Jesus Maria",
              },
              7: {
                codigo: 7,
                barrio: "Lajas",
              },
              8: {
                codigo: 8,
                barrio: "Lomas",
              },
              9: {
                codigo: 9,
                barrio: "Playa Cabuya",
              },
              10: {
                codigo: 10,
                barrio: "Playa Grande",
              },
              11: {
                codigo: 11,
                barrio: "Playa Mina",
              },
              12: {
                codigo: 12,
                barrio: "Playa Real",
              },
              13: {
                codigo: 13,
                barrio: "Puerto Viejo",
              },
              14: {
                codigo: 14,
                barrio: "Salinas",
              },
              15: {
                codigo: 15,
                barrio: "Salinitas",
              },
              16: {
                codigo: 16,
                barrio: "Tacasolapa",
              },
              17: {
                codigo: 17,
                barrio: "Zapotillal",
              },
            },
          },
          9: {
            codigo: 9,
            distrito: "TAMARINDO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Canafistula",
              },
              2: {
                codigo: 2,
                barrio: "Cebadilla",
              },
              3: {
                codigo: 3,
                barrio: "El Llano",
              },
              4: {
                codigo: 4,
                barrio: "Garita",
              },
              5: {
                codigo: 5,
                barrio: "Guatemala",
              },
              6: {
                codigo: 6,
                barrio: "Hernandez",
              },
              7: {
                codigo: 7,
                barrio: "Icacal",
              },
              8: {
                codigo: 8,
                barrio: "La Loma",
              },
              9: {
                codigo: 9,
                barrio: "Linderos",
              },
              10: {
                codigo: 10,
                barrio: "Mangos",
              },
              11: {
                codigo: 11,
                barrio: "Palmar",
              },
              12: {
                codigo: 12,
                barrio: "San Andres",
              },
              13: {
                codigo: 13,
                barrio: "San Jose Pinilla",
              },
              14: {
                codigo: 14,
                barrio: "Santa Rosa",
              },
              15: {
                codigo: 15,
                barrio: "Tamarindo",
              },
            },
          },
        },
      },
      4: {
        codigo: 4,
        canton: "BAGACES",
        distritos: {
          1: {
            codigo: 1,
            distrito: "BAGACES",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Lima",
              },
              2: {
                codigo: 2,
                barrio: "Pedro Nolasco",
              },
              3: {
                codigo: 3,
                barrio: "Redondel",
              },
              4: {
                codigo: 4,
                barrio: "Aguacaliente",
              },
              5: {
                codigo: 5,
                barrio: "Arbolito",
              },
              6: {
                codigo: 6,
                barrio: "Bagatzi",
              },
              7: {
                codigo: 7,
                barrio: "Brisas",
              },
              8: {
                codigo: 8,
                barrio: "Bebedero (parte)",
              },
              9: {
                codigo: 9,
                barrio: "Casavieja (parte)",
              },
              10: {
                codigo: 10,
                barrio: "Cofradia",
              },
              11: {
                codigo: 11,
                barrio: "Colmenar",
              },
              12: {
                codigo: 12,
                barrio: "Llanos de Cortes",
              },
              13: {
                codigo: 13,
                barrio: "Mojica",
              },
              14: {
                codigo: 14,
                barrio: "Montano",
              },
              15: {
                codigo: 15,
                barrio: "Montenegro",
              },
              16: {
                codigo: 16,
                barrio: "Piedras",
              },
              17: {
                codigo: 17,
                barrio: "Pijije",
              },
              18: {
                codigo: 18,
                barrio: "Plazuela",
              },
              19: {
                codigo: 19,
                barrio: "Salitral",
              },
              20: {
                codigo: 20,
                barrio: "Salto (parte)",
              },
              21: {
                codigo: 21,
                barrio: "Santa Rosa",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "FORTUNA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Miravalles",
              },
              2: {
                codigo: 2,
                barrio: "Casavieja (parte)",
              },
              3: {
                codigo: 3,
                barrio: "Cuipilapa",
              },
              4: {
                codigo: 4,
                barrio: "Giganta",
              },
              5: {
                codigo: 5,
                barrio: "Hornillas",
              },
              6: {
                codigo: 6,
                barrio: "Macua",
              },
              7: {
                codigo: 7,
                barrio: "Martillete",
              },
              8: {
                codigo: 8,
                barrio: "Mozotal",
              },
              9: {
                codigo: 9,
                barrio: "Pozo Azul",
              },
              10: {
                codigo: 10,
                barrio: "Sagrada Familia",
              },
              11: {
                codigo: 11,
                barrio: "San Bernardo",
              },
              12: {
                codigo: 12,
                barrio: "San Joaquin",
              },
              13: {
                codigo: 13,
                barrio: "Santa Cecilia",
              },
              14: {
                codigo: 14,
                barrio: "Santa Fe",
              },
              15: {
                codigo: 15,
                barrio: "Santa Rosa",
              },
              16: {
                codigo: 16,
                barrio: "Union Ferrer",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "MOGOTE",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Los Angeles",
              },
              2: {
                codigo: 2,
                barrio: "Oses",
              },
              3: {
                codigo: 3,
                barrio: "Barro de Olla",
              },
              4: {
                codigo: 4,
                barrio: "Horcones",
              },
              5: {
                codigo: 5,
                barrio: "La Ese",
              },
              6: {
                codigo: 6,
                barrio: "Limonal",
              },
              7: {
                codigo: 7,
                barrio: "Manglar",
              },
              8: {
                codigo: 8,
                barrio: "Mochadero",
              },
              9: {
                codigo: 9,
                barrio: "Pueblo Nuevo",
              },
              10: {
                codigo: 10,
                barrio: "Rincon de La Cruz",
              },
              11: {
                codigo: 11,
                barrio: "San Isidro de Limonal",
              },
              12: {
                codigo: 12,
                barrio: "San Jorge",
              },
              13: {
                codigo: 13,
                barrio: "San Pedro",
              },
              14: {
                codigo: 14,
                barrio: "Torno",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "RIO NARANJO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Naranjito",
              },
              2: {
                codigo: 2,
                barrio: "Rio Chiquito",
              },
            },
          },
        },
      },
      5: {
        codigo: 5,
        canton: "CARRILLO",
        distritos: {
          1: {
            codigo: 1,
            distrito: "FILADELFIA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bambu",
              },
              2: {
                codigo: 2,
                barrio: "Cinco Esquinas",
              },
              3: {
                codigo: 3,
                barrio: "Hollywood",
              },
              4: {
                codigo: 4,
                barrio: "La Cruz",
              },
              5: {
                codigo: 5,
                barrio: "Santa Lucia",
              },
              6: {
                codigo: 6,
                barrio: "Ballena (parte)",
              },
              7: {
                codigo: 7,
                barrio: "Corralillo",
              },
              8: {
                codigo: 8,
                barrio: "Guinea",
              },
              9: {
                codigo: 9,
                barrio: "Isleta (parte)",
              },
              10: {
                codigo: 10,
                barrio: "Jocote",
              },
              11: {
                codigo: 11,
                barrio: "Juanilama",
              },
              12: {
                codigo: 12,
                barrio: "Moralito",
              },
              13: {
                codigo: 13,
                barrio: "Ojoche",
              },
              14: {
                codigo: 14,
                barrio: "San Francisco",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "PALMIRA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Coyolera",
              },
              2: {
                codigo: 2,
                barrio: "Maria Auxiliadora",
              },
              3: {
                codigo: 3,
                barrio: "Angeles",
              },
              4: {
                codigo: 4,
                barrio: "Comunidad",
              },
              5: {
                codigo: 5,
                barrio: "Paso Tempisque (parte)",
              },
              6: {
                codigo: 6,
                barrio: "San Rafael",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "SARDINAL",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Carpintera",
              },
              2: {
                codigo: 2,
                barrio: "Colegios",
              },
              3: {
                codigo: 3,
                barrio: "Verdun",
              },
              4: {
                codigo: 4,
                barrio: "Artola",
              },
              5: {
                codigo: 5,
                barrio: "Cacique",
              },
              6: {
                codigo: 6,
                barrio: "Coco",
              },
              7: {
                codigo: 7,
                barrio: "Chorrera",
              },
              8: {
                codigo: 8,
                barrio: "Guacamaya",
              },
              9: {
                codigo: 9,
                barrio: "Huaquitas",
              },
              10: {
                codigo: 10,
                barrio: "Libertad",
              },
              11: {
                codigo: 11,
                barrio: "Los Canales",
              },
              12: {
                codigo: 12,
                barrio: "Matapalo",
              },
              13: {
                codigo: 13,
                barrio: "Nancital",
              },
              14: {
                codigo: 14,
                barrio: "Nuevo Colon",
              },
              15: {
                codigo: 15,
                barrio: "Obandito",
              },
              16: {
                codigo: 16,
                barrio: "Ocotal",
              },
              17: {
                codigo: 17,
                barrio: "Pilas",
              },
              18: {
                codigo: 18,
                barrio: "Playa Hermosa",
              },
              19: {
                codigo: 19,
                barrio: "Playones",
              },
              20: {
                codigo: 20,
                barrio: "San Blas",
              },
              21: {
                codigo: 21,
                barrio: "San Martin",
              },
              22: {
                codigo: 22,
                barrio: "Santa Rita",
              },
              23: {
                codigo: 23,
                barrio: "Segovia",
              },
              24: {
                codigo: 24,
                barrio: "Tabores",
              },
              25: {
                codigo: 25,
                barrio: "Zapotal",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "BELEN",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Villita",
              },
              2: {
                codigo: 2,
                barrio: "Alto San Antonio",
              },
              3: {
                codigo: 3,
                barrio: "Cachimbo",
              },
              4: {
                codigo: 4,
                barrio: "Castilla de Oro",
              },
              5: {
                codigo: 5,
                barrio: "Coyolito",
              },
              6: {
                codigo: 6,
                barrio: "Gallina",
              },
              7: {
                codigo: 7,
                barrio: "Juanilama",
              },
              8: {
                codigo: 8,
                barrio: "Loma Bonita",
              },
              9: {
                codigo: 9,
                barrio: "LLano",
              },
              10: {
                codigo: 10,
                barrio: "Ojochal",
              },
              11: {
                codigo: 11,
                barrio: "Palestina",
              },
              12: {
                codigo: 12,
                barrio: "Palmas",
              },
              13: {
                codigo: 13,
                barrio: "Paraiso",
              },
              14: {
                codigo: 14,
                barrio: "Penca",
              },
              15: {
                codigo: 15,
                barrio: "Planes",
              },
              16: {
                codigo: 16,
                barrio: "Poroporo",
              },
              17: {
                codigo: 17,
                barrio: "Rio Canas Nuevo",
              },
              18: {
                codigo: 18,
                barrio: "Santa Ana",
              },
              19: {
                codigo: 19,
                barrio: "Santo Domingo",
              },
            },
          },
        },
      },
      6: {
        codigo: 6,
        canton: "CANAS",
        distritos: {
          1: {
            codigo: 1,
            distrito: "CANAS",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Albania",
              },
              2: {
                codigo: 2,
                barrio: "Angeles",
              },
              3: {
                codigo: 3,
                barrio: "Bello Horizonte",
              },
              4: {
                codigo: 4,
                barrio: "Cantarrana",
              },
              5: {
                codigo: 5,
                barrio: "Castillo",
              },
              6: {
                codigo: 6,
                barrio: "Cueva",
              },
              7: {
                codigo: 7,
                barrio: "Chorotega",
              },
              8: {
                codigo: 8,
                barrio: "Las Canas",
              },
              9: {
                codigo: 9,
                barrio: "Malinches",
              },
              10: {
                codigo: 10,
                barrio: "Miravalles",
              },
              11: {
                codigo: 11,
                barrio: "Palmas",
              },
              12: {
                codigo: 12,
                barrio: "San Cristobal",
              },
              13: {
                codigo: 13,
                barrio: "San Martin",
              },
              14: {
                codigo: 14,
                barrio: "San Pedro",
              },
              15: {
                codigo: 15,
                barrio: "Santa Isabel Abajo",
              },
              16: {
                codigo: 16,
                barrio: "Santa Isabel Arriba",
              },
              17: {
                codigo: 17,
                barrio: "Tenorio",
              },
              18: {
                codigo: 18,
                barrio: "Tres Marias",
              },
              19: {
                codigo: 19,
                barrio: "Union",
              },
              20: {
                codigo: 20,
                barrio: "Cedros",
              },
              21: {
                codigo: 21,
                barrio: "Cepo",
              },
              22: {
                codigo: 22,
                barrio: "Concepcion",
              },
              23: {
                codigo: 23,
                barrio: "Corobici",
              },
              24: {
                codigo: 24,
                barrio: "Correntadas",
              },
              25: {
                codigo: 25,
                barrio: "Cuesta el Diablo",
              },
              26: {
                codigo: 26,
                barrio: "Cuesta el Mico",
              },
              27: {
                codigo: 27,
                barrio: "Hotel",
              },
              28: {
                codigo: 28,
                barrio: "Jabilla Abajo",
              },
              29: {
                codigo: 29,
                barrio: "Jabilla Arriba",
              },
              30: {
                codigo: 30,
                barrio: "Libertad",
              },
              31: {
                codigo: 31,
                barrio: "Montes de Oro",
              },
              32: {
                codigo: 32,
                barrio: "Paso Lajas",
              },
              33: {
                codigo: 33,
                barrio: "Pedregal",
              },
              34: {
                codigo: 34,
                barrio: "Pochota",
              },
              35: {
                codigo: 35,
                barrio: "Pueblo Nuevo",
              },
              36: {
                codigo: 36,
                barrio: "Sandial (Sandillal)",
              },
              37: {
                codigo: 37,
                barrio: "San Isidro (parte)",
              },
              38: {
                codigo: 38,
                barrio: "Santa Lucia (parte)",
              },
              39: {
                codigo: 39,
                barrio: "Vergel",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "PALMIRA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Aguacaliente",
              },
              2: {
                codigo: 2,
                barrio: "Aguas Gatas (parte)",
              },
              3: {
                codigo: 3,
                barrio: "Coyota",
              },
              4: {
                codigo: 4,
                barrio: "Huacal",
              },
              5: {
                codigo: 5,
                barrio: "Las Flores",
              },
              6: {
                codigo: 6,
                barrio: "Martirio",
              },
              7: {
                codigo: 7,
                barrio: "Panales",
              },
              8: {
                codigo: 8,
                barrio: "Paraiso (parte)",
              },
              9: {
                codigo: 9,
                barrio: "San Isidro (parte)",
              },
              10: {
                codigo: 10,
                barrio: "Santa Lucia (parte)",
              },
              11: {
                codigo: 11,
                barrio: "Tenorio",
              },
              12: {
                codigo: 12,
                barrio: "Vueltas",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "SAN MIGUEL",
            barrios: {
              1: {
                codigo: 1,
                barrio: "El Coco",
              },
              2: {
                codigo: 2,
                barrio: "El Guis",
              },
              3: {
                codigo: 3,
                barrio: "Eskameca (parte)",
              },
              4: {
                codigo: 4,
                barrio: "Gotera",
              },
              5: {
                codigo: 5,
                barrio: "Higueron",
              },
              6: {
                codigo: 6,
                barrio: "Higueron Viejo",
              },
              7: {
                codigo: 7,
                barrio: "San Juan",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "BEBEDERO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Loma",
              },
              2: {
                codigo: 2,
                barrio: "Coopetaboga",
              },
              3: {
                codigo: 3,
                barrio: "Taboga (parte)",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "POROZAL",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Brisas",
              },
              2: {
                codigo: 2,
                barrio: "Eskameca (parte)",
              },
              3: {
                codigo: 3,
                barrio: "Guapinol",
              },
              4: {
                codigo: 4,
                barrio: "Pozas",
              },
              5: {
                codigo: 5,
                barrio: "Puerto Alegre",
              },
              6: {
                codigo: 6,
                barrio: "Quesera",
              },
              7: {
                codigo: 7,
                barrio: "Santa Lucia",
              },
              8: {
                codigo: 8,
                barrio: "Taboga (parte)",
              },
              9: {
                codigo: 9,
                barrio: "Tiquirusas",
              },
            },
          },
        },
      },
      7: {
        codigo: 7,
        canton: "ABANGARES",
        distritos: {
          1: {
            codigo: 1,
            distrito: "JUNTAS",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bellavista",
              },
              2: {
                codigo: 2,
                barrio: "Cinco Esquinas",
              },
              3: {
                codigo: 3,
                barrio: "La Gloria",
              },
              4: {
                codigo: 4,
                barrio: "Paso Ancho",
              },
              5: {
                codigo: 5,
                barrio: "San Antonio",
              },
              6: {
                codigo: 6,
                barrio: "San Jorge",
              },
              7: {
                codigo: 7,
                barrio: "San Pablo",
              },
              8: {
                codigo: 8,
                barrio: "Blanco",
              },
              9: {
                codigo: 9,
                barrio: "Cecilia",
              },
              10: {
                codigo: 10,
                barrio: "Codornices",
              },
              11: {
                codigo: 11,
                barrio: "Concepcion",
              },
              12: {
                codigo: 12,
                barrio: "Coyolito (parte)",
              },
              13: {
                codigo: 13,
                barrio: "Chiqueros",
              },
              14: {
                codigo: 14,
                barrio: "Desjarretado",
              },
              15: {
                codigo: 15,
                barrio: "Irma",
              },
              16: {
                codigo: 16,
                barrio: "Jarquin (parte)",
              },
              17: {
                codigo: 17,
                barrio: "Jesus",
              },
              18: {
                codigo: 18,
                barrio: "Lajas",
              },
              19: {
                codigo: 19,
                barrio: "Las Huacas (Parte)",
              },
              20: {
                codigo: 20,
                barrio: "Limonal",
              },
              21: {
                codigo: 21,
                barrio: "Limonal Viejo",
              },
              22: {
                codigo: 22,
                barrio: "Matapalo",
              },
              23: {
                codigo: 23,
                barrio: "Naranjos Agrios",
              },
              24: {
                codigo: 24,
                barrio: "Palma",
              },
              25: {
                codigo: 25,
                barrio: "Pena",
              },
              26: {
                codigo: 26,
                barrio: "Puente de Tierra",
              },
              27: {
                codigo: 27,
                barrio: "Rancho Alegre (parte)",
              },
              28: {
                codigo: 28,
                barrio: "Lourdes (Rancho Ania) (parte)",
              },
              29: {
                codigo: 29,
                barrio: "San Cristobal",
              },
              30: {
                codigo: 30,
                barrio: "San Francisco",
              },
              31: {
                codigo: 31,
                barrio: "San Juan Chiquito",
              },
              32: {
                codigo: 32,
                barrio: "Santa Lucia",
              },
              33: {
                codigo: 33,
                barrio: "Tortugal",
              },
              34: {
                codigo: 34,
                barrio: "Zapote",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "SIERRA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Aguas Claras",
              },
              2: {
                codigo: 2,
                barrio: "Alto Cebadilla",
              },
              3: {
                codigo: 3,
                barrio: "Campos de Oro",
              },
              4: {
                codigo: 4,
                barrio: "Candelaria",
              },
              5: {
                codigo: 5,
                barrio: "Canitas",
              },
              6: {
                codigo: 6,
                barrio: "Cruz",
              },
              7: {
                codigo: 7,
                barrio: "Cuesta Yugo",
              },
              8: {
                codigo: 8,
                barrio: "Dos de Abangares",
              },
              9: {
                codigo: 9,
                barrio: "Marsellesa",
              },
              10: {
                codigo: 10,
                barrio: "San Antonio",
              },
              11: {
                codigo: 11,
                barrio: "San Rafael",
              },
              12: {
                codigo: 12,
                barrio: "Tornos",
              },
              13: {
                codigo: 13,
                barrio: "Tres Amigos",
              },
              14: {
                codigo: 14,
                barrio: "Turin (parte)",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "SAN JUAN",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Arizona",
              },
              2: {
                codigo: 2,
                barrio: "Congo",
              },
              3: {
                codigo: 3,
                barrio: "Nancital",
              },
              4: {
                codigo: 4,
                barrio: "Portones",
              },
              5: {
                codigo: 5,
                barrio: "Pozo Azul",
              },
              6: {
                codigo: 6,
                barrio: "Rancho Alegre (parte)",
              },
              7: {
                codigo: 7,
                barrio: "Lourdes (Rancho Ania) (parte)",
              },
              8: {
                codigo: 8,
                barrio: "Tierra Colorada",
              },
              9: {
                codigo: 9,
                barrio: "Vainilla",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "COLORADO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Almendros",
              },
              2: {
                codigo: 2,
                barrio: "Barbudal",
              },
              3: {
                codigo: 3,
                barrio: "Gavilanes",
              },
              4: {
                codigo: 4,
                barrio: "Higuerillas",
              },
              5: {
                codigo: 5,
                barrio: "Las Huacas (parte)",
              },
              6: {
                codigo: 6,
                barrio: "Monte Potrero",
              },
              7: {
                codigo: 7,
                barrio: "Quebracho",
              },
              8: {
                codigo: 8,
                barrio: "Penablanca",
              },
              9: {
                codigo: 9,
                barrio: "San Buenaventura",
              },
              10: {
                codigo: 10,
                barrio: "San Joaquin",
              },
              11: {
                codigo: 11,
                barrio: "Solimar",
              },
              12: {
                codigo: 12,
                barrio: "Villafuerte",
              },
            },
          },
        },
      },
      8: {
        codigo: 8,
        canton: "TILARAN",
        distritos: {
          1: {
            codigo: 1,
            distrito: "TILARAN",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Cabra",
              },
              2: {
                codigo: 2,
                barrio: "Carmen",
              },
              3: {
                codigo: 3,
                barrio: "Juan XXIII",
              },
              4: {
                codigo: 4,
                barrio: "Lomalinda",
              },
              5: {
                codigo: 5,
                barrio: "Cuatro Esquinas",
              },
              6: {
                codigo: 6,
                barrio: "Chiripa",
              },
              7: {
                codigo: 7,
                barrio: "Piamonte",
              },
              8: {
                codigo: 8,
                barrio: "Rio Chiquito",
              },
              9: {
                codigo: 9,
                barrio: "San Luis",
              },
              10: {
                codigo: 10,
                barrio: "Tejona",
              },
              11: {
                codigo: 11,
                barrio: "Tres Esquinas",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "QUEBRADA GRANDE",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Barrionuevo",
              },
              2: {
                codigo: 2,
                barrio: "Cabeceras de Canas",
              },
              3: {
                codigo: 3,
                barrio: "Campos de Oro",
              },
              4: {
                codigo: 4,
                barrio: "Dos de Tilaran",
              },
              5: {
                codigo: 5,
                barrio: "Esperanza",
              },
              6: {
                codigo: 6,
                barrio: "Florida",
              },
              7: {
                codigo: 7,
                barrio: "Monte Olivos",
              },
              8: {
                codigo: 8,
                barrio: "Nubes",
              },
              9: {
                codigo: 9,
                barrio: "San Miguel",
              },
              10: {
                codigo: 10,
                barrio: "Turin (parte)",
              },
              11: {
                codigo: 11,
                barrio: "Vueltas",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "TRONADORA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Arenal Viejo",
              },
              2: {
                codigo: 2,
                barrio: "Colonia Menonita",
              },
              3: {
                codigo: 3,
                barrio: "Rio Chiquito Abajo",
              },
              4: {
                codigo: 4,
                barrio: "Silencio",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "SANTA ROSA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Aguilares",
              },
              2: {
                codigo: 2,
                barrio: "Campos Azules",
              },
              3: {
                codigo: 3,
                barrio: "Montes de Oro (parte)",
              },
              4: {
                codigo: 4,
                barrio: "Naranjos Agrios",
              },
              5: {
                codigo: 5,
                barrio: "Palma",
              },
              6: {
                codigo: 6,
                barrio: "Quebrada Azul",
              },
              7: {
                codigo: 7,
                barrio: "Ranchitos",
              },
              8: {
                codigo: 8,
                barrio: "Santa Rosa",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "LIBANO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto Cartago",
              },
              2: {
                codigo: 2,
                barrio: "Maravilla",
              },
              3: {
                codigo: 3,
                barrio: "San Jose",
              },
              4: {
                codigo: 4,
                barrio: "Solania",
              },
            },
          },
          6: {
            codigo: 6,
            distrito: "TIERRAS MORENAS",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Aguacate",
              },
              2: {
                codigo: 2,
                barrio: "Aguas Gatas (parte)",
              },
              3: {
                codigo: 3,
                barrio: "Bajo Paires",
              },
              4: {
                codigo: 4,
                barrio: "Guadalajara",
              },
              5: {
                codigo: 5,
                barrio: "Montes de Oro (parte)",
              },
              6: {
                codigo: 6,
                barrio: "Paraiso (parte)",
              },
              7: {
                codigo: 7,
                barrio: "Rio Piedras",
              },
              8: {
                codigo: 8,
                barrio: "Sabalito",
              },
            },
          },
          7: {
            codigo: 7,
            distrito: "ARENAL",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Mata de Cana",
              },
              2: {
                codigo: 2,
                barrio: "Sangregado",
              },
              3: {
                codigo: 3,
                barrio: "San Antonio",
              },
              4: {
                codigo: 4,
                barrio: "Union",
              },
            },
          },
        },
      },
      9: {
        codigo: 9,
        canton: "NANDAYURE",
        distritos: {
          1: {
            codigo: 1,
            distrito: "CARMONA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Camas",
              },
              2: {
                codigo: 2,
                barrio: "Limones",
              },
              3: {
                codigo: 3,
                barrio: "Maquenco",
              },
              4: {
                codigo: 4,
                barrio: "San Rafael",
              },
              5: {
                codigo: 5,
                barrio: "Vista de Mar",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "SANTA RITA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Angostura",
              },
              2: {
                codigo: 2,
                barrio: "Cacao",
              },
              3: {
                codigo: 3,
                barrio: "Chumico",
              },
              4: {
                codigo: 4,
                barrio: "Guaria",
              },
              5: {
                codigo: 5,
                barrio: "Guastomatal",
              },
              6: {
                codigo: 6,
                barrio: "Morote",
              },
              7: {
                codigo: 7,
                barrio: "Tacanis",
              },
              8: {
                codigo: 8,
                barrio: "Uvita (parte)",
              },
              9: {
                codigo: 9,
                barrio: "Yerbabuena (parte)",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "ZAPOTAL",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Altos de Mora",
              },
              2: {
                codigo: 2,
                barrio: "Cabeceras de Rio Ora",
              },
              3: {
                codigo: 3,
                barrio: "Camaronal",
              },
              4: {
                codigo: 4,
                barrio: "Carmen",
              },
              5: {
                codigo: 5,
                barrio: "Cuesta Bijagua",
              },
              6: {
                codigo: 6,
                barrio: "Leona",
              },
              7: {
                codigo: 7,
                barrio: "Manzanales",
              },
              8: {
                codigo: 8,
                barrio: "Rio Blanco Este",
              },
              9: {
                codigo: 9,
                barrio: "Rio de Oro",
              },
              10: {
                codigo: 10,
                barrio: "Rio Ora",
              },
              11: {
                codigo: 11,
                barrio: "San Martin",
              },
              12: {
                codigo: 12,
                barrio: "San Pedro",
              },
              13: {
                codigo: 13,
                barrio: "Soledad",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "SAN PABLO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Canjel",
              },
              2: {
                codigo: 2,
                barrio: "Canjelito",
              },
              3: {
                codigo: 3,
                barrio: "Corozal Oeste",
              },
              4: {
                codigo: 4,
                barrio: "Chamarro",
              },
              5: {
                codigo: 5,
                barrio: "Isla Berrugate",
              },
              6: {
                codigo: 6,
                barrio: "Pavones",
              },
              7: {
                codigo: 7,
                barrio: "Puerto Thiel",
              },
              8: {
                codigo: 8,
                barrio: "San Pablo Viejo",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "PROVENIR",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Angeles",
              },
              2: {
                codigo: 2,
                barrio: "Bellavista",
              },
              3: {
                codigo: 3,
                barrio: "Cabeceras de Rio Bejuco",
              },
              4: {
                codigo: 4,
                barrio: "Chompipe (parte)",
              },
              5: {
                codigo: 5,
                barrio: "Delicias",
              },
              6: {
                codigo: 6,
                barrio: "Quebrada Grande",
              },
              7: {
                codigo: 7,
                barrio: "San Josecito",
              },
            },
          },
          6: {
            codigo: 6,
            distrito: "BEJUCO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Caletas",
              },
              2: {
                codigo: 2,
                barrio: "Candelillo",
              },
              3: {
                codigo: 3,
                barrio: "Corozalito",
              },
              4: {
                codigo: 4,
                barrio: "Chiruta",
              },
              5: {
                codigo: 5,
                barrio: "Chompipe (parte)",
              },
              6: {
                codigo: 6,
                barrio: "I Griega",
              },
              7: {
                codigo: 7,
                barrio: "Islita",
              },
              8: {
                codigo: 8,
                barrio: "Jabilla",
              },
              9: {
                codigo: 9,
                barrio: "Jabillos",
              },
              10: {
                codigo: 10,
                barrio: "Maicillal",
              },
              11: {
                codigo: 11,
                barrio: "Maquencal",
              },
              12: {
                codigo: 12,
                barrio: "Milagro",
              },
              13: {
                codigo: 13,
                barrio: "Millal",
              },
              14: {
                codigo: 14,
                barrio: "Mono",
              },
              15: {
                codigo: 15,
                barrio: "Pampas",
              },
              16: {
                codigo: 16,
                barrio: "Paso Vigas",
              },
              17: {
                codigo: 17,
                barrio: "Pencal",
              },
              18: {
                codigo: 18,
                barrio: "Playa Coyote",
              },
              19: {
                codigo: 19,
                barrio: "Playa San Miguel",
              },
              20: {
                codigo: 20,
                barrio: "Pueblo Nuevo",
              },
              21: {
                codigo: 21,
                barrio: "Punta Bejuco",
              },
              22: {
                codigo: 22,
                barrio: "Puerto Coyote",
              },
              23: {
                codigo: 23,
                barrio: "Quebrada Nando",
              },
              24: {
                codigo: 24,
                barrio: "Quebrada Seca",
              },
              25: {
                codigo: 25,
                barrio: "Rancho Floriana",
              },
              26: {
                codigo: 26,
                barrio: "San Francisco de Coyote",
              },
              27: {
                codigo: 27,
                barrio: "San Gabriel",
              },
              28: {
                codigo: 28,
                barrio: "San Miguel",
              },
              29: {
                codigo: 29,
                barrio: "Triunfo",
              },
              30: {
                codigo: 30,
                barrio: "Zapote",
              },
            },
          },
        },
      },
      10: {
        codigo: 10,
        canton: "LA CRUZ",
        distritos: {
          1: {
            codigo: 1,
            distrito: "LA CRUZ",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Corazon de Jesus",
              },
              2: {
                codigo: 2,
                barrio: "Fatima",
              },
              3: {
                codigo: 3,
                barrio: "Irving",
              },
              4: {
                codigo: 4,
                barrio: "Orosi",
              },
              5: {
                codigo: 5,
                barrio: "Pinos",
              },
              6: {
                codigo: 6,
                barrio: "Santa Rosa",
              },
              7: {
                codigo: 7,
                barrio: "Bellavista",
              },
              8: {
                codigo: 8,
                barrio: "Bello Horizonte",
              },
              9: {
                codigo: 9,
                barrio: "Brisas",
              },
              10: {
                codigo: 10,
                barrio: "Cacao",
              },
              11: {
                codigo: 11,
                barrio: "Carrizal",
              },
              12: {
                codigo: 12,
                barrio: "Carrizales",
              },
              13: {
                codigo: 13,
                barrio: "Colonia Bolanos",
              },
              14: {
                codigo: 14,
                barrio: "Copalchi",
              },
              15: {
                codigo: 15,
                barrio: "Infierno",
              },
              16: {
                codigo: 16,
                barrio: "Jobo",
              },
              17: {
                codigo: 17,
                barrio: "Libertad",
              },
              18: {
                codigo: 18,
                barrio: "Monte Plata",
              },
              19: {
                codigo: 19,
                barrio: "Montes de Oro",
              },
              20: {
                codigo: 20,
                barrio: "Pampa",
              },
              21: {
                codigo: 21,
                barrio: "Pegon",
              },
              22: {
                codigo: 22,
                barrio: "Penas Blancas",
              },
              23: {
                codigo: 23,
                barrio: "Piedra Pomez",
              },
              24: {
                codigo: 24,
                barrio: "Puerto Soley",
              },
              25: {
                codigo: 25,
                barrio: "Recreo",
              },
              26: {
                codigo: 26,
                barrio: "San Buenaventura",
              },
              27: {
                codigo: 27,
                barrio: "San Dimas",
              },
              28: {
                codigo: 28,
                barrio: "San Paco",
              },
              29: {
                codigo: 29,
                barrio: "San Roque",
              },
              30: {
                codigo: 30,
                barrio: "Santa Rogelia",
              },
              31: {
                codigo: 31,
                barrio: "Santa Rosa",
              },
              32: {
                codigo: 32,
                barrio: "Soley",
              },
              33: {
                codigo: 33,
                barrio: "Sonzapote",
              },
              34: {
                codigo: 34,
                barrio: "Tempatal",
              },
              35: {
                codigo: 35,
                barrio: "Vueltas",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "SANTA CECILIA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Angeles",
              },
              2: {
                codigo: 2,
                barrio: "Corrales Negros",
              },
              3: {
                codigo: 3,
                barrio: "Pueblo Nuevo",
              },
              4: {
                codigo: 4,
                barrio: "Argendora",
              },
              5: {
                codigo: 5,
                barrio: "Armenia",
              },
              6: {
                codigo: 6,
                barrio: "Belice",
              },
              7: {
                codigo: 7,
                barrio: "Bellavista",
              },
              8: {
                codigo: 8,
                barrio: "Brisas",
              },
              9: {
                codigo: 9,
                barrio: "Caoba",
              },
              10: {
                codigo: 10,
                barrio: "Esperanza",
              },
              11: {
                codigo: 11,
                barrio: "Flor del Norte",
              },
              12: {
                codigo: 12,
                barrio: "Lajosa",
              },
              13: {
                codigo: 13,
                barrio: "Marias",
              },
              14: {
                codigo: 14,
                barrio: "Palmares",
              },
              15: {
                codigo: 15,
                barrio: "San Antonio",
              },
              16: {
                codigo: 16,
                barrio: "San Cristobal",
              },
              17: {
                codigo: 17,
                barrio: "San Rafael",
              },
              18: {
                codigo: 18,
                barrio: "San Vicente",
              },
              19: {
                codigo: 19,
                barrio: "Santa Elena",
              },
              20: {
                codigo: 20,
                barrio: "Sardina",
              },
              21: {
                codigo: 21,
                barrio: "Virgen",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "GARITA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Paraiso",
              },
              2: {
                codigo: 2,
                barrio: "Agua Muerta",
              },
              3: {
                codigo: 3,
                barrio: "Andes",
              },
              4: {
                codigo: 4,
                barrio: "Asilo",
              },
              5: {
                codigo: 5,
                barrio: "Canita",
              },
              6: {
                codigo: 6,
                barrio: "Carmen",
              },
              7: {
                codigo: 7,
                barrio: "Fortuna",
              },
              8: {
                codigo: 8,
                barrio: "Gloria",
              },
              9: {
                codigo: 9,
                barrio: "Guapinol",
              },
              10: {
                codigo: 10,
                barrio: "Inocentes",
              },
              11: {
                codigo: 11,
                barrio: "Lavaderos",
              },
              12: {
                codigo: 12,
                barrio: "Pochote",
              },
              13: {
                codigo: 13,
                barrio: "San Antonio",
              },
              14: {
                codigo: 14,
                barrio: "Tapesco",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "SANTA ELENA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Cedros",
              },
              2: {
                codigo: 2,
                barrio: "Guaria",
              },
              3: {
                codigo: 3,
                barrio: "Puerto Castilla",
              },
              4: {
                codigo: 4,
                barrio: "Rabo de Mico (Aguacaliente)",
              },
            },
          },
        },
      },
      11: {
        codigo: 11,
        canton: "HOJANCHA",
        distritos: {
          1: {
            codigo: 1,
            distrito: "HOJANCHA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Angeles",
              },
              2: {
                codigo: 2,
                barrio: "Arena",
              },
              3: {
                codigo: 3,
                barrio: "Ceiba",
              },
              4: {
                codigo: 4,
                barrio: "Cuesta Blanca",
              },
              5: {
                codigo: 5,
                barrio: "Libertad",
              },
              6: {
                codigo: 6,
                barrio: "Maravilla",
              },
              7: {
                codigo: 7,
                barrio: "Matambu",
              },
              8: {
                codigo: 8,
                barrio: "Palo de Jabon",
              },
              9: {
                codigo: 9,
                barrio: "Pilangosta",
              },
              10: {
                codigo: 10,
                barrio: "San Juan Bosco",
              },
              11: {
                codigo: 11,
                barrio: "San Rafael",
              },
              12: {
                codigo: 12,
                barrio: "Santa Elena (parte)",
              },
              13: {
                codigo: 13,
                barrio: "Varillal",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "MONTE ROMO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Altos del Socorro",
              },
              2: {
                codigo: 2,
                barrio: "Bajo Saltos",
              },
              3: {
                codigo: 3,
                barrio: "Cabrera",
              },
              4: {
                codigo: 4,
                barrio: "Cuesta Roja",
              },
              5: {
                codigo: 5,
                barrio: "Delicias",
              },
              6: {
                codigo: 6,
                barrio: "Guapinol",
              },
              7: {
                codigo: 7,
                barrio: "Loros",
              },
              8: {
                codigo: 8,
                barrio: "Mercedes",
              },
              9: {
                codigo: 9,
                barrio: "Palmares",
              },
              10: {
                codigo: 10,
                barrio: "Rio Zapotal",
              },
              11: {
                codigo: 11,
                barrio: "San Isidro",
              },
              12: {
                codigo: 12,
                barrio: "Trinidad",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "PUERTO CARRILLO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Angostura",
              },
              2: {
                codigo: 2,
                barrio: "Arbolito",
              },
              3: {
                codigo: 3,
                barrio: "Cuesta Malanoche",
              },
              4: {
                codigo: 4,
                barrio: "Estrada",
              },
              5: {
                codigo: 5,
                barrio: "Jobo",
              },
              6: {
                codigo: 6,
                barrio: "Lajas",
              },
              7: {
                codigo: 7,
                barrio: "Quebrada Bonita (parte)",
              },
              8: {
                codigo: 8,
                barrio: "San Miguel",
              },
              9: {
                codigo: 9,
                barrio: "Santa Maria",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "HUACAS",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Avellana",
              },
              2: {
                codigo: 2,
                barrio: "Pita Rayada",
              },
              3: {
                codigo: 3,
                barrio: "Rio Blanco Oeste",
              },
              4: {
                codigo: 4,
                barrio: "Tres Quebradas",
              },
            },
          },
        },
      },
    },
  },
  6: {
    codigo: 6,
    provincia: "Puntarenas",
    cantones: {
      1: {
        codigo: 1,
        canton: "PUNTARENAS",
        distritos: {
          1: {
            codigo: 1,
            distrito: "PUNTARENAS",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Angostura",
              },
              2: {
                codigo: 2,
                barrio: "Carmen",
              },
              3: {
                codigo: 3,
                barrio: "Cocal",
              },
              4: {
                codigo: 4,
                barrio: "Playitas",
              },
              5: {
                codigo: 5,
                barrio: "Pochote",
              },
              6: {
                codigo: 6,
                barrio: "Pueblo Nuevo",
              },
              7: {
                codigo: 7,
                barrio: "Isla Bejuco",
              },
              8: {
                codigo: 8,
                barrio: "Isla Caballo",
              },
              9: {
                codigo: 9,
                barrio: "Palmar",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "PITAHAYA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Aranjuez",
              },
              2: {
                codigo: 2,
                barrio: "Brillante (parte)",
              },
              3: {
                codigo: 3,
                barrio: "Cebadilla",
              },
              4: {
                codigo: 4,
                barrio: "Chapernal",
              },
              5: {
                codigo: 5,
                barrio: "Palermo",
              },
              6: {
                codigo: 6,
                barrio: "Pitahaya Vieja",
              },
              7: {
                codigo: 7,
                barrio: "Rancho Grande",
              },
              8: {
                codigo: 8,
                barrio: "San Marcos (parte)",
              },
              9: {
                codigo: 9,
                barrio: "Zapotal",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "CHOMES",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto Pie de Paloma",
              },
              2: {
                codigo: 2,
                barrio: "Cambalache",
              },
              3: {
                codigo: 3,
                barrio: "Cocoroca",
              },
              4: {
                codigo: 4,
                barrio: "Coyoles Motos",
              },
              5: {
                codigo: 5,
                barrio: "Don Jaime",
              },
              6: {
                codigo: 6,
                barrio: "Jarquin (parte)",
              },
              7: {
                codigo: 7,
                barrio: "Judas",
              },
              8: {
                codigo: 8,
                barrio: "Laberinto",
              },
              9: {
                codigo: 9,
                barrio: "Lagarto",
              },
              10: {
                codigo: 10,
                barrio: "Malinche",
              },
              11: {
                codigo: 11,
                barrio: "Morales",
              },
              12: {
                codigo: 12,
                barrio: "Pita",
              },
              13: {
                codigo: 13,
                barrio: "Playa Coco",
              },
              14: {
                codigo: 14,
                barrio: "Pocitos",
              },
              15: {
                codigo: 15,
                barrio: "Punta Morales",
              },
              16: {
                codigo: 16,
                barrio: "San Agustin",
              },
              17: {
                codigo: 17,
                barrio: "San Gerardo",
              },
              18: {
                codigo: 18,
                barrio: "Santa Juana",
              },
              19: {
                codigo: 19,
                barrio: "Sarmiento",
              },
              20: {
                codigo: 20,
                barrio: "Terrero",
              },
              21: {
                codigo: 21,
                barrio: "Vanegas",
              },
              22: {
                codigo: 22,
                barrio: "Yomale",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "LEPANTO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto Fresca",
              },
              2: {
                codigo: 2,
                barrio: "Bajo Mora",
              },
              3: {
                codigo: 3,
                barrio: "Balsa",
              },
              4: {
                codigo: 4,
                barrio: "Balso",
              },
              5: {
                codigo: 5,
                barrio: "Bijagua",
              },
              6: {
                codigo: 6,
                barrio: "Brisas",
              },
              7: {
                codigo: 7,
                barrio: "Cabo Blanco",
              },
              8: {
                codigo: 8,
                barrio: "Camaronal",
              },
              9: {
                codigo: 9,
                barrio: "Cantil",
              },
              10: {
                codigo: 10,
                barrio: "Canablancal",
              },
              11: {
                codigo: 11,
                barrio: "Cerro Frio",
              },
              12: {
                codigo: 12,
                barrio: "Cerro Indio",
              },
              13: {
                codigo: 13,
                barrio: "Cerro Pando",
              },
              14: {
                codigo: 14,
                barrio: "Corozal",
              },
              15: {
                codigo: 15,
                barrio: "Coto",
              },
              16: {
                codigo: 16,
                barrio: "Cuajiniquil",
              },
              17: {
                codigo: 17,
                barrio: "Chanchos",
              },
              18: {
                codigo: 18,
                barrio: "Chiqueros",
              },
              19: {
                codigo: 19,
                barrio: "Dominica",
              },
              20: {
                codigo: 20,
                barrio: "El Mora",
              },
              21: {
                codigo: 21,
                barrio: "Encanto",
              },
              22: {
                codigo: 22,
                barrio: "Fresca",
              },
              23: {
                codigo: 23,
                barrio: "Gloria",
              },
              24: {
                codigo: 24,
                barrio: "Golfo",
              },
              25: {
                codigo: 25,
                barrio: "Guabo",
              },
              26: {
                codigo: 26,
                barrio: "Guadalupe",
              },
              27: {
                codigo: 27,
                barrio: "Ilusion",
              },
              28: {
                codigo: 28,
                barrio: "Isla Venado",
              },
              29: {
                codigo: 29,
                barrio: "Jicaral",
              },
              30: {
                codigo: 30,
                barrio: "Juan de Leon",
              },
              31: {
                codigo: 31,
                barrio: "Milpa",
              },
              32: {
                codigo: 32,
                barrio: "Montana Grande",
              },
              33: {
                codigo: 33,
                barrio: "Nispero",
              },
              34: {
                codigo: 34,
                barrio: "Nubes",
              },
              35: {
                codigo: 35,
                barrio: "Once Estrellas",
              },
              36: {
                codigo: 36,
                barrio: "Piedades",
              },
              37: {
                codigo: 37,
                barrio: "Pilas de Canjel",
              },
              38: {
                codigo: 38,
                barrio: "Punta de Cera",
              },
              39: {
                codigo: 39,
                barrio: "Rio Seco",
              },
              40: {
                codigo: 40,
                barrio: "Sahino",
              },
              41: {
                codigo: 41,
                barrio: "San Blas",
              },
              42: {
                codigo: 42,
                barrio: "San Miguel",
              },
              43: {
                codigo: 43,
                barrio: "San Miguel de Rio Blanco",
              },
              44: {
                codigo: 44,
                barrio: "San Pedro",
              },
              45: {
                codigo: 45,
                barrio: "San Rafael",
              },
              46: {
                codigo: 46,
                barrio: "San Ramon de Rio Blanco",
              },
              47: {
                codigo: 47,
                barrio: "Santa Rosa",
              },
              48: {
                codigo: 48,
                barrio: "Tigra",
              },
              49: {
                codigo: 49,
                barrio: "Tres Rios",
              },
              50: {
                codigo: 50,
                barrio: "Tronconal",
              },
              51: {
                codigo: 51,
                barrio: "Union",
              },
              52: {
                codigo: 52,
                barrio: "Vainilla",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "PAQUERA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Angeles",
              },
              2: {
                codigo: 2,
                barrio: "Astro Blanco",
              },
              3: {
                codigo: 3,
                barrio: "Bajo Negro",
              },
              4: {
                codigo: 4,
                barrio: "Cabeceras de Rio Seco",
              },
              5: {
                codigo: 5,
                barrio: "Campinas",
              },
              6: {
                codigo: 6,
                barrio: "Cerro Brujo",
              },
              7: {
                codigo: 7,
                barrio: "Concepcion",
              },
              8: {
                codigo: 8,
                barrio: "Curu",
              },
              9: {
                codigo: 9,
                barrio: "Dulce Nombre",
              },
              10: {
                codigo: 10,
                barrio: "Espaveles",
              },
              11: {
                codigo: 11,
                barrio: "Esperanza",
              },
              12: {
                codigo: 12,
                barrio: "Flor",
              },
              13: {
                codigo: 13,
                barrio: "Gigante",
              },
              14: {
                codigo: 14,
                barrio: "Guaria",
              },
              15: {
                codigo: 15,
                barrio: "Higueronal",
              },
              16: {
                codigo: 16,
                barrio: "Isla Cedros",
              },
              17: {
                codigo: 17,
                barrio: "Isla Jesucita",
              },
              18: {
                codigo: 18,
                barrio: "Isla Tortuga",
              },
              19: {
                codigo: 19,
                barrio: "Leona",
              },
              20: {
                codigo: 20,
                barrio: "Mango",
              },
              21: {
                codigo: 21,
                barrio: "Naranjo",
              },
              22: {
                codigo: 22,
                barrio: "Panica",
              },
              23: {
                codigo: 23,
                barrio: "Paraiso",
              },
              24: {
                codigo: 24,
                barrio: "Playa Blanca",
              },
              25: {
                codigo: 25,
                barrio: "Playa Cuchillo",
              },
              26: {
                codigo: 26,
                barrio: "Pochote",
              },
              27: {
                codigo: 27,
                barrio: "Punta del Rio",
              },
              28: {
                codigo: 28,
                barrio: "Quebrada Bonita",
              },
              29: {
                codigo: 29,
                barrio: "Rio Grande",
              },
              30: {
                codigo: 30,
                barrio: "Rio Guarial",
              },
              31: {
                codigo: 31,
                barrio: "Rio Seco",
              },
              32: {
                codigo: 32,
                barrio: "Rivas",
              },
              33: {
                codigo: 33,
                barrio: "San Fernando",
              },
              34: {
                codigo: 34,
                barrio: "San Luis",
              },
              35: {
                codigo: 35,
                barrio: "San Pedro",
              },
              36: {
                codigo: 36,
                barrio: "San Rafael",
              },
              37: {
                codigo: 37,
                barrio: "San Vicente",
              },
              38: {
                codigo: 38,
                barrio: "Santa Cecilia",
              },
              39: {
                codigo: 39,
                barrio: "Santa Lucia",
              },
              40: {
                codigo: 40,
                barrio: "Santa Rosa",
              },
              41: {
                codigo: 41,
                barrio: "Sonzapote",
              },
              42: {
                codigo: 42,
                barrio: "Tronco Negro",
              },
              43: {
                codigo: 43,
                barrio: "Valle Azul",
              },
              44: {
                codigo: 44,
                barrio: "Vueltas",
              },
            },
          },
          6: {
            codigo: 6,
            distrito: "MANZANILLO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Abangaritos",
              },
              2: {
                codigo: 2,
                barrio: "Camarita",
              },
              3: {
                codigo: 3,
                barrio: "Costa de Pajaros",
              },
              4: {
                codigo: 4,
                barrio: "Coyolito (parte)",
              },
              5: {
                codigo: 5,
                barrio: "Cuesta Portillo",
              },
            },
          },
          7: {
            codigo: 7,
            distrito: "GUACIMAL",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto Mendez",
              },
              2: {
                codigo: 2,
                barrio: "Altos Fernandez",
              },
              3: {
                codigo: 3,
                barrio: "Angeles",
              },
              4: {
                codigo: 4,
                barrio: "Guaria",
              },
              5: {
                codigo: 5,
                barrio: "Lajon",
              },
              6: {
                codigo: 6,
                barrio: "San Antonio",
              },
              7: {
                codigo: 7,
                barrio: "Santa Rosa",
              },
              8: {
                codigo: 8,
                barrio: "Surtubal",
              },
              9: {
                codigo: 9,
                barrio: "Veracruz",
              },
            },
          },
          8: {
            codigo: 8,
            distrito: "BARRANCA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Rioja",
              },
              2: {
                codigo: 2,
                barrio: "Obregon",
              },
              3: {
                codigo: 3,
                barrio: "San Joaquin",
              },
              4: {
                codigo: 4,
                barrio: "San Miguel",
              },
              5: {
                codigo: 5,
                barrio: "San Miguelito",
              },
              6: {
                codigo: 6,
                barrio: "Santa Ana",
              },
            },
          },
          9: {
            codigo: 9,
            distrito: "MONTE VERDE",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Cerro Plano",
              },
              2: {
                codigo: 2,
                barrio: "Cuesta Blanca",
              },
              3: {
                codigo: 3,
                barrio: "Lindora",
              },
              4: {
                codigo: 4,
                barrio: "Llanos",
              },
              5: {
                codigo: 5,
                barrio: "Monte Verde",
              },
              6: {
                codigo: 6,
                barrio: "San Luis",
              },
            },
          },
          10: {
            codigo: 10,
            distrito: "ISLA DEL COCO",
            barrios: {},
          },
          11: {
            codigo: 11,
            distrito: "COBANO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Abuela",
              },
              2: {
                codigo: 2,
                barrio: "Ario",
              },
              3: {
                codigo: 3,
                barrio: "Bajos de Ario",
              },
              4: {
                codigo: 4,
                barrio: "Bajos de Fernandez",
              },
              5: {
                codigo: 5,
                barrio: "Bello Horizonte",
              },
              6: {
                codigo: 6,
                barrio: "Betel",
              },
              7: {
                codigo: 7,
                barrio: "Cabuya",
              },
              8: {
                codigo: 8,
                barrio: "Canaan",
              },
              9: {
                codigo: 9,
                barrio: "Canada",
              },
              10: {
                codigo: 10,
                barrio: "Cano Seco Abajo",
              },
              11: {
                codigo: 11,
                barrio: "Cano Seco Arriba",
              },
              12: {
                codigo: 12,
                barrio: "Cano Seco Enmedio",
              },
              13: {
                codigo: 13,
                barrio: "Carmen",
              },
              14: {
                codigo: 14,
                barrio: "Cedro",
              },
              15: {
                codigo: 15,
                barrio: "Cerital",
              },
              16: {
                codigo: 16,
                barrio: "Cerro Buenavista",
              },
              17: {
                codigo: 17,
                barrio: "Cocal",
              },
              18: {
                codigo: 18,
                barrio: "Cocalito",
              },
              19: {
                codigo: 19,
                barrio: "Delicias",
              },
              20: {
                codigo: 20,
                barrio: "Malpais",
              },
              21: {
                codigo: 21,
                barrio: "Montezuma",
              },
              22: {
                codigo: 22,
                barrio: "Muelle",
              },
              23: {
                codigo: 23,
                barrio: "Pachanga",
              },
              24: {
                codigo: 24,
                barrio: "Pavon",
              },
              25: {
                codigo: 25,
                barrio: "Penjamo",
              },
              26: {
                codigo: 26,
                barrio: "Piedra Amarilla",
              },
              27: {
                codigo: 27,
                barrio: "Pita",
              },
              28: {
                codigo: 28,
                barrio: "Rio Enmedio",
              },
              29: {
                codigo: 29,
                barrio: "Rio Frio",
              },
              30: {
                codigo: 30,
                barrio: "Rio Negro",
              },
              31: {
                codigo: 31,
                barrio: "San Antonio",
              },
              32: {
                codigo: 32,
                barrio: "San Isidro",
              },
              33: {
                codigo: 33,
                barrio: "San Jorge",
              },
              34: {
                codigo: 34,
                barrio: "San Ramon",
              },
              35: {
                codigo: 35,
                barrio: "Santa Clemencia",
              },
              36: {
                codigo: 36,
                barrio: "Santa Fe",
              },
              37: {
                codigo: 37,
                barrio: "Santa Teresa",
              },
              38: {
                codigo: 38,
                barrio: "Santiago",
              },
              39: {
                codigo: 39,
                barrio: "Tacotales",
              },
              40: {
                codigo: 40,
                barrio: "Tambor",
              },
              41: {
                codigo: 41,
                barrio: "Villalta",
              },
            },
          },
          12: {
            codigo: 12,
            distrito: "CHACARITA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Camboya",
              },
              2: {
                codigo: 2,
                barrio: "Carrizal",
              },
              3: {
                codigo: 3,
                barrio: "Chacarita",
              },
              4: {
                codigo: 4,
                barrio: "Chacarita Norte",
              },
              5: {
                codigo: 5,
                barrio: "Fertica",
              },
              6: {
                codigo: 6,
                barrio: "Fray Casiano",
              },
              7: {
                codigo: 7,
                barrio: "Huerto",
              },
              8: {
                codigo: 8,
                barrio: "Lindavista",
              },
              9: {
                codigo: 9,
                barrio: "Pueblo Redondo",
              },
              10: {
                codigo: 10,
                barrio: "Reyes",
              },
              11: {
                codigo: 11,
                barrio: "San Isidro",
              },
              12: {
                codigo: 12,
                barrio: "Santa Eduvigis",
              },
              13: {
                codigo: 13,
                barrio: "Tanque",
              },
              14: {
                codigo: 14,
                barrio: "Veinte de Noviembre",
              },
            },
          },
          13: {
            codigo: 13,
            distrito: "CHIRA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bocana",
              },
              2: {
                codigo: 2,
                barrio: "Lagartero",
              },
              3: {
                codigo: 3,
                barrio: "Montero",
              },
              4: {
                codigo: 4,
                barrio: "Pilas",
              },
              5: {
                codigo: 5,
                barrio: "Pochote",
              },
              6: {
                codigo: 6,
                barrio: "Puerto Coloradito",
              },
              7: {
                codigo: 7,
                barrio: "Puerto Mauricio",
              },
              8: {
                codigo: 8,
                barrio: "Puerto Palito",
              },
            },
          },
          14: {
            codigo: 14,
            distrito: "ACAPULCO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Acapulco",
              },
              2: {
                codigo: 2,
                barrio: "Aranjuecito",
              },
              3: {
                codigo: 3,
                barrio: "Chapernalito",
              },
              4: {
                codigo: 4,
                barrio: "Claraboya",
              },
              5: {
                codigo: 5,
                barrio: "Coyolar",
              },
              6: {
                codigo: 6,
                barrio: "Quebrada Honda",
              },
              7: {
                codigo: 7,
                barrio: "San Marcos (parte)",
              },
            },
          },
          15: {
            codigo: 15,
            distrito: "EL ROBLE",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Boca de Barranca",
              },
              2: {
                codigo: 2,
                barrio: "Chaguite",
              },
              3: {
                codigo: 3,
                barrio: "El Roble",
              },
            },
          },
          16: {
            codigo: 16,
            distrito: "ARANCIBIA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Poblados Arancibia Norte",
              },
              2: {
                codigo: 2,
                barrio: "Arancibia Sur",
              },
              3: {
                codigo: 3,
                barrio: "Lagunas",
              },
              4: {
                codigo: 4,
                barrio: "Ojo de Agua",
              },
              5: {
                codigo: 5,
                barrio: "Rincon",
              },
              6: {
                codigo: 6,
                barrio: "San Martin Norte",
              },
              7: {
                codigo: 7,
                barrio: "San Martin Sur",
              },
            },
          },
        },
      },
      2: {
        codigo: 2,
        canton: "ESPARZA",
        distritos: {
          1: {
            codigo: 1,
            distrito: "ESPIRITU SANTO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Maranonal (parte)",
              },
              2: {
                codigo: 2,
                barrio: "Mojon",
              },
              3: {
                codigo: 3,
                barrio: "Tejar",
              },
              4: {
                codigo: 4,
                barrio: "Gregg",
              },
              5: {
                codigo: 5,
                barrio: "Humo",
              },
              6: {
                codigo: 6,
                barrio: "Mojoncito",
              },
              7: {
                codigo: 7,
                barrio: "Pan de Azucar",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "SAN JUAN GRANDE",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Jocote",
              },
              2: {
                codigo: 2,
                barrio: "Juanilama",
              },
              3: {
                codigo: 3,
                barrio: "San Juan Chiquito",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "MACACONA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Maranonal (parte)",
              },
              2: {
                codigo: 2,
                barrio: "Bruselas",
              },
              3: {
                codigo: 3,
                barrio: "Guapinol",
              },
              4: {
                codigo: 4,
                barrio: "Nances",
              },
              5: {
                codigo: 5,
                barrio: "San Roque",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "SAN RAFAEL",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto Corteza",
              },
              2: {
                codigo: 2,
                barrio: "Baron",
              },
              3: {
                codigo: 3,
                barrio: "Facio",
              },
              4: {
                codigo: 4,
                barrio: "Llanada del Cacao",
              },
              5: {
                codigo: 5,
                barrio: "Maraton",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "SAN JERONIMO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Cerrillos",
              },
              2: {
                codigo: 2,
                barrio: "Mesetas Abajo",
              },
              3: {
                codigo: 3,
                barrio: "Mesetas Arriba",
              },
              4: {
                codigo: 4,
                barrio: "Pena Blanca",
              },
              5: {
                codigo: 5,
                barrio: "Pretiles",
              },
              6: {
                codigo: 6,
                barrio: "Quebradas",
              },
              7: {
                codigo: 7,
                barrio: "Sabana Bonita",
              },
            },
          },
          6: {
            codigo: 6,
            distrito: "CALDERA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto de Las Mesas",
              },
              2: {
                codigo: 2,
                barrio: "Artieda",
              },
              3: {
                codigo: 3,
                barrio: "Caldera",
              },
              4: {
                codigo: 4,
                barrio: "Cabezas",
              },
              5: {
                codigo: 5,
                barrio: "Cambalache",
              },
              6: {
                codigo: 6,
                barrio: "Cascabel",
              },
              7: {
                codigo: 7,
                barrio: "Corralillo",
              },
              8: {
                codigo: 8,
                barrio: "Cuesta Jocote",
              },
              9: {
                codigo: 9,
                barrio: "Figueroa",
              },
              10: {
                codigo: 10,
                barrio: "Finca Brazo Seco",
              },
              11: {
                codigo: 11,
                barrio: "Finca Cortijo",
              },
              12: {
                codigo: 12,
                barrio: "Guardianes de La Piedra",
              },
              13: {
                codigo: 13,
                barrio: "Hacienda La Moncha",
              },
              14: {
                codigo: 14,
                barrio: "Hacienda Mata de Guinea",
              },
              15: {
                codigo: 15,
                barrio: "Hacienda Playa Linda",
              },
              16: {
                codigo: 16,
                barrio: "Hacienda Salinas",
              },
              17: {
                codigo: 17,
                barrio: "Jesus Maria",
              },
              18: {
                codigo: 18,
                barrio: "Quebrada Honda",
              },
              19: {
                codigo: 19,
                barrio: "Salinas",
              },
              20: {
                codigo: 20,
                barrio: "San Antonio",
              },
              21: {
                codigo: 21,
                barrio: "Silencio",
              },
              22: {
                codigo: 22,
                barrio: "Tivives",
              },
              23: {
                codigo: 23,
                barrio: "Villanueva",
              },
            },
          },
        },
      },
      3: {
        codigo: 3,
        canton: "BUENOS AIRES",
        distritos: {
          1: {
            codigo: 1,
            distrito: "BUENOS AIRES",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto Buenos Aires",
              },
              2: {
                codigo: 2,
                barrio: "Lomas",
              },
              3: {
                codigo: 3,
                barrio: "Alto Alejo",
              },
              4: {
                codigo: 4,
                barrio: "Alto Brisas",
              },
              5: {
                codigo: 5,
                barrio: "Alto Calderon",
              },
              6: {
                codigo: 6,
                barrio: "Bajo Brisas",
              },
              7: {
                codigo: 7,
                barrio: "Bolas",
              },
              8: {
                codigo: 8,
                barrio: "Brujo",
              },
              9: {
                codigo: 9,
                barrio: "Cabagra (parte)",
              },
              10: {
                codigo: 10,
                barrio: "Caracol",
              },
              11: {
                codigo: 11,
                barrio: "Ceibo",
              },
              12: {
                codigo: 12,
                barrio: "Colepato",
              },
              13: {
                codigo: 13,
                barrio: "El Carmen",
              },
              14: {
                codigo: 14,
                barrio: "Guanacaste",
              },
              15: {
                codigo: 15,
                barrio: "Guadalupe",
              },
              16: {
                codigo: 16,
                barrio: "Lopez",
              },
              17: {
                codigo: 17,
                barrio: "Los Altos",
              },
              18: {
                codigo: 18,
                barrio: "Llano Verde",
              },
              19: {
                codigo: 19,
                barrio: "Machomontes",
              },
              20: {
                codigo: 20,
                barrio: "Palmital",
              },
              21: {
                codigo: 21,
                barrio: "Paraiso (Animas)",
              },
              22: {
                codigo: 22,
                barrio: "Paso Verba",
              },
              23: {
                codigo: 23,
                barrio: "Pinera",
              },
              24: {
                codigo: 24,
                barrio: "Platanares",
              },
              25: {
                codigo: 25,
                barrio: "Potrero Cerrado",
              },
              26: {
                codigo: 26,
                barrio: "Puente de Salitre",
              },
              27: {
                codigo: 27,
                barrio: "Rio Azul",
              },
              28: {
                codigo: 28,
                barrio: "Salitre",
              },
              29: {
                codigo: 29,
                barrio: "San Carlos",
              },
              30: {
                codigo: 30,
                barrio: "San Luis (Florida)",
              },
              31: {
                codigo: 31,
                barrio: "San Miguel Este",
              },
              32: {
                codigo: 32,
                barrio: "San Miguel Oeste",
              },
              33: {
                codigo: 33,
                barrio: "San Vicente",
              },
              34: {
                codigo: 34,
                barrio: "Santa Cruz",
              },
              35: {
                codigo: 35,
                barrio: "Santa Eduvigis",
              },
              36: {
                codigo: 36,
                barrio: "Santa Marta",
              },
              37: {
                codigo: 37,
                barrio: "Sipar",
              },
              38: {
                codigo: 38,
                barrio: "Ujarras",
              },
              39: {
                codigo: 39,
                barrio: "Villahermosa",
              },
              40: {
                codigo: 40,
                barrio: "Yheri",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "VOLCAN",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Altamira",
              },
              2: {
                codigo: 2,
                barrio: "Angel Arriba",
              },
              3: {
                codigo: 3,
                barrio: "Bajos del Rio Grande",
              },
              4: {
                codigo: 4,
                barrio: "Cacao",
              },
              5: {
                codigo: 5,
                barrio: "Convento",
              },
              6: {
                codigo: 6,
                barrio: "Cordoncillo",
              },
              7: {
                codigo: 7,
                barrio: "Los Angeles",
              },
              8: {
                codigo: 8,
                barrio: "Longo Mai",
              },
              9: {
                codigo: 9,
                barrio: "Peje",
              },
              10: {
                codigo: 10,
                barrio: "Quebradas",
              },
              11: {
                codigo: 11,
                barrio: "Rio Grande",
              },
              12: {
                codigo: 12,
                barrio: "Sabanilla",
              },
              13: {
                codigo: 13,
                barrio: "Sonador",
              },
              14: {
                codigo: 14,
                barrio: "Tarise",
              },
              15: {
                codigo: 15,
                barrio: "Tres Rios",
              },
              16: {
                codigo: 16,
                barrio: "Ultrapez",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "POTRERO GRANDE",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto La Cruz",
              },
              2: {
                codigo: 2,
                barrio: "Alto Tigre",
              },
              3: {
                codigo: 3,
                barrio: "Angeles",
              },
              4: {
                codigo: 4,
                barrio: "Boca Limon",
              },
              5: {
                codigo: 5,
                barrio: "Brazos de Oro",
              },
              6: {
                codigo: 6,
                barrio: "Cabagra (parte)",
              },
              7: {
                codigo: 7,
                barrio: "Campo Alegre",
              },
              8: {
                codigo: 8,
                barrio: "Capri",
              },
              9: {
                codigo: 9,
                barrio: "Caracol",
              },
              10: {
                codigo: 10,
                barrio: "Caracucho",
              },
              11: {
                codigo: 11,
                barrio: "Clavera",
              },
              12: {
                codigo: 12,
                barrio: "Colegallo",
              },
              13: {
                codigo: 13,
                barrio: "Copal",
              },
              14: {
                codigo: 14,
                barrio: "Coto Brus (parte)",
              },
              15: {
                codigo: 15,
                barrio: "Cuesta Maranones",
              },
              16: {
                codigo: 16,
                barrio: "Delicias",
              },
              17: {
                codigo: 17,
                barrio: "Garrote",
              },
              18: {
                codigo: 18,
                barrio: "Guacimo",
              },
              19: {
                codigo: 19,
                barrio: "Guaria",
              },
              20: {
                codigo: 20,
                barrio: "Helechales",
              },
              21: {
                codigo: 21,
                barrio: "Jabillo",
              },
              22: {
                codigo: 22,
                barrio: "Joron",
              },
              23: {
                codigo: 23,
                barrio: "Juntas",
              },
              24: {
                codigo: 24,
                barrio: "Lucha",
              },
              25: {
                codigo: 25,
                barrio: "Maravilla",
              },
              26: {
                codigo: 26,
                barrio: "Mesas",
              },
              27: {
                codigo: 27,
                barrio: "Mirador",
              },
              28: {
                codigo: 28,
                barrio: "Montelimar",
              },
              29: {
                codigo: 29,
                barrio: "Mosca",
              },
              30: {
                codigo: 30,
                barrio: "Palmira",
              },
              31: {
                codigo: 31,
                barrio: "Paso Real",
              },
              32: {
                codigo: 32,
                barrio: "Peje",
              },
              33: {
                codigo: 33,
                barrio: "Pita",
              },
              34: {
                codigo: 34,
                barrio: "Platanillal",
              },
              35: {
                codigo: 35,
                barrio: "Quijada",
              },
              36: {
                codigo: 36,
                barrio: "Rio Coto",
              },
              37: {
                codigo: 37,
                barrio: "San Antonio",
              },
              38: {
                codigo: 38,
                barrio: "San Carlos",
              },
              39: {
                codigo: 39,
                barrio: "San Rafael de Cabagra",
              },
              40: {
                codigo: 40,
                barrio: "Santa Cecilia",
              },
              41: {
                codigo: 41,
                barrio: "Singri",
              },
              42: {
                codigo: 42,
                barrio: "Tablas",
              },
              43: {
                codigo: 43,
                barrio: "Tamarindo",
              },
              44: {
                codigo: 44,
                barrio: "Terraba",
              },
              45: {
                codigo: 45,
                barrio: "Tres Colinas",
              },
              46: {
                codigo: 46,
                barrio: "Tierras Negras",
              },
              47: {
                codigo: 47,
                barrio: "Volcancito",
              },
              48: {
                codigo: 48,
                barrio: "Vueltas",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "BORUCA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto del Mojon",
              },
              2: {
                codigo: 2,
                barrio: "Bellavista",
              },
              3: {
                codigo: 3,
                barrio: "Cajon",
              },
              4: {
                codigo: 4,
                barrio: "Curre",
              },
              5: {
                codigo: 5,
                barrio: "Chamba",
              },
              6: {
                codigo: 6,
                barrio: "Changuenita",
              },
              7: {
                codigo: 7,
                barrio: "Doboncragua",
              },
              8: {
                codigo: 8,
                barrio: "Iguana",
              },
              9: {
                codigo: 9,
                barrio: "Kuibin",
              },
              10: {
                codigo: 10,
                barrio: "Lagarto",
              },
              11: {
                codigo: 11,
                barrio: "Mano de Tigre",
              },
              12: {
                codigo: 12,
                barrio: "Miravalles",
              },
              13: {
                codigo: 13,
                barrio: "Ojo de Agua (parte)",
              },
              14: {
                codigo: 14,
                barrio: "Presa",
              },
              15: {
                codigo: 15,
                barrio: "Puerto Nuevo",
              },
              16: {
                codigo: 16,
                barrio: "Sabanas (Barranco) (parte)",
              },
              17: {
                codigo: 17,
                barrio: "San Joaquin",
              },
              18: {
                codigo: 18,
                barrio: "Santa Cruz",
              },
              19: {
                codigo: 19,
                barrio: "Tigre",
              },
              20: {
                codigo: 20,
                barrio: "Tres Rios",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "PILAS",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto Pilas",
              },
              2: {
                codigo: 2,
                barrio: "Bajo Pilas",
              },
              3: {
                codigo: 3,
                barrio: "Bijagual",
              },
              4: {
                codigo: 4,
                barrio: "Ceibon",
              },
              5: {
                codigo: 5,
                barrio: "Concepcion (La Danta)",
              },
              6: {
                codigo: 6,
                barrio: "Dibujada",
              },
              7: {
                codigo: 7,
                barrio: "Fortuna",
              },
              8: {
                codigo: 8,
                barrio: "La Gloria (Los Mangos)",
              },
              9: {
                codigo: 9,
                barrio: "Laguna",
              },
              10: {
                codigo: 10,
                barrio: "Ojo de Agua",
              },
              11: {
                codigo: 11,
                barrio: "Paso La Tinta",
              },
              12: {
                codigo: 12,
                barrio: "Pueblo Nuevo",
              },
              13: {
                codigo: 13,
                barrio: "Sabanas (Barranco parte)",
              },
              14: {
                codigo: 14,
                barrio: "Silencio",
              },
              15: {
                codigo: 15,
                barrio: "Tumbas",
              },
            },
          },
          6: {
            codigo: 6,
            distrito: "COLINAS",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Aguas Frescas",
              },
              2: {
                codigo: 2,
                barrio: "Alto Esmeralda",
              },
              3: {
                codigo: 3,
                barrio: "Angeles",
              },
              4: {
                codigo: 4,
                barrio: "Bajo Dioses",
              },
              5: {
                codigo: 5,
                barrio: "Bajo Maiz",
              },
              6: {
                codigo: 6,
                barrio: "Bolsa",
              },
              7: {
                codigo: 7,
                barrio: "Cedral (Boquete)",
              },
              8: {
                codigo: 8,
                barrio: "Escuadra",
              },
              9: {
                codigo: 9,
                barrio: "Filadelfia (Aguabuena)",
              },
              10: {
                codigo: 10,
                barrio: "Guagaral",
              },
              11: {
                codigo: 11,
                barrio: "Jabillo",
              },
              12: {
                codigo: 12,
                barrio: "Jalisco",
              },
              13: {
                codigo: 13,
                barrio: "Laguna",
              },
              14: {
                codigo: 14,
                barrio: "Lajas",
              },
              15: {
                codigo: 15,
                barrio: "Maiz de Boruca",
              },
              16: {
                codigo: 16,
                barrio: "Mallal",
              },
              17: {
                codigo: 17,
                barrio: "Nubes",
              },
              18: {
                codigo: 18,
                barrio: "Ojo de Agua (parte)",
              },
              19: {
                codigo: 19,
                barrio: "San Luis",
              },
              20: {
                codigo: 20,
                barrio: "Virgen",
              },
            },
          },
          7: {
            codigo: 7,
            distrito: "CHANGUENA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto Cacao",
              },
              2: {
                codigo: 2,
                barrio: "Bajo Mamey",
              },
              3: {
                codigo: 3,
                barrio: "Bonga",
              },
              4: {
                codigo: 4,
                barrio: "Cacique",
              },
              5: {
                codigo: 5,
                barrio: "Cantu",
              },
              6: {
                codigo: 6,
                barrio: "Cruces",
              },
              7: {
                codigo: 7,
                barrio: "Limon",
              },
              8: {
                codigo: 8,
                barrio: "Paraiso",
              },
              9: {
                codigo: 9,
                barrio: "Pataste",
              },
              10: {
                codigo: 10,
                barrio: "Pilon",
              },
              11: {
                codigo: 11,
                barrio: "Quebrada Bonita",
              },
              12: {
                codigo: 12,
                barrio: "San Luis",
              },
              13: {
                codigo: 13,
                barrio: "Santa Lucia",
              },
              14: {
                codigo: 14,
                barrio: "Santa Maria",
              },
              15: {
                codigo: 15,
                barrio: "Tres Rios",
              },
              16: {
                codigo: 16,
                barrio: "Vegas de Changuena",
              },
              17: {
                codigo: 17,
                barrio: "Vuelta Campana",
              },
              18: {
                codigo: 18,
                barrio: "Zapotal",
              },
            },
          },
          8: {
            codigo: 8,
            distrito: "BIOLLEY",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Almacigo",
              },
              2: {
                codigo: 2,
                barrio: "Altamira",
              },
              3: {
                codigo: 3,
                barrio: "Alto Sabalo",
              },
              4: {
                codigo: 4,
                barrio: "Bajo Sabalo",
              },
              5: {
                codigo: 5,
                barrio: "Bajos de Coto",
              },
              6: {
                codigo: 6,
                barrio: "Biolley",
              },
              7: {
                codigo: 7,
                barrio: "Carmen",
              },
              8: {
                codigo: 8,
                barrio: "Hamacas",
              },
              9: {
                codigo: 9,
                barrio: "Guayacan",
              },
              10: {
                codigo: 10,
                barrio: "Manzano",
              },
              11: {
                codigo: 11,
                barrio: "Naranjos",
              },
              12: {
                codigo: 12,
                barrio: "Puna",
              },
            },
          },
          9: {
            codigo: 9,
            distrito: "BRUNKA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Achiote",
              },
              2: {
                codigo: 2,
                barrio: "Alto Achiote",
              },
              3: {
                codigo: 3,
                barrio: "Canas",
              },
              4: {
                codigo: 4,
                barrio: "Guadalajara",
              },
              5: {
                codigo: 5,
                barrio: "Llano Bonito",
              },
              6: {
                codigo: 6,
                barrio: "Oasis",
              },
              7: {
                codigo: 7,
                barrio: "San Rafael",
              },
              8: {
                codigo: 8,
                barrio: "Santa Cecilia",
              },
              9: {
                codigo: 9,
                barrio: "Santa Maria",
              },
              10: {
                codigo: 10,
                barrio: "Santa Rosa",
              },
              11: {
                codigo: 11,
                barrio: "Socorro",
              },
            },
          },
        },
      },
      4: {
        codigo: 4,
        canton: "MONTES DE ORO",
        distritos: {
          1: {
            codigo: 1,
            distrito: "MIRAMAR",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto Pavones",
              },
              2: {
                codigo: 2,
                barrio: "Bajo Zamora",
              },
              3: {
                codigo: 3,
                barrio: "Barbudal",
              },
              4: {
                codigo: 4,
                barrio: "Bellavista",
              },
              5: {
                codigo: 5,
                barrio: "Brillante (parte)",
              },
              6: {
                codigo: 6,
                barrio: "Cabuyal",
              },
              7: {
                codigo: 7,
                barrio: "Delicias",
              },
              8: {
                codigo: 8,
                barrio: "Fraijanes",
              },
              9: {
                codigo: 9,
                barrio: "Lagunilla",
              },
              10: {
                codigo: 10,
                barrio: "Rio Seco",
              },
              11: {
                codigo: 11,
                barrio: "Tajo Alto",
              },
              12: {
                codigo: 12,
                barrio: "Trinidad",
              },
              13: {
                codigo: 13,
                barrio: "Zagala Vieja",
              },
              14: {
                codigo: 14,
                barrio: "Zamora",
              },
              15: {
                codigo: 15,
                barrio: "Zapotal (parte)",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "UNION",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bajo Caliente (parte)",
              },
              2: {
                codigo: 2,
                barrio: "Cedral",
              },
              3: {
                codigo: 3,
                barrio: "Laguna",
              },
              4: {
                codigo: 4,
                barrio: "Micas",
              },
              5: {
                codigo: 5,
                barrio: "Palmital",
              },
              6: {
                codigo: 6,
                barrio: "San Buenaventura",
              },
              7: {
                codigo: 7,
                barrio: "Velasquez",
              },
              8: {
                codigo: 8,
                barrio: "Ventanas",
              },
              9: {
                codigo: 9,
                barrio: "Zagala Nueva",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "SAN ISIDRO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Aguabuena",
              },
              2: {
                codigo: 2,
                barrio: "Ciruelas",
              },
              3: {
                codigo: 3,
                barrio: "Cuatro Cruces",
              },
              4: {
                codigo: 4,
                barrio: "Isla",
              },
              5: {
                codigo: 5,
                barrio: "Santa Rosa",
              },
              6: {
                codigo: 6,
                barrio: "Tiocinto",
              },
            },
          },
        },
      },
      5: {
        codigo: 5,
        canton: "OSA",
        distritos: {
          1: {
            codigo: 1,
            distrito: "PUERTO CORTES",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Canada",
              },
              2: {
                codigo: 2,
                barrio: "Cementerio",
              },
              3: {
                codigo: 3,
                barrio: "Cinco Esquinas",
              },
              4: {
                codigo: 4,
                barrio: "Precario",
              },
              5: {
                codigo: 5,
                barrio: "Pueblo Nuevo",
              },
              6: {
                codigo: 6,
                barrio: "Renacimiento",
              },
              7: {
                codigo: 7,
                barrio: "Yuca",
              },
              8: {
                codigo: 8,
                barrio: "Balsar",
              },
              9: {
                codigo: 9,
                barrio: "Bocabrava",
              },
              10: {
                codigo: 10,
                barrio: "Bocachica",
              },
              11: {
                codigo: 11,
                barrio: "Cerron",
              },
              12: {
                codigo: 12,
                barrio: "Coronado",
              },
              13: {
                codigo: 13,
                barrio: "Chontales",
              },
              14: {
                codigo: 14,
                barrio: "Delicias",
              },
              15: {
                codigo: 15,
                barrio: "Embarcadero",
              },
              16: {
                codigo: 16,
                barrio: "Fuente",
              },
              17: {
                codigo: 17,
                barrio: "Isla Sorpresa",
              },
              18: {
                codigo: 18,
                barrio: "Lindavista",
              },
              19: {
                codigo: 19,
                barrio: "Lourdes",
              },
              20: {
                codigo: 20,
                barrio: "Ojochal",
              },
              21: {
                codigo: 21,
                barrio: "Ojo de Agua",
              },
              22: {
                codigo: 22,
                barrio: "Parcelas",
              },
              23: {
                codigo: 23,
                barrio: "Pozo",
              },
              24: {
                codigo: 24,
                barrio: "Punta Mala",
              },
              25: {
                codigo: 25,
                barrio: "Punta Mala Arriba",
              },
              26: {
                codigo: 26,
                barrio: "San Buenaventura",
              },
              27: {
                codigo: 27,
                barrio: "San Juan",
              },
              28: {
                codigo: 28,
                barrio: "San Marcos",
              },
              29: {
                codigo: 29,
                barrio: "Tagual",
              },
              30: {
                codigo: 30,
                barrio: "Tortuga Abajo",
              },
              31: {
                codigo: 31,
                barrio: "Tres Rios",
              },
              32: {
                codigo: 32,
                barrio: "Vista de Terraba",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "PALMAR",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Betania",
              },
              2: {
                codigo: 2,
                barrio: "Once de Abril",
              },
              3: {
                codigo: 3,
                barrio: "Palmar Sur",
              },
              4: {
                codigo: 4,
                barrio: "Alemania",
              },
              5: {
                codigo: 5,
                barrio: "Alto Angeles",
              },
              6: {
                codigo: 6,
                barrio: "Alto Encanto",
              },
              7: {
                codigo: 7,
                barrio: "Alto Montura",
              },
              8: {
                codigo: 8,
                barrio: "Bellavista",
              },
              9: {
                codigo: 9,
                barrio: "Calavera",
              },
              10: {
                codigo: 10,
                barrio: "Cansot",
              },
              11: {
                codigo: 11,
                barrio: "Canablancal (Este)",
              },
              12: {
                codigo: 12,
                barrio: "Canablancal (Oeste) Coobo (Progreso)",
              },
              13: {
                codigo: 13,
                barrio: "Coquito",
              },
              14: {
                codigo: 14,
                barrio: "Gorrion",
              },
              15: {
                codigo: 15,
                barrio: "Jalaca (parte)",
              },
              16: {
                codigo: 16,
                barrio: "Olla Cero",
              },
              17: {
                codigo: 17,
                barrio: "Palma",
              },
              18: {
                codigo: 18,
                barrio: "Paraiso",
              },
              19: {
                codigo: 19,
                barrio: "Primero de Marzo",
              },
              20: {
                codigo: 20,
                barrio: "Puerta del Sol",
              },
              21: {
                codigo: 21,
                barrio: "San Cristobal",
              },
              22: {
                codigo: 22,
                barrio: "San Francisco (Tinoco)",
              },
              23: {
                codigo: 23,
                barrio: "San Gabriel",
              },
              24: {
                codigo: 24,
                barrio: "San Isidro",
              },
              25: {
                codigo: 25,
                barrio: "San Rafael",
              },
              26: {
                codigo: 26,
                barrio: "Santa Elena",
              },
              27: {
                codigo: 27,
                barrio: "Silencio",
              },
              28: {
                codigo: 28,
                barrio: "Trocha",
              },
              29: {
                codigo: 29,
                barrio: "Vergel",
              },
              30: {
                codigo: 30,
                barrio: "Victoria",
              },
              31: {
                codigo: 31,
                barrio: "Zapote",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "SIERPE",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Ajuntaderas",
              },
              2: {
                codigo: 2,
                barrio: "Alto Los Mogos",
              },
              3: {
                codigo: 3,
                barrio: "Alto San Juan",
              },
              4: {
                codigo: 4,
                barrio: "Bahia Chal",
              },
              5: {
                codigo: 5,
                barrio: "Bajos Matias",
              },
              6: {
                codigo: 6,
                barrio: "Barco",
              },
              7: {
                codigo: 7,
                barrio: "Bejuco",
              },
              8: {
                codigo: 8,
                barrio: "Boca Chocuaco",
              },
              9: {
                codigo: 9,
                barrio: "Gallega",
              },
              10: {
                codigo: 10,
                barrio: "Camibar",
              },
              11: {
                codigo: 11,
                barrio: "Campo de Aguabuena",
              },
              12: {
                codigo: 12,
                barrio: "Cantarrana",
              },
              13: {
                codigo: 13,
                barrio: "Charcos",
              },
              14: {
                codigo: 14,
                barrio: "Chocuaco",
              },
              15: {
                codigo: 15,
                barrio: "Garrobo",
              },
              16: {
                codigo: 16,
                barrio: "Guabos",
              },
              17: {
                codigo: 17,
                barrio: "Isidora",
              },
              18: {
                codigo: 18,
                barrio: "Islotes",
              },
              19: {
                codigo: 19,
                barrio: "Jalaca (parte)",
              },
              20: {
                codigo: 20,
                barrio: "Julia",
              },
              21: {
                codigo: 21,
                barrio: "Miramar",
              },
              22: {
                codigo: 22,
                barrio: "Mogos",
              },
              23: {
                codigo: 23,
                barrio: "Monterrey",
              },
              24: {
                codigo: 24,
                barrio: "Playa Palma",
              },
              25: {
                codigo: 25,
                barrio: "Playitas",
              },
              26: {
                codigo: 26,
                barrio: "Potrero",
              },
              27: {
                codigo: 27,
                barrio: "Puerto Escondido",
              },
              28: {
                codigo: 28,
                barrio: "Rincon",
              },
              29: {
                codigo: 29,
                barrio: "Sabalo",
              },
              30: {
                codigo: 30,
                barrio: "San Gerardo",
              },
              31: {
                codigo: 31,
                barrio: "San Juan",
              },
              32: {
                codigo: 32,
                barrio: "Taboga",
              },
              33: {
                codigo: 33,
                barrio: "Taboguita",
              },
              34: {
                codigo: 34,
                barrio: "Tigre",
              },
              35: {
                codigo: 35,
                barrio: "Varillal",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "BAHIA BALLENA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bahia",
              },
              2: {
                codigo: 2,
                barrio: "Ballena",
              },
              3: {
                codigo: 3,
                barrio: "Brisas",
              },
              4: {
                codigo: 4,
                barrio: "Cambutal",
              },
              5: {
                codigo: 5,
                barrio: "Dominical",
              },
              6: {
                codigo: 6,
                barrio: "Dominicalito",
              },
              7: {
                codigo: 7,
                barrio: "Escaleras",
              },
              8: {
                codigo: 8,
                barrio: "Piedra Achiote",
              },
              9: {
                codigo: 9,
                barrio: "Pinuela",
              },
              10: {
                codigo: 10,
                barrio: "Playa Hermosa",
              },
              11: {
                codigo: 11,
                barrio: "Poza Azul",
              },
              12: {
                codigo: 12,
                barrio: "Puerto Nuevo",
              },
              13: {
                codigo: 13,
                barrio: "Quebrada Grande",
              },
              14: {
                codigo: 14,
                barrio: "Rocas Amancio",
              },
              15: {
                codigo: 15,
                barrio: "San Josecito",
              },
              16: {
                codigo: 16,
                barrio: "San Martin",
              },
              17: {
                codigo: 17,
                barrio: "Tortuga Arriba",
              },
              18: {
                codigo: 18,
                barrio: "Ventanas",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "PIEDRAS BLANCAS",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Angeles",
              },
              2: {
                codigo: 2,
                barrio: "Bellavista",
              },
              3: {
                codigo: 3,
                barrio: "Calera",
              },
              4: {
                codigo: 4,
                barrio: "Cerro Oscuro",
              },
              5: {
                codigo: 5,
                barrio: "Chacarita",
              },
              6: {
                codigo: 6,
                barrio: "Fila",
              },
              7: {
                codigo: 7,
                barrio: "Finca Alajuela",
              },
              8: {
                codigo: 8,
                barrio: "Finca Guanacaste",
              },
              9: {
                codigo: 9,
                barrio: "Finca Puntarenas",
              },
              10: {
                codigo: 10,
                barrio: "Florida",
              },
              11: {
                codigo: 11,
                barrio: "Guaria",
              },
              12: {
                codigo: 12,
                barrio: "Kilometro 40",
              },
              13: {
                codigo: 13,
                barrio: "Navidad",
              },
              14: {
                codigo: 14,
                barrio: "Nubes",
              },
              15: {
                codigo: 15,
                barrio: "Porvenir",
              },
              16: {
                codigo: 16,
                barrio: "Rincon Caliente",
              },
              17: {
                codigo: 17,
                barrio: "Salama",
              },
              18: {
                codigo: 18,
                barrio: "San Martin",
              },
              19: {
                codigo: 19,
                barrio: "Santa Cecilia",
              },
              20: {
                codigo: 20,
                barrio: "Santa Rosa",
              },
              21: {
                codigo: 21,
                barrio: "Sinai",
              },
              22: {
                codigo: 22,
                barrio: "Venecia",
              },
              23: {
                codigo: 23,
                barrio: "Villa Bonita",
              },
              24: {
                codigo: 24,
                barrio: "Villa Colon",
              },
            },
          },
          6: {
            codigo: 6,
            distrito: "BAHIA DRAKE",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Angeles",
              },
              2: {
                codigo: 2,
                barrio: "Banegas",
              },
              3: {
                codigo: 3,
                barrio: "Boca Ganado",
              },
              4: {
                codigo: 4,
                barrio: "Campanario",
              },
              5: {
                codigo: 5,
                barrio: "Caletas",
              },
              6: {
                codigo: 6,
                barrio: "Guerra",
              },
              7: {
                codigo: 7,
                barrio: "Planes",
              },
              8: {
                codigo: 8,
                barrio: "Progreso",
              },
              9: {
                codigo: 9,
                barrio: "Quebrada Ganado",
              },
              10: {
                codigo: 10,
                barrio: "Rancho Quemado",
              },
              11: {
                codigo: 11,
                barrio: "Riyito",
              },
              12: {
                codigo: 12,
                barrio: "San Josecito (Rincon)",
              },
              13: {
                codigo: 13,
                barrio: "San Pedrillo",
              },
            },
          },
        },
      },
      6: {
        codigo: 6,
        canton: "AGUIRRE",
        distritos: {
          1: {
            codigo: 1,
            distrito: "QUEPOS",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Boca Vieja",
              },
              2: {
                codigo: 2,
                barrio: "Cocal",
              },
              3: {
                codigo: 3,
                barrio: "Colinas del Este",
              },
              4: {
                codigo: 4,
                barrio: "Inmaculada",
              },
              5: {
                codigo: 5,
                barrio: "Junta Naranjo",
              },
              6: {
                codigo: 6,
                barrio: "La Zona",
              },
              7: {
                codigo: 7,
                barrio: "Rancho Grande",
              },
              8: {
                codigo: 8,
                barrio: "Anita",
              },
              9: {
                codigo: 9,
                barrio: "Bartolo",
              },
              10: {
                codigo: 10,
                barrio: "Boca Naranjo",
              },
              11: {
                codigo: 11,
                barrio: "Canas",
              },
              12: {
                codigo: 12,
                barrio: "Canitas",
              },
              13: {
                codigo: 13,
                barrio: "Cerritos",
              },
              14: {
                codigo: 14,
                barrio: "Cerros",
              },
              15: {
                codigo: 15,
                barrio: "Damas",
              },
              16: {
                codigo: 16,
                barrio: "Delicias",
              },
              17: {
                codigo: 17,
                barrio: "Espadilla",
              },
              18: {
                codigo: 18,
                barrio: "Estero Damas",
              },
              19: {
                codigo: 19,
                barrio: "Estero Garita",
              },
              20: {
                codigo: 20,
                barrio: "Gallega",
              },
              21: {
                codigo: 21,
                barrio: "Llamaron",
              },
              22: {
                codigo: 22,
                barrio: "Llorona",
              },
              23: {
                codigo: 23,
                barrio: "Managua",
              },
              24: {
                codigo: 24,
                barrio: "Manuel Antonio",
              },
              25: {
                codigo: 25,
                barrio: "Maritima",
              },
              26: {
                codigo: 26,
                barrio: "Mona",
              },
              27: {
                codigo: 27,
                barrio: "Papaturro",
              },
              28: {
                codigo: 28,
                barrio: "Paquita",
              },
              29: {
                codigo: 29,
                barrio: "Pastora",
              },
              30: {
                codigo: 30,
                barrio: "Quebrada Azul",
              },
              31: {
                codigo: 31,
                barrio: "Rey",
              },
              32: {
                codigo: 32,
                barrio: "Rios",
              },
              33: {
                codigo: 33,
                barrio: "Roncador",
              },
              34: {
                codigo: 34,
                barrio: "San Rafael",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "SAVEGRE",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Dos Bocas",
              },
              2: {
                codigo: 2,
                barrio: "Guabas",
              },
              3: {
                codigo: 3,
                barrio: "Guapil",
              },
              4: {
                codigo: 4,
                barrio: "Hatillo Nuevo",
              },
              5: {
                codigo: 5,
                barrio: "Hatillo Viejo",
              },
              6: {
                codigo: 6,
                barrio: "Laguna",
              },
              7: {
                codigo: 7,
                barrio: "Nubes",
              },
              8: {
                codigo: 8,
                barrio: "Palma Quemada",
              },
              9: {
                codigo: 9,
                barrio: "Pasito",
              },
              10: {
                codigo: 10,
                barrio: "Paso",
              },
              11: {
                codigo: 11,
                barrio: "Paso Guanacaste",
              },
              12: {
                codigo: 12,
                barrio: "Platanillo",
              },
              13: {
                codigo: 13,
                barrio: "Playa Matapalo",
              },
              14: {
                codigo: 14,
                barrio: "Portalon",
              },
              15: {
                codigo: 15,
                barrio: "Punto de Mira",
              },
              16: {
                codigo: 16,
                barrio: "Salitral",
              },
              17: {
                codigo: 17,
                barrio: "Salsipuedes",
              },
              18: {
                codigo: 18,
                barrio: "San Andres",
              },
              19: {
                codigo: 19,
                barrio: "Santo Domingo",
              },
              20: {
                codigo: 20,
                barrio: "Silencio",
              },
              21: {
                codigo: 21,
                barrio: "Tierras Morenas",
              },
              22: {
                codigo: 22,
                barrio: "Tres Piedras (parte)",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "NARANJITO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bijagual",
              },
              2: {
                codigo: 2,
                barrio: "Buenos Aires",
              },
              3: {
                codigo: 3,
                barrio: "Capital",
              },
              4: {
                codigo: 4,
                barrio: "Concepcion",
              },
              5: {
                codigo: 5,
                barrio: "Cotos",
              },
              6: {
                codigo: 6,
                barrio: "Londres",
              },
              7: {
                codigo: 7,
                barrio: "Negro",
              },
              8: {
                codigo: 8,
                barrio: "Pascua",
              },
              9: {
                codigo: 9,
                barrio: "Paso Indios",
              },
              10: {
                codigo: 10,
                barrio: "Paso Real",
              },
              11: {
                codigo: 11,
                barrio: "Sabalo",
              },
              12: {
                codigo: 12,
                barrio: "Santa Juana",
              },
              13: {
                codigo: 13,
                barrio: "Tocori",
              },
              14: {
                codigo: 14,
                barrio: "Villanueva",
              },
            },
          },
        },
      },
      7: {
        codigo: 7,
        canton: "GOLFITO",
        distritos: {
          1: {
            codigo: 1,
            distrito: "GOLFITO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alamedas",
              },
              2: {
                codigo: 2,
                barrio: "Bellavista",
              },
              3: {
                codigo: 3,
                barrio: "Bolsa",
              },
              4: {
                codigo: 4,
                barrio: "Disco",
              },
              5: {
                codigo: 5,
                barrio: "Kilometro 1",
              },
              6: {
                codigo: 6,
                barrio: "Kilometro 2",
              },
              7: {
                codigo: 7,
                barrio: "Kilometro 3",
              },
              8: {
                codigo: 8,
                barrio: "Laguna",
              },
              9: {
                codigo: 9,
                barrio: "Llano Bonito",
              },
              10: {
                codigo: 10,
                barrio: "Minerva",
              },
              11: {
                codigo: 11,
                barrio: "Naranjal",
              },
              12: {
                codigo: 12,
                barrio: "Oasis de Esperanza",
              },
              13: {
                codigo: 13,
                barrio: "Parroquial",
              },
              14: {
                codigo: 14,
                barrio: "Pueblo Civil",
              },
              15: {
                codigo: 15,
                barrio: "Rotonda",
              },
              16: {
                codigo: 16,
                barrio: "San Andres",
              },
              17: {
                codigo: 17,
                barrio: "San Martin",
              },
              18: {
                codigo: 18,
                barrio: "Zona Gris",
              },
              19: {
                codigo: 19,
                barrio: "Aguada",
              },
              20: {
                codigo: 20,
                barrio: "Animas",
              },
              21: {
                codigo: 21,
                barrio: "Atrocho",
              },
              22: {
                codigo: 22,
                barrio: "Bajo Chontales",
              },
              23: {
                codigo: 23,
                barrio: "Bajo de Coto",
              },
              24: {
                codigo: 24,
                barrio: "Bajo Grapa",
              },
              25: {
                codigo: 25,
                barrio: "Bajo Mansito",
              },
              26: {
                codigo: 26,
                barrio: "Bajo Sucio",
              },
              27: {
                codigo: 27,
                barrio: "Bajos de Canablanca",
              },
              28: {
                codigo: 28,
                barrio: "Cuarenta y Cinco",
              },
              29: {
                codigo: 29,
                barrio: "Dos Rios",
              },
              30: {
                codigo: 30,
                barrio: "Esperanza de Coto",
              },
              31: {
                codigo: 31,
                barrio: "Gallardo",
              },
              32: {
                codigo: 32,
                barrio: "Huacas",
              },
              33: {
                codigo: 33,
                barrio: "Jorge Brenes Duran",
              },
              34: {
                codigo: 34,
                barrio: "Kilometro 5",
              },
              35: {
                codigo: 35,
                barrio: "Kilometro 7",
              },
              36: {
                codigo: 36,
                barrio: "Kilometro 9",
              },
              37: {
                codigo: 37,
                barrio: "Kilometro 16",
              },
              38: {
                codigo: 38,
                barrio: "Kilometro 20",
              },
              39: {
                codigo: 39,
                barrio: "Kilometro 24",
              },
              40: {
                codigo: 40,
                barrio: "Manuel Tucker Martinez",
              },
              41: {
                codigo: 41,
                barrio: "Mona",
              },
              42: {
                codigo: 42,
                barrio: "Nazaret",
              },
              43: {
                codigo: 43,
                barrio: "Paso Higueron",
              },
              44: {
                codigo: 44,
                barrio: "Playa Cacao",
              },
              45: {
                codigo: 45,
                barrio: "Puerto Escondido",
              },
              46: {
                codigo: 46,
                barrio: "Puntarenitas",
              },
              47: {
                codigo: 47,
                barrio: "Purruja",
              },
              48: {
                codigo: 48,
                barrio: "Rancho Relampago",
              },
              49: {
                codigo: 49,
                barrio: "Riyito",
              },
              50: {
                codigo: 50,
                barrio: "Saladero",
              },
              51: {
                codigo: 51,
                barrio: "Saladerito",
              },
              52: {
                codigo: 52,
                barrio: "San Francisco",
              },
              53: {
                codigo: 53,
                barrio: "San Josecito",
              },
              54: {
                codigo: 54,
                barrio: "Torres",
              },
              55: {
                codigo: 55,
                barrio: "Trenzas",
              },
              56: {
                codigo: 56,
                barrio: "Union de Coto",
              },
              57: {
                codigo: 57,
                barrio: "Urena",
              },
              58: {
                codigo: 58,
                barrio: "Valle Bonito",
              },
              59: {
                codigo: 59,
                barrio: "Viquilla Dos",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "PUERTO JIMENEZ",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Pueblo Nuevo",
              },
              2: {
                codigo: 2,
                barrio: "Aguabuena",
              },
              3: {
                codigo: 3,
                barrio: "Agujas",
              },
              4: {
                codigo: 4,
                barrio: "Miramar (Altos Corozal)",
              },
              5: {
                codigo: 5,
                barrio: "Amapola",
              },
              6: {
                codigo: 6,
                barrio: "Balsa",
              },
              7: {
                codigo: 7,
                barrio: "Bambu",
              },
              8: {
                codigo: 8,
                barrio: "Barrigones",
              },
              9: {
                codigo: 9,
                barrio: "Barrio Bonito",
              },
              10: {
                codigo: 10,
                barrio: "Boca Gallardo",
              },
              11: {
                codigo: 11,
                barrio: "Canaza",
              },
              12: {
                codigo: 12,
                barrio: "Carate",
              },
              13: {
                codigo: 13,
                barrio: "Carbonera",
              },
              14: {
                codigo: 14,
                barrio: "Cerro de Oro",
              },
              15: {
                codigo: 15,
                barrio: "Dos Brazos",
              },
              16: {
                codigo: 16,
                barrio: "Guadalupe",
              },
              17: {
                codigo: 17,
                barrio: "Independencia",
              },
              18: {
                codigo: 18,
                barrio: "Lajitas",
              },
              19: {
                codigo: 19,
                barrio: "Neque",
              },
              20: {
                codigo: 20,
                barrio: "Palma",
              },
              21: {
                codigo: 21,
                barrio: "Paloseco",
              },
              22: {
                codigo: 22,
                barrio: "Playa Blanca",
              },
              23: {
                codigo: 23,
                barrio: "Playa Tigre",
              },
              24: {
                codigo: 24,
                barrio: "Puerto Escondido",
              },
              25: {
                codigo: 25,
                barrio: "Quebrada Latarde",
              },
              26: {
                codigo: 26,
                barrio: "Rio Nuevo",
              },
              27: {
                codigo: 27,
                barrio: "Rio Oro",
              },
              28: {
                codigo: 28,
                barrio: "Rio Piro (Coyunda)",
              },
              29: {
                codigo: 29,
                barrio: "Sandalo",
              },
              30: {
                codigo: 30,
                barrio: "San Miguel",
              },
              31: {
                codigo: 31,
                barrio: "Sombrero",
              },
              32: {
                codigo: 32,
                barrio: "Terrones",
              },
              33: {
                codigo: 33,
                barrio: "Tigre",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "GUAYCARA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Santiago",
              },
              2: {
                codigo: 2,
                barrio: "Bajo Bonita",
              },
              3: {
                codigo: 3,
                barrio: "Bajo Cedros",
              },
              4: {
                codigo: 4,
                barrio: "Buenavista",
              },
              5: {
                codigo: 5,
                barrio: "Cerro Cafe",
              },
              6: {
                codigo: 6,
                barrio: "Chiqueros",
              },
              7: {
                codigo: 7,
                barrio: "Delicias",
              },
              8: {
                codigo: 8,
                barrio: "El Alto",
              },
              9: {
                codigo: 9,
                barrio: "Esperanza",
              },
              10: {
                codigo: 10,
                barrio: "Gamba",
              },
              11: {
                codigo: 11,
                barrio: "Kilometro 29",
              },
              12: {
                codigo: 12,
                barrio: "Kilometro 33",
              },
              13: {
                codigo: 13,
                barrio: "La Julieta",
              },
              14: {
                codigo: 14,
                barrio: "Santiago de Caracol",
              },
              15: {
                codigo: 15,
                barrio: "Tigre (Caracol Norte)",
              },
              16: {
                codigo: 16,
                barrio: "Valle Cedros",
              },
              17: {
                codigo: 17,
                barrio: "Vegas de Rio Claro",
              },
              18: {
                codigo: 18,
                barrio: "Villa Briceno",
              },
              19: {
                codigo: 19,
                barrio: "Viquilla Uno",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "PAVON",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Altos de Conte",
              },
              2: {
                codigo: 2,
                barrio: "Banco",
              },
              3: {
                codigo: 3,
                barrio: "Burica",
              },
              4: {
                codigo: 4,
                barrio: "Clarita",
              },
              5: {
                codigo: 5,
                barrio: "Cocal Amarillo",
              },
              6: {
                codigo: 6,
                barrio: "Cuervito",
              },
              7: {
                codigo: 7,
                barrio: "Escuadra",
              },
              8: {
                codigo: 8,
                barrio: "Esperanza de Sabalos",
              },
              9: {
                codigo: 9,
                barrio: "Estero Colorado",
              },
              10: {
                codigo: 10,
                barrio: "Estrella",
              },
              11: {
                codigo: 11,
                barrio: "Flor de Coto",
              },
              12: {
                codigo: 12,
                barrio: "Fortuna de Coto",
              },
              13: {
                codigo: 13,
                barrio: "Guaymi",
              },
              14: {
                codigo: 14,
                barrio: "Higo",
              },
              15: {
                codigo: 15,
                barrio: "Jardin",
              },
              16: {
                codigo: 16,
                barrio: "La Virgen",
              },
              17: {
                codigo: 17,
                barrio: "Lindamar",
              },
              18: {
                codigo: 18,
                barrio: "Manzanillo",
              },
              19: {
                codigo: 19,
                barrio: "Pavones",
              },
              20: {
                codigo: 20,
                barrio: "Penas",
              },
              21: {
                codigo: 21,
                barrio: "Penita",
              },
              22: {
                codigo: 22,
                barrio: "Puerto Pilon",
              },
              23: {
                codigo: 23,
                barrio: "Puesto La Playa",
              },
              24: {
                codigo: 24,
                barrio: "Punta Banco",
              },
              25: {
                codigo: 25,
                barrio: "Quebrada Honda",
              },
              26: {
                codigo: 26,
                barrio: "Riviera",
              },
              27: {
                codigo: 27,
                barrio: "Sabalos",
              },
              28: {
                codigo: 28,
                barrio: "Tigrito",
              },
              29: {
                codigo: 29,
                barrio: "Union del Sur",
              },
              30: {
                codigo: 30,
                barrio: "Vanegas",
              },
              31: {
                codigo: 31,
                barrio: "Yerba",
              },
              32: {
                codigo: 32,
                barrio: "Zancudo",
              },
            },
          },
        },
      },
      8: {
        codigo: 8,
        canton: "COTO BRUS",
        distritos: {
          1: {
            codigo: 1,
            distrito: "SAN VITO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Canada",
              },
              2: {
                codigo: 2,
                barrio: "Maria Auxiliadora",
              },
              3: {
                codigo: 3,
                barrio: "Tres Rios",
              },
              4: {
                codigo: 4,
                barrio: "Aguas Claras",
              },
              5: {
                codigo: 5,
                barrio: "Bajo Reyes",
              },
              6: {
                codigo: 6,
                barrio: "Bajo Venado",
              },
              7: {
                codigo: 7,
                barrio: "Barrantes",
              },
              8: {
                codigo: 8,
                barrio: "Ceibo",
              },
              9: {
                codigo: 9,
                barrio: "Cruces",
              },
              10: {
                codigo: 10,
                barrio: "Cuenca de Oro",
              },
              11: {
                codigo: 11,
                barrio: "Danto",
              },
              12: {
                codigo: 12,
                barrio: "Fila Guinea",
              },
              13: {
                codigo: 13,
                barrio: "Isla",
              },
              14: {
                codigo: 14,
                barrio: "Lindavista",
              },
              15: {
                codigo: 15,
                barrio: "Lourdes",
              },
              16: {
                codigo: 16,
                barrio: "Maravilla",
              },
              17: {
                codigo: 17,
                barrio: "Piedra Pintada",
              },
              18: {
                codigo: 18,
                barrio: "San Joaquin",
              },
              19: {
                codigo: 19,
                barrio: "Santa Clara",
              },
              20: {
                codigo: 20,
                barrio: "Torre Alta",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "SABALITO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Angeles",
              },
              2: {
                codigo: 2,
                barrio: "Brasilia",
              },
              3: {
                codigo: 3,
                barrio: "Casablanca",
              },
              4: {
                codigo: 4,
                barrio: "Chanchera",
              },
              5: {
                codigo: 5,
                barrio: "El Gallo",
              },
              6: {
                codigo: 6,
                barrio: "Juntas",
              },
              7: {
                codigo: 7,
                barrio: "La Esmeralda",
              },
              8: {
                codigo: 8,
                barrio: "Lucha",
              },
              9: {
                codigo: 9,
                barrio: "Mellizas",
              },
              10: {
                codigo: 10,
                barrio: "Miraflores",
              },
              11: {
                codigo: 11,
                barrio: "Piedra de Candela",
              },
              12: {
                codigo: 12,
                barrio: "Plantel",
              },
              13: {
                codigo: 13,
                barrio: "Porto Llano",
              },
              14: {
                codigo: 14,
                barrio: "Primavera",
              },
              15: {
                codigo: 15,
                barrio: "Progreso",
              },
              16: {
                codigo: 16,
                barrio: "Providencia",
              },
              17: {
                codigo: 17,
                barrio: "Pueblo Nuevo",
              },
              18: {
                codigo: 18,
                barrio: "Rio Negro",
              },
              19: {
                codigo: 19,
                barrio: "Rio Sereno",
              },
              20: {
                codigo: 20,
                barrio: "San Antonio",
              },
              21: {
                codigo: 21,
                barrio: "San Bosco",
              },
              22: {
                codigo: 22,
                barrio: "San Francisco",
              },
              23: {
                codigo: 23,
                barrio: "San Luis",
              },
              24: {
                codigo: 24,
                barrio: "San Marcos",
              },
              25: {
                codigo: 25,
                barrio: "San Miguel",
              },
              26: {
                codigo: 26,
                barrio: "San Rafael",
              },
              27: {
                codigo: 27,
                barrio: "San Ramon",
              },
              28: {
                codigo: 28,
                barrio: "Santa Rosa",
              },
              29: {
                codigo: 29,
                barrio: "Santa Teresa",
              },
              30: {
                codigo: 30,
                barrio: "Tigra",
              },
              31: {
                codigo: 31,
                barrio: "Trinidad",
              },
              32: {
                codigo: 32,
                barrio: "Union",
              },
              33: {
                codigo: 33,
                barrio: "Valle Hermoso",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "AGUABUENA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bello Oriente",
              },
              2: {
                codigo: 2,
                barrio: "Campo Tres",
              },
              3: {
                codigo: 3,
                barrio: "Canas Gordas",
              },
              4: {
                codigo: 4,
                barrio: "Concepcion",
              },
              5: {
                codigo: 5,
                barrio: "Copabuena",
              },
              6: {
                codigo: 6,
                barrio: "Copal",
              },
              7: {
                codigo: 7,
                barrio: "Fila Zapote",
              },
              8: {
                codigo: 8,
                barrio: "Metaponto",
              },
              9: {
                codigo: 9,
                barrio: "Pilares",
              },
              10: {
                codigo: 10,
                barrio: "Pueblo Nuevo",
              },
              11: {
                codigo: 11,
                barrio: "Quebrada Bonita",
              },
              12: {
                codigo: 12,
                barrio: "Rio Salto",
              },
              13: {
                codigo: 13,
                barrio: "San Francisco",
              },
              14: {
                codigo: 14,
                barrio: "San Gabriel",
              },
              15: {
                codigo: 15,
                barrio: "San Pedro",
              },
              16: {
                codigo: 16,
                barrio: "Santa Cecilia",
              },
              17: {
                codigo: 17,
                barrio: "Santa Marta",
              },
              18: {
                codigo: 18,
                barrio: "Santo Domingo",
              },
              19: {
                codigo: 19,
                barrio: "Valle Azul",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "LIMONCITO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Aguacate",
              },
              2: {
                codigo: 2,
                barrio: "Alto Limoncito",
              },
              3: {
                codigo: 3,
                barrio: "Angeles",
              },
              4: {
                codigo: 4,
                barrio: "Bonanza",
              },
              5: {
                codigo: 5,
                barrio: "Brusmalis",
              },
              6: {
                codigo: 6,
                barrio: "Chiva",
              },
              7: {
                codigo: 7,
                barrio: "Desamparados",
              },
              8: {
                codigo: 8,
                barrio: "Esperanza",
              },
              9: {
                codigo: 9,
                barrio: "Fila",
              },
              10: {
                codigo: 10,
                barrio: "Manchuria",
              },
              11: {
                codigo: 11,
                barrio: "Sabanilla",
              },
              12: {
                codigo: 12,
                barrio: "San Gerardo",
              },
              13: {
                codigo: 13,
                barrio: "San Juan",
              },
              14: {
                codigo: 14,
                barrio: "San Miguel",
              },
              15: {
                codigo: 15,
                barrio: "San Rafael",
              },
              16: {
                codigo: 16,
                barrio: "Santa Marta",
              },
              17: {
                codigo: 17,
                barrio: "Santa Rita",
              },
              18: {
                codigo: 18,
                barrio: "Union",
              },
              19: {
                codigo: 19,
                barrio: "Valle",
              },
              20: {
                codigo: 20,
                barrio: "Villapalacios",
              },
              21: {
                codigo: 21,
                barrio: "Zumbona",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "PITTIER",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Aguacaliente",
              },
              2: {
                codigo: 2,
                barrio: "Camaquiri",
              },
              3: {
                codigo: 3,
                barrio: "Cocori",
              },
              4: {
                codigo: 4,
                barrio: "Coto Brus (parte)",
              },
              5: {
                codigo: 5,
                barrio: "Fila Mendez",
              },
              6: {
                codigo: 6,
                barrio: "Fila Naranjo",
              },
              7: {
                codigo: 7,
                barrio: "Fila Tigre",
              },
              8: {
                codigo: 8,
                barrio: "Marias",
              },
              9: {
                codigo: 9,
                barrio: "Monterrey",
              },
              10: {
                codigo: 10,
                barrio: "Palmira",
              },
              11: {
                codigo: 11,
                barrio: "Santa Fe",
              },
            },
          },
          6: {
            codigo: 6,
            distrito: "GUTIERREZ BRAUN",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alpha",
              },
              2: {
                codigo: 2,
                barrio: "Alturas de Coton",
              },
              3: {
                codigo: 3,
                barrio: "Brisas",
              },
              4: {
                codigo: 4,
                barrio: "Fila Pinar",
              },
              5: {
                codigo: 5,
                barrio: "Fila San Rafael",
              },
              6: {
                codigo: 6,
                barrio: "Flor del Roble",
              },
              7: {
                codigo: 7,
                barrio: "Guinea Arriba",
              },
              8: {
                codigo: 8,
                barrio: "La Administracion",
              },
              9: {
                codigo: 9,
                barrio: "Libertad",
              },
              10: {
                codigo: 10,
                barrio: "Poma",
              },
              11: {
                codigo: 11,
                barrio: "Rio Marzo",
              },
              12: {
                codigo: 12,
                barrio: "Roble",
              },
              13: {
                codigo: 13,
                barrio: "Roble Arriba",
              },
              14: {
                codigo: 14,
                barrio: "Siete Colinas",
              },
            },
          },
        },
      },
      9: {
        codigo: 9,
        canton: "PARRITA",
        distritos: {
          1: {
            codigo: 1,
            distrito: "PARRITA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Julieta",
              },
              2: {
                codigo: 2,
                barrio: "Pueblo Nuevo",
              },
              3: {
                codigo: 3,
                barrio: "Alto Camacho",
              },
              4: {
                codigo: 4,
                barrio: "Angeles",
              },
              5: {
                codigo: 5,
                barrio: "Bajos Jicote",
              },
              6: {
                codigo: 6,
                barrio: "Bambu",
              },
              7: {
                codigo: 7,
                barrio: "Bandera",
              },
              8: {
                codigo: 8,
                barrio: "Barbudal",
              },
              9: {
                codigo: 9,
                barrio: "Bejuco",
              },
              10: {
                codigo: 10,
                barrio: "Boca del Parrita",
              },
              11: {
                codigo: 11,
                barrio: "Carmen (parte)",
              },
              12: {
                codigo: 12,
                barrio: "Chires",
              },
              13: {
                codigo: 13,
                barrio: "Chires Arriba",
              },
              14: {
                codigo: 14,
                barrio: "Chirraca Abajo",
              },
              15: {
                codigo: 15,
                barrio: "Chirraca Arriba",
              },
              16: {
                codigo: 16,
                barrio: "Esterillos Centro",
              },
              17: {
                codigo: 17,
                barrio: "Esterillos Este",
              },
              18: {
                codigo: 18,
                barrio: "Esterillos Oeste",
              },
              19: {
                codigo: 19,
                barrio: "Fila Surubres",
              },
              20: {
                codigo: 20,
                barrio: "Guapinol",
              },
              21: {
                codigo: 21,
                barrio: "Higuito",
              },
              22: {
                codigo: 22,
                barrio: "I Griega",
              },
              23: {
                codigo: 23,
                barrio: "Isla Damas",
              },
              24: {
                codigo: 24,
                barrio: "Isla Palo Seco",
              },
              25: {
                codigo: 25,
                barrio: "Jicote",
              },
              26: {
                codigo: 26,
                barrio: "Loma",
              },
              27: {
                codigo: 27,
                barrio: "Mesas",
              },
              28: {
                codigo: 28,
                barrio: "Palmas",
              },
              29: {
                codigo: 29,
                barrio: "Palo Seco",
              },
              30: {
                codigo: 30,
                barrio: "Pirris",
              },
              31: {
                codigo: 31,
                barrio: "Playa Palma",
              },
              32: {
                codigo: 32,
                barrio: "Playon",
              },
              33: {
                codigo: 33,
                barrio: "Playon Sur",
              },
              34: {
                codigo: 34,
                barrio: "Pirris (Las Parcelas)",
              },
              35: {
                codigo: 35,
                barrio: "Pocares",
              },
              36: {
                codigo: 36,
                barrio: "Pocarito",
              },
              37: {
                codigo: 37,
                barrio: "Porvenir",
              },
              38: {
                codigo: 38,
                barrio: "Punta Judas",
              },
              39: {
                codigo: 39,
                barrio: "Rincon Morales",
              },
              40: {
                codigo: 40,
                barrio: "Rio Negro (parte)",
              },
              41: {
                codigo: 41,
                barrio: "Rio Seco",
              },
              42: {
                codigo: 42,
                barrio: "San Antonio",
              },
              43: {
                codigo: 43,
                barrio: "San Bosco",
              },
              44: {
                codigo: 44,
                barrio: "San Gerardo",
              },
              45: {
                codigo: 45,
                barrio: "San Isidro",
              },
              46: {
                codigo: 46,
                barrio: "San Juan",
              },
              47: {
                codigo: 47,
                barrio: "San Julian",
              },
              48: {
                codigo: 48,
                barrio: "San Rafael Norte",
              },
              49: {
                codigo: 49,
                barrio: "Sardinal",
              },
              50: {
                codigo: 50,
                barrio: "Sardinal Sur",
              },
              51: {
                codigo: 51,
                barrio: "Surubres",
              },
              52: {
                codigo: 52,
                barrio: "Teca",
              },
              53: {
                codigo: 53,
                barrio: "Tigre",
              },
              54: {
                codigo: 54,
                barrio: "Turbio",
              },
              55: {
                codigo: 55,
                barrio: "Valle Vasconia",
              },
              56: {
                codigo: 56,
                barrio: "Vueltas",
              },
            },
          },
        },
      },
      10: {
        codigo: 10,
        canton: "CORREDORES",
        distritos: {
          1: {
            codigo: 1,
            distrito: "CORREDOR",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bosque",
              },
              2: {
                codigo: 2,
                barrio: "Cano Seco",
              },
              3: {
                codigo: 3,
                barrio: "Capri",
              },
              4: {
                codigo: 4,
                barrio: "Carmen",
              },
              5: {
                codigo: 5,
                barrio: "Corredor",
              },
              6: {
                codigo: 6,
                barrio: "Progreso",
              },
              7: {
                codigo: 7,
                barrio: "San Juan",
              },
              8: {
                codigo: 8,
                barrio: "Valle del Sur",
              },
              9: {
                codigo: 9,
                barrio: "Abrojo",
              },
              10: {
                codigo: 10,
                barrio: "Aguilares",
              },
              11: {
                codigo: 11,
                barrio: "Alto Limoncito",
              },
              12: {
                codigo: 12,
                barrio: "Bajo Indios",
              },
              13: {
                codigo: 13,
                barrio: "Betel",
              },
              14: {
                codigo: 14,
                barrio: "Cacoragua",
              },
              15: {
                codigo: 15,
                barrio: "Campina",
              },
              16: {
                codigo: 16,
                barrio: "Campo Dos",
              },
              17: {
                codigo: 17,
                barrio: "Campo Dos y Medio",
              },
              18: {
                codigo: 18,
                barrio: "Canada",
              },
              19: {
                codigo: 19,
                barrio: "Caracol Sur",
              },
              20: {
                codigo: 20,
                barrio: "Castanos",
              },
              21: {
                codigo: 21,
                barrio: "Coloradito",
              },
              22: {
                codigo: 22,
                barrio: "Concordia",
              },
              23: {
                codigo: 23,
                barrio: "Coto 42",
              },
              24: {
                codigo: 24,
                barrio: "Coto 44",
              },
              25: {
                codigo: 25,
                barrio: "Coto 45",
              },
              26: {
                codigo: 26,
                barrio: "Coto 47",
              },
              27: {
                codigo: 27,
                barrio: "Coto 49",
              },
              28: {
                codigo: 28,
                barrio: "Coto 50-51",
              },
              29: {
                codigo: 29,
                barrio: "Coto 52-53",
              },
              30: {
                codigo: 30,
                barrio: "Cuesta Fila de Cal",
              },
              31: {
                codigo: 31,
                barrio: "Estrella del Sur",
              },
              32: {
                codigo: 32,
                barrio: "Florida",
              },
              33: {
                codigo: 33,
                barrio: "Fortuna",
              },
              34: {
                codigo: 34,
                barrio: "Kilometro 10",
              },
              35: {
                codigo: 35,
                barrio: "Miramar",
              },
              36: {
                codigo: 36,
                barrio: "Montezuma",
              },
              37: {
                codigo: 37,
                barrio: "Nubes",
              },
              38: {
                codigo: 38,
                barrio: "Pangas",
              },
              39: {
                codigo: 39,
                barrio: "Planes",
              },
              40: {
                codigo: 40,
                barrio: "Pueblo Nuevo",
              },
              41: {
                codigo: 41,
                barrio: "Rio Bonito",
              },
              42: {
                codigo: 42,
                barrio: "Rio Nuevo (Norte)",
              },
              43: {
                codigo: 43,
                barrio: "Rio Nuevo (Sur)",
              },
              44: {
                codigo: 44,
                barrio: "San Antonio Abajo",
              },
              45: {
                codigo: 45,
                barrio: "San Francisco",
              },
              46: {
                codigo: 46,
                barrio: "San Josecito",
              },
              47: {
                codigo: 47,
                barrio: "San Rafael",
              },
              48: {
                codigo: 48,
                barrio: "Santa Cecilia",
              },
              49: {
                codigo: 49,
                barrio: "Santa Marta (parte)",
              },
              50: {
                codigo: 50,
                barrio: "Santa Rita",
              },
              51: {
                codigo: 51,
                barrio: "Tropezon",
              },
              52: {
                codigo: 52,
                barrio: "Union",
              },
              53: {
                codigo: 53,
                barrio: "Vegas de Abrojo",
              },
              54: {
                codigo: 54,
                barrio: "Villa Roma",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "LA CUESTA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Canoas Abajo (parte)",
              },
              2: {
                codigo: 2,
                barrio: "Control",
              },
              3: {
                codigo: 3,
                barrio: "Cuervito",
              },
              4: {
                codigo: 4,
                barrio: "Chorro",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "CANOAS",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Lotes (San Jorge)",
              },
              2: {
                codigo: 2,
                barrio: "Altos del Brujo",
              },
              3: {
                codigo: 3,
                barrio: "Bajo Brujo",
              },
              4: {
                codigo: 4,
                barrio: "Bajo",
              },
              5: {
                codigo: 5,
                barrio: "Barrionuevo",
              },
              6: {
                codigo: 6,
                barrio: "Canoas Abajo (parte)",
              },
              7: {
                codigo: 7,
                barrio: "Canoas Arriba",
              },
              8: {
                codigo: 8,
                barrio: "Canaza",
              },
              9: {
                codigo: 9,
                barrio: "Cerro Brujo",
              },
              10: {
                codigo: 10,
                barrio: "Colorado",
              },
              11: {
                codigo: 11,
                barrio: "Chiva",
              },
              12: {
                codigo: 12,
                barrio: "Darizara",
              },
              13: {
                codigo: 13,
                barrio: "Gloria",
              },
              14: {
                codigo: 14,
                barrio: "Guay",
              },
              15: {
                codigo: 15,
                barrio: "Guayabal",
              },
              16: {
                codigo: 16,
                barrio: "Mariposa",
              },
              17: {
                codigo: 17,
                barrio: "Nispero",
              },
              18: {
                codigo: 18,
                barrio: "Palma",
              },
              19: {
                codigo: 19,
                barrio: "Paso Canoas",
              },
              20: {
                codigo: 20,
                barrio: "San Antonio",
              },
              21: {
                codigo: 21,
                barrio: "San Isidro",
              },
              22: {
                codigo: 22,
                barrio: "San Martin",
              },
              23: {
                codigo: 23,
                barrio: "San Miguel",
              },
              24: {
                codigo: 24,
                barrio: "Santa Marta (parte)",
              },
              25: {
                codigo: 25,
                barrio: "Veguitas de Colorado",
              },
              26: {
                codigo: 26,
                barrio: "Veracruz",
              },
              27: {
                codigo: 27,
                barrio: "Villas de Darizara",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "LAUREL",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto Vaquita",
              },
              2: {
                codigo: 2,
                barrio: "Bambito",
              },
              3: {
                codigo: 3,
                barrio: "Bella Luz",
              },
              4: {
                codigo: 4,
                barrio: "Bijagual",
              },
              5: {
                codigo: 5,
                barrio: "Caimito",
              },
              6: {
                codigo: 6,
                barrio: "Cangrejo Verde",
              },
              7: {
                codigo: 7,
                barrio: "Caracol de la Vaca",
              },
              8: {
                codigo: 8,
                barrio: "Cariari",
              },
              9: {
                codigo: 9,
                barrio: "Caucho",
              },
              10: {
                codigo: 10,
                barrio: "Cenizo",
              },
              11: {
                codigo: 11,
                barrio: "Colonia Libertad",
              },
              12: {
                codigo: 12,
                barrio: "Coyoche",
              },
              13: {
                codigo: 13,
                barrio: "Jobo Civil",
              },
              14: {
                codigo: 14,
                barrio: "Kilometro 22",
              },
              15: {
                codigo: 15,
                barrio: "Kilometro 25",
              },
              16: {
                codigo: 16,
                barrio: "Kilometro 27",
              },
              17: {
                codigo: 17,
                barrio: "Kilometro 29",
              },
              18: {
                codigo: 18,
                barrio: "Mango",
              },
              19: {
                codigo: 19,
                barrio: "Pueblo de Dios",
              },
              20: {
                codigo: 20,
                barrio: "Puerto Gonzalez Viquez",
              },
              21: {
                codigo: 21,
                barrio: "Rio Incendio",
              },
              22: {
                codigo: 22,
                barrio: "Roble",
              },
              23: {
                codigo: 23,
                barrio: "San Juan",
              },
              24: {
                codigo: 24,
                barrio: "Santa Lucia",
              },
              25: {
                codigo: 25,
                barrio: "Tamarindo",
              },
              26: {
                codigo: 26,
                barrio: "Vaca (Santa Rosa)",
              },
              27: {
                codigo: 27,
                barrio: "Vereh",
              },
              28: {
                codigo: 28,
                barrio: "Zaragoza",
              },
            },
          },
        },
      },
      11: {
        codigo: 11,
        canton: "GARABITO",
        distritos: {
          1: {
            codigo: 1,
            distrito: "JACO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Agujitas",
              },
              2: {
                codigo: 2,
                barrio: "Buenos Aires",
              },
              3: {
                codigo: 3,
                barrio: "Canablancal",
              },
              4: {
                codigo: 4,
                barrio: "Cerro Fresco",
              },
              5: {
                codigo: 5,
                barrio: "Herradura",
              },
              6: {
                codigo: 6,
                barrio: "Mona",
              },
              7: {
                codigo: 7,
                barrio: "Playa Hermosa",
              },
              8: {
                codigo: 8,
                barrio: "Playa Herradura",
              },
              9: {
                codigo: 9,
                barrio: "Pochotal",
              },
              10: {
                codigo: 10,
                barrio: "Puerto Escondido",
              },
              11: {
                codigo: 11,
                barrio: "Quebrada Amarilla",
              },
              12: {
                codigo: 12,
                barrio: "San Antonio",
              },
              13: {
                codigo: 13,
                barrio: "Turrubaritos",
              },
              14: {
                codigo: 14,
                barrio: "Tusubres",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "TARCOLES",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Agujas",
              },
              2: {
                codigo: 2,
                barrio: "Bajamar",
              },
              3: {
                codigo: 3,
                barrio: "Bellavista",
              },
              4: {
                codigo: 4,
                barrio: "Caletas",
              },
              5: {
                codigo: 5,
                barrio: "Camaronal",
              },
              6: {
                codigo: 6,
                barrio: "Camaronal Arriba",
              },
              7: {
                codigo: 7,
                barrio: "Capulin",
              },
              8: {
                codigo: 8,
                barrio: "Carrizal de Bajamar",
              },
              9: {
                codigo: 9,
                barrio: "Guacalillo",
              },
              10: {
                codigo: 10,
                barrio: "Mantas",
              },
              11: {
                codigo: 11,
                barrio: "Nambi",
              },
              12: {
                codigo: 12,
                barrio: "Penon de Tivives",
              },
              13: {
                codigo: 13,
                barrio: "Pigres",
              },
              14: {
                codigo: 14,
                barrio: "Pita",
              },
              15: {
                codigo: 15,
                barrio: "Playa Azul",
              },
              16: {
                codigo: 16,
                barrio: "Pogeres",
              },
              17: {
                codigo: 17,
                barrio: "Puerto Peje",
              },
              18: {
                codigo: 18,
                barrio: "Punta Leona",
              },
              19: {
                codigo: 19,
                barrio: "Tarcoles",
              },
              20: {
                codigo: 20,
                barrio: "Tarcolitos",
              },
            },
          },
        },
      },
    },
  },
  7: {
    codigo: 7,
    provincia: "Limon",
    cantones: {
      1: {
        codigo: 1,
        canton: "LIMON",
        distritos: {
          1: {
            codigo: 1,
            distrito: "LIMON",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bellavista",
              },
              2: {
                codigo: 2,
                barrio: "Bohio",
              },
              3: {
                codigo: 3,
                barrio: "Bosque",
              },
              4: {
                codigo: 4,
                barrio: "Buenos Aires",
              },
              5: {
                codigo: 5,
                barrio: "Cangrejos",
              },
              6: {
                codigo: 6,
                barrio: "Cariari",
              },
              7: {
                codigo: 7,
                barrio: "Cerro Mocho",
              },
              8: {
                codigo: 8,
                barrio: "Cielo Amarillo",
              },
              9: {
                codigo: 9,
                barrio: "Cieneguita",
              },
              10: {
                codigo: 10,
                barrio: "Colina",
              },
              11: {
                codigo: 11,
                barrio: "Corales",
              },
              12: {
                codigo: 12,
                barrio: "Cruce",
              },
              13: {
                codigo: 13,
                barrio: "Fortin",
              },
              14: {
                codigo: 14,
                barrio: "Garron",
              },
              15: {
                codigo: 15,
                barrio: "Hospital",
              },
              16: {
                codigo: 16,
                barrio: "Jamaica Town",
              },
              17: {
                codigo: 17,
                barrio: "Japdeva",
              },
              18: {
                codigo: 18,
                barrio: "Laureles",
              },
              19: {
                codigo: 19,
                barrio: "Limoncito",
              },
              20: {
                codigo: 20,
                barrio: "Lirios",
              },
              21: {
                codigo: 21,
                barrio: "Moin",
              },
              22: {
                codigo: 22,
                barrio: "Piuta",
              },
              23: {
                codigo: 23,
                barrio: "Portete",
              },
              24: {
                codigo: 24,
                barrio: "Pueblo Nuevo",
              },
              25: {
                codigo: 25,
                barrio: "San Juan",
              },
              26: {
                codigo: 26,
                barrio: "Santa Eduvigis",
              },
              27: {
                codigo: 27,
                barrio: "Trinidad",
              },
              28: {
                codigo: 28,
                barrio: "Veracruz",
              },
              29: {
                codigo: 29,
                barrio: "Buenos Aires",
              },
              30: {
                codigo: 30,
                barrio: "Cocal",
              },
              31: {
                codigo: 31,
                barrio: "Dos Bocas",
              },
              32: {
                codigo: 32,
                barrio: "Empalme Moin",
              },
              33: {
                codigo: 33,
                barrio: "Milla Nueve",
              },
              34: {
                codigo: 34,
                barrio: "Santa Rosa",
              },
              35: {
                codigo: 35,
                barrio: "Valle La Aurora",
              },
              36: {
                codigo: 36,
                barrio: "Villas del Mar Uno",
              },
              37: {
                codigo: 37,
                barrio: "Villas del Mar Dos",
              },
              38: {
                codigo: 38,
                barrio: "Villa Hermosa",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "VALLE LA ESTRELLA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Colonia",
              },
              2: {
                codigo: 2,
                barrio: "Finca Ocho",
              },
              3: {
                codigo: 3,
                barrio: "Guaria",
              },
              4: {
                codigo: 4,
                barrio: "Loras",
              },
              5: {
                codigo: 5,
                barrio: "Pandora Oeste",
              },
              6: {
                codigo: 6,
                barrio: "Rio Ley",
              },
              7: {
                codigo: 7,
                barrio: "Alsacia",
              },
              8: {
                codigo: 8,
                barrio: "Armenia",
              },
              9: {
                codigo: 9,
                barrio: "Atalanta",
              },
              10: {
                codigo: 10,
                barrio: "Bananito Sur",
              },
              11: {
                codigo: 11,
                barrio: "Boca Cuen",
              },
              12: {
                codigo: 12,
                barrio: "Boca Rio Estrella",
              },
              13: {
                codigo: 13,
                barrio: "Bocuare",
              },
              14: {
                codigo: 14,
                barrio: "Bonifacio",
              },
              15: {
                codigo: 15,
                barrio: "Brisas",
              },
              16: {
                codigo: 16,
                barrio: "Buenavista",
              },
              17: {
                codigo: 17,
                barrio: "Burrico",
              },
              18: {
                codigo: 18,
                barrio: "Calveri",
              },
              19: {
                codigo: 19,
                barrio: "Cano Negro",
              },
              20: {
                codigo: 20,
                barrio: "Cartagena",
              },
              21: {
                codigo: 21,
                barrio: "Casa Amarilla",
              },
              22: {
                codigo: 22,
                barrio: "Cerere",
              },
              23: {
                codigo: 23,
                barrio: "Concepcion",
              },
              24: {
                codigo: 24,
                barrio: "Cuen",
              },
              25: {
                codigo: 25,
                barrio: "Chirripo Abajo (parte)",
              },
              26: {
                codigo: 26,
                barrio: "Durfuy (San Miguel)",
              },
              27: {
                codigo: 27,
                barrio: "Duruy",
              },
              28: {
                codigo: 28,
                barrio: "Fortuna",
              },
              29: {
                codigo: 29,
                barrio: "Gavilan",
              },
              30: {
                codigo: 30,
                barrio: "Hueco",
              },
              31: {
                codigo: 31,
                barrio: "I Griega",
              },
              32: {
                codigo: 32,
                barrio: "Jabuy",
              },
              33: {
                codigo: 33,
                barrio: "Llano Grande",
              },
              34: {
                codigo: 34,
                barrio: "Manu",
              },
              35: {
                codigo: 35,
                barrio: "Miramar",
              },
              36: {
                codigo: 36,
                barrio: "Moi (San Vicente)",
              },
              37: {
                codigo: 37,
                barrio: "Nanabre",
              },
              38: {
                codigo: 38,
                barrio: "Nubes",
              },
              39: {
                codigo: 39,
                barrio: "Penshurt",
              },
              40: {
                codigo: 40,
                barrio: "Pleyades",
              },
              41: {
                codigo: 41,
                barrio: "Porvenir",
              },
              42: {
                codigo: 42,
                barrio: "Progreso",
              },
              43: {
                codigo: 43,
                barrio: "Rio Seco",
              },
              44: {
                codigo: 44,
                barrio: "San Andres",
              },
              45: {
                codigo: 45,
                barrio: "San Carlos",
              },
              46: {
                codigo: 46,
                barrio: "San Clemente",
              },
              47: {
                codigo: 47,
                barrio: "San Rafael",
              },
              48: {
                codigo: 48,
                barrio: "Suruy",
              },
              49: {
                codigo: 49,
                barrio: "Talia",
              },
              50: {
                codigo: 50,
                barrio: "Tobruk",
              },
              51: {
                codigo: 51,
                barrio: "Tuba Creek (parte)",
              },
              52: {
                codigo: 52,
                barrio: "Valle de las Rosas",
              },
              53: {
                codigo: 53,
                barrio: "Vegas de Cerere",
              },
              54: {
                codigo: 54,
                barrio: "Vesta",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "RIO BLANCO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Brisas",
              },
              2: {
                codigo: 2,
                barrio: "Brisas de Veragua",
              },
              3: {
                codigo: 3,
                barrio: "Bufalo",
              },
              4: {
                codigo: 4,
                barrio: "Limon 2000",
              },
              5: {
                codigo: 5,
                barrio: "Loma Linda",
              },
              6: {
                codigo: 6,
                barrio: "Mexico",
              },
              7: {
                codigo: 7,
                barrio: "Milla 9",
              },
              8: {
                codigo: 8,
                barrio: "Miravalles",
              },
              9: {
                codigo: 9,
                barrio: "Rio Blanco",
              },
              10: {
                codigo: 10,
                barrio: "Rio Cedro",
              },
              11: {
                codigo: 11,
                barrio: "Rio Madre",
              },
              12: {
                codigo: 12,
                barrio: "Rio Quito",
              },
              13: {
                codigo: 13,
                barrio: "Rio Victoria",
              },
              14: {
                codigo: 14,
                barrio: "Sandoval",
              },
              15: {
                codigo: 15,
                barrio: "Santa Rita",
              },
              16: {
                codigo: 16,
                barrio: "Victoria",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "MATAMA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Aguas Zarcas",
              },
              2: {
                codigo: 2,
                barrio: "Asuncion",
              },
              3: {
                codigo: 3,
                barrio: "Bananito Norte",
              },
              4: {
                codigo: 4,
                barrio: "Bearesem",
              },
              5: {
                codigo: 5,
                barrio: "Beverley",
              },
              6: {
                codigo: 6,
                barrio: "Calle Tranvia",
              },
              7: {
                codigo: 7,
                barrio: "Castillo Nuevo",
              },
              8: {
                codigo: 8,
                barrio: "Dondonia",
              },
              9: {
                codigo: 9,
                barrio: "Filadelfia Norte",
              },
              10: {
                codigo: 10,
                barrio: "Filadelfia Sur",
              },
              11: {
                codigo: 11,
                barrio: "Kent",
              },
              12: {
                codigo: 12,
                barrio: "Maria Luisa",
              },
              13: {
                codigo: 13,
                barrio: "Mountain Cow",
              },
              14: {
                codigo: 14,
                barrio: "Paraiso",
              },
              15: {
                codigo: 15,
                barrio: "Polonia",
              },
              16: {
                codigo: 16,
                barrio: "Quitaria",
              },
              17: {
                codigo: 17,
                barrio: "Rio Banano",
              },
              18: {
                codigo: 18,
                barrio: "San Cecilio",
              },
              19: {
                codigo: 19,
                barrio: "Tigre",
              },
              20: {
                codigo: 20,
                barrio: "Trebol",
              },
              21: {
                codigo: 21,
                barrio: "Westfalia",
              },
            },
          },
        },
      },
      2: {
        codigo: 2,
        canton: "POCOCI",
        distritos: {
          1: {
            codigo: 1,
            distrito: "GUAPILES",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Angeles",
              },
              2: {
                codigo: 2,
                barrio: "Calle Vargas",
              },
              3: {
                codigo: 3,
                barrio: "Cacique",
              },
              4: {
                codigo: 4,
                barrio: "Cecilia",
              },
              5: {
                codigo: 5,
                barrio: "Coopevigua",
              },
              6: {
                codigo: 6,
                barrio: "Diamantes",
              },
              7: {
                codigo: 7,
                barrio: "Emilia",
              },
              8: {
                codigo: 8,
                barrio: "Floresta",
              },
              9: {
                codigo: 9,
                barrio: "Garabito",
              },
              10: {
                codigo: 10,
                barrio: "Jesus",
              },
              11: {
                codigo: 11,
                barrio: "Palma Dorada",
              },
              12: {
                codigo: 12,
                barrio: "Palmera",
              },
              13: {
                codigo: 13,
                barrio: "San Miguel",
              },
              14: {
                codigo: 14,
                barrio: "Sauces",
              },
              15: {
                codigo: 15,
                barrio: "Toro Amarillo",
              },
              16: {
                codigo: 16,
                barrio: "Blanco",
              },
              17: {
                codigo: 17,
                barrio: "Calle Angeles",
              },
              18: {
                codigo: 18,
                barrio: "Calle Gobierno",
              },
              19: {
                codigo: 19,
                barrio: "Corinto",
              },
              20: {
                codigo: 20,
                barrio: "Flores",
              },
              21: {
                codigo: 21,
                barrio: "La Guaria",
              },
              22: {
                codigo: 22,
                barrio: "Marina",
              },
              23: {
                codigo: 23,
                barrio: "Rancho Redondo",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "JIMENEZ",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Granja",
              },
              2: {
                codigo: 2,
                barrio: "Molino",
              },
              3: {
                codigo: 3,
                barrio: "Numancia",
              },
              4: {
                codigo: 4,
                barrio: "Santa Clara",
              },
              5: {
                codigo: 5,
                barrio: "Anita Grande",
              },
              6: {
                codigo: 6,
                barrio: "Calle Diez",
              },
              7: {
                codigo: 7,
                barrio: "Calle Emilia",
              },
              8: {
                codigo: 8,
                barrio: "Calle Seis",
              },
              9: {
                codigo: 9,
                barrio: "Calle Uno",
              },
              10: {
                codigo: 10,
                barrio: "Condado del Rio",
              },
              11: {
                codigo: 11,
                barrio: "Floritas",
              },
              12: {
                codigo: 12,
                barrio: "Parasal",
              },
              13: {
                codigo: 13,
                barrio: "San Luis",
              },
              14: {
                codigo: 14,
                barrio: "San Martin",
              },
              15: {
                codigo: 15,
                barrio: "San Valentin",
              },
              16: {
                codigo: 16,
                barrio: "Suerre",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "RITA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Cruce de Jordan",
              },
              2: {
                codigo: 2,
                barrio: "Peligro",
              },
              3: {
                codigo: 3,
                barrio: "Pueblo Nuevo",
              },
              4: {
                codigo: 4,
                barrio: "Balastre",
              },
              5: {
                codigo: 5,
                barrio: "Cantagallo",
              },
              6: {
                codigo: 6,
                barrio: "Cartagena",
              },
              7: {
                codigo: 7,
                barrio: "Cayuga",
              },
              8: {
                codigo: 8,
                barrio: "Cocori",
              },
              9: {
                codigo: 9,
                barrio: "Chirvalo",
              },
              10: {
                codigo: 10,
                barrio: "Encina",
              },
              11: {
                codigo: 11,
                barrio: "Gallopinto",
              },
              12: {
                codigo: 12,
                barrio: "Hamburgo",
              },
              13: {
                codigo: 13,
                barrio: "I Griega",
              },
              14: {
                codigo: 14,
                barrio: "Indio",
              },
              15: {
                codigo: 15,
                barrio: "Jardin",
              },
              16: {
                codigo: 16,
                barrio: "Mercedes",
              },
              17: {
                codigo: 17,
                barrio: "Palmitas",
              },
              18: {
                codigo: 18,
                barrio: "Porvenir",
              },
              19: {
                codigo: 19,
                barrio: "Primavera",
              },
              20: {
                codigo: 20,
                barrio: "Rotulo",
              },
              21: {
                codigo: 21,
                barrio: "San Carlos",
              },
              22: {
                codigo: 22,
                barrio: "San Cristobal",
              },
              23: {
                codigo: 23,
                barrio: "San Gerardo",
              },
              24: {
                codigo: 24,
                barrio: "San Pedro",
              },
              25: {
                codigo: 25,
                barrio: "Santa Elena",
              },
              26: {
                codigo: 26,
                barrio: "Santa Rosa",
              },
              27: {
                codigo: 27,
                barrio: "Sirena",
              },
              28: {
                codigo: 28,
                barrio: "Suarez",
              },
              29: {
                codigo: 29,
                barrio: "Suerte",
              },
              30: {
                codigo: 30,
                barrio: "Tarire",
              },
              31: {
                codigo: 31,
                barrio: "Teresa",
              },
              32: {
                codigo: 32,
                barrio: "Ticaban",
              },
              33: {
                codigo: 33,
                barrio: "Triangulo",
              },
              34: {
                codigo: 34,
                barrio: "Victoria",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "ROXANA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "La Cruz",
              },
              2: {
                codigo: 2,
                barrio: "Lesville",
              },
              3: {
                codigo: 3,
                barrio: "Punta de Riel",
              },
              4: {
                codigo: 4,
                barrio: "Aguas Frias",
              },
              5: {
                codigo: 5,
                barrio: "Anaban",
              },
              6: {
                codigo: 6,
                barrio: "Boca Guapiles (parte)",
              },
              7: {
                codigo: 7,
                barrio: "Castanal",
              },
              8: {
                codigo: 8,
                barrio: "Cruce",
              },
              9: {
                codigo: 9,
                barrio: "Curia",
              },
              10: {
                codigo: 10,
                barrio: "Curva",
              },
              11: {
                codigo: 11,
                barrio: "Curva del Humo",
              },
              12: {
                codigo: 12,
                barrio: "Esperanza",
              },
              13: {
                codigo: 13,
                barrio: "Fortuna",
              },
              14: {
                codigo: 14,
                barrio: "Humo",
              },
              15: {
                codigo: 15,
                barrio: "La Lidia",
              },
              16: {
                codigo: 16,
                barrio: "Lomas Azules",
              },
              17: {
                codigo: 17,
                barrio: "Londres",
              },
              18: {
                codigo: 18,
                barrio: "Llano Bonito",
              },
              19: {
                codigo: 19,
                barrio: "Maravilla",
              },
              20: {
                codigo: 20,
                barrio: "Mata de Limon",
              },
              21: {
                codigo: 21,
                barrio: "Millon",
              },
              22: {
                codigo: 22,
                barrio: "Milloncito",
              },
              23: {
                codigo: 23,
                barrio: "Oeste",
              },
              24: {
                codigo: 24,
                barrio: "Prado (parte)",
              },
              25: {
                codigo: 25,
                barrio: "Roxana Tres",
              },
              26: {
                codigo: 26,
                barrio: "San Francisco",
              },
              27: {
                codigo: 27,
                barrio: "San Jorge",
              },
              28: {
                codigo: 28,
                barrio: "Vegas de Tortuguero",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "CARIARI",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Astua-Pirie",
              },
              2: {
                codigo: 2,
                barrio: "Campo de Aterrizaje (Pueblo Triste)",
              },
              3: {
                codigo: 3,
                barrio: "Formosa",
              },
              4: {
                codigo: 4,
                barrio: "Palermo",
              },
              5: {
                codigo: 5,
                barrio: "Angeles",
              },
              6: {
                codigo: 6,
                barrio: "Banamola",
              },
              7: {
                codigo: 7,
                barrio: "Boca Guapiles (parte)",
              },
              8: {
                codigo: 8,
                barrio: "Campo Cuatro",
              },
              9: {
                codigo: 9,
                barrio: "Campo Dos",
              },
              10: {
                codigo: 10,
                barrio: "Campo Tres",
              },
              11: {
                codigo: 11,
                barrio: "Campo Tres Este",
              },
              12: {
                codigo: 12,
                barrio: "Campo Tres Oeste",
              },
              13: {
                codigo: 13,
                barrio: "Esperanza (Cantarrana)",
              },
              14: {
                codigo: 14,
                barrio: "Cano Chiquero",
              },
              15: {
                codigo: 15,
                barrio: "Carolina",
              },
              16: {
                codigo: 16,
                barrio: "Ceibo",
              },
              17: {
                codigo: 17,
                barrio: "Coopecariari",
              },
              18: {
                codigo: 18,
                barrio: "Cuatro Esquinas",
              },
              19: {
                codigo: 19,
                barrio: "Encanto",
              },
              20: {
                codigo: 20,
                barrio: "Frutera",
              },
              21: {
                codigo: 21,
                barrio: "Gaviotas",
              },
              22: {
                codigo: 22,
                barrio: "Hojancha",
              },
              23: {
                codigo: 23,
                barrio: "Mana",
              },
              24: {
                codigo: 24,
                barrio: "Monterrey",
              },
              25: {
                codigo: 25,
                barrio: "Nazaret",
              },
              26: {
                codigo: 26,
                barrio: "Progreso",
              },
              27: {
                codigo: 27,
                barrio: "Pueblo Nuevo",
              },
              28: {
                codigo: 28,
                barrio: "Sagrada Familia",
              },
              29: {
                codigo: 29,
                barrio: "San Miguel",
              },
              30: {
                codigo: 30,
                barrio: "Semillero",
              },
              31: {
                codigo: 31,
                barrio: "Vega de Rio Palacios",
              },
              32: {
                codigo: 32,
                barrio: "Zacatales",
              },
            },
          },
          6: {
            codigo: 6,
            distrito: "COLORADO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Barra del Colorado Este",
              },
              2: {
                codigo: 2,
                barrio: "Aragon",
              },
              3: {
                codigo: 3,
                barrio: "Buenavista",
              },
              4: {
                codigo: 4,
                barrio: "Malanga",
              },
              5: {
                codigo: 5,
                barrio: "Puerto Lindo",
              },
              6: {
                codigo: 6,
                barrio: "San Gerardo",
              },
              7: {
                codigo: 7,
                barrio: "Tortuguero",
              },
              8: {
                codigo: 8,
                barrio: "Verdades",
              },
            },
          },
          7: {
            codigo: 7,
            distrito: "LA COLONIA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Santa Elena",
              },
              2: {
                codigo: 2,
                barrio: "Brisas del Toro Amarillo",
              },
              3: {
                codigo: 3,
                barrio: "Cascadas",
              },
              4: {
                codigo: 4,
                barrio: "La Victoria",
              },
              5: {
                codigo: 5,
                barrio: "Losilla",
              },
              6: {
                codigo: 6,
                barrio: "San Bosco",
              },
              7: {
                codigo: 7,
                barrio: "Prado (parte)",
              },
            },
          },
        },
      },
      3: {
        codigo: 3,
        canton: "SIQUIRRES",
        distritos: {
          1: {
            codigo: 1,
            distrito: "SIQUIRRES",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Betania",
              },
              2: {
                codigo: 2,
                barrio: "Brooklin",
              },
              3: {
                codigo: 3,
                barrio: "Indiana Uno",
              },
              4: {
                codigo: 4,
                barrio: "INVU",
              },
              5: {
                codigo: 5,
                barrio: "Maria Auxiliadora",
              },
              6: {
                codigo: 6,
                barrio: "Palmiras",
              },
              7: {
                codigo: 7,
                barrio: "Quebrador",
              },
              8: {
                codigo: 8,
                barrio: "San Rafael",
              },
              9: {
                codigo: 9,
                barrio: "San Martin",
              },
              10: {
                codigo: 10,
                barrio: "Triunfo",
              },
              11: {
                codigo: 11,
                barrio: "Amelia",
              },
              12: {
                codigo: 12,
                barrio: "Amistad",
              },
              13: {
                codigo: 13,
                barrio: "Angeles",
              },
              14: {
                codigo: 14,
                barrio: "Bajo Tigre",
              },
              15: {
                codigo: 15,
                barrio: "Barnstorf",
              },
              16: {
                codigo: 16,
                barrio: "Boca Pacuare",
              },
              17: {
                codigo: 17,
                barrio: "Boca Parismina",
              },
              18: {
                codigo: 18,
                barrio: "Calvario",
              },
              19: {
                codigo: 19,
                barrio: "Calle Tajo",
              },
              20: {
                codigo: 20,
                barrio: "Canada",
              },
              21: {
                codigo: 21,
                barrio: "Cano Blanco",
              },
              22: {
                codigo: 22,
                barrio: "Carmen",
              },
              23: {
                codigo: 23,
                barrio: "Celina",
              },
              24: {
                codigo: 24,
                barrio: "Ciudadela Flores",
              },
              25: {
                codigo: 25,
                barrio: "Cocal",
              },
              26: {
                codigo: 26,
                barrio: "Coco",
              },
              27: {
                codigo: 27,
                barrio: "Dos Bocas (Suerre)",
              },
              28: {
                codigo: 28,
                barrio: "Encanto (norte)",
              },
              29: {
                codigo: 29,
                barrio: "Encanto (sur)",
              },
              30: {
                codigo: 30,
                barrio: "Ganga",
              },
              31: {
                codigo: 31,
                barrio: "Guayacan",
              },
              32: {
                codigo: 32,
                barrio: "Imperio",
              },
              33: {
                codigo: 33,
                barrio: "Indiana Dos",
              },
              34: {
                codigo: 34,
                barrio: "Indiana Tres",
              },
              35: {
                codigo: 35,
                barrio: "Islona",
              },
              36: {
                codigo: 36,
                barrio: "Lindavista",
              },
              37: {
                codigo: 37,
                barrio: "Livingston",
              },
              38: {
                codigo: 38,
                barrio: "Lucha",
              },
              39: {
                codigo: 39,
                barrio: "Maryland",
              },
              40: {
                codigo: 40,
                barrio: "Milla 52",
              },
              41: {
                codigo: 41,
                barrio: "Moravia",
              },
              42: {
                codigo: 42,
                barrio: "Morazan",
              },
              43: {
                codigo: 43,
                barrio: "Nueva Esperanza",
              },
              44: {
                codigo: 44,
                barrio: "Nueva Virginia",
              },
              45: {
                codigo: 45,
                barrio: "Pueblo Civil",
              },
              46: {
                codigo: 46,
                barrio: "San Alberto Nuevo",
              },
              47: {
                codigo: 47,
                barrio: "San Alberto Viejo",
              },
              48: {
                codigo: 48,
                barrio: "San Alejo",
              },
              49: {
                codigo: 49,
                barrio: "San Joaquin",
              },
              50: {
                codigo: 50,
                barrio: "Santo Domingo",
              },
              51: {
                codigo: 51,
                barrio: "Vegas de Imperio",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "PACUARITO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto Mirador",
              },
              2: {
                codigo: 2,
                barrio: "Altos de Pacuarito",
              },
              3: {
                codigo: 3,
                barrio: "Buenos Aires",
              },
              4: {
                codigo: 4,
                barrio: "Cimarrones",
              },
              5: {
                codigo: 5,
                barrio: "Culpeper",
              },
              6: {
                codigo: 6,
                barrio: "Cultivez",
              },
              7: {
                codigo: 7,
                barrio: "Freehold",
              },
              8: {
                codigo: 8,
                barrio: "Freeman (San Rafael)",
              },
              9: {
                codigo: 9,
                barrio: "Galicia",
              },
              10: {
                codigo: 10,
                barrio: "Isla Nueva",
              },
              11: {
                codigo: 11,
                barrio: "Leona",
              },
              12: {
                codigo: 12,
                barrio: "Madre de Dios",
              },
              13: {
                codigo: 13,
                barrio: "Manila",
              },
              14: {
                codigo: 14,
                barrio: "Monteverde",
              },
              15: {
                codigo: 15,
                barrio: "Pacuare",
              },
              16: {
                codigo: 16,
                barrio: "Perla",
              },
              17: {
                codigo: 17,
                barrio: "Perlita",
              },
              18: {
                codigo: 18,
                barrio: "Rio Hondo",
              },
              19: {
                codigo: 19,
                barrio: "San Luis",
              },
              20: {
                codigo: 20,
                barrio: "San Carlos",
              },
              21: {
                codigo: 21,
                barrio: "San Isidro",
              },
              22: {
                codigo: 22,
                barrio: "San Pablo",
              },
              23: {
                codigo: 23,
                barrio: "Santa Rosa",
              },
              24: {
                codigo: 24,
                barrio: "Ten Switch",
              },
              25: {
                codigo: 25,
                barrio: "Trinidad",
              },
              26: {
                codigo: 26,
                barrio: "Union Campesina",
              },
              27: {
                codigo: 27,
                barrio: "Waldeck",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "FLORIDA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "El Alto",
              },
              2: {
                codigo: 2,
                barrio: "Alto Gracias a Dios",
              },
              3: {
                codigo: 3,
                barrio: "Alto Laurelar",
              },
              4: {
                codigo: 4,
                barrio: "Altos de Pascua",
              },
              5: {
                codigo: 5,
                barrio: "Bonilla Abajo",
              },
              6: {
                codigo: 6,
                barrio: "Casorla",
              },
              7: {
                codigo: 7,
                barrio: "Chonta",
              },
              8: {
                codigo: 8,
                barrio: "Destierro",
              },
              9: {
                codigo: 9,
                barrio: "Fourth Cliff",
              },
              10: {
                codigo: 10,
                barrio: "Huecos",
              },
              11: {
                codigo: 11,
                barrio: "Lomas",
              },
              12: {
                codigo: 12,
                barrio: "Llano",
              },
              13: {
                codigo: 13,
                barrio: "Pascua",
              },
              14: {
                codigo: 14,
                barrio: "Roca",
              },
              15: {
                codigo: 15,
                barrio: "Rubi",
              },
              16: {
                codigo: 16,
                barrio: "San Antonio",
              },
              17: {
                codigo: 17,
                barrio: "Tunel Camp",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "GERMANIA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "America",
              },
              2: {
                codigo: 2,
                barrio: "Babilonia",
              },
              3: {
                codigo: 3,
                barrio: "Cacao",
              },
              4: {
                codigo: 4,
                barrio: "Colombiana",
              },
              5: {
                codigo: 5,
                barrio: "Herediana",
              },
              6: {
                codigo: 6,
                barrio: "Milano",
              },
              7: {
                codigo: 7,
                barrio: "Trinidad",
              },
              8: {
                codigo: 8,
                barrio: "Williamsburg",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "CAIRO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Francia",
              },
              2: {
                codigo: 2,
                barrio: "Ana",
              },
              3: {
                codigo: 3,
                barrio: "Bellavista",
              },
              4: {
                codigo: 4,
                barrio: "Boca Rio Jimenez",
              },
              5: {
                codigo: 5,
                barrio: "Catalinas",
              },
              6: {
                codigo: 6,
                barrio: "Castilla",
              },
              7: {
                codigo: 7,
                barrio: "Cocal",
              },
              8: {
                codigo: 8,
                barrio: "Golden Grove",
              },
              9: {
                codigo: 9,
                barrio: "Josefina",
              },
              10: {
                codigo: 10,
                barrio: "Junta",
              },
              11: {
                codigo: 11,
                barrio: "Laureles",
              },
              12: {
                codigo: 12,
                barrio: "Luisiana",
              },
              13: {
                codigo: 13,
                barrio: "Milla 3",
              },
              14: {
                codigo: 14,
                barrio: "Milla 4",
              },
              15: {
                codigo: 15,
                barrio: "Milla 5",
              },
              16: {
                codigo: 16,
                barrio: "Milla 6",
              },
              17: {
                codigo: 17,
                barrio: "Ontario",
              },
              18: {
                codigo: 18,
                barrio: "Peje",
              },
              19: {
                codigo: 19,
                barrio: "Seis Amigos",
              },
              20: {
                codigo: 20,
                barrio: "Silencio",
              },
            },
          },
          6: {
            codigo: 6,
            distrito: "ALEGRIA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto Herediana",
              },
              2: {
                codigo: 2,
                barrio: "Cruce",
              },
              3: {
                codigo: 3,
                barrio: "Porton Iberia",
              },
              4: {
                codigo: 4,
                barrio: "Rio Peje",
              },
              5: {
                codigo: 5,
                barrio: "Vueltas",
              },
            },
          },
        },
      },
      4: {
        codigo: 4,
        canton: "TALAMANCA",
        distritos: {
          1: {
            codigo: 1,
            distrito: "BRATSI",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Fields",
              },
              2: {
                codigo: 2,
                barrio: "Sand Box",
              },
              3: {
                codigo: 3,
                barrio: "Altamira",
              },
              4: {
                codigo: 4,
                barrio: "Akberie (Piedra Grande)",
              },
              5: {
                codigo: 5,
                barrio: "Bambu",
              },
              6: {
                codigo: 6,
                barrio: "Chase",
              },
              7: {
                codigo: 7,
                barrio: "Cuabre",
              },
              8: {
                codigo: 8,
                barrio: "Gavilan Canta",
              },
              9: {
                codigo: 9,
                barrio: "Mleyuk 1",
              },
              10: {
                codigo: 10,
                barrio: "Mleyuk 2",
              },
              11: {
                codigo: 11,
                barrio: "Monte Sion",
              },
              12: {
                codigo: 12,
                barrio: "Olivia",
              },
              13: {
                codigo: 13,
                barrio: "Hu-Berie (Rancho Grande)",
              },
              14: {
                codigo: 14,
                barrio: "Shiroles",
              },
              15: {
                codigo: 15,
                barrio: "Sibuju",
              },
              16: {
                codigo: 16,
                barrio: "Suretka",
              },
              17: {
                codigo: 17,
                barrio: "Uatsi",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "SIXAOLA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Ania",
              },
              2: {
                codigo: 2,
                barrio: "Boca Sixaola",
              },
              3: {
                codigo: 3,
                barrio: "Catarina",
              },
              4: {
                codigo: 4,
                barrio: "Celia",
              },
              5: {
                codigo: 5,
                barrio: "Daytonia",
              },
              6: {
                codigo: 6,
                barrio: "Gandoca",
              },
              7: {
                codigo: 7,
                barrio: "Margarita",
              },
              8: {
                codigo: 8,
                barrio: "Mata de Limon",
              },
              9: {
                codigo: 9,
                barrio: "Noventa y Seis",
              },
              10: {
                codigo: 10,
                barrio: "Palma",
              },
              11: {
                codigo: 11,
                barrio: "Paraiso",
              },
              12: {
                codigo: 12,
                barrio: "Parque",
              },
              13: {
                codigo: 13,
                barrio: "San Miguel",
              },
              14: {
                codigo: 14,
                barrio: "San Miguelito",
              },
              15: {
                codigo: 15,
                barrio: "San Rafael",
              },
              16: {
                codigo: 16,
                barrio: "Virginia",
              },
              17: {
                codigo: 17,
                barrio: "Zavala",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "CAHUITA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Buenavista (Katuir)",
              },
              2: {
                codigo: 2,
                barrio: "Bordon",
              },
              3: {
                codigo: 3,
                barrio: "Carbon",
              },
              4: {
                codigo: 4,
                barrio: "Carbon 1",
              },
              5: {
                codigo: 5,
                barrio: "Carbon 2",
              },
              6: {
                codigo: 6,
                barrio: "Catarata",
              },
              7: {
                codigo: 7,
                barrio: "Cocles",
              },
              8: {
                codigo: 8,
                barrio: "Comadre",
              },
              9: {
                codigo: 9,
                barrio: "Dindiri",
              },
              10: {
                codigo: 10,
                barrio: "Gibraltar",
              },
              11: {
                codigo: 11,
                barrio: "Hone Creek",
              },
              12: {
                codigo: 12,
                barrio: "Hotel Creek",
              },
              13: {
                codigo: 13,
                barrio: "Kekoldi",
              },
              14: {
                codigo: 14,
                barrio: "Limonal",
              },
              15: {
                codigo: 15,
                barrio: "Manzanillo",
              },
              16: {
                codigo: 16,
                barrio: "Mile Creek",
              },
              17: {
                codigo: 17,
                barrio: "Patino",
              },
              18: {
                codigo: 18,
                barrio: "Playa Chiquita",
              },
              19: {
                codigo: 19,
                barrio: "Puerto Viejo",
              },
              20: {
                codigo: 20,
                barrio: "Punta Caliente",
              },
              21: {
                codigo: 21,
                barrio: "Punta Cocles",
              },
              22: {
                codigo: 22,
                barrio: "Punta Mona",
              },
              23: {
                codigo: 23,
                barrio: "Punta Uva",
              },
              24: {
                codigo: 24,
                barrio: "Tuba Creek (parte)",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "TELIRE",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Alto Cuen (Kjacka Bata)",
              },
              2: {
                codigo: 2,
                barrio: "Alto Lari (Durinak)",
              },
              3: {
                codigo: 3,
                barrio: "Alto Uren",
              },
              4: {
                codigo: 4,
                barrio: "Arenal",
              },
              5: {
                codigo: 5,
                barrio: "Bajo Blei",
              },
              6: {
                codigo: 6,
                barrio: "Bajo Cuen",
              },
              7: {
                codigo: 7,
                barrio: "Boca Uren",
              },
              8: {
                codigo: 8,
                barrio: "Bris",
              },
              9: {
                codigo: 9,
                barrio: "Cachabli",
              },
              10: {
                codigo: 10,
                barrio: "Coroma",
              },
              11: {
                codigo: 11,
                barrio: "Crorina",
              },
              12: {
                codigo: 12,
                barrio: "China Kicha",
              },
              13: {
                codigo: 13,
                barrio: "Dururpe",
              },
              14: {
                codigo: 14,
                barrio: "Guachalaba",
              },
              15: {
                codigo: 15,
                barrio: "Katsi",
              },
              16: {
                codigo: 16,
                barrio: "Kichuguecha",
              },
              17: {
                codigo: 17,
                barrio: "Kivut",
              },
              18: {
                codigo: 18,
                barrio: "Mojoncito",
              },
              19: {
                codigo: 19,
                barrio: "Namuwakir",
              },
              20: {
                codigo: 20,
                barrio: "Orochico",
              },
              21: {
                codigo: 21,
                barrio: "Ourut",
              },
              22: {
                codigo: 22,
                barrio: "Purisqui",
              },
              23: {
                codigo: 23,
                barrio: "Purita",
              },
              24: {
                codigo: 24,
                barrio: "Rangalle",
              },
              25: {
                codigo: 25,
                barrio: "San Jose Cabecar",
              },
              26: {
                codigo: 26,
                barrio: "Sepeque",
              },
              27: {
                codigo: 27,
                barrio: "Shewab",
              },
              28: {
                codigo: 28,
                barrio: "Sipurio",
              },
              29: {
                codigo: 29,
                barrio: "Soky",
              },
              30: {
                codigo: 30,
                barrio: "Sorokicha",
              },
              31: {
                codigo: 31,
                barrio: "Sukut",
              },
              32: {
                codigo: 32,
                barrio: "Surayo",
              },
              33: {
                codigo: 33,
                barrio: "Suiri",
              },
              34: {
                codigo: 34,
                barrio: "Telire",
              },
              35: {
                codigo: 35,
                barrio: "Turubokicha",
              },
              36: {
                codigo: 36,
                barrio: "Uren",
              },
            },
          },
        },
      },
      5: {
        codigo: 5,
        canton: "MATINA",
        distritos: {
          1: {
            codigo: 1,
            distrito: "MATINA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Goli",
              },
              2: {
                codigo: 2,
                barrio: "Luisa Oeste",
              },
              3: {
                codigo: 3,
                barrio: "Milla 23",
              },
              4: {
                codigo: 4,
                barrio: "Baltimore",
              },
              5: {
                codigo: 5,
                barrio: "Barra de Matina Norte",
              },
              6: {
                codigo: 6,
                barrio: "Bristol",
              },
              7: {
                codigo: 7,
                barrio: "Colonia Puriscalena",
              },
              8: {
                codigo: 8,
                barrio: "Corina",
              },
              9: {
                codigo: 9,
                barrio: "Chirripo",
              },
              10: {
                codigo: 10,
                barrio: "Chumico",
              },
              11: {
                codigo: 11,
                barrio: "Esperanza",
              },
              12: {
                codigo: 12,
                barrio: "Helvetia",
              },
              13: {
                codigo: 13,
                barrio: "Hilda",
              },
              14: {
                codigo: 14,
                barrio: "Linea B",
              },
              15: {
                codigo: 15,
                barrio: "Milla 4",
              },
              16: {
                codigo: 16,
                barrio: "Palmeras",
              },
              17: {
                codigo: 17,
                barrio: "Pozo Azul",
              },
              18: {
                codigo: 18,
                barrio: "Punta de Lanza",
              },
              19: {
                codigo: 19,
                barrio: "San Miguel",
              },
              20: {
                codigo: 20,
                barrio: "Victoria",
              },
              21: {
                codigo: 21,
                barrio: "Xirinachs",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "BATAAN",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Almendros",
              },
              2: {
                codigo: 2,
                barrio: "Margarita",
              },
              3: {
                codigo: 3,
                barrio: "Milla 24",
              },
              4: {
                codigo: 4,
                barrio: "Milla 25",
              },
              5: {
                codigo: 5,
                barrio: "Parcelas",
              },
              6: {
                codigo: 6,
                barrio: "Ramal Siete",
              },
              7: {
                codigo: 7,
                barrio: "Barbilla",
              },
              8: {
                codigo: 8,
                barrio: "Berta",
              },
              9: {
                codigo: 9,
                barrio: "Damasco",
              },
              10: {
                codigo: 10,
                barrio: "Davao",
              },
              11: {
                codigo: 11,
                barrio: "Dos Ramas",
              },
              12: {
                codigo: 12,
                barrio: "Espavel",
              },
              13: {
                codigo: 13,
                barrio: "Goshen",
              },
              14: {
                codigo: 14,
                barrio: "Leyte",
              },
              15: {
                codigo: 15,
                barrio: "Lola",
              },
              16: {
                codigo: 16,
                barrio: "Luzon",
              },
              17: {
                codigo: 17,
                barrio: "Milla 27",
              },
              18: {
                codigo: 18,
                barrio: "Milla 28",
              },
              19: {
                codigo: 19,
                barrio: "Oracabesa",
              },
              20: {
                codigo: 20,
                barrio: "Sahara",
              },
              21: {
                codigo: 21,
                barrio: "Santa Marta",
              },
              22: {
                codigo: 22,
                barrio: "Titan",
              },
              23: {
                codigo: 23,
                barrio: "Vegas",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "CARANDI",
            barrios: {
              1: {
                codigo: 1,
                barrio: "San Jose",
              },
              2: {
                codigo: 2,
                barrio: "Bananita",
              },
              3: {
                codigo: 3,
                barrio: "Barra de Matina Sur",
              },
              4: {
                codigo: 4,
                barrio: "Boca del Pantano",
              },
              5: {
                codigo: 5,
                barrio: "Boca Rio Matina",
              },
              6: {
                codigo: 6,
                barrio: "Boston",
              },
              7: {
                codigo: 7,
                barrio: "Brisas",
              },
              8: {
                codigo: 8,
                barrio: "California",
              },
              9: {
                codigo: 9,
                barrio: "Indio",
              },
              10: {
                codigo: 10,
                barrio: "Larga Distancia",
              },
              11: {
                codigo: 11,
                barrio: "Lomas del Toro",
              },
              12: {
                codigo: 12,
                barrio: "Luisa Este",
              },
              13: {
                codigo: 13,
                barrio: "Maravilla",
              },
              14: {
                codigo: 14,
                barrio: "Milla 14",
              },
              15: {
                codigo: 15,
                barrio: "Nueva York",
              },
              16: {
                codigo: 16,
                barrio: "Palacios",
              },
              17: {
                codigo: 17,
                barrio: "Palestina",
              },
              18: {
                codigo: 18,
                barrio: "Peje",
              },
              19: {
                codigo: 19,
                barrio: "Punta de Riel",
              },
              20: {
                codigo: 20,
                barrio: "Rio Cuba",
              },
              21: {
                codigo: 21,
                barrio: "Rio Peje",
              },
              22: {
                codigo: 22,
                barrio: "Saborio",
              },
              23: {
                codigo: 23,
                barrio: "San Edmundo",
              },
              24: {
                codigo: 24,
                barrio: "Santa Maria",
              },
              25: {
                codigo: 25,
                barrio: "Sterling",
              },
              26: {
                codigo: 26,
                barrio: "Strafford",
              },
              27: {
                codigo: 27,
                barrio: "Toro",
              },
              28: {
                codigo: 28,
                barrio: "Trinidad",
              },
              29: {
                codigo: 29,
                barrio: "Venecia",
              },
              30: {
                codigo: 30,
                barrio: "Zent",
              },
            },
          },
        },
      },
      6: {
        codigo: 6,
        canton: "GUACIMO",
        distritos: {
          1: {
            codigo: 1,
            distrito: "GUACIMO",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Africa",
              },
              2: {
                codigo: 2,
                barrio: "Cantarrana",
              },
              3: {
                codigo: 3,
                barrio: "Estacion Rudin",
              },
              4: {
                codigo: 4,
                barrio: "Guayacan",
              },
              5: {
                codigo: 5,
                barrio: "Aguacate",
              },
              6: {
                codigo: 6,
                barrio: "Angelina",
              },
              7: {
                codigo: 7,
                barrio: "Bosque",
              },
              8: {
                codigo: 8,
                barrio: "Cabana",
              },
              9: {
                codigo: 9,
                barrio: "Eden",
              },
              10: {
                codigo: 10,
                barrio: "El Tres",
              },
              11: {
                codigo: 11,
                barrio: "Fox Hall",
              },
              12: {
                codigo: 12,
                barrio: "Guaira",
              },
              13: {
                codigo: 13,
                barrio: "Hogar",
              },
              14: {
                codigo: 14,
                barrio: "Parismina",
              },
              15: {
                codigo: 15,
                barrio: "San Luis",
              },
              16: {
                codigo: 16,
                barrio: "Selva",
              },
            },
          },
          2: {
            codigo: 2,
            distrito: "MERCEDES",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Bremen",
              },
              2: {
                codigo: 2,
                barrio: "Argentina",
              },
              3: {
                codigo: 3,
                barrio: "Confianza",
              },
              4: {
                codigo: 4,
                barrio: "Iroquois",
              },
            },
          },
          3: {
            codigo: 3,
            distrito: "POCORA",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Pocora Sur",
              },
              2: {
                codigo: 2,
                barrio: "Ojo de Agua",
              },
            },
          },
          4: {
            codigo: 4,
            distrito: "RIO JIMENEZ",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Angeles",
              },
              2: {
                codigo: 2,
                barrio: "Bocas del Rio Silencio",
              },
              3: {
                codigo: 3,
                barrio: "Camaron",
              },
              4: {
                codigo: 4,
                barrio: "Cartagena",
              },
              5: {
                codigo: 5,
                barrio: "Dulce Nombre",
              },
              6: {
                codigo: 6,
                barrio: "Escocia",
              },
              7: {
                codigo: 7,
                barrio: "Irlanda",
              },
              8: {
                codigo: 8,
                barrio: "Jardin",
              },
              9: {
                codigo: 9,
                barrio: "Ligia",
              },
              10: {
                codigo: 10,
                barrio: "Lucha",
              },
              11: {
                codigo: 11,
                barrio: "Santa Maria",
              },
              12: {
                codigo: 12,
                barrio: "Santa Rosa",
              },
              13: {
                codigo: 13,
                barrio: "Socorro",
              },
            },
          },
          5: {
            codigo: 5,
            distrito: "DUCARI",
            barrios: {
              1: {
                codigo: 1,
                barrio: "Aguas Gatas",
              },
              2: {
                codigo: 2,
                barrio: "Carambola",
              },
              3: {
                codigo: 3,
                barrio: "Castano",
              },
              4: {
                codigo: 4,
                barrio: "Esperanza",
              },
              5: {
                codigo: 5,
                barrio: "Fruta de Pan",
              },
              6: {
                codigo: 6,
                barrio: "Limbo",
              },
              7: {
                codigo: 7,
                barrio: "San Cristobal",
              },
              8: {
                codigo: 8,
                barrio: "Zancudo",
              },
            },
          },
        },
      },
    },
  },
};


export default locations