export const newProductConstants = {
  INVENTARY_OPTIONS: [
    { value: "instock", label: "Hay en existencia" },
    { value: "outofstock", label: "Agotado " },
    { value: "onbackorder", label: "Se puede reservar" },
  ],
  DISCOUNT_BANCO_POPULAR_OPTIONS: [
    { value: "T", label: "Total" },
    { value: "P", label: "Parcial" },
  ],
  PUBLISH_OPTIONS: [{ value: "inmediatamente", label: "Inmediatamente" }],
  VISIBILITY_OPTIONS: [
    { value: "publica", label: "Pública" },
    //{ value: "protegido", label: "Protegido con contraseña" },
    //{ value: "privada", label: "Privada" },
  ],
  VISIBILITY_PLACE_OPTIONS: [
    {
      value: "tienda-y-busqueda",
      label: "En la tienda y en los resultados de búsqueda",
    },
    //{ value: "tienda", label: "Solo en la tienda" },
    //{ value: "busqueda", label: "Solo en los resultados de búsqueda" },
    //{ value: "oculto", label: "Oculto" },
  ],
  TEMPERATURE_OPTIONS: [
    { value: "", label: "Select" },
    { value: "frio", label: "Frío" },
    { value: "caliente", label: "Caliente" },
  ],
  DELIVERY_OPTIONS: [
    { value: "K", label: "Distancia máxima (km)" },
    { value: "P", label: "Provincia" },
  ],
  PROVINCES: [
    { value: "San Jose", label: "San José" },
    { value: "Cartago", label: "Cartago" },
    { value: "Alajuela", label: "Alajuela" },
    { value: "Heredia", label: "Heredia" },
    { value: "Limon", label: "Limón" },
    { value: "Puntarenas", label: "Puntarenas" },
    { value: "Guanacaste", label: "Guanacaste" },
  ],
};

export const handleConvertDate = (date) => {
  let dateConvert = new Date(date * 1000);

  let anio = dateConvert.getFullYear();
  let month = dateConvert.getMonth() + 1;
  let day = dateConvert.getDate();

  day = day > 9 ? day : `0${day}`;
  month = month > 9 ? month : `0${month}`;

  dateConvert = `${anio}-${month}-${day}`;

  return dateConvert;
};

export const toTimestamp = (strDate) => {
  const dateArray = strDate.split("-");
  if (dateArray.length === 3) {
    let d = new Date(
      parseInt(dateArray[0]),
      parseInt(dateArray[1]) - 1,
      parseInt(dateArray[2])
    );
    return Math.floor(d.getTime() / 1000);
  } else {
    return null;
  }
};

export function getMessageNullFields(nullFields) {
  var messageNullFields =
    "Hay campos obligatorios que no contienen los datos correctos. Por favor revisar: ";
  for (var i = 0; i < nullFields.length - 1; i++) {
    messageNullFields = messageNullFields + nullFields[i] + ", ";
  }
  messageNullFields = messageNullFields + nullFields[nullFields.length - 1];
  return messageNullFields;
}

export const formatArrayNewEdit = (array, type, joinArray, option = 1) => {
  return array.map(({ value }) => {
    let objReturn = {
      name: type,
      value,
    };
    //Con el fin de cuando se quieran unir varios array
    if (option === 2) {
      joinArray.push(objReturn);
    }
    return objReturn;
  });
};

export const capitalizarPrimeraLetra = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const handleConvertDataSelected = (data, key1, key2, isCapitalize) => {
  return data.map((item) => {
    return {
      label: isCapitalize ? item[key1] : capitalizarPrimeraLetra(item[key1]),
      value: item[key2],
    };
  });
};

export const handleArrayVisibleEn = (arra1, arra2, action1, action2) => {
  let visibilityInG = arra1.map(({ name, value }) => {
    let nuevoObj = {};
    nuevoObj.name = name;
    nuevoObj.value = value;

    if (arra2.length > 0) {
      arra2.map((arr) => {
        if (value === arr.value) {
          nuevoObj.action = action1;
        } else {
          nuevoObj.action = action2;
        }
        //Posible error retorno null
        return null;
      });
    } else {
      nuevoObj.action = action2;
    }

    return nuevoObj;
  });
  return visibilityInG;
};

export const selectStylesCategory = {
  control: (styles) => ({ ...styles, width: "250px" }),
  indicatorSeparator: () => ({ display: "none" }),
};
export const selectStylesVisibility = {
  control: (styles) => ({ ...styles, width: "7vw" }),
  indicatorSeparator: () => ({ display: "none" }),
};
export const selectStylesVisibilityPlace = {
  control: (styles) => ({ ...styles, width: "10vw" }),
  indicatorSeparator: () => ({ display: "none" }),
};
export const selectStylesPublish = {
  control: (styles) => ({ ...styles, width: "10vw" }),
  indicatorSeparator: () => ({ display: "none" }),
};
export const selectStylesFullWidth = {
  control: (styles) => ({ ...styles, width: "20vw" }),
  indicatorSeparator: () => ({ display: "none" }),
};

export const handleAttributeCopy = (data) => {
  return data.result[0].attribute.map((attr, idx) => {
    return {
      id: idx,
      label: attr.name,
      value: attr.name_slug,
      subAttr: attr.value.map((val) => {
        return {
          label: val.name,
          value: val.id,
        };
      }),
    };
  });
};

export const handleArrayStateParams = (arrayFormat1, arrayFormat2) => {
  return arrayFormat1.map((state) => {
    let isExiste = arrayFormat2.filter(
      (select) => Number(select.value) === state.value
    );

    if (isExiste.length > 0) {
      state.action = "E";
    } else {
      state.action = "D";
    }

    return state;
  });
};

export const handleArrayStateLocal = (arrayFormat1, arrayFormat2) => {
  return arrayFormat1.filter((state) => {
    let isExiste = arrayFormat2.filter(
      (select) => select.value === Number(state.value)
    );

    if (isExiste.length === 0) {
      state.action = "N";
      state.value = Number(state.value);
      return state;
    } else {
      return null;
    }
  });
};

export const handleTexSuppress = (title, isMobile = false) => {
  let amountLetter = isMobile ? 35 : 20
  return title.length > 15 ? `${title.slice(0, amountLetter)}...` : title;
};

export const handleColorInventary = (_stock_status, stock) => {
  // let _stock = parseInt(stock);
  //{ name: `En existencia (${_stock >= 0 ? _stock : null})`
  return _stock_status === "instock"
    ? { name: `En existencia`, color: "#27A36E" }
    : _stock_status === "outofstock"
    ? { name: "Bajo inventario", color: "#FF6D00" }
    : { name: "Se puede reservar", color: "#FF6D00" };
};
