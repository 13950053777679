import './HeaderOptions.css'
import { useState } from "react";

export default function HeaderOptions({ headerOpt, handleOptionsSelected }) {
  const [selectOption, setSelectOption] = useState("1");

  return (
    <div className="headerOptions">
      <ul>
        {headerOpt.map((opt) => (
          <li
            key={opt.id}
            className={selectOption === opt.id ? "isActive" : null}
            onClick={() => {
              setSelectOption(opt.id);
              handleOptionsSelected(opt.id);
            }}>
            <p> {opt.name} </p>
          </li>
        ))}
      </ul>
    </div>
  );
};
