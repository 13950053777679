import lisztClient from "./lisztClient.js"
import PouchDB from "pouchdb-browser"
import upsertBulk from "pouchdb-upsert-bulk"
import { v4 as uuidv4 } from 'uuid'
import { CONTROLLERS, APP_NAME } from "./services.json"
PouchDB.plugin(upsertBulk)

export class EfikaOfflineFirst {
  constructor(controller, showLoading = () => null, hideLoading = () => null) {
    this.app = APP_NAME
    this.controller = controller
    this.serviceName = CONTROLLERS[this.controller.toUpperCase()]
    this.dbList = new PouchDB(`${this.controller}-LIST`)
    this.dbGet = new PouchDB(`${this.controller}-GET`)
    this.dbMisc = new PouchDB("MiscDB")
    this.versionsDb = new PouchDB("Versions")
    this.dbPostPending = new PouchDB("Post")
    this.lisztClient = lisztClient
    this.showLoading = showLoading
    this.hideLoading = hideLoading
  }

  async get(id, idAlias = "_id", callback) {
    const generator = this.getGenerator(id, idAlias)

    for await (const value of generator) callback(value)
  }

  async list(idPropertyName = "_id", callback) {
    const generator = this.listGenerator(idPropertyName)

    for await (const value of generator) callback(value)
  }

  async misc(callback, obj = {}) {
    const generator = this.miscGenerator(obj)

    for await (const value of generator) await callback(value)
  }

  async post(data, callback) {
    let postRes = await this.postAsPromise(data)
    callback(postRes)
  }

  async put(data, callback) {
    const putRes = await this.putAsPromise(data)
    callback(putRes)
  }

  async delete(data, idName = "__id", callback) {
    const deleteRes = await this.deleteAsPromise(data, idName)

    callback(deleteRes)
  }

  async *getGenerator(id, idAlias) {
    const pouchDbGet = this.dbGet
    const dataFromPouch = await this._getDataOffline(id, pouchDbGet)
    let version = 0

    if (dataFromPouch) {
      yield dataFromPouch.data
      version = dataFromPouch.version || 0
    } else this.showLoading()

    let data = {
      app: this.app,
      version: version,
      __action: "GET",
      [idAlias]: id
    }

    try {
      let dataFromLiszt = await this.lisztClient.lisztPost(data, this.serviceName, true)

      if (!dataFromLiszt.status || dataFromLiszt.status !== 200) return

      if (dataFromLiszt.version && dataFromLiszt.version === version) return

      if (dataFromLiszt.recordset.length === 0) return

      await this._retryUntilWritten(
        { _id: id, data: dataFromLiszt.recordset, version: dataFromLiszt.version || version },
        pouchDbGet
      )

      yield dataFromLiszt.recordset
    } catch ({ message }) {
      console.log(message)
    } finally {
      this.hideLoading()
    }
  }

  async *listGenerator(idPropertyName) {
    const pouchDBList = this.dbList
    const versionsDB = this.versionsDb
    let objData = {}
    let version = 0
    let data = []
    let bulkData = []

    try {
      version = await versionsDB.get(`${this.controller}-LIST`)
      version = (version && version.ver) || 0
    } catch (error) {
      console.log(error)
    }

    let dataFromPouch = await pouchDBList.allDocs({ include_docs: true })

    data = dataFromPouch.rows.map(el => {
      return { ...el.doc.data }
    })

    if (data.length > 0) yield data
    else this.showLoading()

    // Pasar el arreglo a objeto usando sus llaves como propiedades
    objData = data.reduce((obj, current) => (obj = { ...obj, [current[idPropertyName]]: current }), {})

    let postObj = {
      app: this.app,
      version: version,
      __action: "LIST"
    }

    try {
      let dataFromLiszt = await this.lisztClient.lisztPost(postObj, this.serviceName, true)

      if (!dataFromLiszt.status || dataFromLiszt.status !== 200) return

      if (dataFromLiszt.version === version) return

      if (dataFromLiszt.recordset.length === 0) return

      for (let i = 0; i < dataFromLiszt.recordset.length; i++) {
        let id = dataFromLiszt.recordset[i][idPropertyName]
        let changeOperation = dataFromLiszt.recordset[i].SYS_CHANGE_OPERATION

        if (changeOperation === "D") {
          try {
            // Eliminar de Pouch
            let document = await pouchDBList.get(id)
            pouchDBList.remove(document)

            // Eliminar de data
            delete objData[id]
          } catch (err) {}
        }

        if (changeOperation === "I" || changeOperation === "U") {
          // Agregar o actualizar data que irá a pouchDB
          bulkData.push({ _id: id, data: dataFromLiszt.recordset[i] })

          // Agregar o actualizar en data
          objData[id] = { ...dataFromLiszt.recordset[i] }
        }
      }

      // Guardar versión
      this._retryUntilWritten({ _id: `${this.controller}-LIST`, ver: dataFromLiszt.version }, versionsDB)

      // Guardar toda la data
      try {
        await pouchDBList.upsertBulk(bulkData)
      } catch (error) {}

      // Convertir objeto a arreglo y asignar a data
      data = Object.keys(objData).reduce((array, key) => (array = [...array, objData[key]]), [])

      // Renderiza online
      yield data
    } catch ({ message }) {
      console.log(message)
    } finally {
      this.hideLoading()
    }
  }

  async *listParamsGenerator(idPropertyName, params) {
    const versionsDB = this.versionsDb
    let objData = {}
    let version = 0
    let data = []
    let bulkData = []
    let key = `${this.controller}-${Buffer.from(JSON.stringify(params)).toString("base64")}-LIST`

    try {
      version = await versionsDB.get(key)
      version = (version && version.ver) || 0
    } catch (error) {}

    const pouchDBList = new PouchDB(key)
    let dataFromPouch = await pouchDBList.allDocs({ include_docs: true })

    data = dataFromPouch.rows.map(el => {
      return { ...el.doc.data }
    })

    if (data.length > 0) yield data
    else this.showLoading()

    // Pasar el arreglo a objeto usando sus llaves como propiedades
    objData = data.reduce((obj, current) => (obj = { ...obj, [current[idPropertyName]]: current }), {})

    let postObj = {
      app: this.app,
      version: version,
      __action: "LIST",
      ...params
    }

    try {
      let dataFromLiszt = await this.lisztClient.lisztPost(postObj, this.serviceName, true)

      if (!dataFromLiszt.status || dataFromLiszt.status !== 200) return

      if (dataFromLiszt.version === version) return

      if (dataFromLiszt.recordset.length === 0) return

      for (let i = 0; i < dataFromLiszt.recordset.length; i++) {
        let id = dataFromLiszt.recordset[i][idPropertyName]
        let changeOperation = dataFromLiszt.recordset[i].SYS_CHANGE_OPERATION

        if (changeOperation === "D") {
          try {
            // Eliminar de Pouch
            let document = await pouchDBList.get(id)
            pouchDBList.remove(document)

            // Eliminar de data
            delete objData[id]
          } catch (err) {}
        }

        if (!changeOperation || changeOperation === "I" || changeOperation === "U") {
          // Agregar o actualizar data que irá a pouchDB
          bulkData.push({ _id: id, data: dataFromLiszt.recordset[i] })

          // Agregar o actualizar en data
          objData[id] = { ...dataFromLiszt.recordset[i] }
        }
      }

      // Guardar versión
      this._retryUntilWritten({ _id: key, ver: dataFromLiszt.version }, versionsDB)

      // Guardar toda la data
      try {
        await pouchDBList.upsertBulk(bulkData)
      } catch (error) {}

      // Convertir objeto a arreglo y asignar a data
      data = Object.keys(objData).reduce((array, key) => (array = [...array, objData[key]]), [])

      // Renderiza online
      yield data
    } catch ({ message }) {
      console.log(message)
    } finally {
      this.hideLoading()
    }
  }

  async *miscGenerator(addData = {}) {
    const pouchDbMisc = this.dbMisc

    const dataFromPouch = await this._getDataOffline(this.controller, pouchDbMisc)
    let version = 0

    if (dataFromPouch) {
      yield dataFromPouch.data
      version = dataFromPouch.version || 0
    }

    let miscObj = {
      app: this.app,
      version: version,
      __action: "MISC",
      ...addData
    }

    try {
      let lisztResponse = await this.lisztClient.lisztPost(miscObj, this.serviceName, true)
      if (!lisztResponse.status || lisztResponse.status !== 200) return

      if (lisztResponse.recordset.length === 0) return

      if (lisztResponse.version === version) return

      const dataLiszt = lisztResponse.recordset

      await this._retryUntilWritten(
        { _id: this.controller, data: dataLiszt, version: lisztResponse.version || version },
        pouchDbMisc
      )

      yield dataLiszt
    } catch ({ message }) {
      console.log(message)
      // return resolve(dataFromPouch.data)
    }
  }

  postAsPromise(data) {
    return new Promise(async (resolve, reject) => {
      this.showLoading()
      let postObj = { __action: "POST", ...data }
      try {
        if (!window.navigator.onLine) {
          this.dbPostPending.put({
            _id: uuidv4(),
            data: postObj,
            serviceName: this.serviceName
          })

          return resolve({ status: 202 })
        }

        console.log("***********************POST****************************")
        console.log(postObj)
        //console.log(JSON.stringify(postObj))

        const dataFromLiszt = await this.lisztClient.lisztPost(postObj, this.serviceName, true)

        if (!dataFromLiszt.status || dataFromLiszt.status !== 200)
          return resolve({ status: dataFromLiszt.status || 400 })

        resolve(dataFromLiszt)
      } catch (err) {
        resolve({ status: 500, err: err.message })
      } finally {
        this.hideLoading()
      }
    })
  }

  deleteAsPromise(data, idName) {
    return new Promise(async (resolve, reject) => {
      const response = { deletedOffline: true, deletedOnline: true, id: data[idName], status: 200 }
      const pouchDbGet = this.dbGet
      const pouchDbList = this.dbList

      const postData = {
        app: this.app,
        __action: "DELETE",
        ...data
      }

      // Online
      try {
        if (!window.navigator.onLine) {
          this.dbPostPending.put({
            _id: uuidv4(),
            data: postData,
            serviceName: this.serviceName
          })

          return resolve(response)
        }

        console.log("**********************DELETE OBJ**************************")
        console.log(postData)

        const lisztDeleteRes = await this.lisztClient.lisztPost(postData, this.serviceName, true)

        if (!lisztDeleteRes.status || lisztDeleteRes.status !== 200) {
          response.status = lisztDeleteRes.status || 400
          response.deletedOnline = false
          response.deletedOffline = false
          return resolve(response)
        }

        // Offline
        try {
          await pouchDbGet.remove(await pouchDbGet.get(data[idName]))
        } catch (error) {
          // Si el error es distinto a 404, significa que existen y no pudieron eliminarse
          if (error.status !== 404) response.deletedOffline = false
        }

        try {
          await pouchDbList.remove(await pouchDbList.get(data[idName]))
        } catch (error) {
          if (error.status !== 404) response.deletedOffline = false
        }

        resolve(response)
      } catch (error) {
        response.deletedOnline = false
        response.status = 500
        resolve(response)
      }
    })
  }

  putAsPromise(data) {
    return new Promise(async (resolve, reject) => {
      let postObj = { __action: "PUT", ...data }
      try {
        if (!window.navigator.onLine) {
          this.dbPostPending.put({
            _id: uuidv4(),
            data: postObj,
            serviceName: this.serviceName
          })

          return resolve({ status: 202 })
        }

        const dataFromLiszt = await this.lisztClient.lisztPost(postObj, this.serviceName, true)
        if (!dataFromLiszt.status || dataFromLiszt.status !== 200)
          return resolve({ status: dataFromLiszt.status || 400 })

        resolve(dataFromLiszt)
      } catch (err) {
        resolve({ status: 500, err: err.message })
        console.error(err)
      }
    })
  }

  /* Auth Functions */
  login(data, encrypt = true) {
    return lisztClient.login(data, encrypt)
  }

  async registerClient(data) {
    return lisztClient.clientRegister(data)
  }

  async _getDataOffline(id, db) {
    const pouchDB = db

    return pouchDB
      .get(id)
      .then(response => {
        return response
      })
      .catch(err => {
        console.log(err)
        return undefined
      })
  }

  async _retryUntilWritten(doc, db) {
    const pouchDB = db

    try {
      const origDoc = await pouchDB.get(doc._id)
      doc._rev = origDoc._rev

      return pouchDB.put(doc)
    } catch (error) {
      if (error.status === 409) return this._retryUntilWritten(doc, pouchDB)
      else return pouchDB.put(doc)
    }
  }

  _advancedFilter(filter, data) {
    var element = data
    var returned = 1

    for (const _filter in filter) {
      if (typeof filter[_filter] === "object") {
        for (const _filterType in filter[_filter]) {
          if (_filterType === "gt") returned *= element[_filter] > filter[_filter]
          if (_filterType === "gte") returned *= element[_filter] >= filter[_filter]
          if (_filterType === "eq") returned *= element[_filter] === filter[_filter]
          if (_filterType === "neq") returned *= element[_filter] !== filter[_filter]
          if (_filterType === "lt") returned *= element[_filter] < filter[_filter]
          if (_filterType === "lte") returned *= element[_filter] <= filter[_filter]
          if (_filterType === "rgex") returned *= new RegExp(filter[_filter]).test(element[_filter])
          if (_filterType === "or") returned *= this._advancedFilter(filter[_filter], data)
        }
      } else {
        returned *= element[_filter] === filter[_filter]
      }
    }

    return returned
  }

  _sortFilterDataHandler(filter, sort, data) {
    let finalData = null

    filter &&
      (finalData = data.filter(element => {
        return this._advancedFilter(filter, element)
      }))
    sort && (finalData = this._sortData(sort, finalData))

    return finalData || data
  }

  _sortData(sort, data) {
    let sortedData = null
    if (sort.direction === "asc") {
      sortedData = data.sort((a, b) => {
        return a[sort.key] - b[sort.key]
      })
    } else {
      sortedData = data.sort((a, b) => {
        return b[sort.key] - a[sort.key]
      })
    }

    return sortedData
  }
}
